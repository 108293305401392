import AgoraRTM from 'agora-rtm-sdk' // Correct import
import { useContext, useEffect, useRef } from 'react'
import { DISPATCH_EVENT_KEYS } from 'src/consumer/constants'
import { ProfileContext } from 'src/consumer/context/ProfileContext'
import { getAgoraSignalingToken } from 'src/consumer/services/featureServices'
import { getAllRolesInBoolean } from 'src/mentor/layout/utils'

const appId = process.env.REACT_APP_AGORA_APP_ID

const AgoraSignallingComponent = () => {
  const { profileData } = useContext(ProfileContext)

  let screenDeviceConfigId = null
  const ROLE_CA_CONFIG_STRING = localStorage.getItem('ROLE_CA_CONFIG')
  if (ROLE_CA_CONFIG_STRING) {
    screenDeviceConfigId = JSON.parse(ROLE_CA_CONFIG_STRING)?.SCREEN_DEVICE_CONFIG_ID
  }

  const clientRef = useRef(null)

  useEffect(() => {
    const roles = getAllRolesInBoolean()
    const shouldInitialize =
      profileData && screenDeviceConfigId && (roles.isClinicAssistant || roles.isChamberScreen)
    if (!shouldInitialize) return

    if (!clientRef.current) {
      console.log('Initializing RTM client with ID:', screenDeviceConfigId)
      clientRef.current = new AgoraRTM.RTM(appId, screenDeviceConfigId)
    }

    const client = clientRef.current

    const messageHandler = (event) => {
      if (!event?.message) {
        return
      }
      try {
        const messageObj = JSON.parse(event?.message)
        const command = messageObj?.command
        if (!command) {
          return
        }
        console.log('COMMAND FROM SINGALING: ' + command)
        const signalingEvent = new CustomEvent(DISPATCH_EVENT_KEYS.signalingEvent, {
          detail: { command: command },
        })
        window.dispatchEvent(signalingEvent)
      } catch (error) {
        console.error('Failed to parse message:', error)
      }
    }

    async function initRTM() {
      try {
        const response = await getAgoraSignalingToken(screenDeviceConfigId)
        if (response?.status === 200) {
          const token = response.data
          if (!token) return

          console.log('Logging in to RTM...')
          await client.login({ token })
          console.log('RTM Login successful')

          client.addEventListener('message', messageHandler)

          client.addEventListener('tokenPrivilegeWillExpire', async () => {
            console.warn('RTM Token will expire soon. Fetching a new one...')
            const refreshResponse = await getAgoraSignalingToken(screenDeviceConfigId)
            if (refreshResponse?.status === 200) {
              const newToken = refreshResponse.data
              await client.renewToken(newToken)
              console.log('RTM Token renewed successfully')
            } else {
              console.error('Failed to renew RTM token')
            }
          })
        }
      } catch (error) {
        console.error('Agora RTM login error:', error)
      }
    }

    initRTM()

    return () => {
      if (clientRef.current) {
        console.log('Cleaning up RTM client...')
        clientRef.current.removeEventListener('message', messageHandler)
        clientRef.current.logout()
      }
    }
  }, [profileData])

  return <></>
}

export default AgoraSignallingComponent

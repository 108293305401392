import React from 'react'
import A4DpiSelect from './A4DpiSelect'
import A4MarginSelection from './A4MarginSelection'
import A4DebugPrintSettings from './A4DebugPrintSettings'
import { Grid } from '@mui/material'

export default function A4PrintSettings({
  printSettings = {},
  setPrintSettings,
  debug,
  clinicBrandId,
  hideMarginSelection = false,
}) {
  let hideMarginThing = false

  if (!debug) {
    if (hideMarginSelection) {
      hideMarginThing = true
    }
  }

  return (
    <Grid container spacing={2}>
      {/* <A4DpiSelect printSettings={printSettings} setPrintSettings={setPrintSettings} /> */}
      {!hideMarginThing && (
        <A4MarginSelection printSettings={printSettings} setPrintSettings={setPrintSettings} />
      )}
      {debug ? (
        <>
          <A4DebugPrintSettings
            printSettings={printSettings}
            setPrintSettings={setPrintSettings}
            clinicBrandId={clinicBrandId}
          />
        </>
      ) : (
        <></>
      )}
    </Grid>
  )
}

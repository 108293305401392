import React from 'react'
import { PMPFooterContainer } from '../../PrescriptionMakingPageStyles'

export default function ListPointersFooter({
  prescriptionData,
  noColor,
  hideQr = true,
  debug = false,
  styleOverrides,
}) {
  return (
    <PMPFooterContainer
      noColor={noColor}
      threeColFooter
      debug={debug}
      style={{
        display: 'flex',
        alignItems: 'flex-center',
        flexDirection: 'column',
        fontSize: `${styleOverrides?.fontSizePixels || 11}px`,
        padding: '5px 10px',
        textAlign: 'left',
      }}
    >
      <div style={{ fontSize: '1.2em', textAlign: 'center' }} className="mb-2">
        {prescriptionData?.footer?.disclaimerText}
      </div>
      <div className="mb-2">{prescriptionData?.footer?.subtext}</div>
      {(prescriptionData?.footer?.footerListItems || [])?.map((listItem, idx) => {
        return (
          <div key={idx} style={{ marginBottom: '2px' }}>
            {listItem}
          </div>
        )
      })}
    </PMPFooterContainer>
  )
}

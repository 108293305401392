import React from 'react'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { PMPBodySectionItems } from './styledComponent'

export default function PrintableTherapyRecommended({
  therapyRecommendedRef,
  debug,
  suggestedBookingTherapy,
  debugHighlightStates,
}) {
  return (
    <PMPBodySectionItems
      ref={therapyRecommendedRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.selectedTherapy.color}
      debugHighlight={debugHighlightStates?.therapyRecommended}
    >
      <strong>Psychotherapy Recommended:</strong> {suggestedBookingTherapy ? 'Yes' : 'No'}
    </PMPBodySectionItems>
  )
}

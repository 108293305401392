import React, { useMemo } from 'react'
import { CustomHTMLSelect, CustomInput } from 'src/consumer/components/helperComponents'
import { A4CustomMarginsContainer, InchesPlaceHolderDivStyles } from '../styledComponents'
import { DocumentScanner } from '@mui/icons-material'
import { Grid } from '@mui/material'
import { genericHandleChange } from 'src/consumer/helpers/utilFunctions'

export default function A4MarginSelection({ printSettings, setPrintSettings }) {
  //   topMargin
  // bottomMargin
  // leftMargin
  // rightMargin

  function handleChange(e) {
    genericHandleChange(e, setPrintSettings)
  }

  return (
    <>
      <div className="d-flex flex-column justify-content-center align-items-center gap-2 w-100">
        <h6>Margin Settings</h6>
        <A4CustomMarginsContainer>
          <div />
          <CustomInput
            id="topMargin"
            value={printSettings?.topMargin}
            type="number"
            onChange={handleChange}
            endIcon={<InchesPlaceHolderDivStyles>in</InchesPlaceHolderDivStyles>}
          />
          <div />
          <CustomInput
            id="leftMargin"
            value={printSettings?.leftMargin}
            type="number"
            onChange={handleChange}
            endIcon={<InchesPlaceHolderDivStyles>in</InchesPlaceHolderDivStyles>}
          />
          <div className="d-flex justify-content-center align-items-center">
            <DocumentScanner sx={{ fontSize: '35px' }} />
          </div>
          <CustomInput
            id="rightMargin"
            value={printSettings?.rightMargin}
            type="number"
            onChange={handleChange}
            endIcon={<InchesPlaceHolderDivStyles>in</InchesPlaceHolderDivStyles>}
          />
          <div />
          <CustomInput
            id="bottomMargin"
            value={printSettings?.bottomMargin}
            type="number"
            onChange={handleChange}
            endIcon={<InchesPlaceHolderDivStyles>in</InchesPlaceHolderDivStyles>}
          />
          <div />
        </A4CustomMarginsContainer>
      </div>
    </>
  )
}

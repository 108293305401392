import { useRef } from 'react'
import { A4PageContainer, A4PageMeasureComponentWrapperContainer } from './styledComponents'

export default function A4PreviewWrapper({
  showPageView,
  a4Width = 0,
  heightOfOnePageInPixels = 0,
  debugMode,
  pageRef,
  children,
  pageNumber = 0,
  pageStartTop = 0,
  margin= 20,
  zIndex = 0,
}) {
  const ref = useRef(null)

  return (
    <>
      {showPageView ? (
        <A4PageContainer margin={margin} divWidth={a4Width} id={`a4-${pageNumber}`}>
          {debugMode ? (
            <div style={{ position: 'absolute', top: `${pageStartTop - 10}px`, right: '0px' }}>
              Page {pageNumber + 1} START 0 Px
            </div>
          ) : (
            <></>
          )}
          <A4PageMeasureComponentWrapperContainer
            ref={pageRef || ref}
            // margin={selectedMargin}
            margin={0}
            heightOfPage={heightOfOnePageInPixels}
            sx={{ zIndex: zIndex }}
          >
            {children}
          </A4PageMeasureComponentWrapperContainer>
        </A4PageContainer>
      ) : (
        children
      )}
    </>
  )
}

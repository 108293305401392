import moment from "moment-timezone"
import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { toast } from "react-toastify"
import { BOOKING_REQUEST_STATUS } from "src/consumer/constants"
import { ProfileContext } from "src/consumer/context/ProfileContext"
import { getBookingRequestsForMentor } from "src/consumer/services/featureServices"
import { isCommonResponseSuccessful } from "src/mentor/helpers/utilityFunctions"

export const BookingRequestNavigationAndToastComponent = () => {
    const { profileData } = useContext(ProfileContext)
    const navigate = useNavigate()
  
    const [data, setData] = useState([])
  
    async function getAllInitiatedBookingRequests() {
      let toReturn = []
  
      if (profileData && profileData?.authorities?.includes('ROLE_TEACHER')) {
        let response = await getBookingRequestsForMentor(
          moment()?.utc()?.subtract(24, 'hours')?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
          true,
        )
  
        if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
          const initiatedRequests = (response?.data?.data?.bookingRequests || []).filter(
            (item) => item?.status === BOOKING_REQUEST_STATUS.BOOKING_INITIATED,
          )
          toReturn = initiatedRequests
        }
        setData(toReturn)
      }
  
      return
    }
  
    function navigateToThePage() {
      if (data?.length > 0) {
        toast.success('You have unanswered Booking Requests')
        navigate('/booking-requests-for-mentors/all')
      }
    }
  
    useEffect(() => {
      getAllInitiatedBookingRequests()
    }, [profileData])
  
    useEffect(() => {
      navigateToThePage()
    }, [data])
  
    return <></>
  }
import { getGenericDrugDataForDrug } from '../../prescriptionHelperFunctions'

const { getCPDataWithParams } = require('src/clinic/services')
const { findDrugsByRelatedName } = require('src/emr/database/indexedDbDao')

async function getRelatedDrugs(drug, clinicId, clinicBrandId) {
  const relatedDrugsFromIdb = await findDrugsByRelatedName(drug?.relatedName) // Search all drugs from IndexedDB
  if (Array.isArray(relatedDrugsFromIdb) && relatedDrugsFromIdb?.length > 0) {
    return [drug, ...relatedDrugsFromIdb?.filter((relatedDrug) => relatedDrug?.id !== drug?.id)]
  }

  if (!drug?.relatedName) {
    return [drug]
  }

  const resp = await getCPDataWithParams('/api/secure/drug/related', {
    'related-name': drug?.relatedName,
    clinicId,
    clinicBrandId,
  })
  if (resp.status === 200) {
    const drugsWithoutTheInputDrug = resp.data?.filter((relDrug) => {
      return relDrug?.id !== drug?.id
    })
    return [drug, ...(drugsWithoutTheInputDrug || [])]
  }

  const data = [drug]

  return data
}

export async function getAndSetDrugWithRelatedNamesAndGenericDrugData(
  doctorSpecializations,
  ddnValue,
  clinicId,
  clinicBrandId,
  setSelectedDrug,
) {
  if (ddnValue?.genericNameId && !ddnValue?.genericName) {
    const genericData = await getGenericDrugDataForDrug(ddnValue?.genericNameId)
    if (genericData?.title) {
      ddnValue = { ...ddnValue, genericName: genericData?.title }
    }
  }

  const emrSettingSwitch = doctorSpecializations?.includes?.('rheumatologist')

  if (!ddnValue || !ddnValue?.id) {
    setSelectedDrug(ddnValue)
    return
  }
  // const response = await getCPDataWithoutParams(`/api/secure/drug/related-names/${ddnValue?.[ddnValue?.length - 1]?.id}`)

  setSelectedDrug(ddnValue)

  if (!emrSettingSwitch) {
    return
  }

  const relatedDrugData = await getRelatedDrugs(ddnValue, clinicId, clinicBrandId)

  const drugName = relatedDrugData
    ?.map((drug) => {
      return drug?.title
    })
    ?.join(' / ')
  setSelectedDrug({ ...ddnValue, label: drugName })
}

export async function getAndSetDrugWithRelatedNamesForPillInput(
  doctorSpecializations,
  ddnValue,
  allDdnValues,
  clinicId,
  clinicBrandId,
  setSelectedDrug,
) {
  const emrSettingSwitch = doctorSpecializations?.includes?.('rheumatologist')

  if (!ddnValue || !ddnValue?.id) {
    setSelectedDrug(allDdnValues)
    return
  }
  // const response = await getCPDataWithoutParams(`/api/secure/drug/related-names/${ddnValue?.[ddnValue?.length - 1]?.id}`)

  setSelectedDrug(allDdnValues)

  if (!emrSettingSwitch) {
    return
  }

  const relatedDrugData = await getRelatedDrugs(ddnValue, clinicId, clinicBrandId)

  const drugName = relatedDrugData
    ?.map((drug) => {
      return drug?.title
    })
    ?.join(' / ')

  setSelectedDrug(
    allDdnValues?.map((item) =>
      item?.id === ddnValue?.id ? { ...ddnValue, label: drugName } : item,
    ),
  )
}

export function getDrugAdviceEntitiesTitleToItemMap({ drugAdviceItems, prescriptionViewSettings }) {
  const drugAdviceItemsMap = {}
  drugAdviceItems?.forEach((item) => {
    const title = getDrugItemOptionValue(item, prescriptionViewSettings)
    drugAdviceItemsMap[title] = item
  })
  return drugAdviceItemsMap
}

export function getDrugItemOptionLabel(item, prescriptionViewSettings) {
  return `${item?.title}${
    prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi') &&
    !!item?.translations?.hi?.title
      ? ` (${item?.translations?.hi?.title})`
      : ''
  }`
}

export function getDrugTypeItemOptionLabel(item, prescriptionViewSettings) {
  return `${item?.shortTitle}${
    prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi') &&
    !!item?.translations?.hi?.shortTitle
      ? ` (${item?.translations?.hi?.shortTitle})`
      : ''
  }`
}

export function getDrugItemOptionValue(item, prescriptionViewSettings) {
  return `${item?.title}${
    prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi') &&
    !!item?.translations?.hi?.title
      ? ` (${item?.translations?.hi?.title})`
      : ''
  }`
}

export function getDrugTypeItemOptionValue(item, prescriptionViewSettings) {
  return `${item?.shortTitle}${
    prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi') &&
    !!item?.translations?.hi?.shortTitle
      ? ` (${item?.translations?.hi?.shortTitle})`
      : ''
  }`
}

export function getRenderDrugAdviceSubSectionsSet(
  subSectionsArray,
  explicitlyRemoveForThisCase = [],
) {
  const subsectionsSet = new Set()

  if (
    Boolean(
      Array.isArray(subSectionsArray) &&
        subSectionsArray.find((item) => item?.uid === 'type') &&
        subSectionsArray.find((item) => item?.uid === 'type')?.selected &&
        !explicitlyRemoveForThisCase.includes('type'),
    )
  ) {
    subsectionsSet.add('type')
  }

  if (
    Boolean(
      Array.isArray(subSectionsArray) &&
        subSectionsArray.find((item) => item?.uid === 'drug_name') &&
        subSectionsArray.find((item) => item?.uid === 'drug_name')?.selected &&
        !explicitlyRemoveForThisCase.includes('drug_name'),
    )
  ) {
    subsectionsSet.add('drug_name')
  }

  if (
    Boolean(
      Array.isArray(subSectionsArray) &&
        subSectionsArray.find((item) => item?.uid === 'dosage') &&
        subSectionsArray.find((item) => item?.uid === 'dosage')?.selected &&
        !explicitlyRemoveForThisCase.includes('dosage'),
    )
  ) {
    subsectionsSet.add('dosage')
  }

  if (
    Boolean(
      Array.isArray(subSectionsArray) &&
        subSectionsArray.find((item) => item?.uid === 'when') &&
        subSectionsArray.find((item) => item?.uid === 'when')?.selected &&
        !explicitlyRemoveForThisCase.includes('when'),
    )
  ) {
    subsectionsSet.add('when')
  }

  if (
    Boolean(
      Array.isArray(subSectionsArray) &&
        subSectionsArray.find((item) => item?.uid === 'frequency') &&
        subSectionsArray.find((item) => item?.uid === 'frequency')?.selected &&
        !explicitlyRemoveForThisCase.includes('frequency'),
    )
  ) {
    subsectionsSet.add('frequency')
  }

  if (
    Boolean(
      Array.isArray(subSectionsArray) &&
        subSectionsArray.find((item) => item?.uid === 'duration') &&
        subSectionsArray.find((item) => item?.uid === 'duration')?.selected &&
        !explicitlyRemoveForThisCase.includes('duration'),
    )
  ) {
    subsectionsSet.add('duration')
  }

  if (
    Boolean(
      Array.isArray(subSectionsArray) &&
        subSectionsArray.find((item) => item?.uid === 'notes') &&
        subSectionsArray.find((item) => item?.uid === 'notes')?.selected &&
        !explicitlyRemoveForThisCase.includes('notes'),
    )
  ) {
    subsectionsSet.add('notes')
  }

  return subsectionsSet
}

export const filterForDrugTypesDropDown = (option, searchText) => {
  if (
    option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
    option.data.value.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true
  }
  if (option.data.title.toLowerCase().includes(searchText.toLowerCase())) {
    return true
  }
  return false
}

export const isCreatingOptionValidForDrugType = (option, searchText) => {
  if (!searchText) {
    return false
  }
  if (
    option.label.toLowerCase().includes(searchText.toLowerCase()) ||
    option.value.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return false
  }
  if (option.title.toLowerCase().includes(searchText.toLowerCase())) {
    return false
  }
  return true
}

export const filterForDosagesDropDown = (option, searchText) => {
  if (
    option.data.label.toLowerCase().includes(searchText.toLowerCase()) ||
    option.data.value.toLowerCase().includes(searchText.toLowerCase())
  ) {
    return true
  }

  // if (option.data.title.toLowerCase().includes(searchText.toLowerCase())) {
  //   return true;
  // }

  // Check the altTitles array if it exists
  if (
    option.data.altTitles &&
    option.data.altTitles.some((altTitle) =>
      altTitle.toLowerCase().includes(searchText.toLowerCase()),
    )
  ) {
    return true
  }

  return false
}

export const isCreateDosageOptionValid = (option, searchText) => {
  if(!searchText){
    console.log("srch text non")
    return false
  }
  if (
    option.label.toLowerCase().includes(searchText.toLowerCase()) ||
    option.value.toLowerCase().includes(searchText.toLowerCase())
  ) {
    console.log("found in label")
    return false
  }
  
  // if (option.data.title.toLowerCase().includes(searchText.toLowerCase())) {
    //   return true;
    // }
    
    // Check the altTitles array if it exists
    if (
      option.altTitles &&
      option.altTitles.some((altTitle) => altTitle.toLowerCase().includes(searchText.toLowerCase()))
    ) {
      console.log("found in altTitle")
      return false
    }
  return true
}

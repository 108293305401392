import React from 'react'
import { PMPBodySectionItems } from './styledComponent'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import PrescriptionKVItem from '../../../prescriptionTemplate/common/PrescriptionKVItem'

export default function PrintableReferredToDoctorName({
  debug,
  debugHighlightStates,
  referredToDoctorNameRef,
  referredToDoctorName,
  prescriptionData,
  styleOverrides,
}) {
  return (
    <>
      {!!referredToDoctorName ? (
        <PMPBodySectionItems
          ref={referredToDoctorNameRef}
          debug={debug}
          debugColor={PRESCRIPTION_COLORS_MAP.referredToDoctorName?.color}
          debugHighlight={debugHighlightStates?.instructions}
        >
          <PrescriptionKVItem itemKey="Referred To" value={referredToDoctorName} />
        </PMPBodySectionItems>
      ) : (
        <></>
      )}
    </>
  )
}

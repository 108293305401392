import axios from 'axios'
import moment from 'moment'
import { toast } from 'react-toastify'
import { apiURL, appVersion, commonHeadersWeb, platformWeb, publicApiURL } from 'src/consumer/services'
import {
  isCommonResponseSuccessful,
  isCommonResponseSuccessfulV2,
} from 'src/mentor/helpers/utilityFunctions'
import { getDataWithParams } from './profileService'
import { getCPDataWithoutParams } from 'src/clinic/services'

export const makeQuoteFavorite = async (id) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.put(`${apiURL}/api/secure/Quote/${id}/favorite`, null, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const makeQuoteUnfavorite = async (id) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.delete(`${apiURL}/api/secure/Quote/${id}/unfavorite`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return response
  } catch (error) {
    console.log(error)
  }
}

export const getArticleById = async (articleId) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/article/${articleId}?languageCode=${sessionStorage.getItem(
        'languageCode',
      )}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const makeCourseFavorite = async (id, toBeMadeFavorite) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.put(
      `${apiURL}/api/secure/course/${id}/${toBeMadeFavorite ? 'favorite' : 'unfavorite'}`,
      {},
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export const saveJournal = async (id, body, callsource = 'default', callsourceid = 'default') => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.post(
      `${apiURL}/api/secure/journals/${id}/ans?src=${callsource}&src-id=${callsourceid}`,
      body,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const saveAssessment = async (
  id,
  body,
  callsource = 'default',
  callsourceid = 'default',
) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.post(
      `${apiURL}/api/secure/assessment/${id}/ans?src=${callsource}&src-id=${callsourceid}`,
      body,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export const getJournalBasedRecommendation = async (id) => {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(`${apiURL}/api/secure/journals/${id}/recommend`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

export const deleteJournalEntry = async (journalEntryId) => {
  const token = localStorage.getItem('token')
  const data = {}

  try {
    const response = await axios.put(
      `${apiURL}/api/secure/journals-entry/${journalEntryId}/delete`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
    return error
  }
}

export async function getDataWithoutParams(url) {
  const token = localStorage.getItem('token')
  try {
    const res = await axios.get(`${apiURL}${url}`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function postDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.post(`${apiURL}${url}`, data, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function putDataWithoutParams(url, data) {
  const token = localStorage.getItem('token')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.put(`${apiURL}${url}`, data, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function putDataWithParams(url, data, params = {}) {
  const token = localStorage.getItem('token')
  try {
    // const os = `${platform.os.family}`.toLowerCase()
    const res = await axios.put(`${apiURL}${url}`, data, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
      params: params,
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getAgoraToken(eventId, asHost = false) {
  const token = localStorage.getItem('token')
  const languageCode = sessionStorage.getItem('languageCode')

  if (asHost) {
    try {
      const res = await axios.get(
        `${apiURL}/api/secure/teacher/token/videocall?languageCode=${languageCode}&platform=${platformWeb}&eventId=${eventId}`,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  } else {
    try {
      const res = await axios.get(
        `${apiURL}/api/secure/live/token/videocall?languageCode=${languageCode}&platform=${platformWeb}&eventId=${eventId}`,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

export async function getAgoraChatToken() {
  const token = localStorage.getItem('token')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.get(`${apiURL}/api/secure/user-chat/token/user`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getAgoraSignalingToken(screenDeviceConfigId) {
  const token = localStorage.getItem('token')
  if (!screenDeviceConfigId) {
    return
  }
  try {
    const res = await axios.get(
      `${apiURL}/api/secure/signaling/token/user-device?deviceId=${screenDeviceConfigId}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function sendShowImageCommandToChamberScreen(chamberRoomId, imageType) {
  if (!(chamberRoomId && imageType)) {
    return
  }
  const token = localStorage.getItem('token')

  try {
    const res = await axios.post(
      `${apiURL}/api/secure/teacher/control/checked-in-screen/show-temp-image?chamberRoomId=${chamberRoomId}&imageType=${imageType}`,
      {},
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    if (res?.status === 200 && isCommonResponseSuccessfulV2(res)) {
      toast.success('Request successful')
    } else {
      toast.error('Request failed')
    }
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function askCheckedInChamberScreenToPollNextAction(chamberRoomId) {
  if (!chamberRoomId) {
    return
  }
  const token = localStorage.getItem('token')

  try {
    const res = await axios.post(
      `${apiURL}/api/secure/teacher/control/checked-in-screen/ask-to-poll-next-action?chamberRoomId=${chamberRoomId}`,
      {},
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    if (res?.status === 200 && isCommonResponseSuccessfulV2(res)) {
      toast.success('Attempted Fast Track Connect')
    } else {
      toast.error('Error fast tracking connection')
    }
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function sendAgoraChatMessage(data) {
  const token = localStorage.getItem('token')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.post(
      `${apiURL}/api/secure/user-chat/send/message?messageType=text`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function sendAgoraCustomMessage(data) {
  const token = localStorage.getItem('token')
  const languageCode = sessionStorage.getItem('languageCode')
  try {
    const res = await axios.post(
      `${apiURL}/api/secure/user-chat/send/message?messageType=custom`,
      data,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getConversationWithThisUser(userId, page) {
  const token = localStorage.getItem('token')
  if (token) {
    try {
      const res = await axios.get(
        `${apiURL}/api/secure/user-chat/get/conversation?withUserId=${userId}&page=${page}&pageSize=20`,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

export async function getHotlineToken(eventId, forMentor = true, callType = 'booked-event') {
  const token = localStorage.getItem('token')
  const languageCode = sessionStorage.getItem('languageCode')

  if (forMentor) {
    try {
      const res = await axios.get(
        `${apiURL}/api/secure/teacher/token/videocall?languageCode=${languageCode}&platform=${platformWeb}&eventId=${eventId}&callType=${callType}`,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  } else {
    try {
      const res = await axios.get(
        `${apiURL}/api/secure/clinic-assistant/token/videocall?languageCode=${languageCode}&platform=${platformWeb}&eventId=${eventId}`,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

export async function startAgoraSessionRecording(eventId, uidRecording) {
  try {
    const res = await axios.get(
      `https://localexpressserver-production.up.railway.app/rte/recording/${eventId}/${uidRecording}/start`,
      {
        headers: {
          //     ...(token && { Authorization: `Bearer ${token}` }),
          //     ...commonHeadersWeb,
          //     'ef-user-country-code': localStorage.getItem('countryCode'),
          //     'ef-app-language': sessionStorage.getItem('languageCode')
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function queryAgoraSessionRecordingStatus(resourceId, sid) {
  try {
    const res = await axios.get(
      `https://localexpressserver-production.up.railway.app/rte/recording/resource/${resourceId}/sid/${sid}/query`,
      {
        headers: {
          //     ...(token && { Authorization: `Bearer ${token}` }),
          //     ...commonHeadersWeb,
          //     'ef-user-country-code': localStorage.getItem('countryCode'),
          //     'ef-app-language': sessionStorage.getItem('languageCode')
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function stopAgoraSessionRecording(eventId, uidRecording, resourceId, sid) {
  try {
    const res = await axios.get(
      `https://localexpressserver-production.up.railway.app/rte/recording/${uidRecording}/${eventId}/resource/${resourceId}/sid/${sid}/stop`,
      {
        headers: {
          //     ...(token && { Authorization: `Bearer ${token}` }),
          //     ...commonHeadersWeb,
          //     'ef-user-country-code': localStorage.getItem('countryCode'),
          //     'ef-app-language': sessionStorage.getItem('languageCode')
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getBookedSessionsForUser(page = 1) {
  const token = localStorage.getItem('token')
  if (token) {
    try {
      const res = await axios.get(
        `${apiURL}/api/secure/user/booked-slots?page=${page}&pageSize=10`,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

export async function getBookedSessionsForMentor(page = 1) {
  const token = localStorage.getItem('token')
  if (token) {
    try {
      const res = await axios.get(
        `${apiURL}/api/secure/teacher/booked-slots/all/paginated?page=${page}&pageSize=10`,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

export async function getAllCoursesCategoryItemsForPublic(
  page = 1,
  categoriesString,
  minDuration,
  maxDuration,
  courseType,
  type,
  tagsString,
  technique,
) {
  console.log(courseType, type)
  const queryParams = []
  queryParams.push(`page=${page}`)
  queryParams.push(`pageSize=10`)
  if (categoriesString) queryParams.push(`categories=${categoriesString}`)
  if (minDuration) queryParams.push(`minDuration=${minDuration}`)
  if (maxDuration) queryParams.push(`maxDuration=${maxDuration}`)
  if (courseType) queryParams.push(`courseType=${courseType}`)
  if (type) queryParams.push(`type=${type}`)
  if (tagsString) queryParams.push(`tags=${tagsString}`)
  if (technique) queryParams.push(`techniqueId=${technique}`)

  // Append query parameters to the URL
  let urlAdditionForQuery = ''
  if (queryParams.length > 0) {
    urlAdditionForQuery += `?${queryParams.join('&')}`
  }

  try {
    const res = await axios.get(`${apiURL}/api/external/search/course/list${urlAdditionForQuery}`, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getAllBreatheForPublic(page = 1) {
  try {
    const res = await axios.get(`${publicApiURL}/external/prana-vayu/list?page=${page}&pageSize=10`, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

// remove intent
export async function getAllTechniqueForPublic(page = 1) {
  try {
    const res = await axios.get(`${publicApiURL}/api/external/technique/list?page=${page}&pageSize=10`, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getPaginatedDataWithAPIString(
  apiString,
  alreadyHasQuestionMark = false,
  page = 1,
  pageSize = 10,
) {
  try {
    const res = await axios.get(
      `${apiURL}${apiString}${
        alreadyHasQuestionMark
          ? `&page=${page}&pageSize=${pageSize}`
          : `?page=${page}&pageSize=${pageSize}`
      }`,
      {
        headers: {
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

// export async function getAssessmentData(string, isSlug = true) {
//   let conditionalApiText
//   if (isSlug) {
//     conditionalApiText = `${apiURL}/api/secure/feature?feature=assessment&slug=${string}`
//   } else {
//     conditionalApiText = `${apiURL}/api/secure/feature?feature=assessment&id=${string}`
//   }
//   const token = localStorage.getItem('token')
//   const headers = {
//     ...commonHeadersWeb,
//     'ef-user-country-code': localStorage.getItem('countryCode'),
//     'ef-app-language': sessionStorage.getItem('languageCode'),
//   }
//   if (token) {
//     headers['Authorization'] = `Bearer ${token}`
//   }
//   try {
//     const res = await axios.get(conditionalApiText, {
//       headers: headers,
//     })
//     return res
//   } catch (err) {
//     console.log(err)
//     return err
//   }
// }

export async function getQuoteData(string, isSlug = true) {
  let conditionalApiText
  if (isSlug) {
    conditionalApiText = `${apiURL}/api/quote?slug=${string}`
  } else {
    conditionalApiText = `${apiURL}/api/quote?id=${string}`
  }

  try {
    const res = await axios.get(conditionalApiText, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getCourseData(courseId) {
  const token = localStorage.getItem('token')

  try {
    const res = await axios.get(
      `${apiURL}/api/secure/v2/explore/courses/${courseId}?languageCode=${sessionStorage.getItem(
        'languageCode',
      )}&appVersion=${appVersion}&platform=${platformWeb}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getMentalStateType(id) {
  try {
    const res = await axios.get(`${apiURL}/api/mental-state-type?id=${id}`, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getJournalDataForUserBySlug(text, isSlug = true) {
  const languageCode = sessionStorage.getItem('languageCode')
  let apiParamText
  if (isSlug) {
    apiParamText = `slug=${text}&languageCode=${languageCode}`
  } else {
    apiParamText = `id=${text}&languageCode=${languageCode}`
  }
  const token = localStorage.getItem('token')
  const headers = {
    ...commonHeadersWeb,
    'ef-user-country-code': localStorage.getItem('countryCode'),
    'ef-app-language': sessionStorage.getItem('languageCode'),
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  try {
    const res = await axios.get(`${apiURL}/api/secure/feature?feature=journal&${apiParamText}`, {
      headers: headers,
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export const prepareDataForPlan = (days, minutes, timeString, profileData) => {
  let studentPersonalizeObject = profileData.personalize.studentPersonalize
  const localTimezoneOffsetInMinutes = moment().utcOffset()

  return {
    ...studentPersonalizeObject,
    preferences: {
      convenientTime: {
        // "location": {
        //     "address": "",
        //     "city": "",
        //     "country": "",
        //     "countryCode": "",
        //     "id": "",
        //     "lat": 0,
        //     "lng": 0,
        //     "pincode": "",
        //     "resolvedBy": "",
        //     "state": "",
        //     "stateCode": ""
        // },
        time: moment(timeString)
          .subtract(localTimezoneOffsetInMinutes, 'minutes')
          .format('YYYY-MM-DD HH:mm:ss'), //instant
        // "type": ""
      },
      planDuration: days, //days
      sessionTime: minutes, // minutes
      withMentor: false,
    },
  }
}

export function getBodyForSavingNotes(bookedEventId, content) {
  return {
    bookedEventId: bookedEventId,
    // "assessmentReports": [
    //     "string"
    // ],
    content: content,
    // "prescriptionId": "string",
    // "id": "string",
  }
}

export const saveNotes = async (role, body) => {
  const token = localStorage.getItem('token')

  try {
    if (role === 'user') {
      const response = await axios.post(`${apiURL}/api/secure/live/user-notes/save`, body, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      })
      return response
    } else if (role === 'panelist') {
      const response = await axios.post(
        `${apiURL}/api/secure/teacher/panelist-1on1-notes/save`,
        body,
        {
          headers: {
            ...(token && { Authorization: `Bearer ${token}` }),
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': sessionStorage.getItem('languageCode'),
          },
        },
      )
      return response
    } else if (role === 'mentor') {
      const response = await axios.post(`${apiURL}/api/secure/teacher/1on1-notes/save`, body, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      })
      return response
    }
    return 'provide valid role'
  } catch (e) {
    console.log(e)
  }
}

export async function getNotesOfBookedEvent(bookedEventId, role) {
  const token = localStorage.getItem('token')
  let apiString
  switch (role) {
    case 'mentor':
      apiString = '/api/secure/teacher/1on1-notes/get'
      break
    case 'panelist':
      apiString = '/api/secure/teacher/panelist-1on1-notes/get'
      break
    case 'user':
      apiString = '/api/secure/live/user-notes/get'
      break
    default:
      break
  }

  try {
    const res = await axios.get(`${apiURL}${apiString}?eventId=${bookedEventId}`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getUserPrescriptionOfEvent(bookedEventId) {
  const token = localStorage.getItem('token')

  try {
    const res = await axios.get(
      `${apiURL}/api/secure/patient-user/prescription/get-by-eventId?eventId=${bookedEventId}&send-auxiliary-data=true`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getPrescriptionOfEventAsClinicAssistant(bookedEventId) {
  const token = localStorage.getItem('token')

  try {
    const res = await axios.get(
      `${apiURL}/api/secure/clinic-assistant/prescription/get-by-eventId?eventId=${bookedEventId}&send-auxiliary-data=true`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export const markJoinOrLeaveSession = async (bookedEventId, action, role) => {
  // action will be either "JOIN" or "LEAVE"
  // role will be either "endUser", "panelist", "mentor"
  const token = localStorage.getItem('token')

  try {
    const response = await axios.post(
      `${apiURL}/api/secure/live/booked-event/log?bookedEventId=${bookedEventId}&action=${action}&role=${role}`,
      {},
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

// serves assessment, journal, activity-plan, mentor, article, technique BUT NOT course
export async function getFeatureList(apiParam, page = 1, externalParams = {}) {
  const token = localStorage.getItem('token')
  const headers = {
    ...commonHeadersWeb,
    'ef-user-country-code': localStorage.getItem('countryCode'),
    'ef-app-language': sessionStorage.getItem('languageCode'),
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  if (token) {
    try {
      const res = await axios.get(`${apiURL}/api/external/feature/list`, {
        headers: {
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
        params: {
          feature: apiParam,
          page: page,
          pageSize: 10,
          ...externalParams,
        },
      })
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  } else {
    try {
      const res = await axios.get(`${apiURL}/api/external/feature/list`, {
        headers: {
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
        params: {
          feature: apiParam,
          page: page,
          pageSize: 10,
          ...externalParams,
        },
      })
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

// serves journal, assessment, mentor, course BUT NOT actvity-plan, article
export async function getFeatureItemBySlug(apiParam, text, isSlug = true, externalParams = {}) {
  const token = localStorage.getItem('token')
  const languageCode = sessionStorage.getItem('languageCode')
  let apiParamText = {}
  if (isSlug) {
    apiParamText = { slug: text }
  } else {
    apiParamText = { id: text }
  }
  const headers = {
    ...commonHeadersWeb,
    'ef-user-country-code': localStorage.getItem('countryCode'),
    'ef-app-language': sessionStorage.getItem('languageCode'),
  }
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  if (token) {
    try {
      const res = await axios.get(`${apiURL}/api/secure/feature`, {
        headers: headers,
        params: {
          feature: apiParam,
          ...apiParamText,
          languageCode: languageCode,
          ...externalParams,
        },
      })
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  } else {
    try {
      const res = await axios.get(`${apiURL}/api/feature`, {
        headers: headers,
        params: {
          feature: apiParam,
          ...apiParamText,
          languageCode: languageCode,
          ...externalParams,
        },
      })
      return res
    } catch (err) {
      console.log(err)
      return err
    }
  }
}

// to remove
export async function getAssessmentData(string, isSlug = true) {
  try {
    const res = await getFeatureItemBySlug('assessment', string, isSlug)
    if (res.status === 200 && isCommonResponseSuccessful(res.data.code)) {
      return { status: 200, data: res.data.data.assessment }
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

// to remove
export async function getJournalDataBySlug(text, isSlug = true) {
  try {
    const res = await getFeatureItemBySlug('journal', text, isSlug)
    if (res.status === 200 && isCommonResponseSuccessful(res.data.code)) {
      return { status: 200, data: res.data.data.journal }
    }
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getRestDataById(text, externalParams = {}, mentorIds) {
  const resp = await getDataWithParams(`/api/secure/teacher/search/rest`, {
    ids: text,
    mentorIds: mentorIds,
  })
  if (resp.status === 200) {
    const foundRest = resp.data?.content?.find((rest) => rest.id === text)
    return { status: 200, data: foundRest }
  } else {
    toast.error('Something went wrong')
  }
}

export async function getMentalStateTypeById(text, externalParams = {}) {
  const resp = await getDataWithParams('/api/mental-state-type', { id: text })
  if (resp.status === 200) {
    return resp
  } else {
    toast.error('Something went wrong.')
  }
}

export async function getMentalStateTypeList(page = 1) {
  const resp = await getDataWithParams('/api/external/mental-state-type/list', { page })
  if (resp.status === 200) {
    return resp
  } else {
    toast.error('Something went wrong.')
  }
}

export async function getArticleData(string, isSlug = true) {
  const languageCode = sessionStorage.getItem('languageCode')

  try {
    let res
    if (isSlug) {
      res = await axios.get(
        `${apiURL}/api/article?id=&slug=${string}&languageCode=${languageCode}`,
        {
          headers: {
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': languageCode,
          },
        },
      )
    } else {
      res = await axios.get(
        `${apiURL}/api/article?id=${string}&slug=&languageCode=${languageCode}`,
        {
          headers: {
            ...commonHeadersWeb,
            'ef-user-country-code': localStorage.getItem('countryCode'),
            'ef-app-language': languageCode,
          },
        },
      )
    }
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export async function getPopularAndMyTimersFromServer() {
  // const response = await getDataWithParams('/api/secure/timer/all-including-my-private/list')
  const response = await getDataWithParams('/api/secure/timer/all-including-my-private/list')
  if (response.status === 200) {
    return response.data
  } else {
    return []
  }
}
export async function getMyTimersOnlyFromServer() {
  // const response = await getDataWithParams('/api/secure/timer/all-including-my-private/list')
  const response = await getDataWithParams('/api/secure/user/timers')
  if (response.status === 200) {
    return response.data
  } else {
    return []
  }
}

// BOOKING REQUEST RELATED - CLINIC ASSISTANT SIDE
export async function saveBookingRequest(params, body) {
  const token = localStorage.getItem('token')

  const eventType = params?.eventType
  const mode = params?.mode
  const clinicBrandId = params?.clinicBrandId
  const clinicLocationId = params?.clinicLocationId

  try {
    const response = await axios.post(
      `${apiURL}/api/secure/clinic-assistant/booking-request/new?eventType=${eventType}&mode=${mode}&clinicBrandId=${clinicBrandId}&clinicLocationId=${clinicLocationId}`,
      body,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export async function getBookingRequestsForAssistant(startTime) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/clinic-assistant/booking-request/all?startTime=${startTime}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function cancelBookingRequestFuncForAssistant(bookingRequestId) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/clinic-assistant/booking-request/cancel?bookingRequestId=${bookingRequestId}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

// BOOKING REQUEST RELATED - MENTOR SIDE

export async function informBookingRequestReceivedForMentor(
  bookingRequestId,
  commType,
  commProvider,
) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.put(
      `${apiURL}/api/secure/teacher/booking-request/log/receive?bookingRequestId=${bookingRequestId}&commType=${commType}&commProvider=${commProvider}`,
      {},
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (e) {
    console.log(e)
  }
}

export async function getBookingRequestsForMentor(startTime, showAll) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/teacher/booking-request/all?startTime=${startTime}&sendIncludingUnavailable=${showAll}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function rejectBookingRequestFuncForMentor(bookingRequestId) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/teacher/booking-request/reject?bookingRequestId=${bookingRequestId}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function acceptBookingRequestFuncForMentor(bookingRequestId) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/teacher/booking-request/accept?bookingRequestId=${bookingRequestId}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

//// CHAMBER ROOM RELATED

export async function getBookedSessionsInSelectedChamberRoomsBwUtcTime(
  chamberRoomsArray,
  selectedDateUtcStartTime,
  selectedDateUtcEndTime,
) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/clinic-assistant/chamber-room/booked-event/list?chamberRoomsIds=${chamberRoomsArray?.join(
        ',',
      )}&startTime=${selectedDateUtcStartTime}&endTime=${selectedDateUtcEndTime}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function getBookingRequestsInSelectedChamberRoomsBwUtcTime(
  chamberRoomsArray,
  selectedDateUtcStartTime,
  selectedDateUtcEndTime,
) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/clinic-assistant/chamber-room/booking-request/list?chamberRoomsIds=${chamberRoomsArray?.join(
        ',',
      )}&startTime=${selectedDateUtcStartTime}&endTime=${selectedDateUtcEndTime}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function sendDaySummaryToDoctor(selectedDateUtcStartTime, selectedDateUtcEndTime) {
  const token = localStorage.getItem('token')

  try {
    const response = await axios.get(
      `${apiURL}/api/secure/teacher/day-summary?startTime=${selectedDateUtcStartTime}&endTime=${selectedDateUtcEndTime}`,
      {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode'),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      },
    )
    return response
  } catch (error) {
    console.log(error)
  }
}

export async function cancelBookedSession(
  cancelBookedSessionApiUrl = '/api/secure/teacher/booked-slot/cancel',
  eventId,
) {
  const token = localStorage.getItem('token')
  const languageCode = sessionStorage.getItem('languageCode')

  try {
    const res = await axios.get(`${apiURL}${cancelBookedSessionApiUrl}?bookedEventId=${eventId}`, {
      headers: {
        ...(token && { Authorization: `Bearer ${token}` }),
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

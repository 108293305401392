import dayjs from 'dayjs'
import { capitalize } from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'
import { GENDER_LIST_ONBOARDING, currency_list, defaultBgImgId } from 'src/consumer/constants'
import { isValidNumber } from 'src/consumer/helpers/utilFunctions'
import { UNSPLASH_API, appVersion, mediaURL, platformWeb } from 'src/consumer/services'
import { getMPDataWithoutParams, postMPDataWithoutParams } from '../services'
import store, { resetStores } from 'src/store'

export const isValidHttpUrl = (string) => {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export const logoutHandlerMentor = () => {
  let lanCode = sessionStorage.getItem('languageCode')
  sessionStorage.clear()
  localStorage.removeItem('token')
  localStorage.removeItem('authorities')
  localStorage.removeItem('FCMToken')
  localStorage.removeItem('submittedFCMTokenForThisUser')
  sessionStorage.setItem(
    'languageCode',
    lanCode ? lanCode : navigator?.language || navigator?.userLanguage.split('-')[0],
  )
  store.dispatch(resetStores())
}

export const getImageLink = (priorityImage, secondaryImage, returnFallBack = true) => {
  let image
  if (priorityImage) {
    if (isValidHttpUrl(priorityImage)) {
      image = priorityImage
    } else {
      image = `${mediaURL}${priorityImage}`
    }
  } else if (secondaryImage) {
    if (isValidHttpUrl(secondaryImage)) {
      image = secondaryImage
    } else {
      image = `${mediaURL}${secondaryImage}`
    }
  } else if (returnFallBack) {
    image = `${mediaURL}${defaultBgImgId}`
  }

  return image
}

export const getImageLinkWithUnsplash = async (
  priorityImage,
  secondaryImage,
  { useUnsplashImage = false, unSplashParamsOverride = {} },
) => {
  let image = getImageLink(priorityImage, secondaryImage, false)
  if (useUnsplashImage && !isValidHttpUrl(image)) {
    image = await UNSPLASH_API.photos
      .getRandom({
        query: 'nature',
        orientation: 'landscape',
        topicIds: ['nature', 'lakes'],
        contentFilter: 'high',
        ...unSplashParamsOverride,
      })
      .then((result) => {
        if (result.status === 200) {
          return result.response?.urls?.regular
        } else {
          return getImageLink(priorityImage, secondaryImage)
        }
      })
      .catch((err) => {
        console.log(err)
        return getImageLink(priorityImage, secondaryImage)
      })
  }
  return image
}

export const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

export const getHoursAndMinutesFromMinutes = (minutes) => {
  const duration = moment.duration(minutes, 'minutes')
  const hours = duration.hours()
  const mins = duration.minutes()

  if (hours === 0) {
    return `${mins} mins`
  } else if (mins === 0) {
    return `${hours} hrs`
  } else {
    return `${hours} hrs ${mins} mins`
  }
}

export function isBasicOnboardingDone(profileData) {
  const arrayOfGenderIds = GENDER_LIST_ONBOARDING?.map((item, index) => {
    return item?.value
  })

  if (
    profileData?.personalize?.studentPersonalize?.contentLanguages?.length === 0 ||
    !Boolean(profileData?.dob) ||
    !Boolean(profileData?.name) ||
    !Boolean(arrayOfGenderIds?.includes(profileData?.gender))
  ) {
    return false
  }
  return true
}

export function isHtmlEmpty(htmlString) {
  // Remove HTML tags and extra spaces
  const textContent = htmlString.replace(/<[^>]*>/g, '').trim()

  // Check if the remaining text content is empty
  return textContent === ''
}

export function linkifyTheText(text) {
  if (text) {
    // Regular expression to match URLs
    var urlRegex = /(https?:\/\/[^\s]+)/g
    // Replace URLs with anchor tags
    var replacedText = text.replaceAll(urlRegex, function (url) {
      return '<a href="' + url + '" target="_blank">' + url + '</a>'
    })
    return replacedText
  }
}

export function boldifyAsteriskText(text) {
  if (text) {
    // Regular expression to match text enclosed within asterisks
    const boldRegex = /\*([^*]+)\*/g

    // Replace matched text with bolded HTML tags
    const replacedText = text.replace(boldRegex, '<b>$1</b>')

    return replacedText
  }
}

export async function markBookedSessionAsCompletedFunction(bookedEventData, callBack) {
  const response = await postMPDataWithoutParams(
    `/api/secure/teacher/booked-event/finish?bookedEventId=${bookedEventData?.id}`,
    {},
  )
  if (response?.status === 200) {
    toast.success('Session marked completed')
  }
  if (callBack) {
    callBack()
  }
  return response
}

export function getEventsNotInAnySchedule(data) {
  if (Array.isArray(data?.eventTypes) && data.eventTypes.length > 0) {
    const filteredEvents = data?.eventTypes?.filter((eventTypeBeingChecked) => {
      return !Boolean(
        data?.schedules?.some((schedule) =>
          schedule?.eventIds?.includes(eventTypeBeingChecked?.uid),
        ),
      )
    })
    return filteredEvents
  } else {
    return []
  }
}

export function getEventsArrayFromEventIdsArray(eventIdsArray, data) {
  const arrayToReturn = []
  if (Array.isArray(eventIdsArray) && eventIdsArray.length > 0) {
    eventIdsArray?.forEach((eventId) => {
      const foundEventTypeData = data?.eventTypes?.find((eventType) => eventType?.uid === eventId)
      if (foundEventTypeData) {
        arrayToReturn.push(foundEventTypeData)
      }
    })
  }
  return arrayToReturn
}

export async function getSubscriptionPlanById(planId) {
  const response = await getMPDataWithoutParams(
    `/api/subscription-plan?id=${planId}&zoneOffset=${encodeURIComponent(
      moment().format('Z'),
    )}&appVersion=${appVersion}&platformType=${platformWeb}&languageCode=${sessionStorage.getItem(
      'languageCode',
    )}&platform=${platformWeb}&countryCode=${localStorage.getItem('countryCode')}`,
  )
  return response
}

export function getDefaultScheduleFromSchedules(schedules) {
  let toReturn
  toReturn = schedules?.find((schedule) => schedule?.defaultSchedule)
  if (!Boolean(toReturn)) {
    toReturn = schedules?.[0]
  }
  return toReturn
}

export function isCommonResponseSuccessful(codeReceived) {
  if (codeReceived === 200 || codeReceived === 2000 || codeReceived === 0) {
    return true
  }
  return false
}

export function isCommonResponseSuccessfulV2(resp) {
  const code = resp?.data?.code
  return isCommonResponseSuccessful(code)
}

export function getDataMapFromCommonResponse(resp) {
  return resp?.data?.data
}

export function getMessageMapFromCommonResponse(resp) {
  return resp?.data?.message
}

export function getClinicBrandIdFromClinicId(clinicsDataArray, clinicId) {
  const thisClinic = clinicsDataArray.find((item) => item.id === clinicId)
  if (thisClinic.clinicBrandId) {
    return thisClinic.clinicBrandId
  }
  return ''
}

export function getStringFromHTMLString(htmlString) {
  const tmpElement = document.createElement('div')
  tmpElement.innerHTML = htmlString
  return tmpElement.textContent || tmpElement.innerText || ''
}

// export function MultipleInheritance(bases) {
//   class Bases {
//     constructor() {
//       bases.forEach((base) => Object.assign(this, new base()))
//     }
//   }
//   bases.forEach((base) => {
//     Object.getOwnPropertyNames(base.prototype)
//       .filter((prop) => prop != 'constructor')
//       .forEach((prop) => (Bases.prototype[prop] = base.prototype[prop]))
//   })
//   return Bases
// }

export function MultipleInheritance(bases) {
  // Define a new class that extends from the provided base classes
  class Combined extends bases[0] {
    constructor(...args) {
      super(...args) // Call the constructor of the first base class
      // Call constructors of other base classes and inherit their properties
      for (let i = 1; i < bases.length; i++) {
        const base = bases[i]
        new base(...args)
      }
    }
  }

  // Inherit prototype properties and methods from all base classes
  bases.forEach((base) => {
    Object.getOwnPropertyNames(base.prototype)
      .filter((prop) => prop !== 'constructor')
      .forEach((prop) => {
        Combined.prototype[prop] = base.prototype[prop]
      })
  })

  return Combined
}

export function getInitialsForChat(name) {
  const givenName = name || 'User'
  const nameArray = givenName.split(' ')
  let str = ''
  nameArray?.forEach((word) => {
    str = str + word[0]
  })
  return str
}

export function maskNameForClinicCounter(name) {
  let toReturn = ''
  if (name) {
    const nameParts = name.split(' ')
    nameParts.forEach((word) => {
      if (word.length > 2) {
        toReturn += word.substring(0, 2) + '*'.repeat(word.length - 2) + ' '
      } else {
        toReturn += word + ' '
      }
    })
  }
  return toReturn
}

export function getPriceStringWithCurrency(price, currencyCode, defaultToINR = true) {
  let currPrice = price
  if (!isValidNumber(currPrice)) {
    currPrice = Number(currPrice)
  }
  let currencyData = currency_list?.find((curr) => {
    return curr?.code === currencyCode
  })

  if (defaultToINR && !currencyData?.symbol) {
    currencyData = currency_list?.find((curr) => {
      return curr?.code === 'inr'
    })
  }

  const formattedPrice = new Intl.NumberFormat('en-IN').format(currPrice)

  return `${currencyData?.symbol}${formattedPrice}`
}

function numberToWords(num, lang = 'en') {
  if (num === 0) return lang === 'en' ? 'zero' : 'शून्य'

  const belowTwenty = {
    en: [
      '',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen',
    ],
    hi: [
      '',
      'एक',
      'दो',
      'तीन',
      'चार',
      'पाँच',
      'छह',
      'सात',
      'आठ',
      'नौ',
      'दस',
      'ग्यारह',
      'बारह',
      'तेरह',
      'चौदह',
      'पंद्रह',
      'सोलह',
      'सत्रह',
      'अठारह',
      'उन्नीस',
    ],
  }

  const tens = {
    en: ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'],
    hi: ['', '', 'बीस', 'तीस', 'चालीस', 'पचास', 'साठ', 'सत्तर', 'अस्सी', 'नब्बे'],
  }

  const thousands = {
    en: ['', 'thousand', 'million', 'billion', 'trillion'],
    hi: ['', 'हज़ार', 'लाख', 'करोड़', 'अरब'],
  }

  function helper(n) {
    if (n === 0) return ''
    if (n < 20) return belowTwenty[lang][n] + ' '
    if (n < 100) return tens[lang][Math.floor(n / 10)] + ' ' + helper(n % 10)
    return (
      belowTwenty[lang][Math.floor(n / 100)] +
      (lang === 'en' ? ' hundred ' : ' सौ ') +
      helper(n % 100)
    )
  }

  let result = ''
  let i = 0

  while (num > 0) {
    if (num % 1000 !== 0) {
      result = helper(num % 1000) + thousands[lang][i] + ' ' + result
    }
    num = Math.floor(num / 1000)
    i++
  }

  return result.trim()
}

export function getPriceWordsStringWithCurrency(price, currencyCode, langCode) {
  let currPrice = price
  if (!isValidNumber(currPrice)) {
    currPrice = Number(currPrice)
  }
  const currencyData = currency_list?.find((curr) => {
    return curr?.code === currencyCode
  })
  let currencyString = `${currencyData?.englishName || currencyData?.name}`
  if (langCode !== 'en') {
    currencyString = currencyData?.localName || currencyData?.englishName || currencyData?.name
  }

  // if (price === 0) {
  //   return
  // }

  return `${capitalize(numberToWords(price, langCode))} ${currencyString}`
}

export function getYearDifferenceBetweenTwoDates(date1, date2) {
  if (!date1 || !date2) {
    console.error('Both dates are required to subtract')
    return 'NA'
  }

  const firstDate = dayjs(date1)
  const secondDate = dayjs(date2)

  // Calculate the difference in years
  const yearDifference = Math.abs(firstDate.diff(secondDate, 'year'))

  // Log the time difference for debugging
  // console.log({
  //   yearDifference,
  //   differenceInMonths: firstDate.diff(secondDate, 'month'),
  //   differenceInDays: firstDate.diff(secondDate, 'day'),
  //   differenceInMilliseconds: firstDate.diff(secondDate, 'millisecond'),
  //   differenceInMins: firstDate.diff(secondDate, 'minutes'),
  //   differenceInseconds: firstDate.diff(secondDate, 'seconds'),
  // });

  return yearDifference
}

export function getNumberStringForInput(numberString) {
  console.log({ valueNumString: numberString })
  if (numberString === '') {
    return null
  }
  if (!(numberString === '0' || numberString === 0) && !numberString) {
    return null
  }

  const num = Number(numberString)

  if (!isValidNumber(num)) {
    return null
  }

  return num
}

export function getChangedNumberValueFromInput(numberString) {
  console.log({ changedNum: numberString })

  if (numberString === '') {
    return null
  }
  const num = Number(numberString)

  if (!isValidNumber(num)) {
    return null
  }
  return num
}

export function getUniqueClinicBrandsFromMentorAssociatedClinics(associatedClinicBrandsOfMentor) {
  const uniqueMap = new Map()

  associatedClinicBrandsOfMentor?.forEach(({ clinicBrandId, clinicBrandName }) => {
    if (!uniqueMap.has(clinicBrandId)) {
      uniqueMap.set(clinicBrandId, { id: clinicBrandId, title: clinicBrandName })
    }
  })

  return Array.from(uniqueMap.values())
}

export function getPastDateFromTimeString(timeString) {
  // Try to extract a number and a unit from the string (e.g., "10 hours")
  const regex = /(\d+)\s*(\w+)/
  const match = timeString?.match(regex)
  if (!match) {
    // Fallback: if the string doesn't match, return the current date
    return dayjs()
  }

  // Parse the numeric value
  const amount = parseInt(match[1], 10)
  if (!amount || amount <= 0) {
    // Fallback: if amount is zero or negative, return the current date
    return dayjs()
  }

  // Extract and normalize the unit (e.g., "hours" becomes "hour")
  let unit = match[2].toLowerCase()
  if (unit.endsWith('s')) {
    unit = unit.slice(0, -1)
  }

  // Allowed units
  const validUnits = ['hour', 'day', 'week', 'month', 'year']
  if (!validUnits.includes(unit)) {
    // Fallback: if the unit is not recognized, return the current date
    return dayjs()
  }

  // Return the date that is `amount` of the specified unit in the past
  return dayjs().subtract(amount, unit)
}
export function formatDuration(daysMonthsWeeksNumber, daysMonthsWeeksType) {
  try {
    // Validate input
    if (
      typeof daysMonthsWeeksNumber !== 'number' ||
      daysMonthsWeeksNumber < 0 ||
      !['days', 'weeks', 'months'].includes(daysMonthsWeeksType)
    ) {
      return null
    }

    // If input type is weeks or months, return directly
    if (daysMonthsWeeksType === 'weeks' || daysMonthsWeeksType === 'months') {
      return `${daysMonthsWeeksNumber} ${daysMonthsWeeksType}`
    }

    // If type is days, check if it's divisible into weeks or months
    if (daysMonthsWeeksType === 'days') {
      if (daysMonthsWeeksNumber % 30 === 0) {
        return `${daysMonthsWeeksNumber / 30} months`
      }
      if (daysMonthsWeeksNumber % 7 === 0) {
        return `${daysMonthsWeeksNumber / 7} weeks`
      }
      if (daysMonthsWeeksNumber <= 15) {
        return `${daysMonthsWeeksNumber} days`
      }
    }

    return null
  } catch (error) {
    console.error('Error in formatDuration:', error)
    return null
  }
}

import axios from 'axios'
import { useEffect, useState } from 'react'
import { createApi } from 'unsplash-js'
import packageJson from '../../../package.json'
import { APP_CONSTANTS, STORAGE_KEYS } from '../constants'

export const apiURL = getApiServerUrlObject().url
export const publicApiURL =
  localStorage.getItem('publicApiURL') || APP_CONSTANTS.PUBLIC_API_CALLS_DOMAIN
export const mediaURL = 'https://media.aumhum.xyz/images/prod/'
export const baseMediaUrl = 'https://media.aumhum.xyz/'
export const appVersion = packageJson.version // only do patch updates
// export const appVersion = '9.9.9.9' // max
export const lastBuildDate = '10 October 2024 - 12:22 IST'
export const platformWeb = 'web'
export const appInstallationId = localStorage.getItem('appInstallationId')

export const UNSPLASH_API = createApi({
  // Don't forget to set your access token here!
  // See https://unsplash.com/developers
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
})

export let commonHeadersWeb = {
  'ef-app-version': appVersion,
  'ef-app-platform': platformWeb,
  'ef-app-package': 'com.aumhum.web',
  'ef-app-installation-id': appInstallationId,
  'ef-device-model': 'iPad11,6',
  'ef-device-os-version': '16',
  'ef-device-manufacturer': 'apple',
  'ef-session-id': sessionStorage.getItem('sessionId'),
  'ef-user-city': 'moscow',
  'ef-device-os-language': (navigator?.language || navigator?.userLanguage).split('-')[0],
}

export const useFetchPublic = (path, customParams = '', platformType) => {
  const [state, setState] = useState({
    loading: true,
    data: null,
    error: null,
  })

  const [countryCode, setCountryCode] = useState(localStorage.getItem('countryCode'))
  const [triggerFetch, setTriggerFetch] = useState(false)

  useEffect(() => {
    function handleChangeStorage() {
      setCountryCode(localStorage.getItem('countryCode'))
    }
    window.addEventListener('storage', handleChangeStorage)
    return () => window.removeEventListener('storage', handleChangeStorage)
  }, [])

  const languageCode = sessionStorage.getItem('languageCode')

  useEffect(() => {
    setState({ loading: true, data: null, error: null })

    const paramsWeb = `languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode?.toLowerCase()}`

    let params = paramsWeb

    axios
      .get(`${publicApiURL}${path}?${params}${customParams}`, {
        headers: {
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode')?.toLowerCase(),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      })
      .then((res) => {
        setState({ data: res.data, loading: false, error: null })
      })
      .catch((error) => {
        setState({ data: null, loading: false, error: error })
      })
  }, [path, customParams, countryCode, languageCode, triggerFetch])

  const refreshFunction = () => {
    setTriggerFetch(!triggerFetch) // Toggle triggerFetch value to re-fetch data
  }

  return { ...state, refreshFunction }
}

export const useFetch = (path, customParams = '', platformType) => {
  const [state, setState] = useState({
    loading: true,
    data: null,
    error: null,
  })

  const [countryCode, setCountryCode] = useState(localStorage.getItem('countryCode'))
  const [triggerFetch, setTriggerFetch] = useState(false)

  useEffect(() => {
    function handleChangeStorage() {
      setCountryCode(localStorage.getItem('countryCode'))
    }
    window.addEventListener('storage', handleChangeStorage)
    return () => window.removeEventListener('storage', handleChangeStorage)
  }, [])

  const languageCode = sessionStorage.getItem('languageCode')
  const token = localStorage.getItem('token')

  useEffect(() => {
    setState({ loading: true, data: null, error: null })

    const paramsWeb = `languageCode=${languageCode}&platform=${platformWeb}&countryCode=${countryCode?.toLowerCase()}`

    let params = paramsWeb

    axios
      .get(`${apiURL}${path}?${params}${customParams}`, {
        headers: {
          ...(token && { Authorization: `Bearer ${token}` }),
          ...commonHeadersWeb,
          'ef-user-country-code': localStorage.getItem('countryCode')?.toLowerCase(),
          'ef-app-language': sessionStorage.getItem('languageCode'),
        },
      })
      .then((res) => {
        setState({ data: res.data, loading: false, error: null })
      })
      .catch((error) => {
        setState({ data: null, loading: false, error: error })
      })
  }, [path, customParams, token, countryCode, languageCode, triggerFetch])

  const refreshFunction = () => {
    setTriggerFetch(!triggerFetch) // Toggle triggerFetch value to re-fetch data
  }

  return { ...state, refreshFunction }
}

export async function verifyLogin(accessToken) {
  const countryCode = localStorage.getItem('countryCode')
  const languageCode = sessionStorage.getItem('languageCode')

  const additionalParams = {}

  const acquisitionReferenceRefId = sessionStorage.getItem('acquisitionReferenceRefId')
  const acquisitionReferenceRefType = sessionStorage.getItem('acquisitionReferenceRefType')

  if (acquisitionReferenceRefId) {
    additionalParams['acquisitionReferenceRefId'] = acquisitionReferenceRefId
  }
  if (acquisitionReferenceRefType) {
    additionalParams['acquisitionReferenceRefType'] = acquisitionReferenceRefType
  }

  const queryParams = new URLSearchParams({
    accessToken,
    countryCode,
    languageCode,
    ...additionalParams,
  }).toString()

  try {
    const res = await axios.get(`${apiURL}/api/user/firebase/login?${queryParams}`, {
      headers: {
        ...commonHeadersWeb,
        'ef-user-country-code': localStorage.getItem('countryCode'),
        'ef-app-language': sessionStorage.getItem('languageCode'),
      },
    })
    return res
  } catch (err) {
    console.log(err)
    return err
  }
}

export function getApiServerUrlObject() {
  const restCustomApiURL = localStorage.getItem(STORAGE_KEYS.restCustomApiURL)
  if (Boolean(restCustomApiURL)) {
    return {
      url: restCustomApiURL,
      isCustom: true,
    }
  } else {
    const restApiURL = localStorage.getItem(STORAGE_KEYS.restApiURL)
    if (Boolean(restApiURL)) {
      return {
        url: restApiURL,
        isCustom: false,
      }
    } else {
      return { url: APP_CONSTANTS.API_CALLS_DOMAIN, isCustom: false }
    }
  }
}

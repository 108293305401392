import { ButtonBase, styled as muiStyled } from '@mui/material'
import { withAsyncPaginate } from 'react-select-async-paginate'
import { default as Creatable } from 'react-select/creatable'

const CreatableAsyncPaginate = withAsyncPaginate(Creatable)

export const DosageContainer = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    `,
)

export const DosagePillItem = muiStyled(ButtonBase)(
  ({ theme, selected, pillUnselectedBackground = theme.palette.grey[200] }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid ${selected ? 'transparent' : '#e0e0e0'};
    background: ${selected ? '#246797' : pillUnselectedBackground};
    color: ${selected ? 'white' : '#000'};
    `,
)

export const CreatableDrugAdviceInput = muiStyled(CreatableAsyncPaginate)(({theme})=>`
  .drug_name_selection__input{
    opacity: 1 !important;
  }
`)
export const CreatableDrugNotesSelect = muiStyled(Creatable)(({theme})=>`
  .drug_notes_selection__input{
    opacity: 1 !important;
  }
`)
import { styled as muiStyled } from '@mui/material'

export const DayJsDateSelectorCalendarStyleContainer = muiStyled('div')(
  ({ theme }) => `
    .react-calendar {
        border: none;
        font-family: 'Outfit';
        line-height: 1.2em;
        width: 19em;
        transition: bacckground 200ms linear;
    }
    .react-calendar__tile--active{
        background: ${theme.palette.emrPrimary.default};
        &:hover {
            background: ${theme.palette.emrPrimary.light} ;
        }
    }
`,
)

import React from 'react'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { PMPBodySectionItems } from './styledComponent'

export default function PrintableObservations({
  debug,
  observationRef,
  selectedObservations,
  debugHighlightStates,
  styleOverrides,
}) {
  return (
    <PMPBodySectionItems
      ref={observationRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.observations?.color}
      debugHighlight={debugHighlightStates?.observations}
    >
      <span
        style={{
          fontSize: styleOverrides?.headingFontSize
            ? `${styleOverrides?.headingFontSize}px`
            : 'inherit',
          color: styleOverrides?.headingColor || 'inherit',
        }}
      >
        <strong>Observations</strong>
      </span>
      <div
        style={{
          fontSize: styleOverrides?.fontSizePixels
            ? `${styleOverrides?.fontSizePixels}px`
            : 'inherit',
          color: styleOverrides?.color || 'inherit',
        }}
      >
        {selectedObservations.map((observation) => observation.label).join(', ')}
      </div>
    </PMPBodySectionItems>
  )
}

import { Navigate } from 'react-router'
import React from 'react'
import { getAllRolesInBoolean } from 'src/mentor/layout/utils'

export default function SupportPortalProtectedRoute({ children }) {
  const authoritiesInBoolean = getAllRolesInBoolean()

  if (
    !authoritiesInBoolean.isL1TherapySupport &&
    !authoritiesInBoolean.isL2TherapySupport &&
    !authoritiesInBoolean.isL3TherapySupport
  ) {
    return <Navigate to="/" />
  }
  // if (!role_array.find((element) => element === 'ROLE_BUSINESS_CUSTOMER')) {
  //   console.log('found')
  //   return <Navigate to="/403" />
  // }
  return children
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getMPData } from 'src/mentor/services'

export const getTeacherProfileData = createAsyncThunk(
  'teacher/getProfileData',
  async (_, { rejectWithValue, getState }) => {
    getTeacherProfileData.pending()
    const { teacherProfileDataStore } = getState()
    const profileData = teacherProfileDataStore?.teacherData?.data

    if (!!profileData) {
      return profileData
    }

    const resp = await getMPData('/api/secure/teacher/profile')
    if (resp?.status === 200) {
      return resp?.data
    } else {
      rejectWithValue(resp.message)
    }
  },
)

import { Checkbox, FormControlLabel, Grid } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  CustomHTMLSelect,
  CustomInput,
  CustomSelect,
  selectTypes,
} from 'src/consumer/components/helperComponents'
import { FOOTER_TYPES_ARRAY, HEADER_TYPES_ARRAY } from 'src/consumer/constants'
import { getMPData, getMPDataWithParams } from 'src/mentor/services'

export default function A4DebugPrintSettings({ printSettings, setPrintSettings, clinicBrandId }) {
  const [prescriptionTemplates, setPrescriptionTemplates] = useState([])
  const [loadingClinicLocations, setLoadingClinicLocations] = useState(true)

  async function getMentorClinicAssociation() {
    const resp = await getMPData('/api/secure/teacher/clinic-profile')
    if (resp?.status === 200) {
      if (
        Array.isArray(resp?.data) &&
        resp?.data?.length > 0 &&
        !Boolean(sessionStorage.getItem('autoNavigatedToEmrOnceInSession'))
      ) {
      }
      let clinicLocations = resp?.data?.filter((clinic) => clinic?.clinicBrandId)
      clinicLocations = [...(clinicLocations || []), { id: 'private', title: 'Private Template' }]
      const prescriptionDataWithClinicLocationData = await Promise.all(
        clinicLocations.map((clinicLocation) =>
          getPrescriptionTemplateDataWithClinicLocation(clinicLocation),
        ),
      )
      setPrescriptionTemplates(prescriptionDataWithClinicLocationData)
    }
  }

  async function getPrescriptionTemplateDataWithClinicLocation(clinicLocation) {
    const clinicId = clinicLocation?.id
    const resp = await getMPDataWithParams('/api/secure/doctor/prescription-template', {
      clinicId: clinicId === 'private' ? null : clinicId,
    })
    if (resp?.status === 200) {
      return { ...(resp?.data || {}), clinicId: clinicId, title: clinicLocation?.title }
      // setPrintSettings((prev) => ({
      //   ...prev,
      //   selectedPrescriptionTemplate: ,
      // }))
    }
  }

  async function setup() {
    setLoadingClinicLocations(true)
    await getMentorClinicAssociation()
    setLoadingClinicLocations(false)
  }

  useEffect(() => {
    setup()
  }, [])

  useEffect(() => {
    console.log({ prescriptionTemplates })
    console.log({ printSettings })
  }, [prescriptionTemplates])

  return (
    <>
      {/* <Grid item xs={12} md={4}>
        <CustomHTMLSelect
          value={printSettings?.printType}
          onChange={(e) => {
            setPrintSettings((prev) => ({ ...(prev || {}), printType: e.target.value }))
          }}
          label="Print Type"
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.title}
          outerMostDivStyle={{ width: '100%' }}
          options={[
            { id: 'pdf', title: 'PDF' },
            { id: 'print', title: 'Print' },
          ]}
        />
      </Grid> */}
      <Grid item xs={12} md={4}>
        <CustomSelect
          value={
            prescriptionTemplates?.find(
              (template) => template?.id === printSettings?.selectedPrescriptionTemplate?.id,
            ) || null
          }
          placeholder="Select Prescription Template..."
          isLoading={loadingClinicLocations}
          onChangeEvent={(e) => {
            setPrintSettings((prev) => ({ ...prev, selectedPrescriptionTemplate: e.target.value }))
          }}
          label="Current Prescription Template"
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.title}
          id="selectedPrescriptionTemplate"
          selectType={selectTypes.object}
          options={prescriptionTemplates}
        />
      </Grid>
      {printSettings?.printType === 'print' ? (
        <Grid item xs={12} md={4}>
          <CustomHTMLSelect
            value={printSettings?.printHeaderType}
            onChange={(e) => {
              setPrintSettings((prev) => ({ ...(prev || {}), printHeaderType: e.target.value }))
            }}
            label="Header Type"
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            outerMostDivStyle={{ width: '100%' }}
            options={HEADER_TYPES_ARRAY}
          />
        </Grid>
      ) : (
        <> </>
      )}
      {printSettings?.printType === 'pdf' ? (
        <Grid item xs={12} md={4}>
          <CustomHTMLSelect
            value={printSettings?.pdfHeaderType}
            onChange={(e) => {
              setPrintSettings((prev) => ({ ...(prev || {}), pdfHeaderType: e.target.value }))
            }}
            label="Header Type"
            getOptionValue={(optn) => optn?.id}
            getOptionLabel={(optn) => optn?.title}
            outerMostDivStyle={{ width: '100%' }}
            options={HEADER_TYPES_ARRAY}
          />
        </Grid>
      ) : (
        <></>
      )}
      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={printSettings?.blankHeader}
              onChange={(e) =>
                setPrintSettings((prev) => ({ ...(prev || {}), blankHeader: e.target.checked }))
              }
            />
          }
          label="Show Blank Header"
        />
      </Grid>
      {printSettings?.printType === 'pdf' && (
        <Grid item xs={12} md={4}>
          <CustomInput
            label="Header Height in inches"
            value={printSettings?.pdfHeaderHeight || ''}
            placeholder="0"
            onChange={(e) =>
              setPrintSettings((prev) => ({ ...prev, pdfHeaderHeight: Number(e.target.value) }))
            }
            type="number"
          />
        </Grid>
      )}
      {printSettings?.printType === 'print' && (
        <Grid item xs={12} md={4}>
          <CustomInput
            label="Header Height in inches"
            value={printSettings?.headerHeight || ''}
            placeholder="0"
            onChange={(e) =>
              setPrintSettings((prev) => ({ ...prev, headerHeight: Number(e.target.value) }))
            }
            type="number"
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <CustomHTMLSelect
          value={printSettings?.printFooterType}
          onChange={(e) => {
            setPrintSettings((prev) => ({ ...(prev || {}), printFooterType: e.target.value }))
          }}
          label="Footer Type"
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.title}
          outerMostDivStyle={{ width: '100%' }}
          options={FOOTER_TYPES_ARRAY}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <FormControlLabel
          control={
            <Checkbox
              checked={printSettings?.blankFooter}
              onChange={(e) =>
                setPrintSettings((prev) => ({ ...(prev || {}), blankFooter: e.target.checked }))
              }
            />
          }
          label="Show Blank Footer"
        />
      </Grid>
      {printSettings?.printType === 'pdf' && (
        <Grid item xs={12} md={4}>
          <CustomInput
            label="Footer Height in inches"
            value={printSettings?.pdfFooterHeight || ''}
            placeholder="0"
            onChange={(e) =>
              setPrintSettings((prev) => ({ ...prev, pdfFooterHeight: Number(e.target.value) }))
            }
            type="number"
          />
        </Grid>
      )}
      {printSettings?.printType === 'print' && (
        <Grid item xs={12} md={4}>
          <CustomInput
            label="Footer Height in inches"
            value={printSettings?.footerHeight || ''}
            placeholder="0"
            onChange={(e) =>
              setPrintSettings((prev) => ({ ...prev, footerHeight: Number(e.target.value) }))
            }
            type="number"
          />
        </Grid>
      )}
      <Grid item xs={12} md={4}>
        <CustomInput
          label="Left Sidebar Width in inches"
          value={printSettings?.leftSidebarWidth || ''}
          placeholder="0"
          onChange={(e) =>
            setPrintSettings((prev) => ({ ...prev, leftSidebarWidth: Number(e.target.value) }))
          }
          type="number"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomInput
          label="Right Sidebar Width in inches"
          value={printSettings?.rightSidebarWidth || ''}
          placeholder="0"
          onChange={(e) =>
            setPrintSettings((prev) => ({ ...prev, rightSidebarWidth: Number(e.target.value) }))
          }
          type="number"
        />
      </Grid>
    </>
  )
}

import { addOrUpdateGenericDrugsInIdb, findGenericDrugInIdb } from 'src/emr/database/indexedDbDao'
import { getMPDataWithParams } from 'src/mentor/services'

export function keepSectionStateBeforeClearAll(clearedSectionName, data) {
  const stringifiedData = JSON.stringify(data)
  const sessionData = JSON.parse(sessionStorage.getItem('sectionStates') || '{}')
  sessionData[clearedSectionName] = stringifiedData
  sessionStorage.setItem('sectionStates', JSON.stringify(sessionData))
}

export function undoClearAllChangeInPrescription(
  clearedSectionName,
  callbackFunctionForRecoveredData,
) {
  const sessionData = JSON.parse(sessionStorage.getItem('sectionStates') || '{}')
  if (sessionData?.[clearedSectionName]) {
    const recoveredData = JSON.parse(sessionData[clearedSectionName])
    delete sessionData[clearedSectionName]
    sessionStorage.setItem('sectionStates', JSON.stringify(sessionData))
    if (callbackFunctionForRecoveredData) {
      callbackFunctionForRecoveredData(recoveredData)
    }
  } else {
    console.log('No data available to undo.')
  }
}

export async function getGenericDrugDataForDrug(genericNameId) {
  // find in idb, else find on server, save in idb, and ultimately return
  console.log('trying to find for ')
  console.log(genericNameId)
  const foundGenDrug = await findGenericDrugInIdb(genericNameId)
  console.log(foundGenDrug)
  if (foundGenDrug) {
    return foundGenDrug
  } else {
    helperOfGetGenericDrugDataForDrug(genericNameId)
    return null
  }
}

async function helperOfGetGenericDrugDataForDrug(genericNameId) {
  const response = await getMPDataWithParams(`/api/secure/doctor/drug-generic-formula/list`, {
    medKey: genericNameId,
  })
  if (response?.status === 200 && response?.data && Array.isArray(response?.data)) {
    addOrUpdateGenericDrugsInIdb(response?.data)
  }
}

const isValidDosage = (dosage) => {
  const dosageTitle = dosage?.title
  if (!dosageTitle) return false
  if (typeof dosageTitle !== 'string') return false

  const parts = dosageTitle.split('-')

  return parts.some((part) => part !== '0') // Valid if at least one part is not "0"
}

export const filterValidDosages = (dosageArray = []) => {
  return dosageArray?.filter(isValidDosage)
}

import { AddCircleOutline, DeleteOutline } from '@mui/icons-material'
import { Dialog } from '@mui/material'
import { memo, useMemo, useState } from 'react'
import { DRUG_TABLE_COLUMN_WEIGHTS, PRESCRIPTION_BUILDER_VIEW_TYPES } from 'src/consumer/constants'
import {
  autoSuggestDrugAdviceUsingDrug,
  DosageSelectMenu,
  DrugSelectMenu,
  DrugTypeSelectMenu,
  DurationSelectMenu,
  FrequencySelectMenu,
  handleDrugAdviceChange,
  loadOptionsDrugs,
  NotesSelectMenu,
  nullAllValuesOfDrugAdvice,
  WhatTimeSelectMenu,
} from '../../PrescriptionMakingPageComponents'
import { PMPSingleRowBox, PMPSingleRowForDrugs } from '../../PrescriptionMakingPageStyles'
import { PRESCRIPTION_COLORS_MAP } from '../common/color-utils'
import PrescriptionPillViewInputComponent from '../common/PrescriptionPillViewInputComponent'
import DrugAdviceSelectedPillComponent from './components/DrugAdviceSelectedPillComponent'
import DrugAdviceContextForm from './DrugAdviceContextForm'
import DrugCreationForm from './DrugCreationForm'
import {
  getAndSetDrugWithRelatedNamesAndGenericDrugData,
  getAndSetDrugWithRelatedNamesForPillInput,
  getDrugAdviceEntitiesTitleToItemMap,
  getRenderDrugAdviceSubSectionsSet,
} from './utils'

const DrugAdviceInput = ({
  prescriptionViewSettings,
  drugsAdvice,
  setDrugsAdvice,
  handleAddDrugAdviceItem,
  handleDeleteDrugAdviceItem,
  clinicId,
  clinicBrandId,
  recommendedHealthEntityForDoctor,
  mentorProfileData,
  drugAdviceTypes,
  drugAdviceWhatTimeOptions,
  drugAdviceFrequencies,
  drugAdviceDurations,
  drugAdviceNotes,
  drugAdviceDosages,
  contextMenuSide,
  drugAdviceSubSections,
}) => {
  const recommendedDrugIds = recommendedHealthEntityForDoctor?.['drug-info']
  const convertedDataForPillUI = drugsAdvice?.map((item) => ({
    ...item?.drug,
    dosage: item?.dosage?.value,
    duration: item?.duration?.value,
    frequency: item?.frequency?.value,
    notes: item?.notes?.value,
    whatTime: item?.whatTime?.value,
    __isNew__: item?.__isNew__,
  }))
  const [showDrugCreationDialog, setShowDrugCreationDialog] = useState(false)
  const [newDrugInputValue, setNewDrugInputValue] = useState('')
  const dosagesMap = useMemo(() => {
    return getDrugAdviceEntitiesTitleToItemMap({
      drugAdviceItems: drugAdviceDosages,
      prescriptionViewSettings,
    })
  }, [drugAdviceDosages, prescriptionViewSettings])

  const drugAdviceWhatTimeTitleToItemMap = useMemo(() => {
    return getDrugAdviceEntitiesTitleToItemMap({
      drugAdviceItems: drugAdviceWhatTimeOptions,
      prescriptionViewSettings,
    })
  }, [drugAdviceWhatTimeOptions, prescriptionViewSettings])
  const drugAdviceFrequenciesTitleToItemMap = useMemo(() => {
    return getDrugAdviceEntitiesTitleToItemMap({
      drugAdviceItems: drugAdviceFrequencies,
      prescriptionViewSettings,
    })
  }, [drugAdviceFrequencies, prescriptionViewSettings])
  const drugAdviceDurationsTitleToItemMap = useMemo(() => {
    return getDrugAdviceEntitiesTitleToItemMap({
      drugAdviceItems: drugAdviceDurations,
      prescriptionViewSettings,
    })
  }, [drugAdviceDurations, prescriptionViewSettings])
  const drugAdviceNotesTitleToItemMap = useMemo(() => {
    return getDrugAdviceEntitiesTitleToItemMap({
      drugAdviceItems: drugAdviceNotes,
      prescriptionViewSettings,
    })
  }, [drugAdviceNotes, prescriptionViewSettings])

  function onChangeForPillInput(valueData) {
    setDrugsAdvice((prev) => {
      let dataToWorkWIth = valueData
      if (typeof valueData === 'function') {
        dataToWorkWIth = valueData(
          prev.map((drug) => {
            return {
              ...drug?.drug,
              dosage: drug?.dosage?.value,
              duration: drug?.duration?.value,
              frequency: drug?.frequency?.value,
              notes: drug?.notes?.value,
              whatTime: drug?.whatTime?.value,
              __isNew__: drug?.__isNew__,
            }
          }),
        )
      }
      return dataToWorkWIth.map((item) => {
        const foundDosage = dosagesMap?.[item?.dosage] || { __isNew__: true }

        const foundWhatTime = drugAdviceWhatTimeTitleToItemMap?.[item?.whatTime] || {
          __isNew__: true,
        }
        const foundFrequency = drugAdviceFrequenciesTitleToItemMap?.[item?.frequency] || {
          __isNew__: true,
        }
        const foundDuration = drugAdviceDurationsTitleToItemMap?.[item?.duration] || {
          __isNew__: true,
        }
        const foundNotes = drugAdviceNotesTitleToItemMap?.[item?.notes] || { __isNew__: true }
        return {
          drug: item,
          dosage: { ...foundDosage, label: item?.dosage, value: item?.dosage },
          whatTime: { ...foundWhatTime, label: item?.whatTime, value: item?.whatTime },
          frequency: { ...foundFrequency, label: item?.frequency, value: item?.frequency },
          duration: { ...foundDuration, label: item?.duration, value: item?.duration },
          notes: { ...foundNotes, label: item?.notes, value: item?.notes },
          __isNew__: item?.__isNew__,
        }
      })
    })
  }

  function handleSelectDrugAdviceItem(newValue, itemIdx) {
    console.log({ newValue })

    if (Boolean(newValue)) {
      handleDrugAdviceChange('drug', itemIdx, newValue, setDrugsAdvice)
      autoSuggestDrugAdviceUsingDrug(itemIdx, newValue, setDrugsAdvice, drugAdviceTypes)
    } else {
      nullAllValuesOfDrugAdvice(itemIdx, setDrugsAdvice)
    }
  }

  function handleSelectDrugAdviceItemForPillInput(newValue, itemIdx) {
    onChangeForPillInput(newValue)
  }

  //for doc on select
  function handleChangeDrugItemForPillInput(allValues, itemIdx, newValue) {
    getAndSetDrugWithRelatedNamesForPillInput(
      mentorProfileData?.specializedCategories,
      newValue,
      allValues,
      clinicId,
      clinicBrandId,
      (val) => handleSelectDrugAdviceItemForPillInput(val),
    )
  }

  function closeDrugCreationDialog() {
    setShowDrugCreationDialog(false)
  }

  function createNewDrug(newValue) {
    setShowDrugCreationDialog(true)
    setNewDrugInputValue(newValue)
  }

  //for table drug select
  function handleChangeDrugItem(newValue, itemIdx, updateSelectedDrugStateOnly) {
    if (updateSelectedDrugStateOnly) {
      handleSelectDrugAdviceItem(newValue, itemIdx)
    } else {
      getAndSetDrugWithRelatedNamesAndGenericDrugData(
        mentorProfileData?.specializedCategories,
        newValue,
        clinicId,
        clinicBrandId,
        (val) => handleSelectDrugAdviceItem(val, itemIdx),
      )
    }
  }

  switch (prescriptionViewSettings?.prescriptionBuilderViewType) {
    case PRESCRIPTION_BUILDER_VIEW_TYPES.tabletView:
      return (
        <>
          <PrescriptionPillViewInputComponent
            additional={{
              page: 1,
              clinicId: clinicId,
              clinicBrandId: clinicBrandId,
              recommendedDrugs: recommendedDrugIds,
            }}
            onChange={(valueArray, idx, newValue) => {
              handleChangeDrugItemForPillInput(valueArray, idx, newValue)
            }}
            onCreate={(inputValue) => {
              createNewDrug(inputValue)
            }}
            prescriptionViewSettings={prescriptionViewSettings}
            searchPlaceholder={'Search for drug'}
            debounceTimeout={200}
            getOptionLabel={(optn) => optn?.label}
            getOptionValue={(optn) => optn?.value}
            getOptionsResponse={loadOptionsDrugs}
            title="Rx"
            pillColor={PRESCRIPTION_COLORS_MAP.drugAdvice.color}
            highlightedPillColor={PRESCRIPTION_COLORS_MAP.drugAdvice.highlightColor}
            value={convertedDataForPillUI?.filter((item) => !!item?.title)}
            itemName={'drug'}
            ContextMenuFormComponent={DrugAdviceContextForm}
            contextMenuOpenSide={contextMenuSide}
            SelectedPillComponent={DrugAdviceSelectedPillComponent}
            showContextFormOnClickNewCondition={
              (item) => item?.__isNew__ || !item?.dosage || item?.duration || !item?.frequency
              // !item?.notes ||
              // !item?.whatTime
            }
            contextMenuAdditionalProps={{
              dosageOptions: drugAdviceDosages,
              durationOptions: drugAdviceDurations,
              frequencyOptions: drugAdviceFrequencies,
              whatTimeOptions: drugAdviceWhatTimeOptions,
              notesOptions: drugAdviceNotes,
              prescriptionViewSettings,
            }}
            useFullPageSearch={true}
          />
          <Dialog open={showDrugCreationDialog} onClose={closeDrugCreationDialog}>
            <DrugCreationForm
              inputString={newDrugInputValue}
              setDrugData={onChangeForPillInput}
              closeDialog={closeDrugCreationDialog}
            />
          </Dialog>
        </>
      )
    default:
      return (
        <DrugAdviceTableInput
          drugsAdvice={drugsAdvice}
          setDrugsAdvice={setDrugsAdvice}
          handleSelectDrugAdviceItem={handleChangeDrugItem}
          handleAddDrugAdviceItem={handleAddDrugAdviceItem}
          handleDeleteDrugAdviceItem={handleDeleteDrugAdviceItem}
          clinicId={clinicId}
          clinicBrandId={clinicBrandId}
          recommendedDrugIds={recommendedDrugIds}
          mentorProfileData={mentorProfileData}
          drugAdviceTypes={drugAdviceTypes}
          drugAdviceWhatTimeOptions={drugAdviceWhatTimeOptions}
          drugAdviceFrequencies={drugAdviceFrequencies}
          drugAdviceDurations={drugAdviceDurations}
          drugAdviceNotes={drugAdviceNotes}
          drugAdviceDosages={drugAdviceDosages}
          prescriptionViewSettings={prescriptionViewSettings}
          drugAdviceSubSections={drugAdviceSubSections}
        />
      )
  }
}

function DrugAdviceTableInput({
  drugsAdvice,
  setDrugsAdvice,
  handleAddDrugAdviceItem,
  handleSelectDrugAdviceItem,
  handleDeleteDrugAdviceItem,
  clinicId,
  clinicBrandId,
  recommendedDrugIds,
  mentorProfileData,
  drugAdviceTypes,
  drugAdviceWhatTimeOptions,
  drugAdviceFrequencies,
  drugAdviceDurations,
  drugAdviceNotes,
  drugAdviceDosages,
  prescriptionViewSettings,
  drugAdviceSubSections,
}) {
  console.log({ drugsAdvice })

  return (
    <div style={{ width: '100%' }}>
      {/* default row */}
      <PMPSingleRowForDrugs isHeaderRow useGreyBorders>
        <PMPSingleRowBox style={{ width: '3%' }} centered borderRight>
          #
        </PMPSingleRowBox>
        {/* do not change the order. or improve so that order does not matter */}
        {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('type') && (
          <PMPSingleRowBox
            style={{
              width: getPercentageOfItemForDrugTableCellWidth(
                'type',
                94,
                getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
              ),
            }}
            borderRight
          >
            {drugAdviceSubSections?.find((item) => item?.uid === 'type')?.title}
          </PMPSingleRowBox>
        )}
        {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('drug_name') && (
          <PMPSingleRowBox
            style={{
              width: getPercentageOfItemForDrugTableCellWidth(
                'drug_name',
                94,
                getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
              ),
            }}
            borderRight
          >
            {drugAdviceSubSections?.find((item) => item?.uid === 'drug_name')?.title}
          </PMPSingleRowBox>
        )}
        {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('dosage') && (
          <PMPSingleRowBox
            style={{
              width: getPercentageOfItemForDrugTableCellWidth(
                'dosage',
                94,
                getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
              ),
            }}
            borderRight
          >
            {drugAdviceSubSections?.find((item) => item?.uid === 'dosage')?.title}
          </PMPSingleRowBox>
        )}
        {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('when') && (
          <PMPSingleRowBox
            style={{
              width: getPercentageOfItemForDrugTableCellWidth(
                'when',
                94,
                getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
              ),
            }}
            borderRight
          >
            {drugAdviceSubSections?.find((item) => item?.uid === 'when')?.title}
          </PMPSingleRowBox>
        )}
        {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('frequency') && (
          <PMPSingleRowBox
            style={{
              width: getPercentageOfItemForDrugTableCellWidth(
                'frequency',
                94,
                getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
              ),
            }}
            borderRight
          >
            {drugAdviceSubSections?.find((item) => item?.uid === 'frequency')?.title}
          </PMPSingleRowBox>
        )}
        {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('duration') && (
          <PMPSingleRowBox
            style={{
              width: getPercentageOfItemForDrugTableCellWidth(
                'duration',
                94,
                getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
              ),
            }}
            borderRight
          >
            {drugAdviceSubSections?.find((item) => item?.uid === 'duration')?.title}
          </PMPSingleRowBox>
        )}
        {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('notes') && (
          <PMPSingleRowBox
            style={{
              width: getPercentageOfItemForDrugTableCellWidth(
                'notes',
                94,
                getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
              ),
            }}
            borderRight
          >
            {drugAdviceSubSections?.find((item) => item?.uid === 'notes')?.title}
          </PMPSingleRowBox>
        )}
        <div style={{ width: '3%' }}></div>
      </PMPSingleRowForDrugs>

      {/* to be rendered a number of times */}
      {drugsAdvice?.map((drugAdviceRow, drugAdviceRowIndex) => (
        <PMPSingleRowForDrugs
          useGreyBorders
          isLastRow={drugAdviceRowIndex + 1 === drugsAdvice?.length}
          key={drugAdviceRowIndex}
        >
          {/* do not change the order. or improve so that order does not matter */}
          <PMPSingleRowBox style={{ width: '3%' }} centered>
            {drugAdviceRowIndex + 1}
          </PMPSingleRowBox>

          {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('type') && (
            <div
              style={{
                width: getPercentageOfItemForDrugTableCellWidth(
                  'type',
                  94,
                  getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
                ),
              }}
            >
              <DrugTypeSelectMenu
                allowEnteringValue={Boolean(drugAdviceRow?.drug)}
                selectedType={drugAdviceRow?.drugType}
                options={drugAdviceTypes}
                prescriptionViewSettings={prescriptionViewSettings}
                setSelectedType={(newValue) =>
                  handleDrugAdviceChange(
                    'drugType',
                    drugAdviceRowIndex,
                    newValue,
                    setDrugsAdvice,
                    drugAdviceTypes,
                  )
                }
              />
              {/* // this is expanding full width, and making the flexgrow container expand too, i need flexGrow to be respoected and it should impose a max width on children like bbelow */}
            </div>
          )}

          {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('drug_name') && (
            <div
              style={{
                width: getPercentageOfItemForDrugTableCellWidth(
                  'drug_name',
                  94,
                  getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
                ),
              }}
            >
              <DrugSelectMenu
                clinicId={clinicId}
                clinicBrandId={clinicBrandId}
                selectedDrug={drugAdviceRow?.drug}
                recommendedDrugs={recommendedDrugIds}
                doctorSpecializations={mentorProfileData?.specializedCategories}
                setSelectedDrug={(newValue, updateSelectedDrugStateOnly) => {
                  handleSelectDrugAdviceItem(
                    newValue,
                    drugAdviceRowIndex,
                    updateSelectedDrugStateOnly,
                  )
                }}
              />
              {/* // this is expanding full width, and making the flexgrow container expand too, i need flexGrow to be respoected and it should impose a max width on children like bbelow */}
            </div>
          )}

          {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('dosage') && (
            <div
              style={{
                width: getPercentageOfItemForDrugTableCellWidth(
                  'dosage',
                  94,
                  getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
                ),
              }}
            >
              <DosageSelectMenu
                allowEnteringValue={Boolean(drugAdviceRow?.drug)}
                selectedDosage={drugAdviceRow?.dosage}
                options={drugAdviceDosages}
                prescriptionViewSettings={prescriptionViewSettings}
                setSelectedDosage={(newValue) =>
                  handleDrugAdviceChange('dosage', drugAdviceRowIndex, newValue, setDrugsAdvice)
                }
              />
            </div>
          )}

          {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('when') && (
            <div
              style={{
                width: getPercentageOfItemForDrugTableCellWidth(
                  'when',
                  94,
                  getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
                ),
              }}
            >
              <WhatTimeSelectMenu
                allowEnteringValue={Boolean(drugAdviceRow?.drug)}
                selectedWhatTime={drugAdviceRow?.whatTime}
                prescriptionViewSettings={prescriptionViewSettings}
                setSelectedWhatTime={(newValue) =>
                  handleDrugAdviceChange('whatTime', drugAdviceRowIndex, newValue, setDrugsAdvice)
                }
                options={drugAdviceWhatTimeOptions}
              />
            </div>
          )}
          {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('frequency') && (
            <div
              style={{
                width: getPercentageOfItemForDrugTableCellWidth(
                  'frequency',
                  94,
                  getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
                ),
              }}
            >
              <FrequencySelectMenu
                allowEnteringValue={Boolean(drugAdviceRow?.drug)}
                selectedFrequency={drugAdviceRow?.frequency}
                prescriptionViewSettings={prescriptionViewSettings}
                setSelectedFrequency={(newValue) =>
                  handleDrugAdviceChange('frequency', drugAdviceRowIndex, newValue, setDrugsAdvice)
                }
                options={drugAdviceFrequencies}
              />
            </div>
          )}
          {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('duration') && (
            <div
              style={{
                width: getPercentageOfItemForDrugTableCellWidth(
                  'duration',
                  94,
                  getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
                ),
              }}
            >
              <DurationSelectMenu
                allowEnteringValue={Boolean(drugAdviceRow?.drug)}
                selectedDurationTime={drugAdviceRow?.duration}
                prescriptionViewSettings={prescriptionViewSettings}
                setSelectedDurationTime={(newValue) =>
                  handleDrugAdviceChange('duration', drugAdviceRowIndex, newValue, setDrugsAdvice)
                }
                options={drugAdviceDurations}
              />
            </div>
          )}
          {getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections).has('notes') && (
            <div
              style={{
                width: getPercentageOfItemForDrugTableCellWidth(
                  'notes',
                  94,
                  getRenderDrugAdviceSubSectionsSet(drugAdviceSubSections),
                ),
              }}
            >
              <NotesSelectMenu
                idx={drugAdviceRowIndex}
                allowEnteringValue={Boolean(drugAdviceRow?.drug)}
                selectedNote={drugAdviceRow?.notes}
                prescriptionViewSettings={prescriptionViewSettings}
                setSelectedNote={(newValue) =>
                  handleDrugAdviceChange('notes', drugAdviceRowIndex, newValue, setDrugsAdvice)
                }
                options={drugAdviceNotes}
              />
            </div>
          )}
          <PMPSingleRowBox style={{ width: '3%' }} whiteBG centered>
            <DeleteOutline
              style={{ cursor: 'pointer' }}
              onClick={() => handleDeleteDrugAdviceItem(drugAdviceRowIndex, setDrugsAdvice)}
            />
          </PMPSingleRowBox>
        </PMPSingleRowForDrugs>
      ))}

      <AddCircleOutline
        onClick={() => handleAddDrugAdviceItem(setDrugsAdvice)}
        style={{ cursor: 'pointer' }}
      />
    </div>
  )
}

export default memo(DrugAdviceInput)

export function getPercentageOfItemForDrugTableCellWidth(
  sectionId,
  availableSpaceInPercentage,
  setOfVisibleItems,
) {
  const weightForThisSection = DRUG_TABLE_COLUMN_WEIGHTS?.[sectionId]

  const weightSum = Object.entries(DRUG_TABLE_COLUMN_WEIGHTS)
    .filter(([key, value]) => setOfVisibleItems.has(key))
    .reduce((acc, [key, value]) => acc + value, 0)
  return `${(weightForThisSection / weightSum) * availableSpaceInPercentage}%`
}

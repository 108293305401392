export function handleChangeHealthEntityInputType1(a, b, setData) {
  const newData = getNewDataForHealthEntity(a, b)
  if (!newData) {
    return
  }
  setData((prev) => [...(prev || []), newData])
}

export function handleChangeHealthEntityInputTypeForDiagnosisTable(
  a,
  b,
  changeDiagnosisItemObject,
  diagnosisRowIndex,
) {
  const newData = getNewDataForHealthEntity(a, b)
  if (!newData) {
    return
  }
  // changeDiagnosisItemObject(newData, diagnosisRowIndex, true)
  changeDiagnosisItemObject(newData, diagnosisRowIndex, true)
}

function getNewDataForHealthEntity(a, b) {
  const newData = onInputChangeCommonHandler(a, b)
  if (!newData) {
    return
  }
  return { _isNew_: true, value: newData, label: newData }
}

export function onInputChangeCommonHandler(a, b) {
  if (b?.action === 'input-blur' && !!b?.prevInputValue) {
    return b?.prevInputValue
  }
  return null
}

export function handleKeyDownAndSupportEdit(e, inputValue, selectedList, setSelectedList, setInputValue) {
  // i need to see if the key pressed is Backspace
  // then we check the existing value. if there is no existing input value, and input value means unselected text value in mutliselect that was being typed
  // if there is existing input value, we let whatever would happen, happen
  // if that is empty or null, then we see if there is an existing selected value in mutliselect
  // the last of those is deleted from selected and the text is made the input value
  // thus helping us remove the behavior that delete the whole entry on backspace and instead allow us to edit what was written
  if (e.key === 'Backspace') {
    // Case 1: If there is text being typed in the input
    if (inputValue) {
      // Let React Select handle backspace by default (remove character)
      console.log('we are not doing anything as react select handles backspace')
      return
    }

    // Case 2: If there is no input text, check if there are selected values
    if (!inputValue && selectedList?.length > 0) {
      // Get the last selected complaint
      const lastSelectedComplaint = selectedList[selectedList.length - 1]

      // Remove the last selected complaint from the selected values using the previous state
      setSelectedList((prevSelectedComplaints) => {
        return prevSelectedComplaints.slice(0, -1) // Remove the last item
      })

      // // Set the input value to the label of the last deleted complaint (for retyping)
      setInputValue(lastSelectedComplaint.label)

      // // Prevent default backspace behavior (don't delete whole entry)
      e.preventDefault()
    }
  }
}

import { createAsyncThunk } from '@reduxjs/toolkit'
import { getData } from 'src/consumer/services/profileService'
import { logoutHandler } from 'src/consumer/services/loginLogoutHandlers'
import { shouldLogOutBasedOnHttpResponseStatus } from 'src/consumer/helpers/utilFunctions'

export const getProfileData = createAsyncThunk(
  'profile/getData',
  async ({ navigate }, { rejectWithValue, getState }) => {
    getProfileData.pending()
    const { profileDataStore } = getState()
    const currentProfileData = profileDataStore?.profileData?.data

    if (!!currentProfileData) {
      return currentProfileData
    }

    const resp = await getData('/api/secure/user/profile')

    if (resp?.status === 200) {
      localStorage.setItem('authorities', JSON.stringify(resp?.data?.authorities))
      return resp.data
    } else if (shouldLogOutBasedOnHttpResponseStatus(resp?.response?.status)) {
      const redirectURI = encodeURIComponent(window.location.href)
      if (window.location.pathname.split('/').includes('chamber-screen-home')) {
        logoutHandler(['ROLE_CA_CONFIG', 'ROLE_SCREEN_CONFIG'], [])
      } else {
        logoutHandler([], ['autoNavigatedToEmrOnceInSession'])
      }
      navigate(`/?redirect=${redirectURI}`)
      return rejectWithValue('Unauthorized access')
    } else {
      return rejectWithValue(resp?.message || 'Failed to fetch profile data')
    }
  }
)

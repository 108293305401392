import { Close } from '@mui/icons-material'
import { DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import { useRef } from 'react'
import { genericHandleChange } from 'src/consumer/helpers/utilFunctions'
import PillInputComponent from '../common/PillInputComponent'
import { getDrugItemOptionLabel, getDrugItemOptionValue } from './utils'
import { DocOnContextSectionContainer, DocOnContextSectionHeader } from '../common/styledComponents'

export default function DrugAdviceContextForm({
  item: drug,
  setItem: setDrug,
  saveItem,
  closeMenu,
  durationOptions,
  whatTimeOptions,
  frequencyOptions,
  dosageOptions,
  notesOptions,
  prescriptionViewSettings,
}) {
  // const [drug, setDrug] = useState(item?.drug)

  const whenInputBoxRef = useRef()
  const frequencyInputBoxRef = useRef()
  const notesInputBoxRef = useRef()

  function handleDrugChange(e) {
    genericHandleChange(e, setDrug)
  }

  function scrollToTop(inputElement, offset = 0) {
    if (inputElement && inputElement.current) {
      const elementPosition = inputElement.current.getBoundingClientRect().top + window.scrollY
      window.scrollTo({ top: elementPosition - offset, behavior: 'smooth' })
    }
  }

  function scrollToWhenInputBox() {
    scrollToTop(whenInputBoxRef, 0)
  }

  function scrollToFrequencyInputBox() {
    scrollToTop(frequencyInputBoxRef, 50)
  }

  function scrollToNotesInputBox() {
    scrollToTop(notesInputBoxRef, 50)
  }

  // useEffect(() => {
  //   setDrug(item)
  // }, [item])

  return (
    <>
      <DialogTitle
        sx={{ display: 'flex', justifyContent: 'space-between', gap: '10px', alignItems: 'center' }}
      >
        <div>
          <span>{drug?.label}</span>
        </div>
        <button className="d-flex flex-column align-items-center justify-content-center ms-2" onClick={() => closeMenu()}>
          <Close />
          <span className="fs-7 fw-normal">Close</span>
        </button>
      </DialogTitle>
      <DialogContent sx={{ height: '100%' }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <DocOnContextSectionContainer>
              <DocOnContextSectionHeader>
                <h5 className="m-0">Dosage</h5>
              </DocOnContextSectionHeader>
              <div className="p-2">
                <PillInputComponent
                  pillUnselectedBackground={"linear-gradient(139deg, rgba(255,255,255,1) 50%, rgba(241,241,241,1) 50%)"}
                  value={drug?.dosage}
                  handleChange={handleDrugChange}
                  valueKey="dosage"
                  options={removeItemsWithDuplicateLabels({
                    options: dosageOptions,
                    getLabel: (option) => getDrugItemOptionLabel(option, prescriptionViewSettings),
                  })}
                  getOptionValue={(option) =>
                    getDrugItemOptionValue(option, prescriptionViewSettings)
                  }
                  getOptionLabel={(option) =>
                    getDrugItemOptionLabel(option, prescriptionViewSettings)
                  }
                />
              </div>
            </DocOnContextSectionContainer>
          </Grid>
          <Grid item xs={12}>
            <DocOnContextSectionContainer ref={whenInputBoxRef}>
              <DocOnContextSectionHeader>
                <h5 className="m-0">What time</h5>
              </DocOnContextSectionHeader>
              <div className="p-2">
                <PillInputComponent
                  value={drug?.whatTime}
                  handleChange={handleDrugChange}
                  valueKey="whatTime"
                  options={removeItemsWithDuplicateLabels({
                    options: whatTimeOptions,
                    getLabel: (option) => getDrugItemOptionLabel(option, prescriptionViewSettings),
                  })}
                  getOptionValue={(option) =>
                    getDrugItemOptionValue(option, prescriptionViewSettings)
                  }
                  getOptionLabel={(option) =>
                    getDrugItemOptionLabel(option, prescriptionViewSettings)
                  }
                />
              </div>
            </DocOnContextSectionContainer>
          </Grid>
          <Grid item xs={12}>
            <DocOnContextSectionContainer ref={frequencyInputBoxRef}>
              <DocOnContextSectionHeader>
                <h5 className="m-0">Frequency</h5>
              </DocOnContextSectionHeader>
              <div className="p-2">
                <PillInputComponent
                  value={drug?.frequency}
                  handleChange={handleDrugChange}
                  valueKey="frequency"
                  options={removeItemsWithDuplicateLabels({
                    options: frequencyOptions,
                    getLabel: (option) => getDrugItemOptionLabel(option, prescriptionViewSettings),
                  })}
                  getOptionValue={(option) =>
                    getDrugItemOptionValue(option, prescriptionViewSettings)
                  }
                  getOptionLabel={(option) =>
                    getDrugItemOptionLabel(option, prescriptionViewSettings)
                  }
                />
              </div>
            </DocOnContextSectionContainer>
          </Grid>
          <Grid item xs={12}>
            <DocOnContextSectionContainer>
              <DocOnContextSectionHeader>
                <h5 className="m-0">Duration</h5>
              </DocOnContextSectionHeader>
              <div className="p-2">
                <PillInputComponent
                  value={drug?.duration}
                  handleChange={handleDrugChange}
                  valueKey="duration"
                  options={removeItemsWithDuplicateLabels({
                    options: durationOptions,
                    getLabel: (option) => getDrugItemOptionLabel(option, prescriptionViewSettings),
                  })}
                  getOptionValue={(option) =>
                    getDrugItemOptionValue(option, prescriptionViewSettings)
                  }
                  getOptionLabel={(option) =>
                    getDrugItemOptionLabel(option, prescriptionViewSettings)
                  }
                />
              </div>
            </DocOnContextSectionContainer>
          </Grid>
          <Grid item xs={12}>
            <DocOnContextSectionContainer>
              <DocOnContextSectionHeader>
                <h5 className="m-0">Notes</h5>
              </DocOnContextSectionHeader>
              <div className="p-2">
                <PillInputComponent
                  value={drug?.notes}
                  handleChange={handleDrugChange}
                  valueKey="notes"
                  options={removeItemsWithDuplicateLabels({
                    options: notesOptions,
                    getLabel: (option) => getDrugItemOptionLabel(option, prescriptionViewSettings),
                  })}
                  getOptionValue={(option) =>
                    getDrugItemOptionValue(option, prescriptionViewSettings)
                  }
                  getOptionLabel={(option) =>
                    getDrugItemOptionLabel(option, prescriptionViewSettings)
                  }
                />
              </div>
            </DocOnContextSectionContainer>
          </Grid>
        </Grid>
      </DialogContent>
    </>
  )
}

function removeItemsWithDuplicateLabels({ options, getLabel }) {
  const labels = new Set()
  return options.filter((option) => {
    const label = getLabel(option)
    if (labels.has(label)) {
      return false
    }
    labels.add(label)
    return true
  })
}

// <Autocomplete
//   id="whatTime"
//   key={'whattime'}
//   inputValue={drug?.whatTime || ''}
//   onInputChange={(e, newValue) =>
//     handleDrugChange({ target: { value: newValue, id: 'whatTime' } })
//   }
//   freeSolo
//   options={whatTimeOptions.map(
//     (option) =>
//       `${option?.title}${
//         prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi')
//           ? ` (${option?.translations?.hi?.title})`
//           : ''
//       }`,
//   )}
//   // renderOption={}
//   renderInput={(params) => (
//     <div ref={params.InputProps.ref}>
//       <CustomInput
//         onClick={() => {
//           scrollToWhenInputBox()
//         }}
//         pureInputProps={params.inputProps}
//         label="When"
//       />
//     </div>
//   )}
// />

{
  /* <Autocomplete
id="notes"
key={'notes'}
inputValue={drug?.notes || ''}
onInputChange={(e, newValue) =>
  handleDrugChange({ target: { value: newValue, id: 'notes' } })
}
freeSolo
options={notesOptions.map(
  (option) =>
    `${option?.title}${
      prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi')
        ? ` (${option?.translations?.hi?.title})`
        : ''
    }`,
)}
// renderOption={}
renderInput={(params) => (
  <div ref={params.InputProps.ref}>
    <CustomInput
      onClick={() => {
        scrollToNotesInputBox()
      }}
      pureInputProps={params.inputProps}
    />
  </div>
)}
/> */
}

{
  /* <Autocomplete
id="frequency"
key={'freq'}
inputValue={drug?.frequency || ''}
onInputChange={(e, newValue) =>
  handleDrugChange({ target: { value: newValue, id: 'frequency' } })
}
freeSolo
options={frequencyOptions.map(
  (option) =>
    `${option?.title}${
      prescriptionViewSettings?.preferredPrescriptionLanguages?.includes('hi')
        ? ` (${option?.translations?.hi?.title})`
        : ''
    }`,
)}
renderInput={(params) => (
  <div ref={params.InputProps.ref}>
    <CustomInput
      onClick={() => {
        scrollToFrequencyInputBox()
      }}
      pureInputProps={params.inputProps}
      label="Frequency"
    />
  </div>
)}
/> */
}

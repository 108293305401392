import { createAsyncThunk } from '@reduxjs/toolkit'
import { logoutHandler } from 'src/consumer/services/loginLogoutHandlers'
import { getData } from 'src/consumer/services/profileService'

export const getSetupData = createAsyncThunk(
  'setup/getData',
  async ({ navigate }, { getState, fulfillWithValue, rejectWithValue, dispatch }) => {
    getSetupData.pending()
    const { setupDataStore } = getState()
    const currentSetupData = setupDataStore?.setupData?.data

    if (!!currentSetupData) {
      return currentSetupData
    }

    const resp = await getData('/api/secure/setup')
    if (resp?.status === 200) {
      return resp.data
    } else if (resp?.status === 401) {
      rejectWithValue(resp.message)
      navigate('/')
    }
  },
)

import React, { useMemo } from 'react'
import JointHomunculusComponent from 'src/emr/components/swollenJointHighlighter/SwollenJointHighlighter'
import { PMPBodySectionItems } from './styledComponent'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { isHomunculusValid } from '../../../utils'

export default function PrintableHomunculus({
  homunculusRef,
  debug,
  jointHomunculusData,
  debugHighlightStates,
}) {
  const showHomunculus = useMemo(() => {
    return isHomunculusValid(jointHomunculusData)
  }, [jointHomunculusData])

  return showHomunculus ? (
    <PMPBodySectionItems
      ref={homunculusRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.homunculus.color}
      sx={{ display: 'flex', justifyContent: 'center' }}
      debugHighlight={debugHighlightStates?.homunculus}
      noPadding
    >
      <JointHomunculusComponent editable={false} data={jointHomunculusData} />
    </PMPBodySectionItems>
  ) : (
    <></>
  )
}

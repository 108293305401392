import { useEffect, useMemo } from 'react'
import { FOOTER_TYPES, PRESCRIPTION_LAYOUTS } from 'src/consumer/constants'
import { PrescriptionDrugAdviceTable } from '../../PrescriptionMakingPageComponents'
import {
  PmpConsultationBodyContentContainer,
  PmpConsultationContentContainer,
} from '../../PrescriptionMakingPageStyles'
import {
  checkAndRenderBasedOnEmrSections,
  EXCLUDED_ORDER_IDS_FROM_PRESC,
  PRESCRIPTION_ITEM_ORDER_IDS,
} from '../utils'
import PrescriptionPrintableFooter from './PrescriptionPrintableFooter'
import ContinuedToAndFromDiv from './prescriptionPrintableItems/ContinuedToAndFromDiv'
import PatientDetails from './prescriptionPrintableItems/PatientDetails'
import PrintableAssessments from './prescriptionPrintableItems/PrintableAssessments'
import PrintableComplaints from './prescriptionPrintableItems/PrintableComplaints'
import PrintableDiagnosis from './prescriptionPrintableItems/PrintableDiagnosis'
import PrintableHomunculus from './prescriptionPrintableItems/PrintableHomunculus'
import PrintableInstructions from './prescriptionPrintableItems/PrintableInstructions'
import PrintableInvestigations from './prescriptionPrintableItems/PrintableInvestigations'
import PrintableNextVisitColumn from './prescriptionPrintableItems/PrintableNextVisitColumn'
import PrintableObservations from './prescriptionPrintableItems/PrintableObservations'
import PrintablePrescriptionAdvice from './prescriptionPrintableItems/PrintablePrescriptionAdvice'
import PrintableSuggestedTherapies from './prescriptionPrintableItems/PrintableSuggestedTherapies'
import PrintableTherapyRecommended from './prescriptionPrintableItems/PrintableTherapyRecommended'
import PrintableVitals from './prescriptionPrintableItems/PrintableVitals'
import { PMPBodyDebugHighlightDiv } from './prescriptionPrintableItems/styledComponent'
import { getValidDiagnosisArrayForPrescription } from './prescriptionPrintableItems/util'
import PrintableReferredToDoctorName from './prescriptionPrintableItems/PrintableReferredToDoctorName'

export default function PrescriptionPrintableBody({
  value,
  setValue,
  placeholder,
  alsoChangePropValue,
  setPropValue,
  selectedTherapies,
  selectedAdvice,
  nextVisitInputType,
  nextVisitInputValue,
  prescriptionData,
  selectedObservations,
  selectedComplaints,
  selectedDiagnosis,
  selectedAssessments,
  referredToDoctorName,
  finalRequestedTestsBy,

  drugsAdvice,
  selectedInstructions,
  suggestedBookingTherapy,
  selectedInvestigations,
  jointHomunculusData,
  prescriptionViewSettings,
  vital,
  debug = false,
  printType,
  debugHighlightStates,
  footerHeight,
  currentPage,
  pageDetailsObject,
  leftSidebar,
  rightSidebar,
  leftSidebarWidth = 0,
  rightSidebarWidth = 0,
  totalPages,
  emrSections,
  footerType,
  isLastPage = false,
}) {
  var layoutStyles

  const pageDetailsItems = pageDetailsObject?.items

  var lenOfData = useMemo(() => {
    var len = 0
    if (Array.isArray(selectedObservations) && selectedObservations?.length > 0) {
      len += 1
    }
    if (Array.isArray(selectedComplaints) && selectedComplaints?.length > 0) {
      len += 1
    }
    if (Array.isArray(selectedDiagnosis) && selectedDiagnosis?.length > 0) {
      len += 1
    }
    return len || 1
  }, [selectedObservations, selectedComplaints, selectedDiagnosis])

  switch (prescriptionData?.layout) {
    case PRESCRIPTION_LAYOUTS.largerFontLayout:
      layoutStyles = {
        display: 'grid',
        gridTemplateColumns: `repeat(${lenOfData}, 1fr)`,
        width: '100%',
      }
      break
    default:
      layoutStyles = {}
      break
  }

  function checkIfItemIsOnCurrentPage(orderId) {
    if (
      !EXCLUDED_ORDER_IDS_FROM_PRESC.includes(orderId) &&
      !checkAndRenderBasedOnEmrSections(orderId, emrSections)
    ) {
      return false
    }
    return pageDetailsItems?.includes(orderId)
  }

  useEffect(() => {
    if (alsoChangePropValue) {
      setPropValue(value)
    }
  }, [value])

  console.log({ footerType })

  return (
    <>
      {checkIfItemIsOnCurrentPage(PRESCRIPTION_ITEM_ORDER_IDS.continuedFrom) && (
        <ContinuedToAndFromDiv currentText={`Continued ${currentPage}/${totalPages}`} />
      )}
      {checkIfItemIsOnCurrentPage('patientDetails') && (
        <PatientDetails
          debug={debug}
          prescriptionData={prescriptionData}
          printType={printType}
          debugHighlightStates={debugHighlightStates}
          prescriptionViewSettings={prescriptionViewSettings}
        />
      )}
      {checkIfItemIsOnCurrentPage('patient-vitals') && (
        <PrintableVitals
          debug={debug}
          vital={vital}
          printType={printType}
          debugHighlightStates={debugHighlightStates}
        />
      )}
      <div
        className={`d-flex justify-content-center ${
          footerType !== FOOTER_TYPES.inLineAtEnd ? 'flex-grow-1' : isLastPage ? '' : 'flex-grow-1'
        }`}
      >
        {leftSidebar && <div style={{ width: `${leftSidebarWidth}px`, flexShrink: 0 }}></div>}
        <PmpConsultationBodyContentContainer
          // headerHeight={headerDimensions?.height}
          // footerHeight={footerDimensions?.height}
          debug={debug}
        >
          {debugHighlightStates?.prescriptionBody && (
            <PMPBodyDebugHighlightDiv
              id="debug-body"
              debugHighlight={debugHighlightStates?.prescriptionBody}
            ></PMPBodyDebugHighlightDiv>
          )}
          {/* for calculating the height of the items inside */}

          <div>
            <PmpConsultationContentContainer debug={debug}>
              <div className="d-flex flex-column">
                <div
                  style={{
                    width: '100%',
                  }}
                >
                  <div style={layoutStyles}>
                    {Array.isArray(selectedObservations) &&
                      selectedObservations?.length > 0 &&
                      checkIfItemIsOnCurrentPage('observations') && (
                        <PrintableObservations
                          debug={debug}
                          selectedObservations={selectedObservations}
                          debugHighlightStates={debugHighlightStates}
                          styleOverrides={
                            prescriptionViewSettings?.styleOverridesForPrintedEmrSections?.[
                              'observations'
                            ]
                          }
                        />
                      )}
                    {Array.isArray(selectedComplaints) &&
                      selectedComplaints?.length > 0 &&
                      checkIfItemIsOnCurrentPage('health_complaints') && (
                        <PrintableComplaints
                          debug={debug}
                          selectedComplaints={selectedComplaints}
                          debugHighlightStates={debugHighlightStates}
                          styleOverrides={
                            prescriptionViewSettings?.styleOverridesForPrintedEmrSections?.[
                              'complaints'
                            ]
                          }
                        />
                      )}
                    {Array.isArray(getValidDiagnosisArrayForPrescription(selectedDiagnosis)) &&
                      getValidDiagnosisArrayForPrescription(selectedDiagnosis)?.length > 0 &&
                      checkIfItemIsOnCurrentPage('diagnosis') && (
                        <PrintableDiagnosis
                          debug={debug}
                          selectedDiagnosis={selectedDiagnosis}
                          debugHighlightStates={debugHighlightStates}
                          styleOverrides={
                            prescriptionViewSettings?.styleOverridesForPrintedEmrSections?.[
                              'diagnosis'
                            ]
                          }
                        />
                      )}
                  </div>
                </div>
                {checkIfItemIsOnCurrentPage('drug_advice') && (
                  <PrescriptionDrugAdviceTable
                    prescriptionData={prescriptionData}
                    drugsAdvice={drugsAdvice}
                    prescriptionViewSettings={prescriptionViewSettings}
                    debug={debug}
                    debugHighlightStates={debugHighlightStates}
                    drugAdviceSubSections={
                      emrSections?.find((item) => item?.id === 'drug_advice')?.subSections
                    }
                    styleOverrides={
                      prescriptionViewSettings?.styleOverridesForPrintedEmrSections?.['drug_advice']
                    }
                  />
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                {Array.isArray(selectedAdvice) &&
                  selectedAdvice.length > 0 &&
                  checkIfItemIsOnCurrentPage('advice') && (
                    <PrintablePrescriptionAdvice
                      debug={debug}
                      selectedAdvice={selectedAdvice}
                      debugHighlightStates={debugHighlightStates}
                    />
                  )}
                {Array.isArray(selectedInvestigations) &&
                  selectedInvestigations.length > 0 &&
                  checkIfItemIsOnCurrentPage('investigations') && (
                    <PrintableInvestigations
                      debug={debug}
                      selectedInvestigations={selectedInvestigations}
                      finalRequestedTestsBy={finalRequestedTestsBy}
                      debugHighlightStates={debugHighlightStates}
                    />
                  )}
                {Array.isArray(selectedAssessments) &&
                  selectedAssessments?.length > 0 &&
                  checkIfItemIsOnCurrentPage(PRESCRIPTION_ITEM_ORDER_IDS.assessments) && (
                    <PrintableAssessments
                      debug={debug}
                      debugHighlightStates={debugHighlightStates}
                      selectedAssessments={selectedAssessments}
                    />
                  )}
                {suggestedBookingTherapy && checkIfItemIsOnCurrentPage('therapyRecommended') && (
                  <PrintableTherapyRecommended
                    debug={debug}
                    debugHighlightStates={debugHighlightStates}
                    suggestedBookingTherapy={suggestedBookingTherapy}
                  />
                )}
                {Array.isArray(selectedTherapies) &&
                  selectedTherapies.length > 0 &&
                  checkIfItemIsOnCurrentPage('suggestedTherapies') && (
                    <PrintableSuggestedTherapies
                      debug={debug}
                      selectedTherapies={selectedTherapies}
                      debugHighlightStates={debugHighlightStates}
                    />
                  )}
                {checkIfItemIsOnCurrentPage('joint_homunculus') && (
                  <PrintableHomunculus
                    debug={debug}
                    jointHomunculusData={jointHomunculusData}
                    debugHighlightStates={debugHighlightStates}
                  />
                )}
                {Array.isArray(selectedInstructions) &&
                  selectedInstructions.length > 0 &&
                  checkIfItemIsOnCurrentPage('instructions') && (
                    <PrintableInstructions
                      debug={debug}
                      debugHighlightStates={debugHighlightStates}
                      selectedInstructions={selectedInstructions}
                    />
                  )}
                {checkIfItemIsOnCurrentPage(PRESCRIPTION_ITEM_ORDER_IDS.refer_to) && (
                  <PrintableReferredToDoctorName
                    debug={debug}
                    debugHighlightStates={debugHighlightStates}
                    referredToDoctorName={referredToDoctorName}
                    prescriptionData={prescriptionData}
                    styleOverrides={
                      prescriptionViewSettings?.styleOverridesForPrintedEmrSections?.[
                        'referredToDoctorName'
                      ]
                    }
                  />
                )}
                {checkIfItemIsOnCurrentPage('next_visit_details') && (
                  <PrintableNextVisitColumn
                    debug={debug}
                    debugHighlightStates={debugHighlightStates}
                    nextVisitInputType={nextVisitInputType}
                    nextVisitInputValue={nextVisitInputValue}
                    prescriptionData={prescriptionData}
                    styleOverrides={
                      prescriptionViewSettings?.styleOverridesForPrintedEmrSections?.[
                        'next_visit_details'
                      ]
                    }
                  />
                )}
              </div>
            </PmpConsultationContentContainer>
          </div>
        </PmpConsultationBodyContentContainer>
        {rightSidebar && <div style={{ width: `${rightSidebarWidth}px`, flexShrink: 0 }}></div>}
      </div>
      {checkIfItemIsOnCurrentPage('prescriptionFooter') && (
        <PrescriptionPrintableFooter
          debugMode={debug}
          prescriptionData={prescriptionData}
          footerHeight={footerHeight}
          printType={printType}
          leaveSpace={false}
          styleOverrides={prescriptionViewSettings?.paperPrintSettings?.footerSettings?.printStyles}
        />
      )}
      {checkIfItemIsOnCurrentPage(PRESCRIPTION_ITEM_ORDER_IDS.continuedTo) && (
        <ContinuedToAndFromDiv currentText={`Continuing ${currentPage}/${totalPages}`} />
      )}
    </>
  )
}

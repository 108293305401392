import React from 'react'
import {
  PmpPatientDetails,
  PrescriptionBody,
  PrescriptionBodyFirstSection,
  PrescriptionDrugAdviceTable,
  PrescriptionFooter,
  PrescriptionHeader,
} from '../PrescriptionMakingPageComponents'
import {
  PmpBodyContentContainer,
  PmpContentContainer,
  PMPParent,
} from '../PrescriptionMakingPageStyles'
import A4PreviewWrapper from './A4PreviewWrapper'
import {
  PRESCRIPTION_BUILDER_VIEW_TYPES,
  PRESCRIPTION_LAYOUTS,
  PRESCRIPTION_PRINT_TYPES,
} from 'src/consumer/constants'
import { PRESCRIPTION_COLORS_MAP } from '../inputComponents/common/color-utils'
import PrintableVitals from './printComponents/prescriptionPrintableItems/PrintableVitals'

export default function OldPrescriptionViewComponent({
  debugMode,
  printSettings,
  prescriptionData,
  printType,
  prescriptionViewSettings,
  selectedObservations,
  selectedComplaints,
  selectedDiagnosis,
  selectedAssessments,
  drugsAdvice,
  value,
  setValue,
  placeholder,
  honorPropValue,
  setPropValue,
  selectedAdvice,
  selectedTherapies,
  nextVisitInputType,
  nextVisitInputValue,
  suggestedBookingTherapy,
  selectedInstructions,
  selectedInvestigations,
  referredToDoctorName,
  finishTestsRequestedBy,
  jointHomunculusData,
  drugEmrSubSections,
  vital,
}) {
  return (
    <A4PreviewWrapper selectedDpi={printSettings?.dpi}>
      <PMPParent
        biggerFont={prescriptionData?.layout === PRESCRIPTION_LAYOUTS.largerFontLayout}
        noBorder={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
        className={
          printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace
            ? ''
            : 'final-printable make-all-borders-black-print'
        }
        hiddenInUI={
          prescriptionViewSettings?.prescriptionBuilderViewType ===
          PRESCRIPTION_BUILDER_VIEW_TYPES.tabletView
        }
      >
        <PrescriptionHeader
          prescriptionData={prescriptionData}
          leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
          debug={debugMode}
          printType={printType}
        />
        <PmpBodyContentContainer debug={debugMode}>
          <PmpPatientDetails
            prescriptionData={prescriptionData}
            leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
            debug={debugMode}
            prescriptionViewSettings={prescriptionViewSettings}
          />
          <PrintableVitals vital={vital} printType={printType} />
          <PmpContentContainer debug={debugMode}>
            <div className="d-flex flex-column gap-3">
              <div
                style={{
                  width: '100%',
                }}
              >
                <PrescriptionBodyFirstSection
                  selectedObservations={selectedObservations}
                  selectedComplaints={selectedComplaints}
                  selectedDiagnosis={selectedDiagnosis}
                  prescriptionData={prescriptionData}
                  debug={debugMode}
                />
              </div>
              <div
                style={{
                  width: '100%',
                  background: debugMode ? PRESCRIPTION_COLORS_MAP.drugAdvice?.color : 'unset',
                }}
              >
                <PrescriptionDrugAdviceTable
                  prescriptionData={prescriptionData}
                  drugsAdvice={drugsAdvice}
                  prescriptionViewSettings={prescriptionViewSettings}
                  debug={debugMode}
                  drugAdviceSubSections={drugEmrSubSections}
                />
              </div>
            </div>
            <PrescriptionBody
              value={value}
              setValue={setValue}
              placeholder={placeholder}
              alsoChangePropValue={Boolean(honorPropValue)}
              setPropValue={setPropValue}
              selectedAdvice={selectedAdvice}
              selectedTherapies={selectedTherapies}
              nextVisitInputType={nextVisitInputType}
              nextVisitInputValue={nextVisitInputValue}
              prescriptionData={prescriptionData}
              selectedAssessments={selectedAssessments}
              suggestedBookingTherapy={suggestedBookingTherapy}
              selectedInstructions={selectedInstructions}
              selectedInvestigations={selectedInvestigations} // todo: put in ViewOnlyPrescriptions - both type
              jointHomunculusData={jointHomunculusData}
              prescriptionViewSettings={prescriptionViewSettings}
              finalRequestedTestsBy={finishTestsRequestedBy}
              referredToDoctorName={referredToDoctorName}
              debug={debugMode}
              drugEmrSubSections={drugEmrSubSections}
              hidePatientDetails
            />
          </PmpContentContainer>
        </PmpBodyContentContainer>
        <PrescriptionFooter
          prescriptionData={prescriptionData}
          leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
          debug={debugMode}
        />
      </PMPParent>
    </A4PreviewWrapper>
  )
}

import React, { useState } from 'react'
import { Button, Popover } from '@mui/material'
import Calendar from 'react-calendar'
import dayjs from 'dayjs'
import 'react-calendar/dist/Calendar.css'
import { DayJsDateSelectorCalendarStyleContainer } from './styledComponents'

const DayjsDatePicker = ({
  date: dayjsDate,
  isDisabled = false,
  onChange,
  format = 'DD-MMM-YYYY',
  placeholder = 'Select a date...',
  extraHandlingAfterOnClick = () => {},
  buttonProps = {},
  ButtonComponent = Button,
  calendarProps = {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null)

  // Open the popover by setting the anchor element (the button)
  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget)
    extraHandlingAfterOnClick()
  }

  // Close the popover
  const handleClose = () => {
    setAnchorEl(null)
  }

  // When a date is selected in the calendar
  const handleDateSelect = (date) => {
    // Convert the selected Date object to a dayjs object
    const newDayjsDate = dayjs(date)
    // Call the provided onChange callback
    onChange(newDayjsDate)
    // Close the popover
    handleClose()
  }

  // Determine if the popover is open
  const open = Boolean(anchorEl)
  const id = open ? 'dayjs-date-picker-popover' : undefined

  // Ensure we have a Date object for react-calendar
  const selectedDate = dayjsDate ? dayjs(dayjsDate).toDate() : new Date()

  return (
    <>
      <ButtonComponent
        disabled={isDisabled}
        variant="outlined"
        onClick={handleButtonClick}
        {...buttonProps}
      >
        {dayjsDate ? dayjs(dayjsDate).format(format) : placeholder}
      </ButtonComponent>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <DayJsDateSelectorCalendarStyleContainer>
          <Calendar onChange={handleDateSelect} value={selectedDate} {...calendarProps} />
        </DayJsDateSelectorCalendarStyleContainer>
      </Popover>
    </>
  )
}

export default DayjsDatePicker

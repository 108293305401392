import { FOOTER_TYPES, HEADER_TYPES } from 'src/consumer/constants'

export const PRESCRIPTION_ITEM_ORDER_IDS = {
  continuedFrom: 'continuedFrom',
  continuedTo: 'continuedTo',
  patientDetails: 'patientDetails',
  prescriptionFooter: 'prescriptionFooter',
  assessments: 'psych_assessments',
  refer_to: 'refer_to',
}

export const EXCLUDED_ORDER_IDS_FROM_PRESC = [
  PRESCRIPTION_ITEM_ORDER_IDS.continuedTo,
  PRESCRIPTION_ITEM_ORDER_IDS.continuedFrom,
  PRESCRIPTION_ITEM_ORDER_IDS.patientDetails,
  PRESCRIPTION_ITEM_ORDER_IDS.prescriptionFooter,
]

export function getOrderItemsArrayWithTopLocation({ orderOfItems, headerHeight, footerHeight }) {
  const baseTopOffset = headerHeight
  let currentPosition = baseTopOffset
  let orderofItemsWithTopOffset = []

  for (let item of orderOfItems) {
    if (!item || !item?.dimension) {
      continue
    }
    let currentItem = { ...item }
    currentItem = { ...item, currentPosition }
    orderofItemsWithTopOffset = [...orderofItemsWithTopOffset, currentItem]

    currentPosition += item?.dimension?.height
  }
  return orderofItemsWithTopOffset
}

export function getPageDetailsNumberOfAnItem({
  headerHeight,
  footerHeight,
  pageHeight,
  pageBreaks = [],
  itemInfo,
}) {
  const currentTopPosition = itemInfo?.currentPosition
  const currentBottomPosition = itemInfo?.currentPosition + itemInfo?.dimension?.height
  const numberOfPages = pageBreaks?.length + 1
  const pagesStartToEndMapArr = Array.from({ length: numberOfPages }, (v, idx) => {
    return {
      start: pageHeight * idx,
      end: pageHeight * (idx + 1),
    }
  })

  let pages = []

  let idx = 0

  for (let pageDetails of pagesStartToEndMapArr) {
    if (currentTopPosition > pageDetails?.start && currentTopPosition < pageDetails?.end) {
      pages?.push(idx + 1)
    }
    if (currentBottomPosition > pageDetails?.start && currentBottomPosition < pageDetails?.end) {
      pages?.push(idx + 1)
    }
    ++idx
  }

  pages = new Set(pages)

  return Array.from(pages || [])
}

function getPageNumberBasedOnPrescriptionCurrentCoordinates({
  pageBreaks,
  yCoordinate,
  xCoordinate,
  pageHeight,
  currentBottomPosition: endingCoordinate,
}) {
  const currentBottomPosition = endingCoordinate || yCoordinate

  const numberOfPages = pageBreaks?.length + 1
  const pagesStartToEndMapArr = Array.from({ length: numberOfPages }, (v, idx) => {
    return {
      start: pageHeight * idx,
      end: pageHeight * (idx + 1),
    }
  })

  let pages = []

  let idx = 0
  for (let pageDetails of pagesStartToEndMapArr) {
    if (yCoordinate > pageDetails?.start && yCoordinate < pageDetails?.end) {
      pages?.push(idx + 1)
    }
    if (currentBottomPosition > pageDetails?.start && currentBottomPosition < pageDetails?.end) {
      pages?.push(idx + 1)
    }
    ++idx
  }
  pages = new Set(pages)

  return Array.from(pages || [])?.[0]
}

export function getCoordinatesStringForPrescItems({ item, a4PageDimensions, prescriptionBreaks }) {
  return `COORDINATES => TL: (${(item?.dimension?.x - a4PageDimensions?.x)?.toFixed(
    2,
  )}, ${item?.currentPosition?.toFixed(2)}, ${getPageNumberBasedOnPrescriptionCurrentCoordinates({
    xCoordinate: item?.dimension?.x - a4PageDimensions?.x,
    yCoordinate: item?.currentPosition,
    pageBreaks: prescriptionBreaks,
    pageHeight: a4PageDimensions?.height,
  })}); 
  TR: (${(item?.dimension?.x - a4PageDimensions?.x + item?.dimension?.width)?.toFixed(
    2,
  )}, ${item?.currentPosition?.toFixed(2)}, ${getPageNumberBasedOnPrescriptionCurrentCoordinates({
    xCoordinate: item?.dimension?.x - a4PageDimensions?.x + item?.dimension?.width,
    yCoordinate: item?.currentPosition,
    pageBreaks: prescriptionBreaks,
    pageHeight: a4PageDimensions?.height,
  })}) BL: (${(item?.dimension?.x - a4PageDimensions?.x)?.toFixed(2)}, ${(
    item?.currentPosition + item?.dimension?.height
  )?.toFixed(2)}, ${getPageNumberBasedOnPrescriptionCurrentCoordinates({
    xCoordinate: item?.dimension?.x - a4PageDimensions?.x,
    yCoordinate: item?.currentPosition + item?.dimension?.height,
    pageBreaks: prescriptionBreaks,
    pageHeight: a4PageDimensions?.height,
  })}) BR: (${(item?.dimension?.x - a4PageDimensions?.x + item?.dimension?.width)?.toFixed(2)}, ${(
    item?.currentPosition + item?.dimension?.height
  )?.toFixed(2)}, ${getPageNumberBasedOnPrescriptionCurrentCoordinates({
    xCoordinate: item?.dimension?.x - a4PageDimensions?.x + item?.dimension?.width,
    yCoordinate: item?.currentPosition + item?.dimension?.height,
    pageBreaks: prescriptionBreaks,
    pageHeight: a4PageDimensions?.height,
  })})`
}

export function getPrescriptionItemsPageDetailsBasedOnHeaderFooterType({
  orderOfItems,
  headerHeight,
  footerHeight,
  headerType,
  footerType,
  pageHeight,
  pageBreaks,
  continuedFromPageHeight,
  continuedToPageHeight,
}) {
  const orderOfItemsWithPageDetails = []

  let currIdx = 0
  let currPage = 1

  // console.log({ orderOfItems })

  for (let item of orderOfItems) {
    let prescriptionBodyHeight = getPrescriptionBodyHeight({
      pageHeight,
      headerHeight: getHeaderHeightBasedOnTypeAndPageIdx({
        headerType,
        pageIdx: currIdx,
        headerHeightSetByUser: headerHeight,
      }),
    })

    if (currIdx === orderOfItems?.length - 1) {
      prescriptionBodyHeight = prescriptionBodyHeight - footerHeight
    }

    const currentPosition = item?.currentPosition - headerHeight
    const currentBottomPosition =
      currentPosition + item?.dimension?.height - prescriptionBodyHeight * (currPage - 1)
    if (currentBottomPosition > prescriptionBodyHeight) {
      currPage += 1
    }

    orderOfItemsWithPageDetails.push({
      ...item,
      page: currPage,
    })
  }

  // console.log({ orderOfItemsWithPageDetails })

  return orderOfItemsWithPageDetails
}

export function getPrescriptionItemRenderingList(
  itemsInOrder,
  headerHeight,
  footerHeight,
  leftSidebarWidth,
  rightSidebarWidth,
  a4Height,
  headerPolicy,
  footerPolicy,
  sidebarPolicy,
  continuedDivHeight,
) {
  let bodyItemsInOrder = itemsInOrder?.filter((item) => !!item?.dimension)
  let finalOrderObjects = []

  let currPage = 1
  let totalHeightInPxAccomodatedByItems = 0

  let currentOrderObject = getPageDetailsObject(
    headerPolicy,
    footerPolicy,
    sidebarPolicy,
    currPage,
    [],
  )

  for (let bodyItem of bodyItemsInOrder) {
    const headerHeightOnPage = getHeaderHeightBasedOnPolicyAndPage(
      headerHeight,
      currPage,
      headerPolicy,
    )
    const footerHeightOnPage = getFooterHeightBasedOnPolicyAndPage(
      footerHeight,
      currPage,
      footerPolicy,
    )
    //sidebar height not needed as of now.

    const remainingPrescriptionBodyHeight = getRemainingPrescriptionBodyHeight(
      a4Height,
      headerHeightOnPage,
      footerHeightOnPage,
      totalHeightInPxAccomodatedByItems,
    )
    const currentItemHeight = getCurrentItemHeight(bodyItem)

    if (
      isItemFittingInRemainingPresciptionBody(remainingPrescriptionBodyHeight, currentItemHeight)
    ) {
      currentOrderObject = {
        ...currentOrderObject,
        items: [...currentOrderObject?.items, bodyItem?.orderId],
      }
      totalHeightInPxAccomodatedByItems += currentItemHeight
    } else {
      currentOrderObject = {
        ...currentOrderObject,
        items: [...currentOrderObject?.items, PRESCRIPTION_ITEM_ORDER_IDS.continuedTo],
      }
      finalOrderObjects.push(currentOrderObject)
      currPage += 1
      totalHeightInPxAccomodatedByItems = bodyItem?.dimension?.height + continuedDivHeight
      currentOrderObject = getPageDetailsObject(
        headerPolicy,
        footerPolicy,
        sidebarPolicy,
        currPage,
        [PRESCRIPTION_ITEM_ORDER_IDS.continuedFrom, bodyItem?.orderId],
      )
    }
  }
  // todo: fooer on last page
  // todo: left and right sidebar space being left on alt pages. Should not happen

  if (!finalOrderObjects?.map((obj) => obj?.currPage)?.includes(currPage)) {
    finalOrderObjects.push(currentOrderObject)
  }

  return finalOrderObjects
}

////////////////////PRIVATE///////////////////////////////
function getHeaderHeightBasedOnTypeAndPageIdx({ headerType, pageIdx, headerHeightSetByUser }) {
  switch (headerType) {
    case HEADER_TYPES.headerOnFirstPage:
      return pageIdx === 0 ? headerHeightSetByUser : 0
    case HEADER_TYPES.onAlternatePages:
      return pageIdx % 2 === 0 ? headerHeightSetByUser : 0
    // case HEADER_TYPES.blankHeader:
    // case HEADER_TYPES.onAllPages:
    //   return headerHeightSetByUser
    default:
      return headerHeightSetByUser
  }
}

function getPrescriptionBodyHeight({ pageHeight, headerHeight }) {
  return pageHeight - headerHeight
}

function getPageDetailsObject(
  headerPolicy,
  footerPolicy,
  sidebarPolicy,
  currPage,
  initialArray = [],
) {
  let obj = {
    items: [...initialArray],
    header: false,
    footer: false,
    leftSidebar: false,
    rightSidebar: false,
    page: currPage,
  }

  if (isHeaderRendered(headerPolicy, currPage)) {
    obj.header = true
  }
  if (isFooterRendered(footerPolicy, currPage)) {
    obj.footer = true
  }
  if (isLeftSidebarRendered(sidebarPolicy, currPage)) {
    obj.leftSidebar = true
  }
  if (isRightSidebarRendered(sidebarPolicy, currPage)) {
    obj.rightSidebar = true
  }
  return obj
}

function isHeaderRendered(headerPolicy, currPage) {
  if (headerPolicy === HEADER_TYPES.onFirstPage) {
    if (currPage !== 1) {
      return false
    }
  }
  if (headerPolicy === HEADER_TYPES.onAlternatePages) {
    if ((currPage - 1) % 2 !== 0) {
      return false
    }
  }
  if (headerPolicy === HEADER_TYPES.onFirstPage) {
    if (currPage > 1) {
      return false
    }
  }

  return true
}
function isFooterRendered(footerPolicy, currPage, isLastPage) {
  if (footerPolicy === FOOTER_TYPES.onAlternatePages) {
    if (currPage - (1 % 2) !== 0) {
      return false
    }
  }
  if (footerPolicy === FOOTER_TYPES.onLastPage || footerPolicy === FOOTER_TYPES.inLineAtEnd) {
    return false
  }

  return true
}
function isLeftSidebarRendered(headerPolicy, currPage) {
  return true
}
function isRightSidebarRendered(headerPolicy, currPage) {
  return true
}

function getHeaderHeightBasedOnPolicyAndPage(headerHeight, currPage, headerPolicy) {
  if (isHeaderRendered(headerPolicy, currPage)) {
    return headerHeight
  }
  return 0
}
function getFooterHeightBasedOnPolicyAndPage(footerHeight, currPage, footerPolicy) {
  if (isFooterRendered(footerPolicy, currPage)) {
    return footerHeight
  }
  return 0
}

function getRemainingPrescriptionBodyHeight(
  a4Height,
  headerHeightOnPage,
  footerHeightOnPage,
  totalHeightInPxAccomodatedByItems,
) {
  const bodyHeight = getBodyHeight(a4Height, headerHeightOnPage, footerHeightOnPage)

  return bodyHeight - totalHeightInPxAccomodatedByItems
}

function getCurrentItemHeight(bodyItem) {
  return bodyItem?.dimension?.height
}

function getBodyHeight(a4Height, headerHeight, footerHeightOnPage) {
  return a4Height - (headerHeight + footerHeightOnPage)
}

function isItemFittingInRemainingPresciptionBody(
  remainingPrescriptionBodyHeight,
  currentItemHeight,
) {
  if (currentItemHeight > remainingPrescriptionBodyHeight) {
    return false
  }
  return true
}

// function getHeaderHeightBasedOnPolicyAndPage(headerHeight, currPage, headerPolicy) {

// }

export function checkAndRenderBasedOnEmrSections(secId, emrSections) {
  let sectionId = secId
  if (sectionId === 'therapyRecommended' || sectionId === 'suggestedTherapies') {
    sectionId = 'therapy_recommendation'
  }

  const foundSection = emrSections?.find((section) => {
    if (section?.id === sectionId) {
      return true
    }
    return false
  })
  if (!foundSection) {
    return false
  }
  if (!foundSection?.selected) {
    return false
  }
  if (!foundSection?.printable) {
    return false
  }
  return true
}

export function findEmrSectionBuSectionId(emrSections, sectionId, findOnUnselected) {
  return emrSections?.find((section) => {
    if (section?.id === sectionId) {
      return true
    }
    return false
  })
}

export function getA4Size() {
  const dpi = window.devicePixelRatio * 96 // Get approximate DPI
  return {
    width: Math.round((210 / 25.4) * dpi), // Convert mm to pixels
    height: Math.round((297 / 25.4) * dpi),
  }
}

import { useEffect, useMemo, useState } from 'react'
import {
  A4DpiToDetailsMap,
  FOOTER_TYPES,
  PRESCRIPTION_BUILDER_VIEW_TYPES,
  PRESCRIPTION_LAYOUTS,
  PRESCRIPTION_PRINT_TYPES,
} from 'src/consumer/constants'
import useElementDimensions from 'src/customHooks/customHooks'
import { PrescriptionFooter, PrescriptionHeader } from '../PrescriptionMakingPageComponents'
import { PMPConsultationParent, PMPV2FooterContainer } from '../PrescriptionMakingPageStyles'
import A4PreviewWrapper from './A4PreviewWrapper'
import PrescriptionFullBody from './printComponents/PrescriptionFullBody'
import PrescriptionPrintableBody from './printComponents/PrescriptionPrintableBody'
import PrescriptionPrintableFooter from './printComponents/PrescriptionPrintableFooter'
import PrescriptionPrintableHeader from './printComponents/PrescriptionPrintableHeader'
import ContinuedToAndFromDiv from './printComponents/prescriptionPrintableItems/ContinuedToAndFromDiv'
import { A4WrapperContainer } from './styledComponents'
import { getA4Size, getPrescriptionItemRenderingList, PRESCRIPTION_ITEM_ORDER_IDS } from './utils'
import OldPrescriptionViewComponent from './OldPrescriptionViewComponent'

export default function PrescriptionViewComponent({
  printSettings,
  prescriptionData: prescData,
  prescriptionViewSettings,
  printType,
  debugMode,
  drugsAdvice,
  selectedAdvice,
  selectedTherapies,
  selectedInstructions,
  selectedInvestigations,
  selectedObservations,
  selectedAssessments,
  selectedComplaints,
  selectedDiagnosis,
  vital,
  jointHomunculusData,
  suggestedBookingTherapy,
  emrSections,
  value,
  setValue,
  placeholder = 'Enter prescription...',
  honorPropValue,
  setPropValue,
  nextVisitInputType,
  nextVisitInputValue,
  prescriptionTemplateData,
  finalRequestedTestsBy,
  oldUIDrugSubSections,
  referredToDoctorName,
  showInUI,
  refreshDimensionsDependencies = [],
  useOldUI = false,
}) {
  const prescriptionData = useMemo(() => {
    if (prescriptionTemplateData) {
      return {
        ...prescriptionTemplateData,
        prescriptionData: prescData?.prescriptionData,
        patientPrefill: prescData?.patientPrefill,
      }
    }
    return prescData
  }, [prescData, prescriptionTemplateData])

  const currentDpiData = A4DpiToDetailsMap?.[printSettings?.dpi]
  // const heightOfPageMeasureComponent = a4PageDimensions?.width * currentDpiData?.aspectRatio
  // const heightOfPageMeasureComponent = 1123.1999999999998 - (76.8 + 4)
  // const heightOfPageMeasureComponent = 794 - 0.5 //a5
  // currentDpiData?.id * 0.4 * 2 - 1 //0.5 sub for height error
  let heightOfPageMeasureComponent = 1123.1999999999998 - 0.5
  // let heightOfPageMeasureComponent = getA4Size().height - 0.5
  let a4PageWidth = 796.8
  // let a4PageWidth = getA4Size().width
  if (printSettings?.topMargin) {
    heightOfPageMeasureComponent =
      heightOfPageMeasureComponent - printSettings?.topMargin * currentDpiData?.id
  }
  if (printSettings?.bottomMargin) {
    heightOfPageMeasureComponent =
      heightOfPageMeasureComponent - printSettings?.bottomMargin * currentDpiData?.id
  }

  if (printSettings?.leftMargin) {
    a4PageWidth = a4PageWidth - printSettings?.leftMargin * currentDpiData?.id
  }
  if (printSettings?.rightMargin) {
    a4PageWidth = a4PageWidth - printSettings?.rightMargin * currentDpiData?.id
  }

  // const a4PageWidth = 559
  // above dimensions are being done in 96 dpi

  const {
    dimensions: a4PageDimensions,
    ref: a4PageRef,
    refresh: a4PageDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: headerDimensions,
    ref: headerRef,
    refresh: headerDimensionsRefresh,
  } = useElementDimensions()
  const { dimensions: prescriptionContinuousBodyDimensions, ref: prescriptionContinuousBodyRef } =
    useElementDimensions()
  const {
    dimensions: footerDimensions,
    ref: footerRef,
    refresh: footerDimensionsRefresh,
  } = useElementDimensions()

  // prescription items dimensions
  const {
    dimensions: patientDetailsDimensions,
    ref: patientDetailsRef,
    refresh: patientDetailsDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: vitalsDimensions,
    ref: vitalsRef,
    refresh: refreshVitalDimensions,
  } = useElementDimensions()
  const {
    dimensions: observationDimensions,
    ref: observationRef,
    refresh: observationsDimenstionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: complaintsDimensions,
    ref: complaintsRef,
    refresh: complaintsDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: diagnosisDimensions,
    ref: diagnosisRef,
    refresh: diagnosisDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: drugAdviceTableDimensions,
    ref: drugAdviceTableRef,
    refresh: drugAdviceDimenstionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: prescriptionAdviceDimensions,
    ref: prescriptionAdviceRef,
    refresh: prescriptionAdviceDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: investigationsDimensions,
    ref: investigationsRef,
    refresh: investigationsDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: assessmentDimensions,
    ref: assessmentsRef,
    refresh: assessmentsDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: therapyRecommendedDimensions,
    ref: therapyRecommendedRef,
    refresh: therapyRecommendedDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: suggestedTherapiesDimensions,
    ref: suggestedTherapiesRef,
    refresh: suggestedTherapiesDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: homunculusDimensions,
    ref: homunculusRef,
    refresh: homunculusDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: instructionsDimensions,
    ref: instructionsRef,
    refresh: instructionsDimensionsRefresh,
  } = useElementDimensions()
  const {
    dimensions: nextVisitDimensions,
    ref: nextVisitRef,
    refresh: nextVisitDimensionsRefresh,
  } = useElementDimensions()

  const {
    dimensions: continuedFromDimensions,
    ref: continuedFromRef,
    refresh: continuedFromDimensionsRefresh,
  } = useElementDimensions()

  const {
    dimensions: referredToDoctorNameDimensions,
    ref: referredToDoctorNameRef,
    refresh: referredToDoctorNameRefresh,
  } = useElementDimensions()

  const [debugHighlightStates, setDebugHighlightStates] = useState({
    patientDetails: false,
    observations: false,
    complaints: false,
    diagnosis: false,
    drugAdvice: false,
    prescriptionAdvice: false,
    therapyRecommended: false,
    suggestedTherapies: false,
    homunculus: false,
    instructions: false,
    investigations: false,
    nextVisit: false,
  })

  const headerStyleToShow =
    printSettings?.printType === 'pdf'
      ? printSettings?.pdfHeaderType
      : printSettings?.printHeaderType

  const headerHeight =
    (printSettings?.printType === 'pdf'
      ? printSettings?.pdfHeaderHeight
      : printSettings?.headerHeight) * currentDpiData?.id

  const footerStyleToShow =
    printSettings?.printType === 'pdf'
      ? printSettings?.pdfFooterType
      : printSettings?.printFooterType

  const footerHeight =
    (printSettings?.printType === 'pdf'
      ? printSettings?.pdfFooterHeight
      : printSettings?.footerHeight) * currentDpiData?.id

  const refreshAllDimensions = () => {
    a4PageDimensionsRefresh()
    headerDimensionsRefresh()
    footerDimensionsRefresh()
    patientDetailsDimensionsRefresh()
    observationsDimenstionsRefresh()
    complaintsDimensionsRefresh()
    diagnosisDimensionsRefresh()
    drugAdviceDimenstionsRefresh()
    prescriptionAdviceDimensionsRefresh()
    investigationsDimensionsRefresh()
    therapyRecommendedDimensionsRefresh()
    assessmentsDimensionsRefresh()
    suggestedTherapiesDimensionsRefresh()
    homunculusDimensionsRefresh()
    instructionsDimensionsRefresh()
    nextVisitDimensionsRefresh()
    continuedFromDimensionsRefresh()
    refreshVitalDimensions()
    referredToDoctorNameRefresh()
  }

  const leftSidebarWidth = printSettings?.leftSidebarWidth * currentDpiData?.id
  const rightSidebarWidth = printSettings?.rightSidebarWidth * currentDpiData?.id

  //log all the prescription items dimensions in map form

  const dimensionsMap = {
    headerDimensions,
    footerDimensions,
    patientDetailsDimensions,
    observationDimensions,
    complaintsDimensions,
    diagnosisDimensions,
    drugAdviceTableDimensions,
    prescriptionAdviceDimensions,
    investigationsDimensions,
    therapyRecommendedDimensions,
    suggestedTherapiesDimensions,
    homunculusDimensions,
    instructionsDimensions,
    nextVisitDimensions,
    vitalsDimensions,
  }

  const orderOfPrescriptionItems = [
    { orderId: 'patientDetails', title: 'Patient Details', dimension: patientDetailsDimensions },
    { orderId: 'patient-vitals', title: 'Patient Vitals', dimension: vitalsDimensions },
    { orderId: 'observations', title: 'Heath Observations', dimension: observationDimensions },
    { orderId: 'health_complaints', title: 'Patient Complaints', dimension: complaintsDimensions },
    { orderId: 'diagnosis', title: 'Diagnosis', dimension: diagnosisDimensions },
    { orderId: 'drug_advice', title: 'Rx', dimension: drugAdviceTableDimensions },
    {
      orderId: 'advice',
      title: 'Prescription Advice',
      dimension: prescriptionAdviceDimensions,
    },
    {
      orderId: 'investigations',
      title: 'Recommended Investigations',
      dimension: investigationsDimensions,
    },
    {
      orderId: PRESCRIPTION_ITEM_ORDER_IDS.assessments,
      title: 'Assessments',
      dimension: assessmentDimensions,
    },
    {
      orderId: 'therapyRecommended',
      title: 'Therapy Recommendations',
      dimension: therapyRecommendedDimensions,
    },

    {
      orderId: 'suggestedTherapies',
      title: 'Suggested Therapies',
      dimension: suggestedTherapiesDimensions,
    },

    {
      orderId: 'joint_homunculus',
      title: 'Body Diagram (Homunculus)',
      dimension: homunculusDimensions,
    },
    {
      orderId: 'instructions',
      title: 'Instructions for Patient',
      dimension: instructionsDimensions,
    },
    {
      orderId: PRESCRIPTION_ITEM_ORDER_IDS.refer_to,
      title: 'Referred To Doctor Name',
      dimension: referredToDoctorNameDimensions,
    },
    { orderId: 'next_visit_details', title: 'Next Visit Schedule', dimension: nextVisitDimensions },
    ...(footerStyleToShow === FOOTER_TYPES.onLastPage ||
    footerStyleToShow === FOOTER_TYPES.inLineAtEnd
      ? [
          {
            orderId: 'prescriptionFooter',
            title: 'Footer',
            dimension: footerDimensions,
          },
        ]
      : []),
  ]

  const itemRenderingDetailsList = getPrescriptionItemRenderingList(
    orderOfPrescriptionItems,
    headerHeight,
    footerHeight || 0,
    printSettings?.leftSidebarWidth,
    printSettings?.rightSidebarWidth,
    a4PageDimensions?.height,
    headerStyleToShow,
    footerStyleToShow,
    null, //TODO : sidebar policy
    continuedFromDimensions?.height || 0,
  )

  function onHoverItem({ e, prescItem }) {
    setDebugHighlightStates((prev) => ({ ...prev, [prescItem?.orderId]: true }))
  }

  function onMouseLeaveItem({ prescItem }) {
    setDebugHighlightStates((prev) => ({ ...prev, [prescItem?.orderId]: false }))
  }

  useEffect(() => {
    refreshAllDimensions()
  }, refreshDimensionsDependencies)

  // useEffect(() => {
  //   console.log({ debugHighlightStates })
  // }, [debugHighlightStates])

  // useEffect(() => {
  //   console.log({ prescriptionContinuousBodyRef })
  // }, [prescriptionContinuousBodyRef])

  useEffect(() => {
    // console.log({ printSettings, itemRenderingDetailsList })
  }, [printSettings, itemRenderingDetailsList])

  // console.log({ itemRenderingDetailsList, orderOfPrescriptionItems })

  // console.log({ emrSections })

  // useEffect(() => {
  //   console.log({ itemRenderingDetailsList })
  // }, [itemRenderingDetailsList])

  // console.log({
  //   printSettings,
  //   itemRenderingDetailsList,
  //   headerHeight,
  //   currentDpiData,
  //   lSbarW: printSettings?.leftSidebarWidth,
  //   rSbarW: printSettings?.rightSidebarWidth,
  // })

  if (useOldUI) {
    return (
      <OldPrescriptionViewComponent
        debugMode={debugMode}
        drugsAdvice={drugsAdvice}
        honorPropValue={honorPropValue}
        jointHomunculusData={jointHomunculusData}
        nextVisitInputType={nextVisitInputType}
        nextVisitInputValue={nextVisitInputValue}
        placeholder={placeholder}
        prescriptionData={prescriptionData}
        prescriptionViewSettings={prescriptionViewSettings}
        printSettings={printSettings}
        printType={printType}
        selectedAdvice={selectedAdvice}
        selectedComplaints={selectedComplaints}
        selectedDiagnosis={selectedDiagnosis}
        selectedInstructions={selectedInstructions}
        selectedInvestigations={selectedInvestigations}
        selectedObservations={selectedObservations}
        selectedAssessments={selectedAssessments}
        selectedTherapies={selectedTherapies}
        oldUIDrugSubSections={oldUIDrugSubSections}
        setPropValue={setPropValue}
        setValue={setValue}
        suggestedBookingTherapy={suggestedBookingTherapy}
        finishTestsRequestedBy={finalRequestedTestsBy}
        referredToDoctorName={referredToDoctorName}
        vital={vital}
        drugEmrSubSections={
          oldUIDrugSubSections
            ? oldUIDrugSubSections
            : emrSections?.find((item) => item?.id === 'drug_advice')?.subSections
        }
        value={value}
      />
    )
  }

  return (
    <A4WrapperContainer showInUI={showInUI}>
      {/* <PrescriptionHeaderBodyFooterDebugLabels
        a4PageWidth={a4PageWidth}
        debugMode={debugMode}
        headerDimensions={headerDimensions}
        headerHeight={headerHeight}
        headerStyleToShow={headerStyleToShow}
        heightOfPageMeasureComponent={heightOfPageMeasureComponent}
        prescriptionBreaks={prescriptionBreaks}
        prescriptionData={prescriptionData}
        printType={printType}
      /> */}
      {/* printable prescription ui */}
      {itemRenderingDetailsList?.map((pageDetails, breakIdx) => {
        return (
          <div key={breakIdx + 1}>
            <A4PreviewWrapper
              margin={0}
              // pageStartTop={breakHeight + 20}
              pageNumber={breakIdx + 1}
              a4Width={a4PageWidth}
              showPageView
              heightOfOnePageInPixels={heightOfPageMeasureComponent}
              // debugMode={debugMode}
              zIndex={0}
            >
              <PMPConsultationParent
                biggerFont={prescriptionData?.layout === PRESCRIPTION_LAYOUTS.largerFontLayout}
                noBorder={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
                className={
                  printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace
                    ? ''
                    : 'final-printable make-all-borders-black-print'
                }
                hiddenInUI={
                  prescriptionViewSettings?.prescriptionBuilderViewType ===
                  PRESCRIPTION_BUILDER_VIEW_TYPES.tabletView
                }
              >
                <PrescriptionPrintableHeader
                  currentPage={breakIdx + 1}
                  debugMode={debugMode}
                  hideDebugLabels
                  headerDimensionsHeight={headerDimensions?.height}
                  headerHeight={pageDetails?.header ? headerHeight : 0}
                  headerStyleToShow={headerStyleToShow}
                  idx={breakIdx}
                  pageHeight={heightOfPageMeasureComponent}
                  prescriptionData={prescriptionData}
                  printType={printType}
                  showBottomBorder={breakIdx !== 0}
                  leaveSpace={printSettings?.blankHeader}
                />
                <PrescriptionPrintableBody
                  value={value}
                  setValue={setValue}
                  placeholder={placeholder}
                  alsoChangePropValue={Boolean(honorPropValue)}
                  setPropValue={setPropValue}
                  selectedAdvice={selectedAdvice}
                  selectedTherapies={selectedTherapies}
                  nextVisitInputType={nextVisitInputType}
                  nextVisitInputValue={nextVisitInputValue}
                  prescriptionData={prescriptionData}
                  suggestedBookingTherapy={suggestedBookingTherapy}
                  selectedAssessments={selectedAssessments}
                  selectedInstructions={selectedInstructions}
                  selectedInvestigations={selectedInvestigations} // todo: put in ViewOnlyPrescriptions - both type
                  referredToDoctorName={referredToDoctorName}
                  finalRequestedTestsBy={finalRequestedTestsBy}
                  jointHomunculusData={jointHomunculusData}
                  prescriptionViewSettings={prescriptionViewSettings}
                  debug={debugMode}
                  drugsAdvice={drugsAdvice}
                  emrSections={emrSections}
                  selectedObservations={selectedObservations}
                  selectedComplaints={selectedComplaints}
                  selectedDiagnosis={selectedDiagnosis}
                  vital={vital}
                  printType={printType}
                  A4Height={heightOfPageMeasureComponent}
                  footerHeight={footerDimensions?.height}
                  headerHeight={headerDimensions?.height}
                  debugHighlightStates={debugHighlightStates}
                  currentPage={breakIdx + 1}
                  pageDetailsObject={pageDetails}
                  leftSidebar={pageDetails?.leftSidebar}
                  rightSidebar={pageDetails?.rightSidebar}
                  leftSidebarWidth={leftSidebarWidth}
                  rightSidebarWidth={rightSidebarWidth}
                  totalPages={itemRenderingDetailsList?.length}
                  footerType={footerStyleToShow}
                  isLastPage={itemRenderingDetailsList?.length - 1 === breakIdx}
                />
                <PrescriptionPrintableFooter
                  debugMode={debugMode}
                  prescriptionData={prescriptionData}
                  footerStyleToShow={footerStyleToShow}
                  footerHeight={pageDetails?.footer ? footerHeight : 0}
                  printType={printType}
                  leaveSpace={printSettings?.blankFooter}
                  styleOverrides={
                    prescriptionViewSettings?.paperPrintSettings?.footerSettings?.printStyles
                  }
                />
              </PMPConsultationParent>
            </A4PreviewWrapper>
          </div>
        )
      })}

      {/* hidden prescription ui */}
      <div
        style={{
          position: 'absolute',
          visibility: 'hidden',
          height: '0px',
          overflow: 'hidden',
          top: 0,
          width: `${a4PageWidth}px`,
        }}
      >
        <A4PreviewWrapper
          key={0}
          a4Width={a4PageWidth}
          margin={0}
          showPageView
          heightOfOnePageInPixels={heightOfPageMeasureComponent}
          pageRef={a4PageRef}
        >
          <PMPConsultationParent
            biggerFont={prescriptionData?.layout === PRESCRIPTION_LAYOUTS.largerFontLayout}
            noBorder={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
            className={
              printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace
                ? ''
                : 'final-printable make-all-borders-black-print'
            }
            hiddenInUI={
              prescriptionViewSettings?.prescriptionBuilderViewType ===
              PRESCRIPTION_BUILDER_VIEW_TYPES.tabletView
            }
          >
            <div ref={headerRef}>
              <PrescriptionHeader
                prescriptionData={prescriptionData}
                leaveSpace={false}
                debug={debugMode}
                printType={printType}
                headerHeight={headerHeight}
              />
            </div>

            <ContinuedToAndFromDiv ref={continuedFromRef} currentText={`Continued from  Page 1`} />
            <div className="d-flex justify-content-center">
              <div style={{ width: `${leftSidebarWidth}px`, flexShrink: 0 }}></div>
              <PrescriptionFullBody
                value={value}
                setValue={setValue}
                placeholder={placeholder}
                alsoChangePropValue={Boolean(honorPropValue)}
                setPropValue={setPropValue}
                selectedAdvice={selectedAdvice}
                selectedTherapies={selectedTherapies}
                nextVisitInputType={nextVisitInputType}
                nextVisitInputValue={nextVisitInputValue}
                prescriptionData={prescriptionData}
                suggestedBookingTherapy={suggestedBookingTherapy}
                selectedInstructions={selectedInstructions}
                selectedInvestigations={selectedInvestigations} // todo: put in ViewOnlyPrescriptions - both type
                jointHomunculusData={jointHomunculusData}
                prescriptionViewSettings={prescriptionViewSettings}
                debug={debugMode}
                drugsAdvice={drugsAdvice}
                selectedObservations={selectedObservations}
                selectedComplaints={selectedComplaints}
                selectedDiagnosis={selectedDiagnosis}
                selectedAssessments={selectedAssessments}
                finalRequestedTestsBy={finalRequestedTestsBy}
                vital={vital}
                emrSections={emrSections}
                printType={printType}
                A4Height={heightOfPageMeasureComponent}
                footerHeight={footerDimensions?.height}
                headerHeight={headerDimensions?.height}
                patientDetailsRef={patientDetailsRef}
                observationRef={observationRef}
                complaintRef={complaintsRef}
                diagnosisRef={diagnosisRef}
                drugAdviceRef={drugAdviceTableRef}
                prescriptionAdviceRef={prescriptionAdviceRef}
                complaintsRef={complaintsRef}
                drugAdviceTableRef={drugAdviceTableRef}
                homunculusRef={homunculusRef}
                instructionsRef={instructionsRef}
                investigationsRef={investigationsRef}
                referredToDoctorName={referredToDoctorName}
                referredToDoctorNameRef={referredToDoctorNameRef}
                therapyRecommendedRef={therapyRecommendedRef}
                assessmentsRef={assessmentsRef}
                nextVisitRef={nextVisitRef}
                suggestedTherapiesRef={suggestedTherapiesRef}
                prescriptionBodyContainerRef={prescriptionContinuousBodyRef}
                vitalsRef={vitalsRef}
              />
              <div style={{ width: `${rightSidebarWidth}px`, flexShrink: 0 }}></div>
            </div>
            <ContinuedToAndFromDiv currentText={`Continuing to Page 2`} />
            <PMPV2FooterContainer debug={debugMode} style={{ height: `${footerHeight}px` }}>
              <div ref={footerRef}>
                <PrescriptionFooter
                  prescriptionData={prescriptionData}
                  leaveSpace={false}
                  debug={debugMode}
                  footerRef={footerRef}
                />
              </div>
            </PMPV2FooterContainer>
          </PMPConsultationParent>
        </A4PreviewWrapper>
      </div>
    </A4WrapperContainer>
  )
}

/*
<div>
        <A4PreviewWrapper
          key={0}
          a4Width={a4PageWidth}
          showPageView={useNewPrintUI}
          heightOfOnePageInPixels={heightOfPageMeasureComponent}
          debugMode={debugMode}
        >
          <PMPConsultationParent
            ref={prescriptionParentDivRef}
            biggerFont={prescriptionData?.layout === PRESCRIPTION_LAYOUTS.largerFontLayout}
            noBorder={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
            className={
              printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace
                ? ''
                : 'final-printable make-all-borders-black-print'
            }
            hiddenInUI={!showInUI}
          >
            {(headerStyleToShow === HEADER_TYPES?.onAllPages ||
              headerStyleToShow === HEADER_TYPES?.onFirstPage ||
              headerStyleToShow === HEADER_TYPES?.onAlternatePages) && (
              <>
                <PrescriptionHeader
                  prescriptionData={prescriptionData}
                  leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
                  debug={debugMode}
                  printType={printType}
                  headerHeight={headerHeight}
                />
                {debugMode ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: `${headerDimensions?.height - 10}px`,
                      right: '0px',
                    }}
                  >
                    HEADER ENDS: {headerDimensions?.height?.toFixed(2)} Px
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
            {headerStyleToShow === HEADER_TYPES?.blankHeader && (
              <>
                <PrescriptionHeader
                  prescriptionData={prescriptionData}
                  leaveSpace={true}
                  headerHeight={headerHeight}
                  debug={debugMode}
                  printType={printType}
                />
                {debugMode ? (
                  <div
                    style={{
                      position: 'absolute',
                      top: `${headerDimensions?.height - 10}px`,
                      right: '0px',
                    }}
                  >
                    HEADER ENDS AND BODY STARTS: {headerDimensions?.height?.toFixed(2)} Px
                  </div>
                ) : (
                  <></>
                )}
              </>
            )}
            {debugMode ? (
              prescriptionItemOrdersWithTopOffsets?.map((prescItem, idx) => (
                <div
                  style={{
                    position: 'absolute',
                    top: `${prescItem?.currentPosition - 10}px`,
                    left: '0px',
                  }}
                  key={`DEBUG${prescItem?.orderId}POSITION`}
                  id={`DEBUG${prescItem?.orderId}POSITION`}
                  onMouseEnter={(e) => {
                    onHoverItem({ e, prescItem })
                  }}
                  onMouseLeave={() => {
                    onMouseLeaveItem({ prescItem })
                  }}
                >
                  <Tooltip
                    title={getCoordinatesStringForPrescItems({
                      a4PageDimensions: a4PageDimensions,
                      item: prescItem,
                      prescriptionBreaks,
                    })}
                  >
                    <span>
                      {prescItem?.title}: <strong>Width:</strong>{' '}
                      {prescItem?.dimension?.width?.toFixed(2)}, <strong>Height:</strong>{' '}
                      {prescItem?.dimension?.height?.toFixed(2)}
                      <br></br>
                      <strong>Start:</strong> {prescItem?.currentPosition?.toFixed(2)} (Page:
                      {
                        getPageDetailsNumberOfAnItem({
                          footerHeight: footerDimensions?.height,
                          headerHeight: headerDimensions?.height,
                          itemInfo: prescItem,
                          pageHeight: heightOfPageMeasureComponent,
                          pageBreaks: prescriptionBreaks,
                        })?.[0]
                      }
                      ): <strong>End:</strong>{' '}
                      {(prescItem?.currentPosition + prescItem?.dimension?.height)?.toFixed(2)}{' '}
                      {getPageDetailsNumberOfAnItem({
                        footerHeight: footerDimensions?.height,
                        headerHeight: headerDimensions?.height,
                        itemInfo: prescItem,
                        pageHeight: heightOfPageMeasureComponent,
                        pageBreaks: prescriptionBreaks,
                      })?.[1]
                        ? `(Page: ${
                            getPageDetailsNumberOfAnItem({
                              footerHeight: footerDimensions?.height,
                              headerHeight: headerDimensions?.height,
                              itemInfo: prescItem,
                              pageHeight: heightOfPageMeasureComponent,
                              pageBreaks: prescriptionBreaks,
                            })?.[1]
                          })`
                        : `(Page: ${
                            getPageDetailsNumberOfAnItem({
                              footerHeight: footerDimensions?.height,
                              headerHeight: headerDimensions?.height,
                              itemInfo: prescItem,
                              pageHeight: heightOfPageMeasureComponent,
                              pageBreaks: prescriptionBreaks,
                            })?.[0]
                          })`}
                    </span>
                  </Tooltip>
                </div>
              ))
            ) : (
              <></>
            )}
            //<PrescriptionPrintableBody
            {debugMode ? (
              <div
                onMouseEnter={(e) => {
                  onHoverItem({
                    e,
                    prescItem: prescriptionBodyItemWithTopOffset,
                  })
                }}
                onMouseLeave={() => {
                  onMouseLeaveItem({
                    prescItem: prescriptionBodyItemWithTopOffset,
                  })
                }}
                style={{
                  position: 'absolute',
                  top: `${headerDimensions?.height + 10}px`,
                  right: '0px',
                }}
              >
                <Tooltip
                  title={getCoordinatesStringForPrescItems({
                    a4PageDimensions: a4PageDimensions,
                    item: prescriptionBodyItemWithTopOffset,
                    prescriptionBreaks,
                  })}
                >
                  Prescription Body Starts: {headerDimensions?.height?.toFixed(2)} Px
                </Tooltip>
              </div>
            ) : (
              <></>
            )}
            <PrescriptionFullBody
              value={value}
              setValue={setValue}
              placeholder={placeholder}
              alsoChangePropValue={Boolean(honorPropValue)}
              setPropValue={setPropValue}
              selectedAdvice={selectedAdvice}
              selectedTherapies={selectedTherapies}
              nextVisitInputType={nextVisitInputType}
              nextVisitInputValue={nextVisitInputValue}
              prescriptionData={prescriptionData}
              suggestedBookingTherapy={suggestedBookingTherapy}
              selectedInstructions={selectedInstructions}
              selectedInvestigations={selectedInvestigations} // todo: put in ViewOnlyPrescriptions - both type
              jointHomunculusData={jointHomunculusData}
              prescriptionViewSettings={prescriptionViewSettings}
              debug={debugMode}
              drugsAdvice={drugsAdvice}
              selectedObservations={selectedObservations}
              selectedComplaints={selectedComplaints}
              selectedDiagnosis={selectedDiagnosis}
              printType={printType}
              A4Height={heightOfPageMeasureComponent}
              footerHeight={footerDimensions?.height}
              headerHeight={headerDimensions?.height}
              debugHighlightStates={debugHighlightStates}
            />
            {debugMode ? (
              <div
                style={{
                  position: 'absolute',
                  // top: `${
                  //   heightOfPageMeasureComponent -
                  //   (headerDimensions?.height + footerDimensions?.height) -
                  //   10
                  // }px`,
                  top: `${
                    prescriptionParentDivDimensions?.height - footerDimensions?.height - 10
                  }px`,
                  right: '0px',
                }}
              >
                Prescirption Body ENDS and footer starts{' '}
                {(prescriptionParentDivDimensions?.height - footerDimensions?.height)?.toFixed(2)}{' '}
                px
              </div>
            ) : (
              <></>
            )}
            <PMPV2FooterContainer
              debug={debugMode}
              prescriptionWidth={prescriptionParentDivDimensions?.width}
            >
              <PrescriptionFooter
                prescriptionData={prescriptionData}
                leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
                debug={debugMode}
                prescriptionWidth={prescriptionParentDivDimensions?.width}
              />
            </PMPV2FooterContainer>
          </PMPConsultationParent>
          {debugMode ? (
            <div
              style={{
                position: 'absolute',
                // top: `${
                //   heightOfPageMeasureComponent -
                //   (headerDimensions?.height + footerDimensions?.height) -
                //   10
                // }px`,
                top: `${heightOfPageMeasureComponent - 30}px`,
                right: '0px',
              }}
            >
              Page 1 ends {heightOfPageMeasureComponent?.toFixed(2)}px
            </div>
          ) : (
            <></>
          )}
        </A4PreviewWrapper>
      </div>
      */

import React from 'react'
import { PMPBodySectionItems } from './styledComponent'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { getValidDiagnosisArrayForPrescription, preparePrescriptionDiagnosisLine } from './util'

export default function PrintableDiagnosis({
  diagnosisRef,
  debug,
  selectedDiagnosis,
  debugHighlightStates,
  styleOverrides,
}) {
  const diagnosisToShow = getValidDiagnosisArrayForPrescription(selectedDiagnosis)
  

  return (
    <PMPBodySectionItems
      ref={diagnosisRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.diagnosis?.color}
      debugHighlight={debugHighlightStates?.diagnosis}
    >
      <span
        style={{
          fontSize: styleOverrides?.headingFontSize
            ? `${styleOverrides?.headingFontSize}px`
            : 'inherit',
          color: styleOverrides?.headingColor || 'inherit',
        }}
      >
        <strong>Diagnosis</strong>
      </span>
      <div
        style={{
          fontSize: styleOverrides?.fontSizePixels
            ? `${styleOverrides?.fontSizePixels}px`
            : 'inherit',
          color: styleOverrides?.color || 'inherit',
        }}
        className="d-flex align-items-center gap-1 flex-wrap"
      >
        {diagnosisToShow.map((diagnosisItem, idx) => (
          <div style={{ paddingRight: '6px' }} key={idx}>
            {preparePrescriptionDiagnosisLine(diagnosisItem)}{' '}
            {diagnosisToShow?.length - 1 !== idx && ', '}
          </div>
        ))}
      </div>
    </PMPBodySectionItems>
  )
}

import moment from 'moment'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import logo from 'src/assets/logo_brand.png'
import { getPatientVitalForBookedEvent } from 'src/clinic/components/ClinicManageBookingsPage/utils'
import { STATUS_SESSION_CANCELLED } from 'src/consumer/constants'
import { appVersion, platformWeb } from 'src/consumer/services'
import {
  getDataWithoutParams,
  putDataWithParams,
  putDataWithoutParams,
} from 'src/consumer/services/featureServices'
import { getDataWithParams } from 'src/consumer/services/profileService'
import { isCommonResponseSuccessful } from 'src/mentor/helpers/utilityFunctions'
import { v4 as uuidv4 } from 'uuid'
import { postCPDataWithParams } from 'src/clinic/services'

export const displayRazorpayForOneOnOne = (orderResponse, showBookingConfirmationPage) => {
  const key = orderResponse?.razorpayClientId
  const amount = orderResponse?.amount
  const currency = orderResponse?.currency
  const name = 'AUMHUM'
  const description = orderResponse?.description
  const order_id = orderResponse?.orderId
  const userName = orderResponse?.userName
  const userEmail = orderResponse?.userEmail
  const userMobile = orderResponse?.userMobile

  var options = {
    key: key, // Enter the Key ID generated from the Dashboard
    amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
    currency: currency,
    name: name, //your business name
    description: description,
    image: logo,
    order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
    prefill: {
      name: userName, //your customer's name
      email: userEmail,
      contact: userMobile,
    },
    handler: function (response) {
      // alert(response.razorpay_payment_id);
      if (typeof response.razorpay_payment_id === 'undefined' || response.razorpay_payment_id < 1) {
        return null
      } else {
        if (showBookingConfirmationPage) {
          showBookingConfirmationPage()
        } else {
          window.location.reload()
        }
      }
    },
    config: {
      display: {
        blocks: {
          banks: {
            name: 'Preferred Method',
            instruments: [
              {
                method: 'upi',
              },
            ],
          },
        },
        hide: [
          {
            method: 'paylater',
          },
        ],
        sequence: ['block.banks'],
        preferences: {
          show_default_blocks: true,
        },
        language: getRazorpayLanguageCode(),
      },
    },
    notes: {
      address: 'Bangalore',
    },
    theme: {
      color: '#7154b0',
    },
  }

  var rzp1 = new window.Razorpay(options)
  rzp1.on('payment.failed', function (response) {
    console.log(response.error.description)
    console.log(response.error.code)
    console.log(response.error.source)
    console.log(response.error.step)
    console.log(response.error.reason)
    console.log(response.error.metadata.order_id)
    console.log(response.error.metadata.payment_id)
  })
  rzp1.open()
}

export function getRazorpayLanguageCode() {
  const languageSelected = sessionStorage.getItem('languageCode')
  let toReturn = 'en'
  if (languageSelected) {
    if (languageSelected === 'bn') {
      toReturn = 'ben'
    } // bengali
    else if (languageSelected === 'hi') {
      toReturn = 'hi'
    } // hindi
    else if (languageSelected === 'mr') {
      toReturn = 'mar'
    } // marathi
    else if (languageSelected === 'gu') {
      toReturn = 'guj'
    } // gujrati
    else if (languageSelected === 'ta') {
      toReturn = 'tam'
    } // tamil
    else if (languageSelected === 'te') {
      toReturn = 'tel'
    } // telugu
  } else {
    return 'en'
  }
  console.log(toReturn)
  return toReturn
}

export const createAvailableTimeSlotsArray = async (
  dateToLookFor,      // local date selected on booking page like 12-03-2025
  userZoneOffset,     // encoded/not +05:30
  mentorZoneOffset,   // encoded/not +02:30
  scheduleOfProfessional,     // metaSchedule.class object
  eventDetailOfRequestedEvent,    // eventType.class object
  mentorId,
  eventId,
  selectedTimeZone,     // timezone selected in select menu on booking page 
  secure,
) => {
  const tempDatesOfMonthArray = []
  const year = moment(dateToLookFor, 'YYYY-MM-DD').year()
  const month = moment(dateToLookFor, 'YYYY-MM-DD').month()
  const startDate = moment({ year, month }).startOf('month').format('YYYY-MM-DD')
  const endDate = moment({ year, month }).endOf('month').format('YYYY-MM-DD')
  let currentDate = moment(startDate)
  while (currentDate?.isSameOrBefore(endDate, 'day')) {
    tempDatesOfMonthArray.push(currentDate.format('YYYY-MM-DD'))
    currentDate.add(1, 'day')
  }
  //made temp array with dates like YYYY-MM-DD

  const datesOfMonthInUserTimezone = []
  const datesOfMonthInUTC = []
  const datesOfMonthInMentorZone = []

  tempDatesOfMonthArray?.forEach((item) => {
    datesOfMonthInUserTimezone?.push({
      date: item,
      dayStart: moment(item)?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(userZoneOffset)}]`),
      dayEnd: moment(item)
        ?.endOf('day')
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(userZoneOffset)}]`),
    })
  }) // getting datesOfMonthInUserTimezone

  datesOfMonthInUserTimezone?.forEach((item) => {
    datesOfMonthInUTC?.push({
      date: item?.date,
      dayStart: moment?.utc(item?.dayStart)?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      dayEnd: moment?.utc(item?.dayEnd)?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
    })
  }) // getting datesOfMonthInUTC

  datesOfMonthInUTC?.forEach((item) => {
    datesOfMonthInMentorZone?.push({
      date: item?.date,
      dayStart: moment
        ?.utc(item?.dayStart)
        ?.utcOffset(decodeURIComponent(mentorZoneOffset))
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(mentorZoneOffset)}]`),
      dayEnd: moment
        ?.utc(item?.dayEnd)
        ?.utcOffset(decodeURIComponent(mentorZoneOffset))
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(mentorZoneOffset)}]`),
    })
  }) // getting datesOfMonthInMentorZone

  datesOfMonthInMentorZone?.forEach((item, index) => {
    if (item?.dayStart?.substring(0, 10) === item?.dayEnd?.substring(0, 10)) {
      const mentorDayData =
        scheduleOfProfessional?.weeklyHourMap?.[
          moment(item?.dayStart?.substring(0, 10))?.format('dddd')?.toLowerCase()
        ]
      if (mentorDayData && mentorDayData?.available) {
        const availableSlotsArray = []
        mentorDayData?.slotList?.forEach((slotItem) => {
          availableSlotsArray?.push({
            startTime:
              item?.dayStart?.substring(0, 10) +
              'T' +
              slotItem?.startTime +
              decodeURIComponent(mentorZoneOffset),
            endTime:
              item?.dayStart?.substring(0, 10) +
              'T' +
              slotItem?.endTime +
              decodeURIComponent(mentorZoneOffset),
          })
        })
        datesOfMonthInMentorZone[index].slotsAvailable = availableSlotsArray
      } else {
        datesOfMonthInMentorZone[index].slotsAvailable = []
      }
    } else {
      const mentorStartDayData =
        scheduleOfProfessional?.weeklyHourMap?.[
          moment(item?.dayStart?.substring(0, 10))?.format('dddd')?.toLowerCase()
        ]
      const mentorEndDayData =
        scheduleOfProfessional?.weeklyHourMap?.[
          moment(item?.dayEnd?.substring(0, 10))?.format('dddd')?.toLowerCase()
        ]
      const slotsTempArray = []

      if (mentorStartDayData?.available) {
        mentorStartDayData?.slotList?.forEach((startDayItem) => {
          if (
            startDayItem?.startTime > item?.dayStart?.substring(11, 19) &&
            startDayItem?.endTime > item?.dayStart?.substring(11, 19)
          ) {
            slotsTempArray?.push({
              startTime:
                item?.dayStart?.substring(0, 10) +
                'T' +
                startDayItem?.startTime +
                item?.dayStart?.substring(19),
              endTime:
                item?.dayStart?.substring(0, 10) +
                'T' +
                startDayItem?.endTime +
                item?.dayStart?.substring(19),
            })
          }
          if (
            startDayItem?.startTime < item?.dayStart?.substring(11, 19) &&
            startDayItem?.endTime > item?.dayStart?.substring(11, 19)
          ) {
            slotsTempArray?.push({
              startTime:
                item?.dayStart?.substring(0, 10) +
                'T' +
                item?.dayStart?.substring(11, 19) +
                item?.dayStart?.substring(19),
              endTime:
                item?.dayStart?.substring(0, 10) +
                'T' +
                startDayItem?.endTime +
                item?.dayStart?.substring(19),
            })
          }
        })
      }

      if (mentorEndDayData?.available) {
        mentorEndDayData?.slotList?.forEach((endDayItem) => {
          if (
            endDayItem?.startTime < item?.dayEnd?.substring(11, 19) &&
            endDayItem?.endTime < item?.dayEnd?.substring(11, 19)
          ) {
            slotsTempArray?.push({
              startTime:
                item?.dayEnd?.substring(0, 10) +
                'T' +
                endDayItem?.startTime +
                item?.dayEnd?.substring(19),
              endTime:
                item?.dayEnd?.substring(0, 10) +
                'T' +
                endDayItem?.endTime +
                item?.dayEnd?.substring(19),
            })
          }
          if (
            endDayItem?.startTime < item?.dayEnd?.substring(11, 19) &&
            endDayItem?.endTime > item?.dayEnd?.substring(11, 19)
          ) {
            slotsTempArray?.push({
              startTime:
                item?.dayEnd?.substring(0, 10) +
                'T' +
                endDayItem?.startTime +
                item?.dayEnd?.substring(19),
              endTime:
                item?.dayEnd?.substring(0, 10) +
                'T' +
                item?.dayEnd?.substring(11, 19) +
                item?.dayEnd?.substring(19),
            })
          }
        })
      }

      datesOfMonthInMentorZone[index].slotsAvailable = slotsTempArray
    }
  })

  datesOfMonthInMentorZone?.forEach((item, index) => {
    const slotsAvailableTemp = []
    item?.slotsAvailable?.forEach((slotItem) => {
      slotsAvailableTemp?.push({
        startTime: moment?.utc(slotItem?.startTime)?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endTime: moment?.utc(slotItem?.endTime)?.format('YYYY-MM-DDTHH:mm:ss[Z]'),
      })
    })
    datesOfMonthInUTC[index].slotsAvailable = slotsAvailableTemp
  })

  datesOfMonthInUTC?.forEach((item, index) => {
    const slotsAvailableTemp = []
    item?.slotsAvailable?.forEach((slotItem) => {
      slotsAvailableTemp?.push({
        startTime: moment
          ?.utc(slotItem?.startTime)
          ?.utcOffset(decodeURIComponent(userZoneOffset))
          ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(userZoneOffset)}]`),
        endTime: moment
          ?.utc(slotItem?.endTime)
          ?.utcOffset(decodeURIComponent(userZoneOffset))
          ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(userZoneOffset)}]`),
      })
    })
    datesOfMonthInUserTimezone[index].slotsAvailable = slotsAvailableTemp
  })

  // by this point, we have mentor availability in user timezone in datesOfMonthInUserTimezone
  // now we get from api, the unavailability

  const monthStartPerUtc = moment
    ?.utc(datesOfMonthInUserTimezone?.[0]?.dayStart)
    ?.format('YYYY-MM-DDTHH:mm:ss[Z]')
  const dayYesterdayPerUtc = moment().subtract(1, 'day').utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
  const startLookingBookedSlotsFrom =
    moment().month() === moment(dateToLookFor, 'YYYY-MM-DD').month()
      ? dayYesterdayPerUtc
      : monthStartPerUtc
  const monthEndPerUtc = moment
    ?.utc(datesOfMonthInUserTimezone?.[datesOfMonthInUserTimezone?.length - 1]?.dayEnd)
    ?.format('YYYY-MM-DDTHH:mm:ss[Z]')

  let response = await getDataWithoutParams(
    `/api/mentor/booked-slots?mentorId=${mentorId}&startTime=${startLookingBookedSlotsFrom}&endTime=${monthEndPerUtc}&eventType=${eventId}`,
  )
  let unavailabilitySlotsOfMonthPerUtc = response?.data || []
  unavailabilitySlotsOfMonthPerUtc = unavailabilitySlotsOfMonthPerUtc?.filter(
    (item) => item?.status !== STATUS_SESSION_CANCELLED,
  )
  const unavailabilitySlotsOfMonthPerUserTime = []

  unavailabilitySlotsOfMonthPerUtc?.forEach((item) => {
    unavailabilitySlotsOfMonthPerUserTime?.push({
      startTime: moment
        ?.utc(item?.startTime)
        ?.utcOffset(decodeURIComponent(userZoneOffset))
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(userZoneOffset)}]`),
      endTime: moment
        ?.utc(item?.endTime)
        ?.utcOffset(decodeURIComponent(userZoneOffset))
        ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(userZoneOffset)}]`),
    })
  }) // also adding pre and post buffer time here

  const dateWiseTimeSlots = await setTimeSlots(
    datesOfMonthInUserTimezone,
    unavailabilitySlotsOfMonthPerUserTime,
    eventDetailOfRequestedEvent,
    selectedTimeZone,
  )
  
  return dateWiseTimeSlots
}

async function setTimeSlots(
  availabilityData,
  nonAvailabilityData,
  eventDetailOfRequestedEvent,
  selectedTimeZone,
) {
  const dateWiseTimeSlots = []
  const eventDuration =
    eventDetailOfRequestedEvent?.preBufferMins +
    eventDetailOfRequestedEvent?.durationInMins +
    eventDetailOfRequestedEvent?.postBufferMins

  availabilityData?.forEach((item, availableDayIndex) => {
    const daySlotsArray = []
    item?.slotsAvailable?.forEach((slotTimeObject) => {
      let currentStartTime = moment(slotTimeObject?.startTime)
      let currentSlotEnd = moment(currentStartTime)?.add(eventDuration, 'minutes')
      let endTime = moment(slotTimeObject?.endTime)

      while (currentStartTime?.isSameOrBefore(endTime) && currentSlotEnd?.isSameOrBefore(endTime)) {
        let thereIsOverlap = [] // this array holds values true and false for overlaps

        nonAvailabilityData?.forEach((nonAvailableSlot) => {
          const UnavailableTimeSlotStart = moment(nonAvailableSlot?.startTime)?.subtract(
            eventDetailOfRequestedEvent?.preBufferMins,
            'minutes',
          )
          const UnavailableTimeSlotEnd = moment(nonAvailableSlot?.endTime)?.add(
            eventDetailOfRequestedEvent?.postBufferMins,
            'minutes',
          )

          if (
            currentStartTime?.isSameOrAfter(UnavailableTimeSlotEnd) ||
            currentSlotEnd?.isSameOrBefore(UnavailableTimeSlotStart)
          ) {
            thereIsOverlap?.push(false)
          } else {
            thereIsOverlap?.push(true)
          }
        })

        // we have tested all non available slots and compared them with the slot we are checking. on each comparison, we store the result of overlapping in an array.
        // if there is no overlap, all items in the array should be false or the array should be empty in case there were no booked slots to start with.
        // if there is any item that is true, we don't add the particular slot that we are checking into available time slots.

        if (!thereIsOverlap?.some((element) => Boolean(element))) {
          // !(it will always return false even in empty array unless there is an item that is true) = it will always return true even in empty array unless there is an item that is true
          const perUtcCurrentStart = moment?.utc(currentStartTime)?.format('YYYY-MM-DDTHH:mm:ss[Z]')
          const perUtcSlotEnd = moment?.utc(currentSlotEnd)?.format('YYYY-MM-DDTHH:mm:ss[Z]')
          const perUserCurrentStart = moment
            ?.utc(perUtcCurrentStart)
            ?.utcOffset(decodeURIComponent(selectedTimeZone))
            ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(selectedTimeZone)}]`)
          const perUserSlotEnd = moment
            ?.utc(perUtcSlotEnd)
            ?.utcOffset(decodeURIComponent(selectedTimeZone))
            ?.format(`YYYY-MM-DDTHH:mm:ss[${decodeURIComponent(selectedTimeZone)}]`)

          //perspective of user : per user

          if (
            moment
              ?.utc(perUtcCurrentStart)
              ?.utcOffset(decodeURIComponent(selectedTimeZone))
              ?.isAfter(moment()?.utcOffset(decodeURIComponent(selectedTimeZone)))
          ) {
            daySlotsArray?.push({
              startTime: perUserCurrentStart,
              endTime: perUserSlotEnd,
            })
          }
        } else {
          // there is overlap
          // console.log(currentStartTime?.format() + " to " + slotEnd?.format() + " is not available")
        }

        currentStartTime?.add(eventDuration, 'minutes')
        currentSlotEnd?.add(eventDuration, 'minutes')
      }
    })
    dateWiseTimeSlots?.push({ date: item?.date, availableTimeSlots: daySlotsArray })
  })

  return dateWiseTimeSlots
}

export function adjustPrebufferTimeAndConvertAMPM(timeString, startBufferMins) {
  return moment(timeString, 'HH:mm').add(startBufferMins, 'minutes').format('hh:mm a')
}

export function adjustPostbufferTimeAndConvertAMPM(timeString, endBufferMins) {
  return moment(timeString, 'HH:mm').subtract(endBufferMins, 'minutes').format('hh:mm a')
}

export async function payAndMakeEventBooking(
  bookingEventDetails,
  chosenSlotData,
  paymentGateway,
  giftCardId,
  stripePromise,
  mentorId,
  userProfileData,
  giftCodeErrorSetter,
  afterConfirmationFunction,
  eventIdField = 'uid',
  bookingType = 'regular',
  orderCreationDataSetter = () => null,
  referenceData,
) {
  const { data, preBufferMins, postBufferMins } = chosenSlotData
  const clientTransactionId = uuidv4()
  const dataToSubmit = {
    apiParam: 'booked-event-info',
    startTime: moment
      ?.utc(data?.startTime)
      ?.add(preBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment
      ?.utc(data?.endTime)
      ?.subtract(postBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    eventType: bookingEventDetails?.type,
    eventTypeId: bookingEventDetails?.[eventIdField],
    mentorId: mentorId,
    subscriptionPlanId: bookingEventDetails?.subscriptionPlanId,
    // "paymentStatus": "paid",
    id: '',
    userId: userProfileData?.userId,
    clinicId: bookingEventDetails?.contextId || '',
    clinicBrandId: bookingEventDetails?.clinicBrandId || '',
    references: referenceData || null,
    // patientId
  }
  const response = await putDataWithoutParams(
    `/api/secure/purchase/one-on-one?paymentGateway=${paymentGateway}&giftCode=${
      giftCardId ? giftCardId : 'GFT-default'
    }&clientTransactionId=${clientTransactionId}&paywall=upgrade&paywallContext=one-on-one&paywallDetails=${mentorId}&platform=${platformWeb}&paymentMethod=upi&cancelUrl=${encodeURIComponent(
      window.location.href,
    )}&successUrl=${encodeURIComponent(window.location.origin)}${encodeURIComponent(
      '/my-live-sessions',
    )}&pricingPackId=${bookingEventDetails?.subscriptionPlanId}`,
    dataToSubmit,
  )
  // TODO: ideally pricingpackid should have actual pricing pack id from credits but as we have already confirmed those things at prev levels, skipping here. might be needed when one pricingPackId can purchase N number of subscription plans

  orderCreationDataSetter(response?.data?.data)
  if (paymentGateway === 'credits') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      afterConfirmationFunction()
    } else {
      toast.error('Error booking session using credits. Please reload the page and try again.')
    }
  }
  if (paymentGateway === 'giftup') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      afterConfirmationFunction()
    } else {
      giftCodeErrorSetter(true)
    }
  }
  if (paymentGateway === 'razorpay') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      displayRazorpayForOneOnOne(response?.data?.data, afterConfirmationFunction)
    } else {
      toast.error('Error creating order')
    }
  }
  if (paymentGateway === 'stripe') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      stripePromise.redirectToCheckout({
        sessionId: response?.data?.data?.sessionId,
      })
      // setPageView("stripePaymentPage")
    } else {
      toast.error('Error creating order')
    }
  }
  if (paymentGateway === 'free') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      afterConfirmationFunction()
    } else {
      toast.error('Error Booking Session')
    }
  }
}

export async function payForTheBundle(
  bundleId,
  stripePromise,
  paymentGateway,
  setBundleBuyingWaitingState,
) {
  const clientTransactionId = uuidv4()
  setBundleBuyingWaitingState({ bundleBuy: 'waiting', eventBooking: null })

  const responseForOrderCreationBundle = await putDataWithoutParams(
    `/api/secure/purchase/bundle?bundlePackId=${bundleId}&paymentGateway=${paymentGateway}&giftCode=GFT-default&clientTransactionId=${clientTransactionId}&platform=${platformWeb}&paymentMethod=upi&cancelUrl=${encodeURIComponent(
      window.location.href,
    )}&successUrl=${encodeURIComponent(window.location.href)}${encodeURIComponent(
      '/my-live-sessions',
    )}`,
    {},
  )
  if (paymentGateway === 'razorpay') {
    if (
      responseForOrderCreationBundle?.status === 200 &&
      isCommonResponseSuccessful(responseForOrderCreationBundle?.data?.code)
    ) {
      displayRazorpayForOneOnOne(responseForOrderCreationBundle?.data?.data, () =>
        setBundleBuyingWaitingState({ bundleBuy: 'success', eventBooking: null }),
      )
    } else {
      toast.error('Error creating order')
    }
  }
  if (paymentGateway === 'stripe') {
    stripePromise.redirectToCheckout({
      sessionId: responseForOrderCreationBundle?.data?.data?.sessionId,
    })
  }
}

export async function payAndMakeEventBookingV2(
  bookingEventDetails,
  chosenSlotData,
  paymentGateway,
  giftCardId,
  stripePromise,
  mentorId,
  userProfileData,
  giftCodeErrorSetter,
  afterConfirmationFunction,
  eventIdField = 'uid',
  successRoute = '/my-live-sessions',
) {
  const { data, preBufferMins, postBufferMins } = chosenSlotData
  const clientTransactionId = uuidv4()
  const dataToSubmit = {
    apiParam: 'booked-event-info',
    startTime: moment
      ?.utc(data?.startTime)
      ?.add(preBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    endTime: moment
      ?.utc(data?.endTime)
      ?.subtract(postBufferMins, 'minutes')
      ?.format('YYYY-MM-DD HH:mm:ss'),
    eventType: bookingEventDetails?.type,
    eventTypeId: bookingEventDetails?.[eventIdField],
    mentorId: mentorId,
    subscriptionPlanId: bookingEventDetails?.subscriptionPlanId,
    // "paymentStatus": "paid",
    id: '',
    userId: userProfileData?.userId,
  }

  const response = await putDataWithParams('/api/secure/purchase/event', dataToSubmit, {
    paymentGateway: paymentGateway,
    giftCode: giftCardId ? giftCardId : 'GFT-default',
    clientTransactionId: clientTransactionId,
    eventType: bookingEventDetails?.type,
    paywall: 'upgrade',
    paywallContext: 'one-on-one',
    paywallDetails: mentorId,
    platform: platformWeb,
    paymentMethod: 'upi',
    cancelUrl: window.location.href,
    successUrl: window.location.origin + successRoute,
  })
  // const response = await putDataWithoutParams(
  //   `/api/secure/purchase/event?paymentGateway=${paymentGateway}&giftCode=${
  //     giftCardId ? giftCardId : 'GFT-default'
  //   }&clientTransactionId=${clientTransactionId}&eventType=${
  //     bookingEventDetails?.type
  //   }&paywall=upgrade&paywallContext=one-on-one&paywallDetails=${mentorId}&platform=${platformWeb}&paymentMethod=upi&cancelUrl=${encodeURIComponent(
  //     window.location.href,
  //   )}&successUrl=${encodeURIComponent(window.location.origin)}${encodeURIComponent(successRoute)}`,
  //   dataToSubmit,
  // )
  if (paymentGateway === 'giftup') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      afterConfirmationFunction()
    } else {
      giftCodeErrorSetter(true)
    }
  }
  if (paymentGateway === 'razorpay') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      displayRazorpayForOneOnOne(response?.data?.data, afterConfirmationFunction)
    } else {
      toast.error('Error creating order')
    }
  }
  if (paymentGateway === 'stripe') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      stripePromise.redirectToCheckout({
        sessionId: response?.data?.data?.sessionId,
      })
      // setPageView("stripePaymentPage")
    } else {
      toast.error('Error creating order')
    }
  }
  if (paymentGateway === 'free') {
    if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
      afterConfirmationFunction()
    } else {
      toast.error('Error Booking Session')
    }
  }
}

export async function getBookingPlanData(allEvents, eventId, eventIdField = 'uid') {
  let resp
  const subscriptionPlanId = allEvents?.find(
    (event) => event?.[eventIdField] === eventId,
  )?.subscriptionPlanId
  if (subscriptionPlanId) {
    resp = await getDataWithParams(`/api/subscription-plan`, {
      id: subscriptionPlanId,
      zoneOffset: encodeURIComponent(moment().format('Z')),
      appVersion: appVersion,
      platformType: platformWeb,
      languageCode: sessionStorage.getItem('languageCode'),
      platform: platformWeb,
      countryCode: localStorage.getItem('countryCode'),
    })
  }
  if (resp?.status === 200) {
    return resp?.data
  }
}

export async function getBundlesDataFromBundlesArray(allEvents, eventId, eventIdField = 'uid') {
  let resp
  const bundlePlanIds = allEvents?.find((event) => event?.[eventIdField] === eventId)?.bundlePlanIds
  const bundleData = []
  if (Array.isArray(bundlePlanIds)) {
    resp = await getDataWithParams(`/api/subscription-plan/bundle`, {
      ids: bundlePlanIds?.join(','),
      zoneOffset: encodeURIComponent(moment().format('Z')),
      appVersion: appVersion,
      platformType: platformWeb,
      languageCode: sessionStorage.getItem('languageCode'),
      platform: platformWeb,
      countryCode: localStorage.getItem('countryCode'),
    })
    if (resp?.status === 200) {
      bundleData.push(...resp?.data)
    }
  }
  return bundleData
}

export async function getProfessionalData(mentorSlug) {
  const resp = await getDataWithParams('/api/service-provider', {
    id: mentorSlug,
    slug: mentorSlug,
    zoneOffset: encodeURIComponent(moment().format('Z')),
    appVersion: appVersion,
    platformType: platformWeb,
  })
  if (resp.status === 200) {
    return resp.data
  }
}

export async function attemptToBindLatestTodayVitalToThisBooking(
  bookedEventId,
  bookedEventStartTime,
  patientId,
  clinicId,
) {
  try {
    const latestVital = await getPatientVitalForBookedEvent(patientId, clinicId)
    if (latestVital && !Boolean(latestVital?.bookedEventId)) {
      const eventStartTime = dayjs(bookedEventStartTime).local()
      const vitalCreatedTime = dayjs(latestVital?.createdTime).local()

      if (eventStartTime.isSame(vitalCreatedTime, 'day')) {
        await mergeBookingAndVital(bookedEventId, latestVital)
      }
    }
  } catch (e) {
    console.error('Error binding latest vital to this session:', e)
  }
}

async function mergeBookingAndVital(bookedEventId, latestVital) {
  await postCPDataWithParams(
    '/api/secure/patient/vitals/save',
    { ...latestVital, bookedEventId: bookedEventId },
    {},
  )
}

export function convertParamDateStringToNativeDate(dateString) {
  if (!dateString || typeof dateString !== 'string') {
    return null
  }
  const date = moment(dateString, 'DD-MM-YYYY', true) // 'true' ensures strict parsing
  if (!date.isValid()) {
    return null
  }
  const localTimezoneOffsetInMinutes = moment().utcOffset()
  const localDate = date
    .utcOffset(localTimezoneOffsetInMinutes)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  const nativeDate = localDate.toDate()
  if (isNaN(nativeDate.getTime())) {
    return null
  }
  return nativeDate
}

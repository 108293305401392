import { useNavigate } from 'react-router-dom'
import {
  getDataMapFromCommonResponse,
  isCommonResponseSuccessfulV2,
  logoutHandlerMentor,
} from '../helpers/utilityFunctions'
import { getMPDataWithoutParams } from '../services'
import { MENTOR_ONBOARDING_TYPES } from 'src/consumer/constants'
import { getCPDataWithoutParams } from 'src/clinic/services'

export function getUserRoles() {
  try {
    const role = localStorage.getItem('authorities')
    return JSON.parse(role)
  } catch (error) {
    logoutHandlerMentor()
    window.location.reload()
  }
}

export function getIsTeacherAndCreatorData() {
  const role = getUserRoles()
  const isTeacher = role?.includes('ROLE_TEACHER')
  const isCreator = role?.includes('ROLE_CREATOR')
  return { isTeacher, isCreator }
}

export function getAllRolesInBoolean() {
  try {
    const role = getUserRoles()
    const isNormalUser = role?.includes('ROLE_USER')
    const isProfessionalUser = role?.includes('ROLE_PROFESSIONAL_USER')
    const isPatient = role?.includes('ROLE_PATIENT')
    const isCreator = role?.includes('ROLE_CREATOR')
    const isTeacher = role?.includes('ROLE_TEACHER')
    const isDoctor = role?.includes('ROLE_DOCTOR')
    const isBusinessProspect = role?.includes('ROLE_BUSINESS_PROSPECT')
    const isBusinessCustomer = role?.includes('ROLE_BUSINESS_CUSTOMER')
    const isAdmin = role?.includes('ROLE_ADMIN')
    const isDeveloper = role?.includes('ROLE_DEVELOPER')
    const isAppManager = role?.includes('ROLE_APP_MANAGER')
    const isClinicProspect = role?.includes('ROLE_CLINIC_PROSPECT')
    const isClinicAssistant = role?.includes('ROLE_CLINIC_ASSISTANT')
    const isClinicManager = role?.includes('ROLE_CLINIC_MANAGER')
    const isClinicOwner = role?.includes('ROLE_CLINIC_OWNER')
    const isPharmacist = role?.includes('ROLE_PHARMACIST')
    const isChamberScreen = role?.includes('ROLE_CHAMBER_SCREEN')
    const isL1TherapySupport = role?.includes('ROLE_THERAPY_SUP_L1')
    const isL2TherapySupport = role?.includes('ROLE_THERAPY_SUP_L2')
    const isL3TherapySupport = role?.includes('ROLE_THERAPY_SUP_L3')
    return {
      isTeacher,
      isCreator,
      isDoctor,
      isPatient,
      isBusinessCustomer,
      isNormalUser,
      isProfessionalUser,
      isAdmin,
      isDeveloper,
      isAppManager,
      isBusinessProspect,
      isClinicProspect,
      isClinicAssistant,
      isClinicManager,
      isClinicOwner,
      isPharmacist,
      isChamberScreen,
      isL1TherapySupport,
      isL2TherapySupport,
      isL3TherapySupport,
    }
  } catch (error) {
    logoutHandlerMentor()
    window.location.reload()
  }
}

export const ALL_SUPPORTED_ROLES_FOR_FLOW_SELECTION = [
  'ROLE_USER',
  'ROLE_PROFESSIONAL_USER',
  'ROLE_PATIENT',
  'ROLE_CREATOR',
  'ROLE_TEACHER',
  'ROLE_DOCTOR',
  'ROLE_BUSINESS_PROSPECT',
  'ROLE_BUSINESS_CUSTOMER',
  'ROLE_ADMIN',
  'ROLE_DEVELOPER',
  'ROLE_APP_MANAGER',
  'ROLE_CLINIC_PROSPECT',
  'ROLE_CLINIC_ASSISTANT',
  'ROLE_CLINIC_MANAGER',
  'ROLE_CLINIC_OWNER',
  'ROLE_PHARMACIST',
]

export function isJustOnboardedTeacher() {
  const enteringFirstTimeLocal = localStorage.getItem('justOnboardedTeacher')
  return JSON.parse(enteringFirstTimeLocal)
}

export async function getAllDomains() {
  const response = getMPDataWithoutParams('/api/secure/domain/all/list')
  return response
}

export function getLocalStorageOnboardingType() {
  const role = localStorage.getItem('onboardingType')
  return role
}

export function removeOnboardingTypeFromLocalStorage() {
  localStorage.removeItem('onboardingType')
}

export function setLocalStorageOnboardingType(role) {
  localStorage.setItem('onboardingType', role)
}

export function getOnboardingType() {
  return getLocalStorageOnboardingType()
}

export function isOnboardedAsDoctor() {
  const actuallyDoctor = getAllRolesInBoolean().isDoctor
  if (actuallyDoctor) {
    return true
  }

  return getOnboardingType() === MENTOR_ONBOARDING_TYPES.DOCTOR
}

export function isOnboardedAsTherapist() {
  return getOnboardingType() === MENTOR_ONBOARDING_TYPES.THERAPIST
}

export function setJustOnboardedTeacher(val) {
  localStorage.setItem('justOnboardedTeacher', val)
}

export function removeJustOnboardedTeacher() {
  localStorage.removeItem('justOnboardedTeacher')
}

export async function getClinicAppointmentsForThisClinicForMentors(locationId, brandId) {
  if (!locationId) {
    return
  }
  if (!brandId) {
    return
  }

  const resp = await getCPDataWithoutParams(
    `/api/secure/teacher/clinic-appointment-type/list?clinicLocationId=${locationId}&clinicBrandId=${brandId}`,
  )
  if (resp?.status === 200 && isCommonResponseSuccessfulV2(resp)) {
    return getDataMapFromCommonResponse(resp)?.appointmentTypes || []
  }
}

import { useRef } from 'react'
import { CustomInput } from 'src/consumer/components/helperComponents'
import { sleep } from 'src/mentor/helpers/utilityFunctions'
import { DosagePillItem } from '../drugAdvice/components/styledComponents'
import PrescriptionPillOptionsInputComponent from './PrescriptionPillOptionsInputComponent'

export default function PillInputComponent({
  value,
  handleChange,
  options,
  getOptionValue,
  getOptionLabel,
  valueKey,
  pillUnselectedBackground,
}) {
  const otherOptionNotSelected = options.map((dos) => getOptionValue(dos))?.includes(value)
  const customInputRef = useRef()

  async function handleOtherPillClick() {
    handleChange({ target: { value: '', id: valueKey } })
    await sleep(300)
    customInputRef.current.focus()
  }

  return (
    <div>
      <PrescriptionPillOptionsInputComponent
        options={options}
        onChange={(newValue) => {
          handleChange({ target: { value: getOptionValue(newValue), id: valueKey } })
        }}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={value}
        pillUnselectedBackground={pillUnselectedBackground}
      ></PrescriptionPillOptionsInputComponent>
      <div className="d-flex align-items-center gap-1 mt-1">
        <DosagePillItem selected={!otherOptionNotSelected} onClick={handleOtherPillClick}>
          Other
        </DosagePillItem>
        {!otherOptionNotSelected && (
          <div style={{ maxWidth: '100%' }}>
            <CustomInput
              inputRef={customInputRef}
              value={options.map((dos) => getOptionValue(dos))?.includes(value) ? '' : value}
              onChange={handleChange}
              id={valueKey}
            />
          </div>
        )}
      </div>
    </div>
  )
}

// inputAnsMessageContainerStyles={{
//   background: '#ebebeb',
// }}
// pureInputProps={{
//   style: {
//     color:
//       options.map((dos) => getOptionValue(dos))?.includes(value) || value === ''
//         ? '#000'
//         : '#fff',
//   },
// }}

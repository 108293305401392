import { createSlice } from '@reduxjs/toolkit'
import {
  REDUX_STATUS_FAILED,
  REDUX_STATUS_IDLE,
  REDUX_STATUS_LOADING,
  REDUX_STATUS_SUCCESS,
} from 'src/consumer/constants'
import { resetStores } from 'src/store'
import { getTeacherProfileData } from './services/teacherProfile'

const initialState = {
  teacherData: {
    data: null,
    status: REDUX_STATUS_IDLE,
    error: null,
  },
}

const teacherProfileDataSlice = createSlice({
  name: 'teacherProfileDataStore',
  initialState,
  reducers: {
    setTeacherProfileData(state, action) {
      return {
        ...state,
        profileData: {
          data: action.payload,
          status: REDUX_STATUS_SUCCESS,
          error: null,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTeacherProfileData.pending, (state) => {
        state.teacherData.status = REDUX_STATUS_LOADING
      })
      .addCase(getTeacherProfileData.fulfilled, (state, action) => {
        state.teacherData.status = REDUX_STATUS_SUCCESS
        state.teacherData.data = action.payload
      })
      .addCase(getTeacherProfileData.rejected, (state, action) => {
        state.teacherData.status = REDUX_STATUS_FAILED
        state.teacherData.error = action.payload
      })
      .addCase(resetStores, () => initialState)
  },
})

export const teacherProfileDataReducer = teacherProfileDataSlice.reducer

export const { setTeacherProfileData } = teacherProfileDataSlice.actions

export const selectTeacherProfileData = (state) => state.teacherProfileDataStore.teacherData

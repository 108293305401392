import React from 'react'

export default function PrescriptionKVItem({
  itemKey,
  value,
  topContainerProps = {},
  valueItemProps = {},
  containerType = 'span',
}) {
  if (containerType === 'div') {
    return (
      <div className="d-flex align-items-center gap-1" {...topContainerProps}>
        <strong>{itemKey}: </strong>
        <span {...valueItemProps}>{value}</span>
      </div>
    )
  }

  if (containerType === 'span')
    return (
      <span {...topContainerProps}>
        <strong>{itemKey}: </strong>
        <span {...valueItemProps}>{value}</span>
      </span>
    )
}

import { PRESCRIPTION_LAYOUTS } from 'src/consumer/constants'
import {
  PMPHeaderDoctorText,
  PMPHeaderLeftPart,
  PMPHeaderRightPart,
  PMPHeadingBanner,
} from '../../PrescriptionMakingPageStyles'
import PrescriptionKVItem from '../common/PrescriptionKVItem'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'

export default function ListPointersFooterPrescriptionHeader({
  prescriptionData,
  leaveSpace,
  debug = false,
  topPadding = '2px',
}) {
  const currentLayout = prescriptionData?.layout

  // PRESCRIPTION_LAYOUTS.listPointersFooter

  return (
    <>
      <PMPHeadingBanner
        debug={debug}
        noColor={true}
        style={{
          paddingTop: topPadding,
          flexDirection: 'row',
          justifyContent: 'center',
          letterSpacing: '0px',
          gap: '16px',
        }}
      >
        <div style={{ aspectRatio: 1, width: '70px', height: '70px' }}>
          {!!prescriptionData?.clinicBrandLogo && (
            <img
              style={{ width: '100%', height: '100%' }}
              src={getImageLink(prescriptionData?.clinicBrandLogo)}
              alt="clinicBrandLogo"
            ></img>
          )}
        </div>
        <div
          className="d-flex flex-column"
          style={{ fontFamily: "'Noto Sans Devanagari'", fontSize: '16px' }}
        >
          <div
            style={{
              border: '2px solid #000',
              borderRadius: '8px',
              padding: '0 24px',
              fontSize: '1.8em',
              fontWeight: '900',
            }}
          >
            <span>{prescriptionData?.header?.title}</span>
          </div>
          {prescriptionData?.header?.subTitle && (
            <span className="sub-heading" style={{ fontSize: '1em' }}>
              {prescriptionData?.header?.subTitle}
            </span>
          )}
        </div>
        <div style={{ aspectRatio: 1, width: '50px' }}></div>
      </PMPHeadingBanner>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PMPHeaderLeftPart>
          <PMPHeaderDoctorText
            main
            style={{
              fontSize:
                currentLayout === PRESCRIPTION_LAYOUTS.listPointersFooter ? '18px' : 'unset',
            }}
          >
            {prescriptionData?.doctorName}
          </PMPHeaderDoctorText>
          <PMPHeaderDoctorText>{prescriptionData?.designation}</PMPHeaderDoctorText>
          <PMPHeaderDoctorText>{prescriptionData?.listOfDegrees?.join(', ')}</PMPHeaderDoctorText>
        </PMPHeaderLeftPart>
        <PMPHeaderRightPart
          style={{
            flexShrink: 0,
            width: 'unset',
          }}
        >
          {prescriptionData?.regId && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="Regn No."
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.regId}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.contactEmail && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="Email"
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.contactEmail}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.website && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="Website"
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.website}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.cmoRegId && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="C.M.O. Regd. No."
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.cmoRegId}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.affiliations?.map((affiliation, affIndex) => (
            <PMPHeaderDoctorText key={affIndex}>
              <b>{affiliation}</b>
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderRightPart>
      </div>
    </>
  )
}

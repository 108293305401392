import { createContext } from 'react'
import { platformWeb, useFetchPublic } from '../services'

export const LocalizationContext = createContext({})

export const LocalizationContextProvider = ({ children }) => {
  const {
    loading: localizedDataLoading,
    data: localizedData,
    error: localizedDataError,
    refreshFunction: refreshLocaleData,
  } = useFetchPublic('/api/external/locale', `&platformType=${platformWeb}&product=com.aumhum.web`, 'web')

  return (
    <LocalizationContext.Provider
      value={{ localizedDataLoading, localizedData, localizedDataError, refreshLocaleData }}
    >
      {children}
    </LocalizationContext.Provider>
  )
}

import React from 'react'
import { HEADER_TYPES, PRESCRIPTION_PRINT_TYPES } from 'src/consumer/constants'
import { PrescriptionHeader } from '../../PrescriptionMakingPageComponents'

export default function PrescriptionPrintableHeader({
  headerStyleToShow,
  prescriptionData,
  headerHeight,
  currentPage,
  printType,
  debugMode,
  hideDebugLabels,
  pageHeight,
  headerDimensionsHeight,
  showBottomBorder,
  idx,
  leaveSpace,
}) {
  return (
    <PrescriptionHeader
      prescriptionData={prescriptionData}
      leaveSpace={leaveSpace}
      debug={debugMode}
      printType={printType}
      headerHeight={headerHeight}
    />
  )
}
// return (
//   <>
//     {headerStyleToShow === HEADER_TYPES?.onAllPages ? (
//       <div style={{ borderBottom: showBottomBorder ? '1px solid #000' : 'unset' }}>

//         {debugMode && !hideDebugLabels ? (
//           <div
//             style={{
//               position: 'absolute',
//               top: `${pageHeight * (currentPage - 1) + headerDimensionsHeight - 10}px`,
//               right: '0px',
//             }}
//           >
//             HEADER ENDS AND BODY STARTS: {headerDimensionsHeight?.toFixed(2)} Px
//           </div>
//         ) : (
//           <></>
//         )}
//       </div>
//     ) : (
//       <></>
//     )}
//     {headerStyleToShow === HEADER_TYPES?.onAlternatePages ? (
//       <>
//         {idx % 2 === 0 ? (
//           <div style={{ borderBottom: showBottomBorder ? '1px solid #000' : 'unset' }}>
//             <PrescriptionHeader
//               prescriptionData={prescriptionData}
//               leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
//               headerHeight={headerHeight}
//               debug={debugMode}
//               printType={printType}
//             />
//             {debugMode && !hideDebugLabels ? (
//               <div
//                 style={{
//                   position: 'absolute',
//                   top: `${pageHeight * idx + headerDimensionsHeight - 10}px`,
//                   right: '0px',
//                 }}
//               >
//                 HEADER ENDS AND BODY STARTS: {headerDimensionsHeight?.toFixed(2)} Px
//               </div>
//             ) : (
//               <></>
//             )}
//           </div>
//         ) : (
//           <>
//             {debugMode && !hideDebugLabels ? (
//               <div
//                 style={{
//                   position: 'absolute',
//                   top: `${pageHeight * idx + 30}px`,
//                   right: '0px',
//                 }}
//               >
//                 HEADER ENDS AND BODY STARTS: 0 Px
//               </div>
//             ) : (
//               <></>
//             )}
//           </>
//         )}
//       </>
//     ) : (
//       <></>
//     )}
//     {headerStyleToShow === HEADER_TYPES?.blankHeader ? (
//       <div style={{ borderBottom: showBottomBorder ? '1px solid #000' : 'unset' }}>
//         <PrescriptionHeader
//           prescriptionData={prescriptionData}
//           leaveSpace={true}
//           headerHeight={headerHeight}
//           debug={debugMode}
//           printType={printType}
//         />
//         {debugMode && !hideDebugLabels ? (
//           <div
//             style={{
//               position: 'absolute',
//               top: `${pageHeight * idx + headerDimensionsHeight - 10}px`,
//               right: '0px',
//             }}
//           >
//             HEADER ENDS AND BODY STARTS: {headerDimensionsHeight?.toFixed(2)} Px
//           </div>
//         ) : (
//           <></>
//         )}
//       </div>
//     ) : (
//       <></>
//     )}
//     {debugMode && !hideDebugLabels ? (
//       <div
//         style={{
//           position: 'absolute',
//           // top: `${
//           //   heightOfPageMeasureComponent -
//           //   (headerDimensions?.height + footerDimensions?.height) -
//           //   10
//           // }px`,
//           top: `${pageHeight * idx - 10}px`,
//           right: '0px',
//         }}
//       >
//         Page {idx + 1} ends {headerDimensionsHeight?.toFixed(2)}px
//       </div>
//     ) : (
//       <></>
//     )}
//   </>
// )

import { CalendarMonth } from '@mui/icons-material'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import WatchLaterIcon from '@mui/icons-material/WatchLater'
import { IconButton } from '@mui/material'
import { loadStripe } from '@stripe/stripe-js'
import moment from 'moment'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import Calendar from 'react-calendar'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import {
  APP_CONSTANTS,
  FAQ_CATEGORIES,
  currency_list,
  platformOptions,
} from 'src/consumer/constants'
import { LocalizationContext } from 'src/consumer/context/LocalizationContext'
import confirmationTickLottie from 'src/consumer/lotties/confirmationTickLottie.json'
import { appVersion, platformWeb, useFetch } from 'src/consumer/services'
import { getDataWithoutParams } from 'src/consumer/services/featureServices'
import { logoutHandler } from 'src/consumer/services/loginLogoutHandlers'
import { getData } from 'src/consumer/services/profileService'
import { getTimeZoneOptionsWithValueAsTimezoneOffset } from 'src/mentor/components/MentorAvailabilityPage/MentorAvailabilityPageStyles'
import { getImageLink, sleep } from 'src/mentor/helpers/utilityFunctions'
import { GenericDisplayFlex, GenericDisplayFlexColumn } from 'src/mentor/layout/Styles'
import { BLANK_MENTOR_SCHEDULE } from '../../constants'
import { LoadingSpinner } from '../Loaders'
import { PublicAppFooter } from '../PublicAppFooter'
import PublicAppHeader from '../PublicAppHeader'
import { AppFAQModalComponent } from '../ServiceProvidersListingPage/ServiceProvidersListingComponents'
import { ClubbedAuthModal } from '../SignIn/ClubbedAuthComponents'
import {
  adjustPostbufferTimeAndConvertAMPM,
  adjustPrebufferTimeAndConvertAMPM,
  convertParamDateStringToNativeDate,
  createAvailableTimeSlotsArray,
  getBookingPlanData,
  getBundlesDataFromBundlesArray,
  payAndMakeEventBooking,
  payForTheBundle,
} from './BookingHelperFunctions'
import {
  ActionButton,
  ActionButtonsContainer,
  AnimatedContainerTimeSlots,
  CalendarContainer,
  CalendarPartContainer,
  ConfirmationContainer,
  ContainerOne,
  ContainerTwo,
  DetailsContainer,
  EBBundleItemConfirmButton,
  EBBundleItemParent,
  EBBundleItemTitleContainer,
  EBBundleItemsContainer,
  ErrorMessage,
  EventBookingInteractionPage,
  EventBookingPageBackground,
  GiftCardInput,
  GiftCodeInputContainer,
  GiftCodeSubmitButton,
  HeadingConfirmationPage,
  HeadingPaymentsPage,
  PayLineSpan,
  PaymentMethodBox,
  PaymentOptionImage,
  PaymentOptionName,
  PaymentsContainer,
  ProfessionalHeading,
  ProfessionalPicture,
  ProfessionalText,
  SelectDateHeading,
  SignedInUserName,
  StyledSelectTimeZone,
  SubheadingConfirmationPage,
  SubheadingPaymentsPage,
  TickLottie,
  TimeButton,
  TimeChoosingContainer,
  TimeChoosingContainerHeading,
} from './EventBookingStyles'
import NextAvailableSlots from './components/NextAvailableSlots'
import {
  selectUserCreditsData,
  setUserCreditsData,
} from 'src/consumer/reducers/personalData/personalDataReducer'
import { SEOComponent } from 'src/consumer/helpers/utilFunctions'

export const PAGE_VIEW_STATES = {
  next_few_slots: 'next_few_slots',
  calendar_view: 'calendar_view',
  bundleSelection: 'bundle_selection',
  confirm_slot_choose_payment: 'confirm_slot_choose_payment',
  payUsingGiftCard: 'payUsingGiftCard',
  bookingConfirmation: 'bookingConfirmation',
}

// NOTE : any change here should be made in SecuredComponentEventBooking also.

export const PublicComponentEventBooking = ({
  mentorSlug,
  eventId,
  addictionFormData,
  date,
  tgt,
  tlt,
}) => {
  // data related states :
  const {
    loading: professionalDataLoading,
    data: professionalData,
    error: professionalDataError,
  } = useFetch(
    '/api/service-provider',
    `&id=${mentorSlug}&slug=${mentorSlug}&zoneOffset=${encodeURIComponent(
      moment().format('Z'),
    )}&appVersion=${appVersion}&platformType=${platformWeb}`,
    'web',
  )
  const [planData, setPlanData] = useState()
  const [faqOpen, setFaqOpen] = useState(false)
  const { localizedData } = useContext(LocalizationContext)
  const eventBookingPageStrings = localizedData?.strings?.eventBookingPage

  // calendar related states :
  const [calendarLoading, setCalendarLoading] = useState(false)
  const [selectedDateValue, setSelectedDateValue] = useState()
  const [selectedTimeZone, setSelectedTimeZone] = useState(encodeURIComponent(moment().format('Z')))
  const [lastActiveStartDate, setLastActiveStartDate] = useState(moment()?.format('YYYY-MM-DD'))
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState()
  const [availableTimeSlots, setAvailableTimeSlots] = useState()

  const [showClubbedAuthModal, setShowClubbedAuthModal] = useState(false)
  const [typeOfClubbedAuthModal, setTypeOfClubbedAuthModal] = useState('login')

  const mentorId = professionalData?.id

  const [searchParams, setSearchParams] = useSearchParams()
  const whereYouCameFrom = searchParams.get('src')

  useEffect(() => {
    ;(async () => {
      if (!professionalDataLoading && Boolean(professionalData)) {
        const resp = await getDataWithoutParams(
          `/api/subscription-plan?id=${
            professionalData?.eventTypes?.find((event) => event?.uid === eventId)
              ?.subscriptionPlanId
          }&zoneOffset=${encodeURIComponent(
            moment().format('Z'),
          )}&appVersion=${appVersion}&platformType=${platformWeb}&languageCode=${sessionStorage.getItem(
            'languageCode',
          )}&platform=${platformWeb}&countryCode=${localStorage.getItem('countryCode')}`,
        )
        if (resp?.status === 200) {
          setPlanData(resp?.data)
        }
      }
    })()
  }, [professionalDataLoading, professionalData]) // setting plan data

  const eventDetailOfRequestedEvent = useMemo(() => {
    return professionalData?.eventTypes?.find((event) => event?.uid === eventId) // getting event details of requested event
  }, [professionalData, eventId]) // setting event details of requested event

  const scheduleOfProfessional = useMemo(() => {
    const toReturn = getScheduleForThisEvent(
      professionalData,
      eventId,
      eventDetailOfRequestedEvent,
      selectedTimeZone,
    )
    return toReturn
  }, [professionalData]) // setting schedule of professional

  useEffect(() => {
    ;(async () => {
      if (professionalData) {
        setCalendarLoading(true)
        const response = await createAvailableTimeSlotsArray(
          lastActiveStartDate,
          selectedTimeZone,
          scheduleOfProfessional?.timeZone,
          scheduleOfProfessional,
          eventDetailOfRequestedEvent,
          mentorId,
          eventId,
          selectedTimeZone,
          false,
        )
        setCalendarLoading(false)
        setAvailableTimeSlots(response)
      }
    })()
  }, [selectedTimeZone, professionalData]) // updating calendar data everytime timezone changes or professionaldata changes

  const handleMonthChange = async (action, activeStartDate, value, view) => {
    if (
      (action === 'prev' && view === 'month') ||
      (action === 'prev2' && view === 'month') ||
      (action === 'next' && view === 'month') ||
      (action === 'next2' && view === 'month') ||
      (action === 'drillDown' && view === 'month')
    ) {
      setLastActiveStartDate(moment(activeStartDate)?.format('YYYY-MM-DD'))
      const activeStartDateBasicFormat = moment(activeStartDate)?.format('YYYY-MM-DD')
      setCalendarLoading(true)
      const response = await createAvailableTimeSlotsArray(
        activeStartDateBasicFormat,
        selectedTimeZone,
        scheduleOfProfessional?.timeZone,
        scheduleOfProfessional,
        eventDetailOfRequestedEvent,
        mentorId,
        eventId,
        selectedTimeZone,
        false,
      )
      setAvailableTimeSlots(response)
      setCalendarLoading(false)
    }
    setSelectedDateValue(null)
  } // function to handle when month is changed, getting all available time slots

  function onChangeDate(nextValue) {
    setSelectedTimeSlotIndex()
    setSelectedDateValue(nextValue)
  } // change date function for calendar

  useEffect(() => {
    if (window.innerWidth < 660) {
      const timeSlotChoosingContainer = document.getElementById('timeSlotChoosingContainer')
      if (timeSlotChoosingContainer) {
        timeSlotChoosingContainer.scrollIntoView({
          behavior: 'smooth', // Use smooth scrolling behavior
        })
      }
    }
  }, [selectedDateValue])

  function autoDateSelectAndSlotSelectBasedOnQueryParams(date, tgt, tlt) {
    console.log(availableTimeSlots)
    if (date && tgt && tlt && availableTimeSlots) {
      return
    }
    const jsDate = convertParamDateStringToNativeDate(date)
    let possibleSlotIndex = -1
    console.log(
      availableTimeSlots?.find((item) => item?.date === moment(jsDate)?.format('YYYY-MM-DD'))
        ?.availableTimeSlots,
    )
    if (jsDate && possibleSlotIndex > -1) {
      setSelectedDateValue(jsDate)
      setSelectedTimeSlotIndex(possibleSlotIndex)
    }
  }

  useEffect(() => {
    autoDateSelectAndSlotSelectBasedOnQueryParams(date, tgt, tlt)
  }, [availableTimeSlots])

  if (professionalDataLoading) {
    return <LoadingSpinner bgColor="#F5F5F599" dark />
  } // loader if professional data is coming

  if (
    !Boolean(mentorId) ||
    !Boolean(eventId) ||
    mentorId === 'undefined' ||
    mentorId === 'null' ||
    eventId === 'null' ||
    eventId === 'undefined' ||
    !Boolean(mentorSlug) ||
    mentorSlug === 'undefined' ||
    mentorSlug === 'null' ||
    !professionalData?.eventTypes?.some((item) => item?.uid === eventId)
  ) {
    return (
      <>
        <PublicAppHeader />
        <div>
          ⚠️ Invalid Request, Please try again or choose another professional. If you are still
          unable to book session, please write to us here
        </div>
        <div>📨{APP_CONSTANTS.SUPPORT_EMAIL}</div>
      </>
    )
  } // handling cases when url is not valid

  const dateToday = new Date()
  const maxDate = new Date(dateToday)
  maxDate?.setMonth(dateToday?.getMonth() + 2) // setting max date of calendar

  function tileDisabled({ date, view }) {
    // Disable tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      // return disabledDates.find(dDate => isSameDay(dDate, date));

      // only checking if professional available, not checking exempt dates

      const elementFoundTimeSlot = availableTimeSlots?.find(
        (item) => item?.date === moment(date)?.format('YYYY-MM-DD'),
      )
      if (
        elementFoundTimeSlot?.availableTimeSlots &&
        elementFoundTimeSlot?.availableTimeSlots?.length > 0
      ) {
        return false
      }

      return true
    }
  } // disabling unavailable days

  function adjustPrebufferTimeAndConvertAMPM(timeString, startBufferMins) {
    return moment(timeString, 'HH:mm').add(startBufferMins, 'minutes').format('h:mm a')
  }

  function handleGoBack() {
    window.history.back()
  }

  console.log(eventDetailOfRequestedEvent)
  let typeName = ''
  switch (eventDetailOfRequestedEvent?.type) {
    case '1on1':
      typeName = 'one on one session'
      break
    case 'interview':
      typeName = 'interview'
      break
    case 'consultation':
      typeName = 'consultation'
      break
    case 'therapy-session':
      typeName = 'therapy session'
      break
    case 'webinar':
      typeName = 'webinar'
      break
    case 'screening':
      typeName = 'screening'
      break
    default:
      typeName = 'session'
  }

  return (
    <>
      <PublicAppHeader
        config={{ showLinks: false, showBackButton: window.history.length > 2 }}
        backButtonFunction={handleGoBack}
        loginFunction={() => {
          setTypeOfClubbedAuthModal('login')
          setShowClubbedAuthModal(true)
        }}
        registerFunction={() => {
          setTypeOfClubbedAuthModal('signup')
          setShowClubbedAuthModal(true)
        }}
      />
      {professionalData && eventDetailOfRequestedEvent?.title && (
        <SEOComponent
          title={`Book ${eventDetailOfRequestedEvent?.title} with ${professionalData?.name} | AUMHUM`}
          description={`${
            platformOptions?.find((item) => item?.id === eventDetailOfRequestedEvent?.meetingSrc)
              ?.name
          } ${typeName} with ${professionalData?.name}`}
          image={
            professionalData?.heroCardImage
              ? getImageLink(professionalData?.heroCardImage)
              : APP_CONSTANTS.APP_LOGO_LINK
          }
          keywordsArr={['mentor', 'online', 'job']}
        />
      )}

      <EventBookingPageBackground>
        <EventBookingInteractionPage
          dontMakeItARow={whereYouCameFrom === 'behavioral-addiction-plan'}
        >
          <DetailsContainer isNotInARow={whereYouCameFrom === 'behavioral-addiction-plan'}>
            {whereYouCameFrom !== 'behavioral-addiction-plan' && (
              <ContainerTwo>
                <ProfessionalPicture
                  src={getImageLink(professionalData?.profilePicId)}
                  alt="ProfessionalProfile"
                />
                <div>
                  <ProfessionalHeading main>{professionalData?.name}</ProfessionalHeading>
                  <ProfessionalText main>{professionalData?.shortDesc}</ProfessionalText>
                </div>
              </ContainerTwo>
            )}

            <ContainerOne
              noBorder={whereYouCameFrom === 'behavioral-addiction-plan'}
              fullWidth={whereYouCameFrom === 'behavioral-addiction-plan'}
              style={{
                paddingLeft: whereYouCameFrom === 'behavioral-addiction-plan' ? '0px' : null,
              }}
            >
              {whereYouCameFrom !== 'behavioral-addiction-plan' && (
                <ProfessionalText>{eventBookingPageStrings?.service || 'Service'}</ProfessionalText>
              )}
              <ProfessionalHeading main={whereYouCameFrom === 'behavioral-addiction-plan'}>
                {eventDetailOfRequestedEvent?.title} ({eventDetailOfRequestedEvent?.durationInMins}{' '}
                mins)
              </ProfessionalHeading>
              <ProfessionalText main style={{ marginTop: '0px' }}>
                {eventDetailOfRequestedEvent?.description}
              </ProfessionalText>
              {whereYouCameFrom !== 'behavioral-addiction-plan' && (
                <>
                  <ProfessionalText>{eventBookingPageStrings?.rate || 'Rate'}</ProfessionalText>
                  {eventDetailOfRequestedEvent?.free ? (
                    <ProfessionalHeading>Free</ProfessionalHeading>
                  ) : (
                    <ProfessionalHeading>
                      <span>
                        {currency_list?.find((item) => item?.code === planData?.currency)?.symbol}
                      </span>
                      <span
                        style={{
                          textDecoration: 'line-through',
                          marginRight: '10px',
                          fontWeight: '300',
                        }}
                      >
                        {planData?.price}
                      </span>
                      <span>
                        {currency_list?.find((item) => item?.code === planData?.currency)?.symbol}
                      </span>
                      <span>{planData?.finalPrice}</span>
                    </ProfessionalHeading>
                  )}
                </>
              )}
            </ContainerOne>
          </DetailsContainer>

          <GenericDisplayFlexColumn>
            <SelectDateHeading isNotInARow={whereYouCameFrom === 'behavioral-addiction-plan'}>
              {eventBookingPageStrings?.select_date_and_time_in_calendar || 'Select a Date & Time '}
              {calendarLoading && (
                <Spinner animation="border" variant="dark" size="sm" style={{ margin: '0 auto' }} />
              )}
            </SelectDateHeading>
            <div style={{ display: 'flex', gap: '5px' }}>
              <SignedInUserName noPadding={whereYouCameFrom === 'behavioral-addiction-plan'}>
                You need to Sign in to Book a session.
              </SignedInUserName>
              <SignedInUserName
                style={{ cursor: 'pointer' }}
                main
                onClick={() => {
                  setTypeOfClubbedAuthModal('login')
                  setShowClubbedAuthModal(true)
                }}
              >
                {' '}
                Sign In
              </SignedInUserName>
            </div>

            <CalendarPartContainer
              noMargin={whereYouCameFrom === 'behavioral-addiction-plan'}
              noPadding={whereYouCameFrom === 'behavioral-addiction-plan'}
            >
              <CalendarContainer>
                <Calendar
                  onChange={onChangeDate}
                  value={selectedDateValue}
                  minDate={dateToday}
                  maxDate={maxDate}
                  minDetail="decade"
                  tileDisabled={tileDisabled}
                  showNeighboringMonth={false}
                  onActiveStartDateChange={({ action, activeStartDate, value, view }) =>
                    handleMonthChange(action, activeStartDate, value, view)
                  }
                />
                <StyledSelectTimeZone
                  value={selectedTimeZone}
                  onChange={(e) => setSelectedTimeZone(e.target.value)}
                >
                  {getTimeZoneOptionsWithValueAsTimezoneOffset()}
                </StyledSelectTimeZone>
              </CalendarContainer>

              <TimeChoosingContainer
                selectedDateValue={selectedDateValue}
                id="timeSlotChoosingContainer"
              >
                <TimeChoosingContainerHeading>
                  {moment(selectedDateValue)?.format('ddd, MMM D')}
                </TimeChoosingContainerHeading>

                <AnimatedContainerTimeSlots key={selectedDateValue}>
                  {availableTimeSlots?.find(
                    (item) => item?.date === moment(selectedDateValue)?.format('YYYY-MM-DD'),
                  )?.availableTimeSlots?.length === 0 && (
                    <div style={{ color: 'red' }}>All slots are already booked</div>
                  )}
                  {availableTimeSlots
                    ?.find((item) => item?.date === moment(selectedDateValue)?.format('YYYY-MM-DD'))
                    ?.availableTimeSlots?.map((timeSlotItem, index) => {
                      return (
                        <GenericDisplayFlex style={{ gap: '5px' }} key={index}>
                          <TimeButton onClick={() => setSelectedTimeSlotIndex(index)}>
                            {adjustPrebufferTimeAndConvertAMPM(
                              timeSlotItem?.startTime?.substring(11, 16),
                              eventDetailOfRequestedEvent?.preBufferMins,
                            )}
                          </TimeButton>
                          {selectedTimeSlotIndex === index && (
                            <TimeButton
                              confirm
                              onClick={() => {
                                setTypeOfClubbedAuthModal('login')
                                setShowClubbedAuthModal(true)
                              }}
                            >
                              Confirm
                            </TimeButton>
                          )}
                        </GenericDisplayFlex>
                      )
                    })}
                </AnimatedContainerTimeSlots>
              </TimeChoosingContainer>
            </CalendarPartContainer>
          </GenericDisplayFlexColumn>
        </EventBookingInteractionPage>
        <ProfessionalText style={{ cursor: 'pointer' }} onClick={() => setFaqOpen(true)}>
          Have Questions?
        </ProfessionalText>
        <ClubbedAuthModal
          open={showClubbedAuthModal}
          onClose={() => setShowClubbedAuthModal(false)}
          loginVariant={typeOfClubbedAuthModal === 'login'}
        />
      </EventBookingPageBackground>

      <AppFAQModalComponent
        categories={[FAQ_CATEGORIES.event_booking_faqs]}
        open={faqOpen}
        onClose={() => setFaqOpen(false)}
      />

      <PublicAppFooter />
    </>
  )
}

// any change here should be made in PublicComponentEventBooking also.
export const SecuredComponentEventBooking = ({
  fromOnboarding,
  mentorSlug,
  eventId,
  pastSelectedTimeSlotIndex,
  pastSelectedDateValue,
  openedAsWebview,
  addictionFormData,
  date,
  tgt,
  tlt,
}) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userCreditsDataFromStore = useSelector(selectUserCreditsData)

  // view related states :
  const [pageView, setPageView] = useState(PAGE_VIEW_STATES.calendar_view)
  const [bundleBuyingWaitingState, setBundleBuyingWaitingState] = useState() // { bundleBuy: null, eventBooking: null } [states: null, "waiting", "failed, "success"]
  const [faqOpen, setFaqOpen] = useState(false)
  const { localizedData } = useContext(LocalizationContext)
  const eventBookingPageStrings = localizedData?.strings?.eventBookingPage
  const [searchParams, setSearchParams] = useSearchParams()
  const whereYouCameFrom = searchParams.get('src')

  // data related states :
  const {
    loading: professionalDataLoading,
    data: professionalData,
    error: professionalDataError,
  } = useFetch(
    '/api/service-provider',
    `&id=${mentorSlug}&slug=${mentorSlug}&zoneOffset=${encodeURIComponent(
      moment().format('Z'),
    )}&appVersion=${appVersion}&platformType=${platformWeb}`,
    'web',
  )
  const [planData, setPlanData] = useState()
  const [bundlesData, setBundlesData] = useState()
  const [chosenPurchaseTypeId, setChosenPurchaseTypeId] = useState('single') // 'single' or the id of bundle
  const [userProfileData, setUserProfileData] = useState()

  // calendar related states :
  const [calendarLoading, setCalendarLoading] = useState(false)
  const [selectedDateValue, setSelectedDateValue] = useState(
    Boolean(pastSelectedDateValue) && pastSelectedDateValue,
  )
  const [selectedTimeZone, setSelectedTimeZone] = useState(encodeURIComponent(moment().format('Z')))
  const [lastActiveStartDate, setLastActiveStartDate] = useState(moment()?.format('YYYY-MM-DD'))
  const [chosenSlotData, setChosenSlotData] = useState()
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState(
    (Boolean(pastSelectedTimeSlotIndex) || pastSelectedTimeSlotIndex === 0) &&
      pastSelectedTimeSlotIndex,
  )
  const [availableTimeSlots, setAvailableTimeSlots] = useState()

  const mentorId = professionalData?.id

  // stripe states:
  const [stripePromise, setStripePromise] = useState(null)

  // gift code related details :
  const [giftCode, setGiftCode] = useState()
  const [giftCodeError, setGiftCodeError] = useState(false)

  // booking related states :
  const [confirmBookingLoading, setConfirmBookingLoading] = useState(false)
  const [paymentMethodChosen, setPaymentMethodChosen] = useState()

  const isMountedOnAWebView = Boolean(
    sessionStorage.getItem('isThisWebviewSession') || openedAsWebview,
  )

  const cameFromDeAddictionPlanPage = whereYouCameFrom === 'behavioral-addiction-plan'

  async function getAndSetUserCreditsInStore(force = false) {
    if (force ? true : !Boolean(userCreditsDataFromStore)) {
      const response = await getData('/api/secure/wallet/user/credits')
      if (response?.status === 200 && Array.isArray(response?.data)) {
        dispatch(setUserCreditsData(response?.data))
      }
    }
  }

  useEffect(() => {
    getAndSetUserCreditsInStore()
  }, [])

  useEffect(() => {
    ;(async () => {
      if (!professionalDataLoading && Boolean(professionalData)) {
        const respData = await getBookingPlanData(professionalData?.eventTypes, eventId)
        const respBundleData = await getBundlesDataFromBundlesArray(
          professionalData?.eventTypes,
          eventId,
        )
        setPlanData(respData)
        setBundlesData(respBundleData)
      }
    })()
  }, [professionalDataLoading, professionalData]) // setting plan data

  useEffect(() => {
    ;(async () => {
      const resp = await getData('/api/secure/user/profile')
      if (resp?.status === 200) {
        setUserProfileData(resp?.data)
      }
    })()
  }, []) // user profile data

  useEffect(() => {
    loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY).then((result) => {
      setStripePromise(result)
    })
  }, []) // making stripe promise

  const eventDetailOfRequestedEvent = useMemo(() => {
    return professionalData?.eventTypes?.find((event) => event?.uid === eventId) // getting event details of requested event
  }, [professionalData, eventId]) // setting event details of requested event

  const scheduleOfProfessional = useMemo(() => {
    const toReturn = getScheduleForThisEvent(
      professionalData,
      eventId,
      eventDetailOfRequestedEvent,
      selectedTimeZone,
    )
    return toReturn
  }, [professionalData]) // setting schedule of professional

  useEffect(() => {
    ;(async () => {
      if (professionalData) {
        setCalendarLoading(true)
        const response = await createAvailableTimeSlotsArray(
          lastActiveStartDate,
          selectedTimeZone,
          scheduleOfProfessional?.timeZone,
          scheduleOfProfessional,
          eventDetailOfRequestedEvent,
          mentorId,
          eventId,
          selectedTimeZone,
          false,
        )
        setCalendarLoading(false)
        setAvailableTimeSlots(response)
      }
    })()
  }, [selectedTimeZone, professionalData]) // updating calendar data everytime timezone changes or professionaldata changes

  async function onSuccessfulBuyingBundle() {
    if (
      bundleBuyingWaitingState?.bundleBuy === 'success' &&
      bundleBuyingWaitingState?.eventBooking === null
    ) {
      // setPageView(PAGE_VIEW_STATES.bookingConfirmation)
      navigate('/event-booking-successful', {
        state: {
          eventDetailOfRequestedEvent,
          chosenSlotData,
          fromOnboarding,
          professionalData,
          openedAsWebview,
          bundleBuyingWaitingState,
        },
      })

      // initiate booking using the credit here
      setBundleBuyingWaitingState({ bundleBuy: 'success', eventBooking: 'waiting' })
      await payAndMakeEventBooking(
        eventDetailOfRequestedEvent, // bookingEventDetails
        chosenSlotData, // chosenSlotData
        'credits', // paymentGateway
        'GFT-DEFAULT', //  giftCardId
        stripePromise, // stripePromise
        mentorId, // mentorId
        userProfileData, // userProfileData
        setGiftCodeError, // giftCodeErrorSetter
        () => {
          setBundleBuyingWaitingState({ bundleBuy: 'success', eventBooking: 'success' })
          getAndSetUserCreditsInStore()
        }, // afterConfirmationFunction
        'uid', // eventIdField
        'regular', // bookingType
        () => null, // orderCreationDataSetter
        [addictionFormData],
      )
    }
  }

  useEffect(() => {
    onSuccessfulBuyingBundle()
  }, [bundleBuyingWaitingState])

  const handleMonthChange = async (action, activeStartDate, value, view) => {
    if (
      (action === 'prev' && view === 'month') ||
      (action === 'prev2' && view === 'month') ||
      (action === 'next' && view === 'month') ||
      (action === 'next2' && view === 'month') ||
      (action === 'drillDown' && view === 'month')
    ) {
      setLastActiveStartDate(moment(activeStartDate)?.format('YYYY-MM-DD'))
      const activeStartDateBasicFormat = moment(activeStartDate)?.format('YYYY-MM-DD')
      setCalendarLoading(true)
      const response = await createAvailableTimeSlotsArray(
        activeStartDateBasicFormat,
        selectedTimeZone,
        scheduleOfProfessional?.timeZone,
        scheduleOfProfessional,
        eventDetailOfRequestedEvent,
        mentorId,
        eventId,
        selectedTimeZone,
        true,
      )
      setAvailableTimeSlots(response)
      setCalendarLoading(false)
    }
    setSelectedDateValue(null)
  } // function to handle when month is changed, getting all available time slots

  function onChangeDate(nextValue) {
    setSelectedTimeSlotIndex()
    setSelectedDateValue(nextValue)
  } // change date function for calendar

  useEffect(() => {
    if (window.innerWidth < 660) {
      const timeSlotChoosingContainer = document.getElementById('timeSlotChoosingContainer')
      if (timeSlotChoosingContainer) {
        timeSlotChoosingContainer.scrollIntoView({
          behavior: 'smooth', // Use smooth scrolling behavior
        })
      }
    }
  }, [selectedDateValue])

  useEffect(() => {
    if (cameFromDeAddictionPlanPage) {
      setPageView(PAGE_VIEW_STATES.next_few_slots)
    }
  }, [cameFromDeAddictionPlanPage])

  if (professionalDataLoading) {
    return <LoadingSpinner bgColor="#F5F5F599" dark />
  } // loader if secure setup data is coming

  if (
    !Boolean(mentorId) ||
    !Boolean(eventId) ||
    mentorId === 'undefined' ||
    mentorId === 'null' ||
    eventId === 'null' ||
    eventId === 'undefined' ||
    !Boolean(mentorSlug) ||
    mentorSlug === 'undefined' ||
    mentorSlug === 'null' ||
    !professionalData?.eventTypes?.some((item) => item?.uid === eventId)
  ) {
    return (
      <>
        <PublicAppHeader config={{ showLinks: true }} />
        <div>
          ⚠️ Invalid Request, Please try again or choose another professional. If you are still
          unable to book session, please write to us here
        </div>
        <div>📨{APP_CONSTANTS.SUPPORT_EMAIL}</div>
      </>
    )
  } // handling cases when url is not valid

  const dateToday = new Date()
  const maxDate = new Date(dateToday)
  maxDate?.setMonth(dateToday?.getMonth() + 2) // setting max date of calendar

  function tileDisabled({ date, view }) {
    // Disable tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      // return disabledDates.find(dDate => isSameDay(dDate, date));

      // only checking if professional available, not checking exempt dates

      const elementFoundTimeSlot = availableTimeSlots?.find(
        (item) => item?.date === moment(date)?.format('YYYY-MM-DD'),
      )
      if (
        elementFoundTimeSlot?.availableTimeSlots &&
        elementFoundTimeSlot?.availableTimeSlots?.length > 0
      ) {
        return false
      }

      return true
    }
  } // disabling unavailable days

  function adjustPrebufferTimeAndConvertAMPM(timeString, startBufferMins) {
    return moment(timeString, 'HH:mm').add(startBufferMins, 'minutes').format('h:mm a')
  }

  function adjustPostbufferTimeAndConvertAMPM(timeString, endBufferMins) {
    return moment(timeString, 'HH:mm').subtract(endBufferMins, 'minutes').format('h:mm a')
  }

  async function confirmDateAndTime(data, preBufferMins, postBufferMins) {
    setChosenSlotData({ data: data, preBufferMins: preBufferMins, postBufferMins: postBufferMins })
    if (Array.isArray(bundlesData) && bundlesData?.length > 0) {
      if (noOfCreditsForThisEvent && noOfCreditsForThisEvent > 0) {
        setPageView(PAGE_VIEW_STATES.confirm_slot_choose_payment)
      } else {
        setPageView(PAGE_VIEW_STATES.bundleSelection)
      }
    } else {
      setPageView(PAGE_VIEW_STATES.confirm_slot_choose_payment)
    }
  }

  async function goBackFromPaymentMethods() {
    if (Array.isArray(bundlesData) && bundlesData?.length > 0) {
      if (noOfCreditsForThisEvent && noOfCreditsForThisEvent > 0) {
        setPageView(PAGE_VIEW_STATES.calendar_view)
      } else {
        setPageView(PAGE_VIEW_STATES.bundleSelection)
      }
    } else {
      setChosenSlotData()
      setPageView(PAGE_VIEW_STATES.calendar_view)
    }
  }

  async function confirmBundleAndMoveToNextPage() {
    setPageView(PAGE_VIEW_STATES.confirm_slot_choose_payment)
  }

  function showBookingConfirmationPage() {
    // setPageView(PAGE_VIEW_STATES.bookingConfirmation)
    navigate('/event-booking-successful', {
      state: {
        eventDetailOfRequestedEvent,
        chosenSlotData,
        fromOnboarding,
        professionalData,
        openedAsWebview,
        bundleBuyingWaitingState,
      },
    })
  }

  async function payAndMakeBooking(paymentGateway, giftCardId) {
    setConfirmBookingLoading(true)

    if (chosenPurchaseTypeId === 'single') {
      await payAndMakeEventBooking(
        eventDetailOfRequestedEvent, // bookingEventDetails
        chosenSlotData, // chosenSlotData
        paymentGateway, // paymentGateway
        giftCardId, //  giftCardId
        stripePromise, // stripePromise
        mentorId, // mentorId
        userProfileData, // userProfileData
        setGiftCodeError, // giftCodeErrorSetter
        showBookingConfirmationPage, // afterConfirmationFunction
        'uid', // eventIdField
        'regular', // bookingType
        () => null, // orderCreationDataSetter
        [addictionFormData], //for now addiction type form data,
      )
    } else {
      await payForTheBundle(
        chosenPurchaseTypeId, // chosenBundleId
        stripePromise, // stripePromise
        paymentGateway, // paymentGateway
        setBundleBuyingWaitingState,
      )
    }
    setConfirmBookingLoading(false)
  }

  function handleGoBack() {
    window.history.back()
  }

  const noOfCreditsForThisEvent = userCreditsDataFromStore?.find(
    (item) => item?.pricingPackId === eventDetailOfRequestedEvent?.subscriptionPlanId,
  )?.numberOfCredits

  console.log(eventDetailOfRequestedEvent)
  let typeName = ''
  switch (eventDetailOfRequestedEvent?.type) {
    case '1on1':
      typeName = 'one on one session'
      break
    case 'interview':
      typeName = 'interview'
      break
    case 'consultation':
      typeName = 'consultation'
      break
    case 'therapy-session':
      typeName = 'therapy session'
      break
    case 'webinar':
      typeName = 'webinar'
      break
    case 'screening':
      typeName = 'screening'
      break
    default:
      typeName = 'session'
  }

  return (
    <>
      {sessionStorage.getItem('isThisWebviewSession') || openedAsWebview ? (
        <div
          style={{
            padding: '10px 20px',
            position: 'fixed',
            top: 0,
            background: '#fff',
            width: '100%',
          }}
        >
          {sessionStorage.getItem('isThisWebviewSession') && (
            <IconButton onClick={handleGoBack}>
              <KeyboardBackspaceIcon sx={{ fontSize: 28, color: 'black', marginRight: '5px' }} />
            </IconButton>
          )}
        </div>
      ) : (
        <PublicAppHeader
          config={{ showLinks: false, showBackButton: window.history.length > 2 }}
          backButtonFunction={handleGoBack}
        />
      )}
      <EventBookingPageBackground>
        <EventBookingInteractionPage dontMakeItARow={cameFromDeAddictionPlanPage}>
          {pageView !== PAGE_VIEW_STATES.bookingConfirmation &&
            pageView !== PAGE_VIEW_STATES.bundleSelection && (
              <MentorDetailSection
                bookingEvent={eventDetailOfRequestedEvent}
                chosenSlotData={chosenSlotData}
                pageView={pageView}
                planData={planData}
                bundlesData={bundlesData}
                chosenPurchaseTypeId={chosenPurchaseTypeId}
                professionalData={professionalData}
                isMountedOnAWebView={isMountedOnAWebView}
                rateText={eventBookingPageStrings?.rate || 'Rate'}
                showMentorDetails={!cameFromDeAddictionPlanPage}
                isNotInARow={cameFromDeAddictionPlanPage}
              />
            )}

          {pageView === PAGE_VIEW_STATES.bundleSelection && (
            <div style={{ width: '100%', height: '100%' }}>
              <GenericDisplayFlexColumn style={{ alignItems: 'center', gap: '40px' }}>
                <SubheadingPaymentsPage
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setPageView(PAGE_VIEW_STATES.calendar_view)
                    setChosenPurchaseTypeId('single')
                  }}
                >
                  <KeyboardBackspaceIcon
                    sx={{ fontSize: 15, color: 'black', marginRight: '5px' }}
                  />
                  Back to Calendar
                </SubheadingPaymentsPage>
                <div style={{ fontSize: '18px', fontWeight: '500' }}>Want a bundle?</div>
                <EBBundleItemsContainer>
                  <EBBundleItem
                    title="This Session Only"
                    currencySymbol={planData?.currency}
                    price={planData?.price}
                    discountedPrice={planData?.finalPrice}
                    themeColor={colorsArray[0]}
                    clickFunction={() => {
                      setChosenPurchaseTypeId('single')
                      confirmBundleAndMoveToNextPage()
                    }}
                  />
                  {bundlesData?.map((bundleItem, bundleItemIndex) => {
                    console.log(bundleItem)
                    return (
                      <EBBundleItem
                        key={bundleItemIndex}
                        title={bundleItem?.title}
                        currencySymbol={bundleItem?.currency}
                        price={bundleItem?.price}
                        themeColor={colorsArray[bundleItemIndex + 1]}
                        discountedPrice={bundleItem?.finalPrice}
                        bundle={bundleItem?.bundle}
                        clickFunction={() => {
                          setChosenPurchaseTypeId(bundleItem?.id)
                          confirmBundleAndMoveToNextPage()
                        }}
                      />
                    )
                  })}
                </EBBundleItemsContainer>
              </GenericDisplayFlexColumn>
            </div>
          )}

          {pageView === PAGE_VIEW_STATES.next_few_slots && (
            <NextAvailableSlots
              availableTimeSlots={availableTimeSlots}
              dateUnavailableChecker={tileDisabled}
              eventBookingPageStrings={eventBookingPageStrings}
              loading={calendarLoading}
              userProfileData={userProfileData}
              onClick={(timeSlot) => {
                confirmDateAndTime(
                  timeSlot,
                  eventDetailOfRequestedEvent.preBufferMins,
                  eventDetailOfRequestedEvent.postBufferMins,
                )
                setSelectedDateValue(moment(timeSlot?.startTime).format('YYYY-MM-DD'))
              }}
              onClickSeeMore={() => setPageView(PAGE_VIEW_STATES.calendar_view)}
              handleMonthChange={handleMonthChange}
            />
          )}

          {pageView === PAGE_VIEW_STATES.calendar_view && (
            <GenericDisplayFlexColumn>
              <SelectDateHeading isNotInARow={cameFromDeAddictionPlanPage}>
                {eventBookingPageStrings?.select_date_and_time_in_calendar ||
                  'Select a Date & Time '}
                {calendarLoading && (
                  <Spinner
                    animation="border"
                    variant="dark"
                    size="sm"
                    style={{ margin: '0 auto' }}
                  />
                )}
              </SelectDateHeading>
              {userProfileData && (
                <div style={{ display: 'flex', gap: '5px' }}>
                  <SignedInUserName noPadding={cameFromDeAddictionPlanPage}>
                    {eventBookingPageStrings?.signed_in_as || 'Signed in as'}
                  </SignedInUserName>
                  <SignedInUserName main noPadding>
                    {' '}
                    {userProfileData?.name}.
                  </SignedInUserName>
                  {!isMountedOnAWebView && (
                    <SignedInUserName
                      main
                      noPadding
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        logoutHandler()
                        navigate('/login')
                      }}
                    >
                      {' '}
                      {eventBookingPageStrings?.logout || 'Logout'}?
                    </SignedInUserName>
                  )}
                </div>
              )}
              <CalendarPartContainer
                noMargin={cameFromDeAddictionPlanPage}
                noPadding={cameFromDeAddictionPlanPage}
              >
                <CalendarContainer>
                  <Calendar
                    onChange={onChangeDate}
                    value={selectedDateValue}
                    minDate={dateToday}
                    maxDate={maxDate}
                    minDetail="decade"
                    tileDisabled={tileDisabled}
                    showNeighboringMonth={false}
                    locale={sessionStorage.getItem('languageCode') || 'en'}
                    onActiveStartDateChange={({ action, activeStartDate, value, view }) =>
                      handleMonthChange(action, activeStartDate, value, view)
                    }
                  />
                  <StyledSelectTimeZone
                    value={selectedTimeZone}
                    onChange={(e) => setSelectedTimeZone(e.target.value)}
                  >
                    {getTimeZoneOptionsWithValueAsTimezoneOffset()}
                  </StyledSelectTimeZone>
                </CalendarContainer>

                <TimeChoosingContainer
                  selectedDateValue={selectedDateValue}
                  id="timeSlotChoosingContainer"
                >
                  <TimeChoosingContainerHeading>
                    {moment(selectedDateValue)?.format('ddd, MMM D')}
                  </TimeChoosingContainerHeading>

                  <AnimatedContainerTimeSlots key={selectedDateValue}>
                    {availableTimeSlots
                      ?.find(
                        (item) => item?.date === moment(selectedDateValue)?.format('YYYY-MM-DD'),
                      )
                      ?.availableTimeSlots?.map((timeSlotItem, index) => {
                        return (
                          <GenericDisplayFlex style={{ gap: '5px' }} key={index}>
                            <TimeButton onClick={() => setSelectedTimeSlotIndex(index)}>
                              {adjustPrebufferTimeAndConvertAMPM(
                                timeSlotItem?.startTime?.substring(11, 16),
                                eventDetailOfRequestedEvent?.preBufferMins,
                              )}
                            </TimeButton>
                            {selectedTimeSlotIndex === index && (
                              <TimeButton
                                confirm
                                onClick={() =>
                                  confirmDateAndTime(
                                    timeSlotItem,
                                    eventDetailOfRequestedEvent?.preBufferMins,
                                    eventDetailOfRequestedEvent?.postBufferMins,
                                  )
                                }
                              >
                                {eventBookingPageStrings?.confirm || 'Confirm'}
                              </TimeButton>
                            )}
                          </GenericDisplayFlex>
                        )
                      })}
                  </AnimatedContainerTimeSlots>
                </TimeChoosingContainer>
              </CalendarPartContainer>
            </GenericDisplayFlexColumn>
          )}

          {pageView === PAGE_VIEW_STATES.confirm_slot_choose_payment && (
            <ConfirmSlotChoosePaymentComponent
              bookingEvent={eventDetailOfRequestedEvent}
              chosenSlotData={chosenSlotData}
              confirmBookingLoading={confirmBookingLoading}
              payAndMakeBooking={payAndMakeBooking}
              paymentMethodChosen={paymentMethodChosen}
              planData={planData}
              bundlesData={bundlesData}
              chosenPurchaseTypeId={chosenPurchaseTypeId}
              setChosenSlotData={setChosenSlotData}
              setPageView={setPageView}
              userProfileData={userProfileData}
              setPaymentMethodChosen={setPaymentMethodChosen}
              isWebview={Boolean(sessionStorage.getItem('isThisWebviewSession') || openedAsWebview)}
              goBackToCalendarButtonFunction={goBackFromPaymentMethods}
            />
          )}

          {pageView === PAGE_VIEW_STATES.payUsingGiftCard && (
            <PayUsingGiftCardComponent
              confirmBookingLoading={confirmBookingLoading}
              giftCode={giftCode}
              setGiftCode={setGiftCode}
              giftCodeError={giftCodeError}
              setGiftCodeError={setGiftCodeError}
              payAndMakeBooking={payAndMakeBooking}
              paymentMethodChosen={paymentMethodChosen}
              setPageView={setPageView}
            />
          )}

          {pageView === PAGE_VIEW_STATES.bookingConfirmation && (
            <PaymentConfirmationComponent
              bookingEventDetails={eventDetailOfRequestedEvent}
              chosenSlotData={chosenSlotData}
              fromOnboarding={fromOnboarding}
              professionalData={professionalData}
              hideHomeButton={sessionStorage.getItem('isThisWebviewSession') || openedAsWebview}
              bundleBuyingWaitingState={bundleBuyingWaitingState}
              isWebview={sessionStorage.getItem('isThisWebviewSession') || openedAsWebview}
            />
          )}
        </EventBookingInteractionPage>
        <ProfessionalText style={{ cursor: 'pointer' }} onClick={() => setFaqOpen(true)}>
          {eventBookingPageStrings?.have_questions || 'Have Questions'}?
        </ProfessionalText>
      </EventBookingPageBackground>

      {professionalData && eventDetailOfRequestedEvent?.title && (
        <SEOComponent
          title={`Book ${eventDetailOfRequestedEvent?.title} with ${professionalData?.name} | AUMHUM`}
          description={`${
            platformOptions?.find((item) => item?.id === eventDetailOfRequestedEvent?.meetingSrc)
              ?.name
          } ${typeName} with ${professionalData?.name}`}
          image={
            professionalData?.heroCardImage
              ? getImageLink(professionalData?.heroCardImage)
              : APP_CONSTANTS.APP_LOGO_LINK
          }
          keywordsArr={['mentor', 'online', 'job']}
        />
      )}

      <AppFAQModalComponent
        categories={[FAQ_CATEGORIES.event_booking_faqs]}
        open={faqOpen}
        onClose={() => setFaqOpen(false)}
      />

      {sessionStorage.getItem('isThisWebviewSession') || openedAsWebview ? (
        <></>
      ) : (
        <PublicAppFooter />
      )}
    </>
  )
}

///// Generic Booking Component

export function ConfirmSlotChoosePaymentComponent({
  bookingEvent,
  setPageView,
  chosenSlotData,
  setChosenSlotData,
  setPaymentMethodChosen = () => {},
  planData,
  bundlesData,
  chosenPurchaseTypeId,
  userProfileData,
  payAndMakeBooking,
  confirmBookingLoading,
  paymentMethodChosen,
  showBackButton = true,
  isWebview,
  goBackToCalendarButtonFunction = () => {
    setPageView('calendar_view')
    setChosenSlotData()
  },
}) {
  const navigate = useNavigate()

  const { localizedData } = useContext(LocalizationContext)
  const eventBookingPageStrings = localizedData?.strings?.eventBookingPage

  const userCreditsDataFromStore = useSelector(selectUserCreditsData)
  const noOfCreditsForThisEvent = userCreditsDataFromStore?.find(
    (item) => item?.pricingPackId === bookingEvent?.subscriptionPlanId,
  )?.numberOfCredits

  return (
    <>
      <GenericDisplayFlexColumn>
        <PaymentsContainer>
          {showBackButton && (
            <SubheadingPaymentsPage
              style={{ marginBottom: '10px', cursor: 'pointer' }}
              onClick={goBackToCalendarButtonFunction}
            >
              {' '}
              <KeyboardBackspaceIcon sx={{ fontSize: 15, color: 'black', marginRight: '5px' }} />
              {eventBookingPageStrings?.go_back_to_calendar || 'Go Back to Calendar'}
            </SubheadingPaymentsPage>
          )}

          {!bookingEvent?.free && (
            <>
              <HeadingPaymentsPage>
                {eventBookingPageStrings?.provide_payment_details || 'Provide Payment Details'}
              </HeadingPaymentsPage>
              <SubheadingPaymentsPage>
                {eventBookingPageStrings?.choose_a_method_of_payment_for_this_session_booking ||
                  'Choose a method of payment for this session booking'}
              </SubheadingPaymentsPage>
            </>
          )}

          {bookingEvent?.free ? (
            <>
              <div style={{ margin: '20px 0' }}>
                <PayLineSpan>This is a </PayLineSpan>
                <PayLineSpan amount>Free</PayLineSpan>
                <PayLineSpan> session</PayLineSpan>
              </div>
              <div>
                <PayLineSpan>
                  {moment(chosenSlotData?.data?.startTime?.substring(0, 10))?.format(
                    'MMMM D, YYYY',
                  )}{' '}
                </PayLineSpan>
                <PayLineSpan>
                  (
                  {`${adjustPrebufferTimeAndConvertAMPM(
                    chosenSlotData?.data?.startTime?.substring(11, 16),
                    chosenSlotData?.preBufferMins,
                  )} - ${adjustPostbufferTimeAndConvertAMPM(
                    chosenSlotData?.data?.endTime?.substring(11, 16),
                    chosenSlotData?.postBufferMins,
                  )}`}
                  )
                </PayLineSpan>
              </div>
            </>
          ) : (
            <div style={{ margin: '20px 0' }}>
              {eventBookingPageStrings?.pay_amount_using?.replace(
                '{1}',
                `${currency_list?.find((item) => item?.code === planData?.currency)?.symbol} ${
                  chosenPurchaseTypeId === 'single'
                    ? planData?.finalPrice
                    : bundlesData?.find((item) => item?.id === chosenPurchaseTypeId)?.finalPrice
                }`,
              ) || (
                <>
                  <PayLineSpan>Pay </PayLineSpan>
                  <PayLineSpan amount>
                    {currency_list?.find((item) => item?.code === planData?.currency)?.symbol}{' '}
                    {chosenPurchaseTypeId === 'single'
                      ? planData?.finalPrice
                      : bundlesData?.find((item) => item?.id === chosenPurchaseTypeId)?.finalPrice}
                  </PayLineSpan>
                  <PayLineSpan> using</PayLineSpan>
                </>
              )}
            </div>
          )}

          <div>
            {bookingEvent?.free && (
              <PaymentMethodBox
                onClick={() => {
                  !confirmBookingLoading && payAndMakeBooking('free')
                }}
              >
                <GenericDisplayFlex>
                  <PaymentOptionName>
                    {eventBookingPageStrings?.confirm || 'Confirm'}
                  </PaymentOptionName>
                </GenericDisplayFlex>
                <div>
                  {confirmBookingLoading ? (
                    <Spinner animation="border" variant="dark" size="sm" />
                  ) : (
                    <ArrowRightAltIcon sx={{ fontSize: 35, color: 'gray' }} />
                  )}
                </div>
              </PaymentMethodBox>
            )}

            {!bookingEvent?.free &&
              chosenPurchaseTypeId === 'single' &&
              noOfCreditsForThisEvent &&
              noOfCreditsForThisEvent > 0 && (
                <PaymentMethodBox
                  onClick={() => {
                    setPaymentMethodChosen('credits')
                    !confirmBookingLoading && payAndMakeBooking('credits')
                  }}
                >
                  <GenericDisplayFlex>
                    <PaymentOptionImage
                      src={require('src/assets/credits_image.png')}
                      alt="creditCardImg"
                    />
                    <PaymentOptionName>Credit Vouchers</PaymentOptionName>
                    <div style={{ textAlign: 'right' }}>
                      Use 1 of your {noOfCreditsForThisEvent} credit vouchers
                    </div>
                  </GenericDisplayFlex>
                  <div>
                    {paymentMethodChosen === 'credits' && confirmBookingLoading ? (
                      <Spinner animation="border" variant="dark" size="sm" />
                    ) : (
                      <ArrowRightAltIcon sx={{ fontSize: 35, color: 'gray' }} />
                    )}
                  </div>
                </PaymentMethodBox>
              )}

            {userProfileData?.location?.countryCode === 'in' && !bookingEvent?.free && (
              <PaymentMethodBox
                onClick={() => {
                  setPaymentMethodChosen('razorpay')
                  !confirmBookingLoading && payAndMakeBooking('razorpay')
                }}
              >
                <GenericDisplayFlex>
                  <PaymentOptionImage
                    src={require('src/assets/payment_options.png')}
                    alt="razorpay"
                  />
                  <PaymentOptionName>Razorpay</PaymentOptionName>
                  <div style={{ textAlign: 'right' }}>
                    {eventBookingPageStrings?.upi_credit_and_debit_netbanking_wallet ||
                      'UPI, Credit and Debit Cards, Netbanking, and Wallets'}
                  </div>
                </GenericDisplayFlex>
                <div>
                  {paymentMethodChosen === 'razorpay' && confirmBookingLoading ? (
                    <Spinner animation="border" variant="dark" size="sm" />
                  ) : (
                    <ArrowRightAltIcon sx={{ fontSize: 35, color: 'gray' }} />
                  )}
                </div>
              </PaymentMethodBox>
            )}

            {!bookingEvent?.free && (
              <PaymentMethodBox
                onClick={() => {
                  setPaymentMethodChosen('stripe')
                  !confirmBookingLoading && payAndMakeBooking('stripe')
                }}
              >
                <GenericDisplayFlex>
                  <PaymentOptionImage src={require('src/assets/stripe_logo.png')} alt="stripLogo" />
                  <PaymentOptionName>Stripe</PaymentOptionName>
                  <div style={{ textAlign: 'right' }}>
                    {eventBookingPageStrings?.credit_and_debit_cards || 'Credit and Debit Cards'}
                  </div>
                </GenericDisplayFlex>
                <div>
                  {paymentMethodChosen === 'stripe' && confirmBookingLoading ? (
                    <Spinner animation="border" variant="dark" size="sm" />
                  ) : (
                    <ArrowRightAltIcon sx={{ fontSize: 35, color: 'gray' }} />
                  )}
                </div>
              </PaymentMethodBox>
            )}

            {!bookingEvent?.free && !isWebview && chosenPurchaseTypeId === 'single' && (
              <>
                <div>
                  <PayLineSpan style={{ cursor: 'default' }}>Use a Gift Card or </PayLineSpan>
                  <PayLineSpan
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => navigate('/gift-card')}
                  >
                    Buy one here.
                  </PayLineSpan>
                </div>
                <PaymentMethodBox onClick={() => setPageView('payUsingGiftCard')}>
                  <GenericDisplayFlex>
                    <PaymentOptionImage src={require('src/assets/giftcard.png')} alt="giftCard" />
                    <PaymentOptionName>Gift Card</PaymentOptionName>
                  </GenericDisplayFlex>
                  <ArrowRightAltIcon sx={{ fontSize: 35, color: 'gray' }} />
                </PaymentMethodBox>
              </>
            )}
          </div>
        </PaymentsContainer>
      </GenericDisplayFlexColumn>
    </>
  )
}

export function PayUsingGiftCardComponent({
  setPageView,
  giftCode,
  setGiftCode,
  confirmBookingLoading,
  setGiftCodeError,
  payAndMakeBooking,
  giftCodeError,
  CustomGiftCodeInputContainer = GiftCodeInputContainer,
}) {
  return (
    <GenericDisplayFlexColumn>
      <PaymentsContainer>
        <SubheadingPaymentsPage
          style={{ marginBottom: '10px', cursor: 'pointer' }}
          onClick={() => {
            setPageView('confirm_slot_choose_payment')
          }}
        >
          {' '}
          <KeyboardBackspaceIcon sx={{ fontSize: 15, color: 'black', marginRight: '5px' }} />
          Go Back to Payment Methods
        </SubheadingPaymentsPage>

        <HeadingPaymentsPage>Pay using Gift Card</HeadingPaymentsPage>
        <SubheadingPaymentsPage>
          Enter your gift card details in the box below
        </SubheadingPaymentsPage>

        <CustomGiftCodeInputContainer>
          <GiftCardInput value={giftCode} onChange={(e) => setGiftCode(e.target.value)} />
          <GiftCodeSubmitButton
            src={require('src/assets/submit_button_purple.png')}
            alt="submitButton"
            onClick={() => {
              if (!confirmBookingLoading) {
                setGiftCodeError(false)
                payAndMakeBooking('giftup', giftCode)
              }
            }}
          />
        </CustomGiftCodeInputContainer>

        {confirmBookingLoading && <Spinner animation="border" variant="dark" size="sm" />}
        {giftCodeError && <ErrorMessage>Please enter a valid Gift Card Code</ErrorMessage>}
      </PaymentsContainer>
    </GenericDisplayFlexColumn>
  )
}

export function MentorDetailSection({
  professionalData,
  bookingEvent,
  pageView,
  planData,
  bundlesData,
  chosenPurchaseTypeId,
  chosenSlotData,
  eventStartDate,
  eventDuration,
  rateText = 'Rate',
  ContainerComponent = DetailsContainer,
  EventDetailsContainerStyledComponent = ContainerOne,
  isMountedOnAWebView,
  showMentorDetails = true,
  isNotInARow,
}) {
  const bookingEventDuration = bookingEvent?.durationInMins || eventDuration

  const { localizedData } = useContext(LocalizationContext)
  const eventBookingPageStrings = localizedData?.strings?.eventBookingPage

  const userCreditsDataFromStore = useSelector(selectUserCreditsData)

  // it should have total credits of this subscription plan, not just find one
  const noOfCreditsForThisEvent = userCreditsDataFromStore?.find(
    (item) => item?.pricingPackId === bookingEvent?.subscriptionPlanId,
  )?.numberOfCredits

  return (
    <ContainerComponent isNotInARow={isNotInARow}>
      {showMentorDetails && (
        <ContainerTwo onWebView={isMountedOnAWebView}>
          <ProfessionalPicture
            src={getImageLink(professionalData?.profilePicId)}
            alt="ProfilePic"
          />
          <div>
            <ProfessionalHeading main>{professionalData?.name}</ProfessionalHeading>
            <ProfessionalText main>
              {professionalData?.shortDesc || professionalData?.longDesc}
            </ProfessionalText>
          </div>
        </ContainerTwo>
      )}

      <EventDetailsContainerStyledComponent
        fullWidth={!showMentorDetails}
        noBorder={!showMentorDetails}
        style={{
          paddingLeft: !showMentorDetails ? '0px' : null,
        }}
      >
        {showMentorDetails && (
          <ProfessionalText>{eventBookingPageStrings?.service || 'Service'}</ProfessionalText>
        )}
        <ProfessionalHeading main={isNotInARow}>
          {bookingEvent?.title}{' '}
          {bookingEventDuration &&
            `(${bookingEventDuration} ${eventBookingPageStrings?.mins || 'mins'})`}
        </ProfessionalHeading>
        <ProfessionalText main style={{ marginTop: '0px' }}>
          {chosenPurchaseTypeId === 'single'
            ? bookingEvent?.description
            : `(Part of bundle - ${
                bundlesData?.find((item) => item?.id === chosenPurchaseTypeId)?.title
              })`}
        </ProfessionalText>
        {showMentorDetails && <ProfessionalText>{rateText}</ProfessionalText>}
        {showMentorDetails && (
          <>
            {!planData && bookingEvent?.free ? (
              <ProfessionalHeading>Free</ProfessionalHeading>
            ) : (
              <ProfessionalHeading>
                <span>
                  {currency_list?.find((item) => item?.code === planData?.currency)?.symbol}
                </span>
                <span
                  style={{
                    textDecoration: 'line-through',
                    marginRight: '10px',
                    fontWeight: '300',
                  }}
                >
                  {chosenPurchaseTypeId === 'single'
                    ? planData?.price
                    : bundlesData?.find((item) => item?.id === chosenPurchaseTypeId)?.price}
                </span>
                <span>
                  {currency_list?.find((item) => item?.code === planData?.currency)?.symbol}
                </span>
                <span>
                  {chosenPurchaseTypeId === 'single'
                    ? planData?.finalPrice
                    : bundlesData?.find((item) => item?.id === chosenPurchaseTypeId)?.finalPrice}
                </span>
              </ProfessionalHeading>
            )}
          </>
        )}
        {noOfCreditsForThisEvent && noOfCreditsForThisEvent > 0 && (
          <div style={{ fontSize: '12px' }}>
            (You have {noOfCreditsForThisEvent} credit vouchers for this session)
          </div>
        )}

        {/* <ProfessionalHeading>{(currency_list?.find(item => item?.code === bookingEvent?.defaultCurrency))?.symbol} {bookingEvent?.defaultPrice}</ProfessionalHeading> */}
        {chosenSlotData &&
          (pageView === 'confirm_slot_choose_payment' || pageView === 'payUsingGiftCard') && (
            <>
              <ProfessionalText>{eventBookingPageStrings?.date || 'Date'}</ProfessionalText>
              <ProfessionalHeading>
                {moment(chosenSlotData?.data?.startTime?.substring(0, 10))?.format('MMMM D, YYYY')}
              </ProfessionalHeading>
              <ProfessionalText>{eventBookingPageStrings?.time || 'Time'}</ProfessionalText>
              <ProfessionalHeading>{`${adjustPrebufferTimeAndConvertAMPM(
                chosenSlotData?.data?.startTime?.substring(11, 16),
                chosenSlotData?.preBufferMins,
              )} - ${adjustPostbufferTimeAndConvertAMPM(
                chosenSlotData?.data?.endTime?.substring(11, 16),
                chosenSlotData?.postBufferMins,
              )}`}</ProfessionalHeading>
            </>
          )}
        {eventStartDate}
      </EventDetailsContainerStyledComponent>
    </ContainerComponent>
  )
}

export function PaymentConfirmationComponent({
  bookingEventDetails,
  fromOnboarding,
  professionalData,
  chosenSlotData,
  customActionButtonComponent,
  hideHomeButton,
  bundleBuyingWaitingState,
  isWebview,
}) {
  const navigate = useNavigate()

  const ActionButtonComponent = useMemo(() => {
    return customActionButtonComponent ? (
      customActionButtonComponent
    ) : (
      <>
        {fromOnboarding ? (
          <ActionButtonsContainer>
            <ActionButton suggested onClick={() => navigate('/onboarding')}>
              Complete Onboarding
            </ActionButton>
          </ActionButtonsContainer>
        ) : (
          <ActionButtonsContainer>
            {Boolean(isWebview) ? (
              <ActionButton
                suggested
                onClick={() => {
                  console.log('calling handleNotifyMe method of IOS')
                  try {
                    if (
                      window.webkit &&
                      window.webkit.messageHandlers &&
                      window.webkit.messageHandlers.iosBridge
                    ) {
                      window.webkit.messageHandlers.iosBridge.postMessage({
                        method: 'handleNotifyMe',
                      })
                    }
                  } catch (error) {
                    console.error(
                      'Got error in calling iOS interface method handleNotifyMe:',
                      error,
                    )
                  }
                }}
              >
                Notify Me
              </ActionButton>
            ) : (
              <ActionButton suggested onClick={() => navigate('/my-live-sessions')}>
                View your Bookings
              </ActionButton>
            )}
            {!Boolean(hideHomeButton) && (
              <ActionButton onClick={() => navigate('/')}>Home</ActionButton>
            )}
          </ActionButtonsContainer>
        )}
      </>
    )
  }, [customActionButtonComponent])

  const [sequenceStepOneWaiting, setSequenceStepOneWaiting] = useState(true)
  const [sequenceStepTwoWaiting, setSequenceStepTwoWaiting] = useState(true)
  const [sequenceStepFinalWaiting, setSequenceStepFinalWaiting] = useState(true)

  async function changeSequenceStatus() {
    await sleep(2000)
    setSequenceStepOneWaiting(false)
    await sleep(2000)
    setSequenceStepTwoWaiting(false)
    await sleep(2000)
    setSequenceStepFinalWaiting(false)
  }

  useEffect(() => {
    changeSequenceStatus()
  }, [])

  return (
    <ConfirmationContainer>
      {bundleBuyingWaitingState ? (
        bundleBuyingWaitingState?.bundleBuy === 'success' && (
          <>
            <TickLottie animationData={confirmationTickLottie} loop={false} autoPlay={true} />
            <HeadingConfirmationPage>Bundle Purchase Successful!</HeadingConfirmationPage>
            <SubheadingConfirmationPage>
              Your credit vouchers have been transferred to your account.
            </SubheadingConfirmationPage>
            <SubheadingConfirmationPage style={{ marginBottom: '50px' }}>
              These can be redeemed from your profile in the app.
            </SubheadingConfirmationPage>
          </>
        )
      ) : (
        <>
          <TickLottie animationData={confirmationTickLottie} loop={false} autoPlay={true} />
          <HeadingConfirmationPage>Booking Confirmed!</HeadingConfirmationPage>

          {bookingEventDetails?.free ? (
            <SubheadingConfirmationPage>Your session is confirmed.</SubheadingConfirmationPage>
          ) : (
            <SubheadingConfirmationPage>
              Your payment is completed and your session is confirmed.
            </SubheadingConfirmationPage>
          )}

          {fromOnboarding && (
            <SubheadingConfirmationPage>
              You will find details of your booking inside the app after you complete your
              onboarding.
            </SubheadingConfirmationPage>
          )}
        </>
      )}

      {bundleBuyingWaitingState ? (
        <>
          {(bundleBuyingWaitingState?.eventBooking === 'waiting' ||
            bundleBuyingWaitingState?.eventBooking === 'success') && (
            <div>
              <div style={{ display: 'flex', gap: '5px', alignItems: 'center', marginTop: '10px' }}>
                {sequenceStepOneWaiting ? (
                  <Spinner animation="border" variant="dark" size="sm" />
                ) : (
                  <CheckCircleIcon style={{ color: 'green' }} />
                )}
                <div>Transfering credit vouchers to your wallet</div>
              </div>
              <div style={{ display: 'flex', gap: '5px', alignItems: 'center', marginTop: '10px' }}>
                {sequenceStepTwoWaiting ? (
                  <Spinner animation="border" variant="dark" size="sm" />
                ) : (
                  <CheckCircleIcon style={{ color: 'green' }} />
                )}
                <div>Adding bundle items</div>
              </div>
              <div style={{ display: 'flex', gap: '5px', alignItems: 'center', marginTop: '10px' }}>
                {!sequenceStepFinalWaiting &&
                bundleBuyingWaitingState?.eventBooking === 'success' ? (
                  <CheckCircleIcon style={{ color: 'green' }} />
                ) : (
                  <Spinner animation="border" variant="dark" size="sm" />
                )}
                <div>
                  Booking your session with {professionalData?.name} for{' '}
                  {chosenSlotData &&
                    moment(chosenSlotData?.data?.startTime)?.format('DD MMMM, YYYY')}{' '}
                  {`${adjustPrebufferTimeAndConvertAMPM(
                    chosenSlotData?.data?.startTime?.substring(11, 16),
                    chosenSlotData?.preBufferMins,
                  )} onwards`}
                </div>
              </div>
            </div>
          )}
          {bundleBuyingWaitingState?.eventBooking === 'failed' && (
            <>
              <div style={{ fontSize: '20px', color: '#c3352a', fontWeight: '500' }}>
                Booking Failed
              </div>
              <SubheadingPaymentsPage style={{ marginTop: '50px' }}>
                Your session could not be booked.{' '}
                <span
                  style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => window.location.reload()}
                >
                  Click here
                </span>{' '}
                to try again. You can use your credit vouchers.
              </SubheadingPaymentsPage>
            </>
          )}
        </>
      ) : (
        <>
          <SubheadingPaymentsPage style={{ marginTop: '50px' }}>
            Session Details
          </SubheadingPaymentsPage>
          <HeadingPaymentsPage style={{ marginTop: '20px' }}>
            {bookingEventDetails?.title}
          </HeadingPaymentsPage>
          <HeadingPaymentsPage>with {professionalData?.name}</HeadingPaymentsPage>
          <HeadingPaymentsPage
            style={{
              marginTop: '5px',
              display: 'flex',
              alignItems: 'center',
              fontWeight: '400',
            }}
          >
            {chosenSlotData && (
              <CalendarMonth sx={{ fontSize: 20, color: 'purple', marginRight: '5px' }} />
            )}
            {chosenSlotData && moment(chosenSlotData?.data?.startTime)?.format('DD MMMM, YYYY')}
          </HeadingPaymentsPage>
          {chosenSlotData && (
            <HeadingPaymentsPage
              style={{
                marginTop: '5px',
                display: 'flex',
                alignItems: 'center',
                fontWeight: '400',
              }}
            >
              <WatchLaterIcon sx={{ fontSize: 20, color: 'purple', marginRight: '5px' }} />
              {`${adjustPrebufferTimeAndConvertAMPM(
                chosenSlotData?.data?.startTime?.substring(11, 16),
                chosenSlotData?.preBufferMins,
              )} onwards`}
            </HeadingPaymentsPage>
          )}
        </>
      )}

      {bundleBuyingWaitingState
        ? bundleBuyingWaitingState?.bundleBuy === 'success' &&
          bundleBuyingWaitingState?.eventBooking === 'success' &&
          ActionButtonComponent
        : ActionButtonComponent}
    </ConfirmationContainer>
  )
}

export const GenericEventBookingComponent = ({
  eventId,
  scheduleForThisEvent,
  professionalData,
  getSelectionDetailFunction,
}) => {
  const [calendarLoading, setCalendarLoading] = useState(false)
  const [selectedDateValue, setSelectedDateValue] = useState()
  const [selectedTimeZone, setSelectedTimeZone] = useState(encodeURIComponent(moment().format('Z')))
  const [selectedTimeSlotIndex, setSelectedTimeSlotIndex] = useState()
  const [lastActiveStartDate, setLastActiveStartDate] = useState(moment()?.format('YYYY-MM-DD'))
  const [availableTimeSlots, setAvailableTimeSlots] = useState()
  const mentorId = professionalData?.id
  const eventDetailOfRequestedEvent = useMemo(() => {
    return professionalData?.eventTypes?.find((event) => event?.uid === eventId) // getting event details of requested event
  }, [professionalData, eventId]) // setting event details of requested event

  const { localizedData } = useContext(LocalizationContext)
  const eventBookingPageStrings = localizedData?.strings?.eventBookingPage

  useEffect(() => {
    ;(async () => {
      setCalendarLoading(true)
      const response = await createAvailableTimeSlotsArray(
        lastActiveStartDate,
        selectedTimeZone,
        scheduleForThisEvent?.timeZone,
        scheduleForThisEvent,
        eventDetailOfRequestedEvent,
        mentorId,
        eventId,
        selectedTimeZone,
        false,
      )
      setCalendarLoading(false)
      setAvailableTimeSlots(response)
    })()
  }, [selectedTimeZone]) // updating calendar data everytime timezone changes or professionaldata changes

  const handleMonthChange = async (action, activeStartDate, value, view) => {
    if (
      (action === 'prev' && view === 'month') ||
      (action === 'prev2' && view === 'month') ||
      (action === 'next' && view === 'month') ||
      (action === 'next2' && view === 'month') ||
      (action === 'drillDown' && view === 'month')
    ) {
      setLastActiveStartDate(moment(activeStartDate)?.format('YYYY-MM-DD'))
      const activeStartDateBasicFormat = moment(activeStartDate)?.format('YYYY-MM-DD')
      setCalendarLoading(true)
      const response = await createAvailableTimeSlotsArray(
        activeStartDateBasicFormat,
        selectedTimeZone,
        scheduleForThisEvent?.timeZone,
        scheduleForThisEvent,
        eventDetailOfRequestedEvent,
        mentorId,
        eventId,
        selectedTimeZone,
        true,
      )
      setAvailableTimeSlots(response)
      setCalendarLoading(false)
    }
    setSelectedDateValue(null)
  } // function to handle when month is changed, getting all available time slots

  function onChangeDate(nextValue) {
    setSelectedTimeSlotIndex()
    setSelectedDateValue(nextValue)
    if (window.innerWidth < 660) {
      const timeSlotChoosingContainer = document.getElementById('timeSlotChoosingContainer')
      if (timeSlotChoosingContainer) {
        timeSlotChoosingContainer.scrollIntoView({
          behavior: 'smooth', // Use smooth scrolling behavior
        })
      }
    }
  } // change date function for calendar

  const dateToday = new Date()
  const maxDate = new Date(dateToday)
  maxDate?.setMonth(dateToday?.getMonth() + 2) // setting max date of calendar

  function tileDisabled({ date, view }) {
    // Disable tiles in month view only
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of disabled dates
      // return disabledDates.find(dDate => isSameDay(dDate, date));

      // only checking if professional available, not checking exempt dates

      const elementFoundTimeSlot = availableTimeSlots?.find(
        (item) => item?.date === moment(date)?.format('YYYY-MM-DD'),
      )
      if (
        elementFoundTimeSlot?.availableTimeSlots &&
        elementFoundTimeSlot?.availableTimeSlots?.length > 0
      ) {
        return false
      }

      return true
    }
  } // disabling unavailable days

  function adjustPrebufferTimeAndConvertAMPM(timeString, startBufferMins) {
    return moment(timeString, 'HH:mm').add(startBufferMins, 'minutes').format('h:mm a')
  }

  return (
    <EventBookingInteractionPage>
      <GenericDisplayFlexColumn>
        <SelectDateHeading>
          {eventBookingPageStrings?.select_date_and_time_in_calendar || 'Select a Date & Time '}
          {calendarLoading && (
            <Spinner animation="border" variant="dark" size="sm" style={{ margin: '0 auto' }} />
          )}
        </SelectDateHeading>

        <CalendarPartContainer noPadding={true}>
          <CalendarContainer>
            <Calendar
              onChange={onChangeDate}
              value={selectedDateValue}
              minDate={dateToday}
              maxDate={maxDate}
              minDetail="decade"
              tileDisabled={tileDisabled}
              showNeighboringMonth={false}
              onActiveStartDateChange={({ action, activeStartDate, value, view }) =>
                handleMonthChange(action, activeStartDate, value, view)
              }
            />
            <StyledSelectTimeZone
              value={selectedTimeZone}
              onChange={(e) => setSelectedTimeZone(e.target.value)}
            >
              {getTimeZoneOptionsWithValueAsTimezoneOffset()}
            </StyledSelectTimeZone>
          </CalendarContainer>
          <TimeChoosingContainer
            selectedDateValue={selectedDateValue}
            id="timeSlotChoosingContainer"
          >
            <TimeChoosingContainerHeading>
              {moment(selectedDateValue)?.format('ddd, MMM D')}
            </TimeChoosingContainerHeading>
            <AnimatedContainerTimeSlots key={selectedDateValue}>
              {availableTimeSlots
                ?.find((item) => item?.date === moment(selectedDateValue)?.format('YYYY-MM-DD'))
                ?.availableTimeSlots?.map((timeSlotItem, index) => {
                  return (
                    <GenericDisplayFlex style={{ gap: '5px' }} key={index}>
                      <TimeButton
                        onClick={() => {
                          setSelectedTimeSlotIndex(index)
                          getSelectionDetailFunction({
                            timeSlotItem: timeSlotItem,
                            preBufferMins: eventDetailOfRequestedEvent?.preBufferMins,
                            postBufferMins: eventDetailOfRequestedEvent?.postBufferMins,
                          })
                        }}
                      >
                        {adjustPrebufferTimeAndConvertAMPM(
                          timeSlotItem?.startTime?.substring(11, 16),
                          eventDetailOfRequestedEvent?.preBufferMins,
                        )}
                      </TimeButton>
                      {selectedTimeSlotIndex === index && (
                        <TimeButton
                          confirm
                          onClick={() =>
                            getSelectionDetailFunction({
                              timeSlotItem: timeSlotItem,
                              preBufferMins: eventDetailOfRequestedEvent?.preBufferMins,
                              postBufferMins: eventDetailOfRequestedEvent?.postBufferMins,
                            })
                          }
                        >
                          Selected
                        </TimeButton>
                      )}
                    </GenericDisplayFlex>
                  )
                })}
            </AnimatedContainerTimeSlots>
          </TimeChoosingContainer>
        </CalendarPartContainer>
      </GenericDisplayFlexColumn>
    </EventBookingInteractionPage>
  )
}

export const EBBundleItem = ({
  title,
  currencySymbol,
  price,
  discountedPrice,
  themeColor,
  bundle,
  clickFunction,
}) => {
  return (
    <EBBundleItemParent>
      <EBBundleItemTitleContainer themeColor={themeColor}>{title}</EBBundleItemTitleContainer>
      <div style={{ flexGrow: '1', padding: '20px' }}>
        {Array.isArray(bundle) &&
          bundle?.length > 0 &&
          bundle?.map((bundleItem, bundleItemIndex) => {
            return (
              <div key={bundleItemIndex} style={{ fontSize: '12px' }}>
                •{'  '}
                {bundleItem?.maxCount && `${bundleItem?.maxCount} `}
                {bundleItem?.title}
              </div>
            )
          })}
      </div>
      <div
        style={{
          margin: '0px auto',
          background: '#c6c6c6',
          width: '80%',
          height: '1px',
        }}
      ></div>
      <div style={{ fontWeight: '500', fontSize: '20px', padding: '8px' }}>
        {price > discountedPrice && (
          <span style={{ textDecoration: 'line-through', marginRight: '2px', fontSize: '12px' }}>
            <span style={{ marginRight: '2px' }}>
              {currency_list?.find((item) => item?.code === currencySymbol)?.symbol}
            </span>
            {price}
          </span>
        )}
        <span style={{ marginRight: '2px' }}>
          {currency_list?.find((item) => item?.code === currencySymbol)?.symbol}
        </span>
        <span>{discountedPrice}</span>
      </div>
      <EBBundleItemConfirmButton onClick={clickFunction} themeColor={themeColor}>
        <b>BUY</b>
      </EBBundleItemConfirmButton>
    </EBBundleItemParent>
  )
}

export function getScheduleForThisEvent(
  professionalData,
  eventId,
  eventDetailOfRequestedEvent,
  selectedTimeZone,
) {
  const defaultSchedule = professionalData?.schedules?.find((schedule) => schedule?.defaultSchedule) // getting default schedule of professional
  const scheduleWithRequestedEventId = professionalData?.schedules?.find((schedule) =>
    schedule?.eventIds?.includes(eventId),
  ) // getting schedule that has the requested event id

  let toReturn

  if (
    professionalData?.schedules &&
    professionalData?.schedules?.length !== 0 &&
    eventDetailOfRequestedEvent?.enabled
  ) {
    if (Boolean(defaultSchedule) && defaultSchedule?.eventIds?.includes(eventId)) {
      // first if condition checks if default schedule has that event :
      toReturn = defaultSchedule
    } else if (
      Boolean(scheduleWithRequestedEventId) &&
      scheduleWithRequestedEventId?.eventIds?.includes(eventId)
    ) {
      // second else if condition checks if any schedule has that event and returns that :
      toReturn = scheduleWithRequestedEventId
    } else {
      // else condition handles if no schedule has that event
      toReturn = {
        ...BLANK_MENTOR_SCHEDULE,
        timeZone: selectedTimeZone,
      }
    }
  } else {
    // else : if there is no schedules array or event is not enabled, return blank schedule so grayed out dates appear
    toReturn = {
      ...BLANK_MENTOR_SCHEDULE,
      timeZone: selectedTimeZone,
    }
  }
  return toReturn
}

const colorsArray = [
  '#ebf8ff',
  '#ebffed',
  '#fff1eb',
  '#feffc4',
  '#ebf8ff',
  '#ebffed',
  '#fff1eb',
  '#feffc4',
]

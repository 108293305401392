import React from 'react'
import { PMPBodySectionItems } from './styledComponent'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'

export default function PrintableSuggestedTherapies({
  suggestedTherapiesRef,
  debug,
  selectedTherapies,
  debugHighlightStates
}) {
  return (
    <PMPBodySectionItems
      ref={suggestedTherapiesRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.suggestedTherapy.color}
      debugHighlight={debugHighlightStates?.suggestedTherapies}
    >
      <strong>Suggested Therapies</strong>
      <div>{selectedTherapies?.map((item) => item?.label)?.join(', ')}</div>
    </PMPBodySectionItems>
  )
}

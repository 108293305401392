import React from 'react'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { PMPBodySectionItems } from './styledComponent'
import { PmpPrintableVitals } from '../../../PrescriptionMakingPageComponents'

export default function PrintableVitals({
  containerRef,
  debug,
  printType,
  vital,
  debugHighlightStates,
}) {
  return (
    <PMPBodySectionItems
      ref={containerRef}
      noPadding
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.vitals?.color}
      debugHighlight={debugHighlightStates?.vitals}
    >
      <PmpPrintableVitals vital={vital} debug={debug} />
    </PMPBodySectionItems>
  )
}

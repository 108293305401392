import { HE_KEY_DRUGS, HE_KEY_PSYCH_ASSESSMENTS, IDB_STORES } from 'src/consumer/constants'
import { createERMDatabase } from './indexedDb'

async function getDB() {
  const db = await createERMDatabase()
  return db
}

export function getFunctionForAddOrUpdateHealthEntitySingle(healthEntity, data) {
  return getFunctionForAddOrUpdateHealthEntityMulitple(healthEntity, [data])
}

export function getFunctionForAddOrUpdateHealthEntityMulitple(healthEntity, data) {
  switch (healthEntity) {
    case 'complaints':
      return addOrUpdateHealthComplaintsInIdb(data)
    case 'diagnosis':
      return addOrUpdateDiagnosisInIdb(data)
    case 'observations':
      return addOrUpdateObservationsInIdb(data)
    case 'advices':
      return addOrUpdateAdvicesInIdb(data)
    case 'instructions':
      return addOrUpdateInstructionsInIdb(data)
    case 'investigation':
      return addOrUpdateInvestigationInIdb(data)
    case HE_KEY_PSYCH_ASSESSMENTS:
      return addOrUpdatePsychAssessmentsInIdb(data)
    default:
      // return addOrUpdateHealthEntitiesInIdbBasedOnStoreName(data, healthEntity)
      return null
  }
}

// IDB_STORES.HEALTH_COMPLAINTS.name:

export async function addOrUpdateHealthComplaintsInIdb(complaints) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.HEALTH_COMPLAINTS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.HEALTH_COMPLAINTS.name)

  for (const complaint of complaints) {
    if (complaint?.title) {
      await store.put({ ...complaint, lowerCaseIndexField: complaint?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllHealthComplaintsFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.HEALTH_COMPLAINTS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.HEALTH_COMPLAINTS.name)
  const allComplaints = await store.getAll()
  await tx.done
  return allComplaints
}

export async function searchHealthComplaintsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllHealthComplaintsFromIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.HEALTH_COMPLAINTS.name, 'titleIDX', true)
}

export async function getHealthComplaintsByIds(healthComplaintIds) {
  if (!Array.isArray(healthComplaintIds) || healthComplaintIds.length === 0) {
    return []
  }

  return await getItemsByIds(healthComplaintIds, IDB_STORES.HEALTH_COMPLAINTS.name)
}

// IDB_STORES.DIAGNOSIS.name:

export async function addOrUpdateDiagnosisInIdb(diagnosisList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.DIAGNOSIS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.DIAGNOSIS.name)

  for (const diagnosis of diagnosisList) {
    if (diagnosis?.title) {
      await store.put({ ...diagnosis, lowerCaseIndexField: diagnosis?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllDiagnosisFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.DIAGNOSIS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.DIAGNOSIS.name)
  const allDiagnosis = await store.getAll()
  await tx.done
  return allDiagnosis
}

export async function searchDiagnosisInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllDiagnosisFromIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.DIAGNOSIS.name, 'titleIDX', true)
}

export async function getDiagnosisByIds(diagnosisIds) {
  if (!Array.isArray(diagnosisIds) || diagnosisIds.length === 0) {
    return []
  }

  return await getItemsByIds(diagnosisIds, IDB_STORES.DIAGNOSIS.name)
}

// IDB_STORES.OBSERVATIONS.name:

export async function addOrUpdateObservationsInIdb(observationsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.OBSERVATIONS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.OBSERVATIONS.name)

  for (const observation of observationsList) {
    if (observation?.title) {
      await store.put({ ...observation, lowerCaseIndexField: observation?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllObservationsInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.OBSERVATIONS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.OBSERVATIONS.name)
  const allInstructions = await store.getAll()
  await tx.done
  return allInstructions
}

export async function searchObservationsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllObservationsInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.OBSERVATIONS.name, 'titleIDX', true)
}

export async function getObservationsByIds(observationsIds) {
  if (!Array.isArray(observationsIds) || observationsIds.length === 0) {
    return []
  }

  return await getItemsByIds(observationsIds, IDB_STORES.OBSERVATIONS.name)
}

// IDB_STORES.ADVICE.name:

export async function addOrUpdateAdvicesInIdb(advicesList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.ADVICE.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.ADVICE.name)

  for (const advice of advicesList) {
    if (advice?.title) {
      await store.put({ ...advice, lowerCaseIndexField: advice?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllAdvicesInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.ADVICE.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.ADVICE.name)
  const allInstructions = await store.getAll()
  await tx.done
  return allInstructions
}

export async function searchAdvicesInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllAdvicesInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.ADVICE.name, 'titleIDX', true)
}

export async function getAdvicesByIds(advicesByIds) {
  if (!Array.isArray(advicesByIds) || advicesByIds.length === 0) {
    return []
  }

  return await getItemsByIds(advicesByIds, IDB_STORES.ADVICE.name)
}

// IDB_STORES.INSTRUCTIONS.name:

export async function addOrUpdateInstructionsInIdb(instructionsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.INSTRUCTIONS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.INSTRUCTIONS.name)

  for (const instruction of instructionsList) {
    if (instruction?.title) {
      await store.put({ ...instruction, lowerCaseIndexField: instruction?.title?.toLowerCase() }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllInstructionsInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.INSTRUCTIONS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.INSTRUCTIONS.name)
  const allInstructions = await store.getAll()
  await tx.done
  return allInstructions
}

export async function searchInstructionsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllInstructionsInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.INSTRUCTIONS.name, 'titleIDX', true)
}

export async function getInstructionsByIds(instructionsByIds) {
  if (!Array.isArray(instructionsByIds) || instructionsByIds.length === 0) {
    return []
  }

  return await getItemsByIds(instructionsByIds, IDB_STORES.INSTRUCTIONS.name)
}

// IDB_STORES.INVESTIGATION.name:

export async function addOrUpdateInvestigationInIdb(investigationList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.INVESTIGATION.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.INVESTIGATION.name)

  for (const investigation of investigationList) {
    if (investigation?.title) {
      await store.put({
        ...investigation,
        lowerCaseIndexField: investigation?.title?.toLowerCase(),
      }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllInvestigationInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.INVESTIGATION.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.INVESTIGATION.name)
  const allInvestigation = await store.getAll()
  await tx.done
  return allInvestigation
}

export async function searchInvestigationInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllInvestigationInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.INVESTIGATION.name, 'titleIDX', true)
}

export async function getInvestigationByIds(investigationByIds) {
  if (!Array.isArray(investigationByIds) || investigationByIds.length === 0) {
    return []
  }

  return await getItemsByIds(investigationByIds, IDB_STORES.INVESTIGATION.name)
}

export async function findAllPanelsWithGivenTestId(testId) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.INVESTIGATION.name, 'readonly')
  const store = tx.store

  const allItems = []
  let cursor = await store.openCursor()

  while (cursor) {
    if (cursor?.value?.investigationType === 'panel' && cursor.value?.testIds?.includes(testId)) {
      allItems.push(cursor.value)
    }
    cursor = await cursor.continue()
  }
  return allItems
}

// IDB_STORES.PSYCH_ASSESSMENTS.name:

export async function addOrUpdatePsychAssessmentsInIdb(psychAssessmentsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.PSYCH_ASSESSMENTS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.PSYCH_ASSESSMENTS.name)

  for (const psychAssessment of psychAssessmentsList) {
    if (psychAssessment?.title) {
      await store.put({
        ...psychAssessment,
        lowerCaseIndexField: psychAssessment?.title?.toLowerCase(),
      }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllPsychAssessmentsInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.PSYCH_ASSESSMENTS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.PSYCH_ASSESSMENTS.name)
  const allPsychAssessments = await store.getAll()
  await tx.done
  return allPsychAssessments
}

export async function searchPsychAssessmentsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllPsychAssessmentsInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.PSYCH_ASSESSMENTS.name, 'titleIDX', true)
}

export async function getPsychAssessmentsByIds(psychAssessmentsByIds) {
  if (!Array.isArray(psychAssessmentsByIds) || psychAssessmentsByIds.length === 0) {
    return []
  }

  return await getItemsByIds(psychAssessmentsByIds, IDB_STORES.PSYCH_ASSESSMENTS.name)
}

// IDB_STORES.GENERIC_DRUGS.name:

export async function addOrUpdateGenericDrugsInIdb(genericDrugsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.GENERIC_DRUGS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.GENERIC_DRUGS.name)

  for (const genericDrug of genericDrugsList) {
    if (genericDrug?.title) {
      await store.put({
        ...genericDrug,
        lowerCaseIndexField: genericDrug?.title?.toLowerCase(),
      }) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllGenericDrugsInIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.GENERIC_DRUGS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.GENERIC_DRUGS.name)
  const allGenericDrugs = await store.getAll()
  await tx.done
  return allGenericDrugs
}

export async function searchGenericDrugsInIdb(searchTerm) {
  if (!searchTerm) {
    return await getAllGenericDrugsInIdb()
  }
  return await searchItemsInIdb(searchTerm, IDB_STORES.GENERIC_DRUGS.name, 'titleIDX', true)
}

export async function findGenericDrugInIdb(searchGenericNameId) {
  if (searchGenericNameId == null || searchGenericNameId === '') {
    return null
  }

  const searchKey = Number(searchGenericNameId)
  if (Number.isNaN(searchKey)) {
    return null
  }

  const db = await getDB()
  const tx = db.transaction(IDB_STORES.GENERIC_DRUGS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.GENERIC_DRUGS.name)
  const index = store.index('medKeyIDX')

  const result = await index.get(searchKey)

  await tx.done
  return result || null
}

export async function getGenericDrugsByIds(genericDrugsByIds) {
  if (!Array.isArray(genericDrugsByIds) || genericDrugsByIds.length === 0) {
    return []
  }

  return await getItemsByIds(genericDrugsByIds, IDB_STORES.GENERIC_DRUGS.name)
}

// IDB_STORES.DRUGS.name:

export async function addOrUpdateDrugsInIdb(drugsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.DRUGS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.DRUGS.name)

  for (const drug of drugsList) {
    if (drug?.name) {
      const record = {
        ...drug,
        lowerCaseIndexField: drug.name.toLowerCase(),
      }
      await store.put(record) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllDrugsFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.DRUGS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.DRUGS.name)
  const allDiagnosis = await store.getAll()
  await tx.done
  return allDiagnosis
}

export async function searchDrugsInIdb(searchTerm, currentMentorId) {
  if (!searchTerm) {
    return await getAllDrugsFromIdb()
  }
  return await searchItemsInIdb(
    searchTerm,
    IDB_STORES.DRUGS.name,
    'titleIDX',
    true,
    currentMentorId,
  )
}

export async function findDrugsByRelatedName(relatedName) {
  if (!relatedName || relatedName.trim() === '') {
    return []
  }

  const db = await getDB()
  const tx = db.transaction(IDB_STORES.DRUGS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.DRUGS.name)

  const matchedDrugs = []

  // Use a cursor to iterate through all drugs
  let cursor = await store.openCursor()

  while (cursor) {
    // Check if the relatedName matches the input
    if (cursor.value?.relatedName === relatedName) {
      matchedDrugs.push(cursor.value)
    }
    cursor = await cursor.continue() // Move to the next record
  }

  await tx.done
  return matchedDrugs
}

export async function getDrugsByIDs(drugIds) {
  if (!Array.isArray(drugIds) || drugIds.length === 0) {
    return []
  }

  return await getItemsByIds(drugIds, IDB_STORES.DRUGS.name)
}

//PATIENTS:

export async function addOrUpdatePatientsInIdb(patientsList) {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.PATIENTS.name, 'readwrite')
  const store = tx.objectStore(IDB_STORES.PATIENTS.name)

  for (const patient of patientsList) {
    if (patient?.name) {
      const record = {
        ...patient,
        ...(patient?.name.toLowerCase() && { nameIDX: patient?.name.toLowerCase() }),
        ...(patient?.mobile && { mobileIDX: patient?.mobile }),
        ...(patient?.email && { emailIDX: patient?.email }),
        ...(patient?.patientNumber && { patientNumberIDX: patient?.patientNumber }),
      }
      await store.put(record) // `put` will add or update the record
    }
  }

  await tx.done
}

export async function getAllPatientsFromIdb() {
  const db = await getDB()
  const tx = db.transaction(IDB_STORES.PATIENTS.name, 'readonly')
  const store = tx.objectStore(IDB_STORES.PATIENTS.name)
  const allPatients = await store.getAll()
  await tx.done
  return allPatients
}

export async function searchPatientsInIdb(searchTerm) {
  if (!searchTerm) {
    const items = await getAllPatientsFromIdb()
    return (items || []).slice(0, 20)
  }
  // depends on search
  const nameResults = await searchItemsInIdb(searchTerm, IDB_STORES.PATIENTS.name, 'nameIDX', false)
  const mobileResults = await searchItemsInIdb(
    searchTerm,
    IDB_STORES.PATIENTS.name,
    'mobileIDX',
    false,
  )
  const emailResults = await searchItemsInIdb(
    searchTerm,
    IDB_STORES.PATIENTS.name,
    'emailIDX',
    false,
  )
  const patientNumberResults = await searchItemsInIdb(
    searchTerm,
    IDB_STORES.PATIENTS.name,
    'patientNumberIDX',
    false,
  )

  const allResults = [...nameResults, ...mobileResults, ...emailResults, ...patientNumberResults]

  const uniqueResults = Array.from(new Set(allResults.map((patient) => patient.id))).map((id) =>
    allResults.find((patient) => patient.id === id),
  )

  return uniqueResults
}

export async function getPatientsByIds(patientIds) {
  if (!Array.isArray(patientIds) || patientIds.length === 0) {
    return []
  }

  return await getItemsByIds(patientIds, IDB_STORES.PATIENTS.name)
}

//IDB_DRUGS_DOSAGE_STORENAME:

// export async function addOrUpdateHealthEntitiesInIdbBasedOnStoreName(heList, storeName) {
//   const db = await getDB()
//   const tx = db.transaction(storeName, 'readwrite')
//   const store = tx.objectStore(storeName)

//   for (const healthEntity of heList) {
//     if (healthEntity?.title) {
//       await store.put({ ...healthEntity, lowerCaseIndexField: healthEntity?.title?.toLowerCase() }) // `put` will add or update the record
//     }
//   }

//   await tx.done
// }

// export async function getAllHealthEntityItemsBasedOnStoreNameFromIdb(storeName) {
//   const db = await getDB()
//   const tx = db.transaction(storeName, 'readonly')
//   const store = tx.objectStore(storeName)
//   const allDosage = await store.getAll()
//   await tx.done
//   return allDosage
// }

// export async function searchAllHealthEntityItemsBasedOnStoreNameInIdb(searchTerm, storeName) {
//   if (!searchTerm) {
//     return await getAllHealthEntityItemsBasedOnStoreNameFromIdb(storeName)
//   }
//   return await searchItemsInIdb(searchTerm, storeName, 'titleIDX', true)
// }

// export async function getHealthEntityItemsByIdsBasedOnStoreName(heIds, storeName) {
//   if (!Array.isArray(heIds) || heIds.length === 0) {
//     return []
//   }

//   return await getItemsByIds(heIds, storeName)
// }

//
//
//

export async function searchItemsInIdb(
  searchTerm,
  storeName,
  indexName,
  onlyStartsWithSearch = true,
  currentMentorId,
) {
  if (!searchTerm || searchTerm.trim() === '') {
    return []
  }
  const db = await getDB()
  const tx = db.transaction(storeName, 'readonly')

  const index = tx.store.index(indexName)
  const searchLowercase = searchTerm.toLowerCase()
  let cursor
  let searchResults = []

  if (onlyStartsWithSearch) {
    const range = IDBKeyRange.bound(searchLowercase, searchLowercase + '\uffff')
    cursor = await index.openCursor(range)
  } else {
    cursor = await index.openCursor()
  }

  while (cursor) {
    const value = cursor.value

    if (onlyStartsWithSearch) {
      searchResults.push(cursor.value)
    } else {
      const indexedValue = value?.[indexName]
      if (indexedValue && String(indexedValue).toLowerCase().includes(searchLowercase)) {
        searchResults.push(value)
      }
    }
    cursor = await cursor.continue()
  }

  if (storeName === HE_KEY_DRUGS) {
    searchResults = searchResults.sort((a, b) => {
      // Primary Sort: Entries that have a mentorId come first
      if (a.doctorId !== currentMentorId && !b.doctorId !== currentMentorId) return -1
      if (!a.doctorId !== currentMentorId && b.doctorId !== currentMentorId) return 1

      // Secondary Sort: Entries with a genericNameId come first
      if (a.genericNameId && !b.genericNameId) return -1
      if (!a.genericNameId && b.genericNameId) return 1

      // Tertiary Sort: Alphabetically by title (A-Z)
      return a.title.localeCompare(b.title)
    })
  }

  return searchResults
}

export async function searchItemsInIdbById(id, storeName) {
  if (!id) {
    return null
  }

  const db = await getDB()
  const tx = db.transaction(storeName, 'readonly')
  const store = tx.store

  // Get the item directly by the primary key (id)
  const result = await store.get(id)

  return result ? result : null
}

export async function getItemsByIds(ids, storeName) {
  if (!Array.isArray(ids) || ids.length === 0) {
    return []
  }

  // Use `Promise.all` to fetch all items by their IDs in parallel
  const results = await Promise.all(ids.map((id) => searchItemsInIdbById(id, storeName)))

  // Filter out null values for items that weren't found
  return results.filter((item) => item !== null)
}

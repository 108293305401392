import { PMPV2FooterContainer } from '../../PrescriptionMakingPageStyles'
import { PrescriptionFooter } from '../../PrescriptionMakingPageComponents'

export default function PrescriptionPrintableFooter({
  debugMode,
  prescriptionData,
  prescriptionParentDivDimensions,
  printType,
  footerHeight,
  leaveSpace,
  styleOverrides
}) {
  return (
    <PMPV2FooterContainer
      debug={debugMode}
      style={{ height: `${footerHeight}px`, borderTop: leaveSpace ? '1px solid #000' : 'unset' }}
      prescriptionWidth={prescriptionParentDivDimensions?.width}
    >
      <PrescriptionFooter
        prescriptionData={prescriptionData}
        leaveSpace={leaveSpace}
        debug={debugMode}
        prescriptionWidth={prescriptionParentDivDimensions?.width}
        styleOverrides={styleOverrides}
      />
    </PMPV2FooterContainer>
  )
}

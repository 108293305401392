import React from 'react'
import { PmpPatientDetails } from '../../../PrescriptionMakingPageComponents'
import { PRESCRIPTION_PRINT_TYPES } from 'src/consumer/constants'
import { PMPBodySectionItems } from './styledComponent'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'

export default function PatientDetails({
  containerRef,
  debug,
  printType,
  prescriptionData,
  debugHighlightStates,
  prescriptionViewSettings,
}) {
  return (
    <PMPBodySectionItems
      ref={containerRef}
      noPadding
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.patientDetails?.color}
      debugHighlight={debugHighlightStates?.patientDetails}
    >
      <PmpPatientDetails
        prescriptionData={prescriptionData}
        leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
        prescriptionViewSettings={prescriptionViewSettings}
        debug={debug}
      />
    </PMPBodySectionItems>
  )
}

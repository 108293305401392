import { mediaURL } from 'src/consumer/services'
import { calculateNextVisitDate } from '../../PrescriptionMakingPageComponents'
import { isValidNumber } from 'src/consumer/helpers/utilFunctions'
import { formatDuration } from 'src/mentor/helpers/utilityFunctions'

export function DefaultNextVisitRowComponent({
  nextVisitInputValue,
  nextVisitInputType,
  prescriptionData,
  showPrescriptionQRCode = false,
  children,
  styleOverrides,
}) {
  const nextVisitDateString = calculateNextVisitDate(
    nextVisitInputValue,
    nextVisitInputType,
    'DD/MM/YYYY, dddd',
  )

  const showNextVisitDateInNumber = isValidNumber(nextVisitInputValue) && !!nextVisitInputType

  const nextVisitText = formatDuration(nextVisitInputValue, nextVisitInputType)

  return (
    <>
      <div
        className="flex-shrink-0"
        style={{
          width: '50%',
        }}
      >
        {nextVisitInputValue && nextVisitInputType && (
          <>
            <span
              style={{
                fontSize: styleOverrides?.headingFontSize
                  ? `${styleOverrides?.headingFontSize}px`
                  : 'inherit',
                color: styleOverrides?.headingColor || 'inherit',
              }}
            >
              <strong>Next Visit Date</strong>
            </span>
            <div
              style={{
                fontSize: styleOverrides?.fontSizePixels
                  ? `${styleOverrides?.fontSizePixels}px`
                  : 'inherit',
                color: styleOverrides?.color || 'inherit',
              }}
            >
              {nextVisitDateString}{' '}
              {/* {showNextVisitDateInNumber ? `(${nextVisitInputValue} ${nextVisitInputType})` : ''} */}
              {showNextVisitDateInNumber && !!nextVisitText ? `(after ${nextVisitText})` : ''}
            </div>
          </>
        )}
      </div>
      {children}
      <div
        style={{
          flexShrink: prescriptionData?.signature ? 0 : 'unset',
        }}
      >
        {prescriptionData?.signature && (
          <div>
            <img
              style={{
                height: '50px',
                width: '100px',
                marginBottom: '10px',
              }}
              src={`${mediaURL}${prescriptionData?.signature}`}
              alt="doctor's signature"
            ></img>
            <div>{prescriptionData?.doctorName}</div>
          </div>
        )}
      </div>
    </>
  )
}

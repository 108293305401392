export const PRESCRIPTION_COLORS_MAP = {
  patientDetails: {
    color: '#af90a0',
    highlightColor: '#ef1085',
  },
  vitals: {
    color: '#af90a0',
    highlightColor: '#ef1085',
  },
  diagnosis: {
    color: '#3264b6',
    highlightColor: '#001f49',
  },
  observations: {
    color: '#cf0052',
    highlightColor: '#49011d',
  },
  complaints: {
    color: '#41bf8f',
    highlightColor: '#41bf8f',
  },
  drugAdvice: {
    color: '#007044',
    highlightColor: '#002717',
  },
  homunculus: {
    color: '#e69e0e',
    highlightColor: '#f6c20b',
  },
  advice: {
    color: '#8b4a02',
    highlightColor: '#603d0f',
  },
  investigations: {
    color: '#CF96CE',
    highlightColor: '#C896CE',
  },
  selectedTherapy: {
    color: '#2D05B7',
    highlightColor: '#2A05B7',
  },
  suggestedTherapy: {
    color: '#EDCA6A',
    highlightColor: '#E2CA6A',
  },
  instructions: {
    color: '#AE030D',
    highlightColor: '#AD030D',
  },
  referredToDoctorName: {
    color: '#EEDDDD',
    highlightColor: '#EEDDDA',
  },
  header: {
    color: '#D1F7F2',
    highlightColor: '#D2F7F2',
  },
  footer: {
    color: '#7D8FD8',
    highlightColor: '#7E8FD8',
  },
  nextVisitAndSignature: {
    color: '#893A37',
    highlightColor: '#844A37',
  },
  bodyColor: {
    color: '#e4ff7d',
    highlightColor: '#e4ff7d',
  },
}

import { useEffect, useMemo } from 'react'
import { PRESCRIPTION_LAYOUTS } from 'src/consumer/constants'
import { PrescriptionDrugAdviceTable } from '../../PrescriptionMakingPageComponents'
import {
  PmpConsultationBodyContentContainer,
  PmpConsultationContentContainer,
} from '../../PrescriptionMakingPageStyles'
import {
  checkAndRenderBasedOnEmrSections,
  findEmrSectionBuSectionId,
  PRESCRIPTION_ITEM_ORDER_IDS,
} from '../utils'
import PatientDetails from './prescriptionPrintableItems/PatientDetails'
import PrintableAssessments from './prescriptionPrintableItems/PrintableAssessments'
import PrintableComplaints from './prescriptionPrintableItems/PrintableComplaints'
import PrintableDiagnosis from './prescriptionPrintableItems/PrintableDiagnosis'
import PrintableHomunculus from './prescriptionPrintableItems/PrintableHomunculus'
import PrintableInstructions from './prescriptionPrintableItems/PrintableInstructions'
import PrintableInvestigations from './prescriptionPrintableItems/PrintableInvestigations'
import PrintableNextVisitColumn from './prescriptionPrintableItems/PrintableNextVisitColumn'
import PrintableObservations from './prescriptionPrintableItems/PrintableObservations'
import PrintablePrescriptionAdvice from './prescriptionPrintableItems/PrintablePrescriptionAdvice'
import PrintableSuggestedTherapies from './prescriptionPrintableItems/PrintableSuggestedTherapies'
import PrintableTherapyRecommended from './prescriptionPrintableItems/PrintableTherapyRecommended'
import PrintableVitals from './prescriptionPrintableItems/PrintableVitals'
import { PMPBodyDebugHighlightDiv } from './prescriptionPrintableItems/styledComponent'
import { getValidDiagnosisArrayForPrescription } from './prescriptionPrintableItems/util'
import PrintableReferredToDoctorName from './prescriptionPrintableItems/PrintableReferredToDoctorName'

export default function PrescriptionFullBody({
  value,
  alsoChangePropValue,
  setPropValue,
  selectedTherapies,
  selectedAdvice,
  nextVisitInputType,
  nextVisitInputValue,
  prescriptionData,
  selectedObservations,
  selectedComplaints,
  selectedDiagnosis,
  selectedAssessments,
  referredToDoctorName,
  finalRequestedTestsBy,
  drugsAdvice,
  vital,
  selectedInstructions,
  suggestedBookingTherapy,
  selectedInvestigations,
  jointHomunculusData,
  prescriptionViewSettings,
  debug = false,
  printType,
  emrSections,
  patientDetailsRef,
  observationRef,
  complaintsRef,
  diagnosisRef,
  drugAdviceTableRef,
  prescriptionAdviceRef,
  investigationsRef,
  therapyRecommendedRef,
  suggestedTherapiesRef,
  referredToDoctorNameRef,
  assessmentsRef,
  homunculusRef,
  instructionsRef,
  nextVisitRef,
  vitalsRef,
  notHidden = false,
  debugHighlightStates = {},
  prescriptionBodyContainerRef,
}) {
  var layoutStyles
  var lenOfData = useMemo(() => {
    var len = 0
    if (Array.isArray(selectedObservations) && selectedObservations?.length > 0) {
      len += 1
    }
    if (Array.isArray(selectedComplaints) && selectedComplaints?.length > 0) {
      len += 1
    }
    if (Array.isArray(selectedDiagnosis) && selectedDiagnosis?.length > 0) {
      len += 1
    }
    return len || 1
  }, [selectedObservations, selectedComplaints, selectedDiagnosis])

  switch (prescriptionData?.layout) {
    case PRESCRIPTION_LAYOUTS.largerFontLayout:
      layoutStyles = {
        display: 'grid',
        gridTemplateColumns: `repeat(${lenOfData}, 1fr)`,
        width: '100%',
      }
      break
    default:
      layoutStyles = {}
      break
  }

  useEffect(() => {
    if (alsoChangePropValue) {
      setPropValue(value)
    }
  }, [value])

  console.log({ selectedAssessments })

  return (
    <PmpConsultationBodyContentContainer
      // headerHeight={headerDimensions?.height}
      // footerHeight={footerDimensions?.height}
      ref={prescriptionBodyContainerRef}
      debug={debug}
    >
      {debugHighlightStates?.prescriptionBody && (
        <PMPBodyDebugHighlightDiv
          id="debug-body"
          debugHighlight={debugHighlightStates?.prescriptionBody}
        ></PMPBodyDebugHighlightDiv>
      )}
      {/* for calculating the height of the items inside */}

      <div>
        {checkAndRenderBasedOnEmrSections('next_visit_details', emrSections) && (
          <PatientDetails
            containerRef={patientDetailsRef}
            debug={debug}
            prescriptionData={prescriptionData}
            printType={printType}
            debugHighlightStates={debugHighlightStates}
          />
        )}
        {checkAndRenderBasedOnEmrSections('patient-vitals', emrSections) && (
          <PrintableVitals
            containerRef={vitalsRef}
            debug={debug}
            vital={vital}
            printType={printType}
            debugHighlightStates={debugHighlightStates}
          />
        )}
        <PmpConsultationContentContainer debug={debug}>
          <div className="d-flex flex-column">
            <div
              style={{
                width: '100%',
              }}
            >
              <div style={layoutStyles}>
                {checkAndRenderBasedOnEmrSections('observations', emrSections) &&
                  Array.isArray(selectedObservations) &&
                  selectedObservations?.length > 0 && (
                    <PrintableObservations
                      debug={debug}
                      observationRef={observationRef}
                      selectedObservations={selectedObservations}
                      debugHighlightStates={debugHighlightStates}
                    />
                  )}
                {checkAndRenderBasedOnEmrSections('health_complaints', emrSections) &&
                  Array.isArray(selectedComplaints) &&
                  selectedComplaints?.length > 0 && (
                    <PrintableComplaints
                      complaintsRef={complaintsRef}
                      debug={debug}
                      selectedComplaints={selectedComplaints}
                      debugHighlightStates={debugHighlightStates}
                    />
                  )}
                {checkAndRenderBasedOnEmrSections('diagnosis', emrSections) &&
                  Array.isArray(getValidDiagnosisArrayForPrescription(selectedDiagnosis)) &&
                  getValidDiagnosisArrayForPrescription(selectedDiagnosis)?.length > 0 && (
                    <PrintableDiagnosis
                      debug={debug}
                      diagnosisRef={diagnosisRef}
                      subSections={findEmrSectionBuSectionId(emrSections, 'diagnosis')}
                      selectedDiagnosis={selectedDiagnosis}
                      debugHighlightStates={debugHighlightStates}
                    />
                  )}
              </div>
            </div>
            {checkAndRenderBasedOnEmrSections('drug_advice', emrSections) && (
              <PrescriptionDrugAdviceTable
                prescriptionData={prescriptionData}
                drugsAdvice={drugsAdvice}
                prescriptionViewSettings={prescriptionViewSettings}
                debug={debug}
                drugAdviceTableRef={drugAdviceTableRef}
                debugHighlightStates={debugHighlightStates}
                drugAdviceSubSections={
                  emrSections?.find((item) => item?.id === 'drug_advice')?.subSections
                }
              />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {checkAndRenderBasedOnEmrSections('advice', emrSections) &&
              Array.isArray(selectedAdvice) &&
              selectedAdvice.length > 0 && (
                <PrintablePrescriptionAdvice
                  debug={debug}
                  prescriptionAdviceRef={prescriptionAdviceRef}
                  selectedAdvice={selectedAdvice}
                  debugHighlightStates={debugHighlightStates}
                />
              )}
            {checkAndRenderBasedOnEmrSections('investigations', emrSections) &&
              Array.isArray(selectedInvestigations) &&
              selectedInvestigations.length > 0 && (
                <PrintableInvestigations
                  debug={debug}
                  investigationsRef={investigationsRef}
                  selectedInvestigations={selectedInvestigations}
                  finalRequestedTestsBy={finalRequestedTestsBy}
                  debugHighlightStates={debugHighlightStates}
                />
              )}
            {checkAndRenderBasedOnEmrSections(
              PRESCRIPTION_ITEM_ORDER_IDS.assessments,
              emrSections,
            ) &&
              Array.isArray(selectedAssessments) &&
              selectedAssessments?.length > 0 && (
                <PrintableAssessments
                  debug={debug}
                  assessmentsRef={assessmentsRef}
                  debugHighlightStates={debugHighlightStates}
                  selectedAssessments={selectedAssessments}
                />
              )}
            {checkAndRenderBasedOnEmrSections('therapy_recommendation', emrSections) &&
              suggestedBookingTherapy && (
                <PrintableTherapyRecommended
                  debug={debug}
                  debugHighlightStates={debugHighlightStates}
                  suggestedBookingTherapy={suggestedBookingTherapy}
                  therapyRecommendedRef={therapyRecommendedRef}
                />
              )}
            {checkAndRenderBasedOnEmrSections('therapy_recommendation', emrSections) &&
              Array.isArray(selectedTherapies) &&
              selectedTherapies.length > 0 && (
                <PrintableSuggestedTherapies
                  debug={debug}
                  selectedTherapies={selectedTherapies}
                  suggestedTherapiesRef={suggestedTherapiesRef}
                  debugHighlightStates={debugHighlightStates}
                />
              )}
            {checkAndRenderBasedOnEmrSections('joint_homunculus', emrSections) && (
              <PrintableHomunculus
                debug={debug}
                homunculusRef={homunculusRef}
                jointHomunculusData={jointHomunculusData}
                debugHighlightStates={debugHighlightStates}
              />
            )}
            {checkAndRenderBasedOnEmrSections('instructions', emrSections) &&
              Array.isArray(selectedInstructions) &&
              selectedInstructions.length > 0 && (
                <PrintableInstructions
                  debug={debug}
                  debugHighlightStates={debugHighlightStates}
                  instructionsRef={instructionsRef}
                  selectedInstructions={selectedInstructions}
                />
              )}
            {checkAndRenderBasedOnEmrSections(
              PRESCRIPTION_ITEM_ORDER_IDS.refer_to,
              emrSections,
            ) && (
              <PrintableReferredToDoctorName
                debug={debug}
                debugHighlightStates={debugHighlightStates}
                referredToDoctorName={referredToDoctorName}
                prescriptionData={prescriptionData}
                referredToDoctorNameRef={referredToDoctorNameRef}
                styleOverrides={
                  prescriptionViewSettings?.styleOverridesForPrintedEmrSections?.[
                    'referredToDoctorName'
                  ]
                }
              />
            )}
            <PrintableNextVisitColumn
              debug={debug}
              debugHighlightStates={debugHighlightStates}
              nextVisitInputType={nextVisitInputType}
              nextVisitInputValue={nextVisitInputValue}
              nextVisitRef={nextVisitRef}
              prescriptionData={prescriptionData}
            />
          </div>
        </PmpConsultationContentContainer>
      </div>
    </PmpConsultationBodyContentContainer>
  )
}

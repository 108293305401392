import {
  PMPHeaderDoctorText,
  PMPHeaderLeftPart,
  PMPHeaderRightPart,
  PMPHeadingBanner,
} from '../../PrescriptionMakingPageStyles'
import PrescriptionKVItem from '../common/PrescriptionKVItem'

export default function TwoColumnFooterWithDisclaimerHeaderWithDegreesInSeperateLines({
  prescriptionData,
  leaveSpace,
  debug = false,
}) {
  return (
    <>
      <PMPHeadingBanner debug={debug} noColor={true}>
        <span>{prescriptionData?.header?.title}</span>
        {/* {prescriptionData?.header?.subTitle && ( */}
        <span className="sub-heading">{prescriptionData?.header?.subTitle}</span>
        {/* )} */}
      </PMPHeadingBanner>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PMPHeaderLeftPart>
          <PMPHeaderDoctorText main style={{ fontSize: '18px' }}>
            {prescriptionData?.doctorName}
          </PMPHeaderDoctorText>
          <PMPHeaderDoctorText main>{prescriptionData?.designation}</PMPHeaderDoctorText>
          {prescriptionData?.listOfDegrees?.map((degree, degreeIndex) => (
            <PMPHeaderDoctorText main key={degreeIndex}>
              {degree}
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderLeftPart>
        <PMPHeaderRightPart
          style={{
            flexShrink: 0,
            width: 'unset',
          }}
        >
          {prescriptionData?.regId && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="Regn No."
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.regId}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.contactEmail && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="Email"
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.contactEmail}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.website && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="Website"
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.website}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.cmoRegId && (
            <PMPHeaderDoctorText>
              <PrescriptionKVItem
                itemKey="C.M.O. Regd. No."
                topContainerProps={{
                  className: 'd-flex align-items-center justify-content-end gap-1',
                }}
                value={prescriptionData?.cmoRegId}
              />
            </PMPHeaderDoctorText>
          )}
          {prescriptionData?.affiliations?.map((affiliation, affIndex) => (
            <PMPHeaderDoctorText key={affIndex}>
              <b>{affiliation}</b>
            </PMPHeaderDoctorText>
          ))}
        </PMPHeaderRightPart>
      </div>
    </>
  )
}

import React, { useEffect } from 'react'
import {
  PrescriptionPillViewInputPillContainer,
  PrescriptionRemoveIconButton,
} from '../../common/styledComponents'
import { Close } from '@mui/icons-material'

export default function DrugAdviceSelectedPillComponent({
  value,
  idx,
  getOptionValue,
  getLabel,
  onClickOption,
  onRemoveOption,
  selectedItemIndex,
  pillColor,
  highlightedPillColor,
}) {
  const showSecondaryText = value?.dosage && value?.duration

  useEffect(() => {
    console.log({ value })
  }, [value])

  return (
    <PrescriptionPillViewInputPillContainer
      onClick={() => {
        onClickOption(value, idx)
      }}
      selected
      highlighted={selectedItemIndex === idx}
      selectedColor={pillColor}
      highlightedColor={highlightedPillColor}
      key={`${idx}-${getOptionValue(value)}`}
    >
      <div className="d-flex flex-column">
        <span>{getLabel(value)}</span>{' '}
        {showSecondaryText && (
          <span className='fw-light'>
            {value?.dosage} x {value?.duration}
          </span>
        )}
      </div>
      <PrescriptionRemoveIconButton
        // onMouseDown={(e) => {
        //   e.stopPropagation()
        //   setTempFoc(focused || false)
        // }} not working as expected
        onClick={(e) => {
          e.stopPropagation()
          onRemoveOption(value, idx)
        }}
        size="small"
      >
        <Close sx={{ fontSize: '1.3rem' }} />
      </PrescriptionRemoveIconButton>
    </PrescriptionPillViewInputPillContainer>
  )
}

import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import React, { useState } from 'react'
import { CustomInput } from 'src/consumer/components/helperComponents'
import { genericHandleChange } from 'src/consumer/helpers/utilFunctions'

export default function DrugCreationForm({ inputString, setDrugData, closeDialog }) {
  const [newDrug, setNewDrug] = useState({
    name: inputString,
  })

  function createNewDrug() {
    const drugToCreate = {
      ...newDrug,
      label: newDrug.name,
      title: newDrug.name,
      __isNew__: true,
    }
    setDrugData((prev) => {
      return [drugToCreate, ...prev]
    })
    closeDialog()
  }

  function handleChange(e) {
    genericHandleChange(e, setNewDrug)
  }

  return (
    <>
      <DialogTitle>Create a new drug</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CustomInput id="name" label="Drug Name" value={newDrug?.name} onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <CustomInput id="genericName" label="Generic Name" value={newDrug?.genericName} onChange={handleChange} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={closeDialog} sx={{ color: '#000' }} variant="text">
          Cancel
        </Button>
        <Button size="small" variant="contained" disabled={!newDrug?.name} onClick={createNewDrug}>
          Create
        </Button>
      </DialogActions>
    </>
  )
}

import React from 'react'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { PMPBodySectionItems } from './styledComponent'
import { prepareFinalRequestedTestsSentence } from './util'

export default function PrintableInvestigations({
  investigationsRef,
  debug,
  selectedInvestigations,
  finalRequestedTestsBy,
  debugHighlightStates,
}) {
  const finalRequestedTestSentence = prepareFinalRequestedTestsSentence(finalRequestedTestsBy)

  return (
    <PMPBodySectionItems
      ref={investigationsRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.investigations.color}
      debugHighlight={debugHighlightStates?.investigations}
      investigations
    >
      <strong>
        Advised Tests {finalRequestedTestSentence ? `(${finalRequestedTestSentence})` : ''}
      </strong>
      <div>{selectedInvestigations?.map((item) => item?.label)?.join(', ')}</div>
    </PMPBodySectionItems>
  )
}

import moment from 'moment'
import { getCPDataWithoutParams } from 'src/clinic/services'
import { appVersion, platformWeb } from 'src/consumer/services'
import { queueAudioAndSpeech } from 'src/textToSpeechUtil'

export function shouldShowYoutubeVideoForOOH(thisClinicData) {
  // let returnBool = false
  // const scheduleForToday = getDefaultScheduleFromSchedules(thisClinicData?.schedules)
  //   ?.weeklyHourMap?.[moment()?.format('dddd')?.toLowerCase()]
  // // skipping the timezone consideration here as no need to internationalize it now
  // if (scheduleForToday?.available) {
  //   const timeNow = moment()?.format('HH:mm:ss')
  //   for (const slot of scheduleForToday.slotList) {
  //     const timeMomentStart = moment(slot.startTime, 'HH:mm:ss').subtract(2, 'hours')
  //     const newTimeStart = timeMomentStart.format('HH:mm:ss')

  //     const timeMomentEnd = moment(slot.endTime, 'HH:mm:ss').add(2, 'hours')
  //     const newTimeEnd = timeMomentEnd.format('HH:mm:ss')

  //     if (timeNow >= newTimeStart && timeNow <= newTimeEnd) {
  //       returnBool = true
  //     }
  //   }
  // }
  // return returnBool

  const currentTime = moment().format('HH:mm:ss')
  const startTime = moment('09:00:00', 'HH:mm:ss')
  const endTime = moment('21:00:00', 'HH:mm:ss')

  const isBetween9AMAnd9PM = moment(currentTime, 'HH:mm:ss').isBetween(startTime, endTime)
  return isBetween9AMAnd9PM
}

export async function getActiveMentorsInAClinicLocation({ clinicLocationId }) {
  const response = await getCPDataWithoutParams(
    `/api/secure/clinic-assistant/active-mentors-in-clinic?clinicLocationId=${clinicLocationId}&zoneOffset=${encodeURIComponent(
      moment().format('Z'),
    )}&appVersion=${appVersion}&platformType=${platformWeb}`,
  )
  if (response.status === 200) {
    return response?.data
  }
  return {}
}

async function getMentorForClinicAssistant({ serviceProviderId }) {
  const response = await getCPDataWithoutParams(
    `/api/secure/clinic-assistant/service-provider?id=${serviceProviderId}&zoneOffset=${encodeURIComponent(
      moment().format('Z'),
    )}&appVersion=${appVersion}&platformType=${platformWeb}`,
  )
  if (response.status === 200) {
    return response?.data
  }
  return null
}

export async function getMentorListForClinicAssistant({ serviceProviders = [] }) {
  // let mentorList = await Promise.all(
  //   serviceProviders.map((serviceProvider) =>
  //     getMentorForClinicAssistant({ serviceProviderId: serviceProvider?.id }),
  //   ),
  // )
  let mentorList = serviceProviders

  mentorList = mentorList.filter((mentor) => !!mentor)

  return mentorList?.sort((a, b) => {
    return a?.title?.localeCompare(b?.title)
  })
}

export const compareStartTime = (a, b) => {
  const dateA = new Date(a.slotStart)
  const dateB = new Date(b.slotStart)
  return dateA - dateB
}

export const compareStartTimeForLatestFirstTime = (a, b) => {
  const dateA = new Date(a.slotStart)
  const dateB = new Date(b.slotStart)
  return dateB - dateA
}

export function getNextCompletedBookedSlots({ bookedSlots }) {
  let nextBookingsDue = bookedSlots?.sort(compareStartTime)
  let firstPatientDueIndex = 0
  nextBookingsDue?.forEach((item, idx) => {
    if (item?.markedCompleted) {
      firstPatientDueIndex = idx + 1
    }
  })
  return nextBookingsDue?.slice(firstPatientDueIndex) || []
}

export async function getAndSetBookedSlotsOfMentorAtSelectedDate({
  screeningMentor,
  selectedDateUtcStartTime,
  selectedDateUtcEndTime,
  clinicId,
}) {
  // let response = await getCPDataWithoutParams(
  //   `/api/secure/clinic-assistant/booked-slots/all?mentorId=${screeningMentor?.id}&doctorId=${screeningMentor?.id}&startTime=${selectedDateUtcStartTime}&endTime=${selectedDateUtcEndTime}`,
  // )
  let response2 = await getCPDataWithoutParams(
    `/api/secure/clinic-assistant/appointment-numbers?mentorId=${screeningMentor?.id}&clinicId=${clinicId}&startTime=${selectedDateUtcStartTime}&endTime=${selectedDateUtcEndTime}`,
  )
  // if (response?.status === 200) {
  //   if (Array.isArray(response?.data) && response?.data?.length > 0) {
  //     console.log({ orignalBookedSlots: response?.data })

  //     let nextBookingsDue = response?.data?.sort(compareStartTime)
  //     let firstPatientDueIndex = 0
  //     nextBookingsDue?.forEach((item, idx) => {
  //       if (item?.markedCompleted) {
  //         firstPatientDueIndex = idx + 1
  //       }
  //     })
  //     return nextBookingsDue.slice(firstPatientDueIndex)
  //   }
  // }
  if (response2?.status === 200) {
    if (Array.isArray(response2?.data) && response2?.data?.length > 0) {
      return response2?.data
    }
  }
  return []
}

export async function getScreenersWithTheirBookedSlots({
  screeners = [],
  selectedDateUtcStartTime,
  selectedDateUtcEndTime,
  clinicId,
}) {
  let screenersWithBookedSlots = await Promise.all(
    screeners?.map(async (screeningMentor) => {
      let slots = await getAndSetBookedSlotsOfMentorAtSelectedDate({
        screeningMentor,
        selectedDateUtcStartTime,
        selectedDateUtcEndTime,
        clinicId,
      })
      return { ...screeningMentor, bookedSlots: slots }
    }),
  )

  console.log({ screenersWithBookedSlots })

  return screenersWithBookedSlots?.sort((a, b) => {
    return a?.title?.localeCompare(b?.title)
  })
}

export function getUpdatedActiveAppointmentMapAndAnnounceForChanges(
  activeAppointmentMap,
  mentorId,
  mentorName,
  mentorLatestAppointmentNumber,
  mentorNextAppointmentNumber,
  lang = 'hi',
) {
  if (!activeAppointmentMap) {
    return {}
  }
  if (!mentorName) {
    return activeAppointmentMap
  }
  if (!mentorLatestAppointmentNumber) {
    delete activeAppointmentMap[mentorId]
    return activeAppointmentMap
  }
  if (mentorId in activeAppointmentMap) {
    const currentAppointmentNumber = activeAppointmentMap?.[mentorId]

    if (!currentAppointmentNumber) {
      // Case: mentorId exists, but the value is null, undefined, or an empty string
      activeAppointmentMap[mentorId] = mentorLatestAppointmentNumber
      callPatient(mentorLatestAppointmentNumber, mentorNextAppointmentNumber, mentorName, lang)
      return activeAppointmentMap
    } else if (currentAppointmentNumber === mentorLatestAppointmentNumber) {
      // Case: The current appointment matches the latest appointment
      return activeAppointmentMap
    } else {
      // Case: The current appointment does not match the latest appointment
      activeAppointmentMap[mentorId] = mentorLatestAppointmentNumber
      callPatient(mentorLatestAppointmentNumber, mentorNextAppointmentNumber, mentorName, lang)
      return activeAppointmentMap
    }
  } else {
    // Case: mentorId is not a key in the map
    activeAppointmentMap[mentorId] = mentorLatestAppointmentNumber
    callPatient(mentorLatestAppointmentNumber, mentorNextAppointmentNumber, mentorName, lang)
    return activeAppointmentMap
  }
}

function callPatient(thisPatientToken, nextPatientToken, mentorName, language) {
  if (thisPatientToken && mentorName) {
    if (language === 'hi') {
      // queueAudioAndSpeech(
      //   '/pre_text_tone_1.wav',
      //   `<speak>टोकन नंबर ${thisPatientToken} ${formatMentorName(
      //     mentorName,
      //   )} के चैम्बर में आईये।<break time="1000ms"/>नंबर ${thisPatientToken} ${formatMentorName(
      //     mentorName,
      //   )} के चैम्बर में आईये।<break time="1000ms"/>नंबर ${thisPatientToken} ${formatMentorName(
      //     mentorName,
      //   )} के चैम्बर में आईये।</speak>`,
      //   'hi-IN',
      // )

      queueAudioAndSpeech(
        '/pre_text_tone_1.wav',
        `टोकन नंबर ${thisPatientToken} ${formatMentorName(mentorName)} के चैम्बर में आईये।`,
        'hi-IN',
      )
    } else {
      queueAudioAndSpeech(
        '/pre_text_tone_1.wav',
        `Token ${thisPatientToken}, please meet${mentorName}, ${thisPatientToken}`,
        'en-IN',
      )
    }
  }
  if (nextPatientToken && mentorName) {
    if (language === 'hi') {
      queueAudioAndSpeech(
        '/pre_text_tone_1.wav',
        `${nextPatientToken}, ${formatMentorName(mentorName)} से मिलने के लिए तैयार रहिये।`,
        'hi-IN',
      )
    } else {
      queueAudioAndSpeech(
        '/pre_text_tone_1.wav',
        `Get Ready, token ${nextPatientToken} for ${mentorName}`,
        'en-IN',
      )
    }
  }
}

export function getScreenTitleForMentor(mentorProfileData) {
  if (Array.isArray(mentorProfileData?.categories)) {
    if (mentorProfileData.categories.includes('clinical_psychologist')) {
      return 'Psychologist'
    }
    if (
      mentorProfileData.categories.includes('counselling_psychologist') &&
      mentorProfileData.categories.includes('therapist')
    ) {
      return 'Therapists'
    }
    if (
      mentorProfileData.categories.includes('doctor') &&
      mentorProfileData.categories.includes('psychiatrist')
    ) {
      return 'Pyschiatrist'
    }
    if (
      mentorProfileData.categories.includes('doctor') &&
      Array.isArray(mentorProfileData.specializedCategories) &&
      mentorProfileData.specializedCategories.includes('psychiatrist')
    ) {
      return 'Pyschiatrist'
    }
  } else {
    return ''
  }
}

export function getPrefixRemovedAppointmentNumber(appointmentNumber) {
  if (appointmentNumber) {
    const match = appointmentNumber.match(/^([A-Za-z]+)(\d+)$/)
    if (match) {
      return match[2]
    }
    return appointmentNumber
  }
  return null // If appointment number is null or undefined
}

export function formatMentorName(mentorName) {
  if (!mentorName || typeof mentorName !== 'string') {
    console.error('Invalid mentor name provided.')
    return mentorName
  }

  // Check if the name starts with 'Dr ' or 'Dr. ' (case-insensitive)
  if (/^dr\.?\s/i.test(mentorName)) {
    // Replace 'Dr ' or 'Dr. ' with 'Doctor '
    return mentorName.replace(/^dr\.?\s/i, 'Doctor ')
  }

  return mentorName
}

import React from 'react'
import { PMPBodySectionItems } from './styledComponent'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'

export default function PrintableInstructions({
  instructionsRef,
  debug,
  debugHighlightStates,
  selectedInstructions,
}) {
  return (
    <PMPBodySectionItems
      ref={instructionsRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.instructions?.color}
      debugHighlight={debugHighlightStates?.instructions}
    >
      <strong>Advised Instructions</strong>
      <ul className="d-flex flex-column">
        {selectedInstructions?.map((item, idx) => (
          <li key={idx}>{item?.label}</li>
        ))}
      </ul>
    </PMPBodySectionItems>
  )
}

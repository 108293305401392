import React from 'react'
import { CustomHTMLSelect, CustomInput } from 'src/consumer/components/helperComponents'
import DayjsDatePicker from '../../diagnosis/components/DayjsDatePicker'
import { MOMENT_FORMATS } from 'src/consumer/constants'
import { CalendarMonth } from '@mui/icons-material'

export const TESTS_TYPES_MAP = {
  none: { id: 'none', title: 'None' },
  today: { id: 'today', title: 'Today' },
  days: { id: 'days', title: 'Days' },
  asap: { id: 'asap', title: 'Asap' },
  calendar: { id: 'calendar', title: 'Calendar' },
  weeks: { id: 'weeks', title: 'Weeks' },
  months: { id: 'months', title: 'Months' },
  'next-visit': { id: 'next-visit', title: 'Next Visit' },
}

const FINAL_REQUESTED_TESTS_TYPES = [
  TESTS_TYPES_MAP.none, // { id: undefined, title: 'None' }
  TESTS_TYPES_MAP.today, // { id: 'today', title: 'Today' }
  TESTS_TYPES_MAP.days, // { id: 'days', title: 'Days' }
  TESTS_TYPES_MAP.asap, // { id: 'asap', title: 'Asap' }
  TESTS_TYPES_MAP.calendar, // { id: 'calendar', title: 'Calendar' }
  TESTS_TYPES_MAP.weeks, // { id: 'weeks', title: 'Weeks' }
  TESTS_TYPES_MAP.months, // { id: 'months', title: 'Months' }
  TESTS_TYPES_MAP['next-visit'], // { id: 'next-visit', title: 'Next Visit' }
]

export default function InvestigationDateComponent({
  finalRequestedTestsBy,
  setFinalRequestedTestsBy,
}) {
  return (
    <div className="d-flex flex-shrink-0 flex-column">
      <span>By When</span>
      <div
        className="flex-shrink-0 d-flex align-items-end"
        style={{ border: '1px solid #ebecf5', borderRadius: '8px', overflow: 'hidden' }}
      >
        {finalRequestedTestsBy?.valueType !== 'calendar' ? (
          <CustomInput
            value={finalRequestedTestsBy?.value}
            componentContainerStyles={{ gap: '0px' }}
            pureInputProps={{ style: { width: '130px' } }}
            inputAnsMessageContainerStyles={{
              padding: '5px',
              background: '#fff',
              borderRadius: '0px',
              border: 'none',
              borderRight: '1px solid #ebecf5',
            }}
            onChange={(e) => {
              setFinalRequestedTestsBy((prev) => ({ ...prev, value: e.target.value }))
            }}
          />
        ) : (
          <DayjsDatePicker
            date={finalRequestedTestsBy?.calendarValue}
            format="DD-MMM-YYYY"
            placeholder="Select Date"
            buttonProps={{
              variant: 'text',
              startIcon: <CalendarMonth />,
              sx: {
                background: '#fff',
                textTransform: 'none',
                border: 'none',
                width: '140px',
                // "&hover": {
                //     border: 'none'
                // }
              },
            }}
            onChange={(val) => {
              setFinalRequestedTestsBy((prev) => ({
                ...prev,
                calendarValue: val.format(MOMENT_FORMATS.formatThatServerSends),
              }))
            }}
          />
        )}
        <CustomHTMLSelect
          value={finalRequestedTestsBy?.valueType}
          outerMostDivStyle={{ minWidth: '100px' }}
          getOptionValue={(optn) => optn?.id}
          getOptionLabel={(optn) => optn?.title}
          style={{ padding: '5px', background: '#f9fafb', borderRadius: '5px' }}
          options={FINAL_REQUESTED_TESTS_TYPES}
          onChange={(e) => {
            if (e.target.value === TESTS_TYPES_MAP.none.id) {
              setFinalRequestedTestsBy((prev) => ({ ...prev, valueType: undefined }))
              return 
            }
            setFinalRequestedTestsBy((prev) => ({ ...prev, valueType: e.target.value }))
          }}
        />
      </div>
    </div>
  )
}

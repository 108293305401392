import { GENDER_LIST_ONBOARDING } from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import { getDateOfPrescription } from '../../../PrescriptionMakingPageComponents'
import { PMPHeaderDetails } from '../../../PrescriptionMakingPageStyles'
import PrescriptionKVItem from '../../common/PrescriptionKVItem'

export default function ThreeColumnPrescriptionFooterPatientDetails({
  prescriptionData,
  leaveSpace,
  debug,
  prescriptionViewSettings,
}) {
  return (
    <>
      <PMPHeaderDetails
        debug={debug}
        noColor={true}
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          borderTop: '1px solid black',
          padding: '5px 8px',
        }}
      >
        <PrescriptionKVItem
          itemKey="Patient"
          value={
            <>
              {prescriptionData?.patientPrefill?.patientNumber
                ? `(${prescriptionData?.patientPrefill?.patientNumber})`
                : ''}{' '}
              {prescriptionData?.patientPrefill?.name},{' '}
              {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
              {
                GENDER_LIST_ONBOARDING?.find(
                  (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
                )?.label
              }
              {prescriptionViewSettings?.showPatientPhoneOnPrescription &&
              prescriptionData?.patientPrefill?.mobile
                ? `, ${prescriptionData?.patientPrefill?.mobile}`
                : ''}
              {prescriptionViewSettings?.showPatientAddressOnPrescription &&
              prescriptionData?.patientPrefill?.location?.city
                ? `, ${prescriptionData?.patientPrefill?.location?.city}`
                : ''}
            </>
          }
        />
        <PrescriptionKVItem
          containerType="div"
          itemKey="Date"
          topContainerProps={{ className: 'd-flex align-items-center justify-content-end gap-1' }}
          value={getDateOfPrescription(prescriptionData, false)}
        />
      </PMPHeaderDetails>
    </>
  )
}

import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { PMPBodySectionItems } from './styledComponent'

export default function PrintableComplaints({
  complaintsRef,
  debug,
  selectedComplaints,
  debugHighlightStates,
  styleOverrides,
  prescriptionViewSettings,
}) {
  return (
    <PMPBodySectionItems
      ref={complaintsRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.complaints?.color}
      debugHighlight={debugHighlightStates?.complaints}
    >
      <span
        style={{
          fontSize: styleOverrides?.headingFontSize ? `${styleOverrides?.headingFontSize}px` : 'inherit',
          color: styleOverrides?.headingColor || 'inherit',
        }}
      >
        <strong>Complaints</strong>
      </span>
      <div
        style={{
          fontSize: styleOverrides?.fontSizePixels ? `${styleOverrides?.fontSizePixels}px` : 'inherit',
          color: styleOverrides?.color || 'inherit',
        }}
      >
        {selectedComplaints.map((complaint) => complaint.label).join(', ')}
      </div>
    </PMPBodySectionItems>
  )
}

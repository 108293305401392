import { Person, Print } from '@mui/icons-material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import WestIcon from '@mui/icons-material/West'
import { Button, CircularProgress, Collapse } from '@mui/material'
import moment from 'moment'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import useInfiniteScroll from 'react-infinite-scroll-hook'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { makeAndStoreAgoraConnectionInRedux } from 'src/components/FloatingIntercom/FloatingIntercomButton'
import {
  APP_CONSTANTS,
  PRESCRIPTION_PRINT_TYPES,
  WEBSITE_VIEW_TYPES,
  platformOptions,
} from 'src/consumer/constants'
import { LocalizationContext } from 'src/consumer/context/LocalizationContext'
import {
  selectAgoraChatId,
  selectAgoraChatServerConnection,
  selectAgoraChatToken,
} from 'src/consumer/reducers/personalData/personalDataReducer'
import {
  getBookedSessionsForUser,
  getNotesOfBookedEvent,
  getUserPrescriptionOfEvent,
} from 'src/consumer/services/featureServices'
import { getDataWithParams } from 'src/consumer/services/profileService'
import { useScreenSize, useScrollTo } from 'src/customHooks/customHooks'
import {
  PmpPatientDetails,
  PrescriptionBody,
  PrescriptionBodyFirstSection,
  PrescriptionDrugAdviceTable,
  PrescriptionFooter,
  PrescriptionHeader,
  getDateOfPrescription,
  getInitialDataForSettingDaysOfNextVisit,
  getInitialDataForSettingExistingDrugAdvice,
  getInitialDataForSettingExistingHealthEntity,
  getInitialDataForSettingExistingSelectedAdvice,
  getInitialDataForSettingExistingSelectedComplaints,
  getInitialDataForSettingExistingSelectedDiagnosis,
  getInitialDataForSettingExistingSelectedInstructions,
  getInitialDataForSettingExistingSelectedInvestigations,
  getInitialDataForSettingExistingSelectedObservations,
  getInitialDataForSettingExistingSelectedTherapies,
} from 'src/mentor/components/PrescriptionMakingPage/PrescriptionMakingPageComponents'
import {
  PMPParent,
  PMPParentVarTwo,
  PmpBodyContentContainer,
  PmpContentContainer,
} from 'src/mentor/components/PrescriptionMakingPage/PrescriptionMakingPageStyles'
import {
  getImageLink,
  isCommonResponseSuccessful,
  sleep,
} from 'src/mentor/helpers/utilityFunctions'
import { GenericDisplayFlex } from 'src/mentor/layout/Styles'
import { ChatContainer } from '../../../components/ChatUIComponents/ChatStyles'
import {
  ChatBoxDateComponent,
  ChatMessageOtherUserWrapper,
  ChatMessageThisUserWrapper,
} from '../../../components/ChatUIComponents/chatComponents'
import { prepareMessagesArray } from '../../../components/ChatUIComponents/helperFunctions'
import { formatTime } from '../LeaderboardPage/LeaderboardPageComponents'
import { AddToGoogleCalendarButton } from '../LiveSessionPage/LiveSessionPageComponents'
import { getSingleBookedSessionResponseBySlug } from '../LiveSessionPage/live-session-utils'
import { LoadingSpinner } from '../Loaders'
import ProfessionalsListPage from '../Professionals List Page'
import { SentryRefHandler } from '../helperComponents'
import {
  BackButton,
  BookNewSessionButton,
  BookedEventsShortDescription,
  BookedSlotsContainer,
  DescriptionOfSession,
  DetailsButton,
  DetailsOfSessionContainer,
  HeadlineOfSession,
  IndexContainer,
  IndexItemContainer,
  JoinMeetingButton,
  MeetingButtonsContainer,
  MeetingDetails,
  MeetingPlatformImage,
  MentorImage,
  MentorName,
  MentorPartDetails,
  MentorPictureAndName,
  MySessionsBackground,
  NameAndSubtext,
  PictureAndName,
  SecondaryContainer,
  SecondarySisterContainer,
  StartsInTextOne,
  StartsInTextTwo,
  SubheadingOfSession,
  TextPartDetails,
  TopIndexComponent,
  TopIndexTitle,
  YourMentorText,
  getTertiaryColor,
} from './MyLiveSessionStyles'
import {
  bookedPageViewTypes,
  canEventBeJoined,
  checkIfAllowedToJoinAllDay,
  checkIfEventUpcoming,
  getMyRoleForThisEvent,
  prefixedLinkForNavigates,
} from './my-live-sessions-utils'
import PrescriptionViewComponent from 'src/mentor/components/PrescriptionMakingPage/printComponents/PrescriptionViewComponent'
import ViewOnlyPrescriptionV2 from 'src/mentor/components/PrescriptionMakingPage/viewOnlyComponents/ViewOnlyPrescriptionV2'

export default function MyLiveSessionsPage({
  openedAsWebview,
  propSetupData: passedSetupData,
  propUserProfileData: passedUserProfileData,
}) {
  const websiteViewType =
    sessionStorage.getItem('isThisWebviewSession') || openedAsWebview
      ? WEBSITE_VIEW_TYPES.webview
      : WEBSITE_VIEW_TYPES.default

  const navigate = useNavigate()
  const { eventId } = useParams()
  const [chosenEventId, setChosenEventId] = useState()
  const { localizedData } = useContext(LocalizationContext)
  const myLiveSessionsStrings = localizedData?.strings?.myLiveSessionsPage

  const [pageNumber, setPageNumber] = useState(1)

  const [bookedSlotsData, setBookedSlotsData] = useState([])

  const selectedEventData = bookedSlotsData?.find((item) => item?.id === chosenEventId)
  const [currentTime, setCurrentTime] = useState(moment().format('YYYY-MM-DD HH:mm:ss'))

  const { width: screenWidth } = useScreenSize()
  const isMobile = screenWidth <= 800

  const [hasNextPage, setHasNextPage] = useState(true)
  const [initialLoading, setInitialLoading] = useState(true)

  const mentorSubtextArray = []
  selectedEventData?.categories?.forEach((item) => {
    mentorSubtextArray?.push(
      passedSetupData?.metaCategories?.find((categoryItem) => categoryItem?.id === item)?.name,
    )
  })

  if (sessionStorage.getItem('isThisWebviewSession') || openedAsWebview) {
    sessionStorage.setItem('notificationsAreDenied', true)
    sessionStorage.setItem('isThisWebviewSession', true)
  }

  function goToBookaNewSession() {
    navigate(`${prefixedLinkForNavigates?.[websiteViewType]}/${bookedPageViewTypes?.new}`)
  }

  async function setBookedSessionsFirstPage() {
    const resp = await getBookedSessionsForUser(1)
    if (resp?.status === 200) {
      const urlPassedBookedEvent = eventId && (await fetchUrlPassedBookedSession())
      if (urlPassedBookedEvent) {
        setBookedSlotsData([
          urlPassedBookedEvent,
          ...(resp?.data || [])?.filter((item) => item?.slug !== urlPassedBookedEvent?.slug),
        ])
        setChosenEventId(urlPassedBookedEvent?.id)
        return
      }
      setChosenEventId(resp?.data?.[0]?.id)
      setBookedSlotsData(resp?.data)
    }
  }

  async function fetchUrlPassedBookedSession() {
    const resp = await getSingleBookedSessionResponseBySlug(eventId)
    if (resp?.status === 200 && isCommonResponseSuccessful(resp?.data?.code)) {
      return resp?.data?.data.bookedEvent
    }
    return null
  }

  async function getNextBookedSessionsPage() {
    const nextPageNumber = pageNumber + 1
    const resp = await getBookedSessionsForUser(nextPageNumber)
    if (resp?.status === 200) {
      if (resp?.data && resp?.data?.length > 0) {
        const bookedEventsToSet = resp.data?.filter((item) => item?.slug !== eventId)
        setPageNumber(nextPageNumber)
        setBookedSlotsData((prev) => [...prev, ...(bookedEventsToSet || [])])
      } else {
        // If there's no more data to load, set hasNextPage to false
        setHasNextPage(false)
      }
    } else {
      setHasNextPage(false)
    }
  }

  async function Setup() {
    setInitialLoading(true)
    const promises = [setBookedSessionsFirstPage()]
    await Promise.all(promises)
    setInitialLoading(false)
  }

  const [sentryRef] = useInfiniteScroll({
    hasNextPage,
    onLoadMore: getNextBookedSessionsPage,
    rootMargin: '0px 0px 20px 0px',
  })

  const [sentryRefTwo] = useInfiniteScroll({
    hasNextPage,
    onLoadMore: getNextBookedSessionsPage,
    rootMargin: '0px 0px 20px 0px',
  })

  // const pageViewUrlTypesToFunction = useCallback(() => {
  //   return {
  //     [bookedPageViewTypes?.booked_sessions]: goToBookaNewSession,
  //     [bookedPageViewTypes?.new]: goToBookedSessions,
  //   }
  // }, [])

  // function setChosenEventId(id) {
  //   const preLink = `${prefixedLinkForNavigates?.[websiteViewType]}/${bookedPageViewTypes?.booked_sessions}`
  //   if (!!id) {
  //     navigate(`${preLink}/${id}`)
  //   }
  // }

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(moment().format('YYYY-MM-DD HH:mm:ss'))
    }, 60000)

    return () => clearInterval(interval)
  }, []) //setting time every minute

  useEffect(() => {
    if (!initialLoading && bookedSlotsData.length === 0) {
      goToBookaNewSession()
    }
  }, [initialLoading, bookedSlotsData])

  // useEffect(() => {
  //   // const pageViewFunction =
  //   //   pageViewUrlTypesToFunction?.[pageView] ||
  //   //   pageViewUrlTypesToFunction[bookedPageViewTypes.booked_sessions]
  //   // pageViewFunction()
  //   // if (
  //   //   !pageView ||
  //   //   pageView !== bookedPageViewTypes?.booked_sessions ||
  //   //   pageView !== bookedPageViewTypes?.new
  //   // ) {
  //   //   if (chosenEventId) {
  //   //     navigate(`${prefixedLinkForNavigates?.[websiteViewType]}/${bookedPageViewTypes?.booked_sessions}/${chosenEventId}`)
  //   //   } else {
  //   //     pageView === bookedPageViewTypes?.booked_sessions ? goToBookedSessions() : goToBookaNewSession()
  //   //   }
  //   // }
  // }, [pageViewUrlTypesToFunction])

  // getting booked sessions for user and paginated loading:

  useEffect(() => {
    Setup()
  }, [])

  return initialLoading ? (
    <LoadingSpinner bgColor="#fff" dark />
  ) : (
    <MySessionsBackground>
      <SecondaryContainer>
        <TopIndexComponent coverFullHeight={isMobile}>
          <div
            style={{
              position: 'sticky',
              top: '0px',
              background: '#7a52b5',
              padding: '40px 0px 10px 0px',
              margin: '0 -1px',
              zIndex: '3',
            }}
          >
            <TopIndexTitle>
              {!Boolean(sessionStorage.getItem('isThisWebviewSession')) && (
                <KeyboardBackspaceIcon
                  onClick={() => navigate('/')}
                  sx={{ fontColor: 25, color: 'white' }}
                />
              )}

              <div>Your Bookings</div>
              <div style={{ minWidth: '24px' }}></div>
            </TopIndexTitle>

            <BookNewSessionButton
              style={{ width: '100%', marginBottom: '10px' }}
              onClick={goToBookaNewSession}
            >
              {myLiveSessionsStrings?.book_a_new_session || 'Book a New Session'}
            </BookNewSessionButton>
          </div>
          <BookedSlotsContainer>
            {isMobile &&
              bookedSlotsData?.map((item, index) => {
                return (
                  <IndexItemContainer
                    eventType={item?.eventType}
                    style={{
                      borderRadius: '7px',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      padding: '15px 25px',
                    }}
                    role={getMyRoleForThisEvent(item, passedUserProfileData)}
                    selected={chosenEventId === item?.id}
                    key={index}
                    onClick={() => setChosenEventId(item?.id)}
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '5px',
                            alignItems: 'center',
                            flexShrink: '0',
                            width: '70px',
                          }}
                        >
                          <StartsInTextOne
                            eventType={item?.eventType}
                            selected={chosenEventId === item?.id}
                          >
                            {checkIfEventUpcoming(item, currentTime) ? 'Starts' : 'Ended'}
                          </StartsInTextOne>
                          <StartsInTextTwo selected={chosenEventId === item?.id}>
                            {checkIfEventUpcoming(item, currentTime)
                              ? moment?.utc(item?.startTime).local()?.format('h:mm A')
                              : moment?.utc(item?.endTime).local()?.format('h:mm A')}
                          </StartsInTextTwo>
                          <StartsInTextTwo selected={chosenEventId === item?.id}>
                            {checkIfEventUpcoming(item, currentTime)
                              ? moment?.utc(item?.startTime).local()?.format('DD, MMM')
                              : moment?.utc(item?.endTime).local()?.format('DD, MMM')}
                          </StartsInTextTwo>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              borderRight: `2px solid ${
                                item?.id === chosenEventId ? '#00000045' : '#ffffff80'
                              }`,
                              height: '90%',
                            }}
                          ></div>
                        </div>
                      </div>
                      <BookedEventsShortDescription
                        style={{
                          padding: '0px 0px 0px 10px',
                        }}
                      >
                        <div
                          style={{
                            fontSize: '16px',
                            textAlign: 'right',
                          }}
                        >
                          <u>
                            {getMyRoleForThisEvent(item, passedUserProfileData) === 'mentor' &&
                              'Mentor for'}
                            {getMyRoleForThisEvent(item, passedUserProfileData) === 'panelist' &&
                              'Panelist for'}
                          </u>
                          {` ${item?.eventName}`}
                        </div>
                        <DetailsButton selected={chosenEventId === item?.id}>
                          {/* <img
                              src={require('src/assets/user_icon.png')}
                              style={{ width: '15px' }}
                              alt="person"
                            /> */}
                          <Person sx={{ color: getTertiaryColor(item?.eventType) }} />
                          {item?.mentorName}
                        </DetailsButton>
                      </BookedEventsShortDescription>
                    </div>
                    <Collapse in={item?.id === chosenEventId} timeout={200}>
                      <EventDetailViewComponent
                        bookedSlotsData={bookedSlotsData}
                        chosenEventId={chosenEventId}
                        currentTime={currentTime}
                        selectedEventData={item}
                        isMobile={isMobile}
                        userProfileData={passedUserProfileData}
                        openedAsWebview={openedAsWebview}
                      />
                    </Collapse>
                  </IndexItemContainer>
                )
              })}
            <SentryRefHandler hasNextPage={hasNextPage} sentryRef={sentryRefTwo} />
          </BookedSlotsContainer>
        </TopIndexComponent>

        <IndexContainer>
          <BackButton className="bg-transparent" onClick={() => navigate('/')}>
            {' '}
            <WestIcon sx={{ fontSize: 22, color: 'white' }} />
            {myLiveSessionsStrings?.home || 'Home'}
          </BackButton>
          <HeadlineOfSession
            style={{ color: '#FFFFFF', textAlign: 'center', width: 'calc(100% - 49px)' }}
          >
            Your Booked Sessions
          </HeadlineOfSession>
          <BookNewSessionButton onClick={goToBookaNewSession}>
            {myLiveSessionsStrings?.book_a_new_session || 'Book a New Session'}
          </BookNewSessionButton>
          <BookedSlotsContainer>
            {bookedSlotsData?.map((item, index) => {
              return (
                <IndexItemContainer
                  eventType={item?.eventType}
                  role={getMyRoleForThisEvent(item, passedUserProfileData)}
                  selected={chosenEventId === item?.id}
                  key={index}
                  onClick={() => setChosenEventId(item?.id)}
                >
                  <div>
                    <u>
                      {getMyRoleForThisEvent(item, passedUserProfileData) === 'mentor' &&
                        'Mentor for'}
                      {getMyRoleForThisEvent(item, passedUserProfileData) === 'panelist' &&
                        'Panelist for'}
                    </u>
                    {` ${item?.eventName}`}
                  </div>
                  <GenericDisplayFlex sb style={{ marginTop: '10px' }}>
                    <div>
                      <StartsInTextOne selected={chosenEventId === item?.id}>
                        {!checkIfEventUpcoming(item, currentTime)
                          ? `${myLiveSessionsStrings?.started || 'Started'}`
                          : `${myLiveSessionsStrings?.starts || 'Starts'}`}
                      </StartsInTextOne>
                      <StartsInTextTwo selected={chosenEventId === item?.id}>
                        {moment?.utc(item?.startTime).local()?.format('MMM DD, YYYY')}
                      </StartsInTextTwo>
                      <StartsInTextTwo selected={chosenEventId === item?.id}>
                        {moment?.utc(item?.startTime).local()?.format('h:mma')}
                      </StartsInTextTwo>
                    </div>
                    <DetailsButton selected={chosenEventId === item?.id}>
                      {/* <img src={userIconBooking} style={{ width: '15px' }} alt="person" /> */}
                      <Person sx={{ color: '#7215d5' }} />
                      {item?.mentorName}
                    </DetailsButton>
                  </GenericDisplayFlex>
                </IndexItemContainer>
              )
            })}

            <SentryRefHandler hasNextPage={hasNextPage} sentryRef={sentryRef} />

            {bookedSlotsData?.length === 0 && (
              <div style={{ color: '#FFF' }}>
                {myLiveSessionsStrings?.no_booked_sessions || 'No Sessions here'}
              </div>
            )}
          </BookedSlotsContainer>
        </IndexContainer>

        {!isMobile && (
          <div style={{ height: '100vh', overflowY: 'auto', width: '100%' }}>
            <EventDetailViewComponent
              bookedSlotsData={bookedSlotsData}
              chosenEventId={chosenEventId}
              currentTime={currentTime}
              selectedEventData={selectedEventData}
              isMobile={isMobile}
              userProfileData={passedUserProfileData}
              openedAsWebview={openedAsWebview}
            />
          </div>
        )}
      </SecondaryContainer>
    </MySessionsBackground>
  )
}

export function BookANewSessionPage({
  openedAsWebview,
  propSetupData: passedSetupData,
  propOneOnOneMentorsData,
}) {
  const navigate = useNavigate()

  if (sessionStorage.getItem('isThisWebviewSession') || openedAsWebview) {
    sessionStorage.setItem('notificationsAreDenied', true)
    sessionStorage.setItem('isThisWebviewSession', true)
  }

  const { localizedData } = useContext(LocalizationContext)
  const myLiveSessionsStrings = localizedData?.strings?.myLiveSessionsPage

  const websiteViewType =
    sessionStorage.getItem('isThisWebviewSession') || openedAsWebview
      ? WEBSITE_VIEW_TYPES.webview
      : WEBSITE_VIEW_TYPES.default

  function goToBookedSessions() {
    navigate(
      `${prefixedLinkForNavigates?.[websiteViewType]}/${bookedPageViewTypes?.booked_sessions}`,
    )
  }

  return (
    <MySessionsBackground>
      <SecondarySisterContainer>
        <BackButton
          dark
          style={{
            backgroundColor: '#624DE3',
            color: 'black',
            borderRadius: '9999px',
            width: '100%',
            backgroundColor: 'transparent',
          }}
        >
          {websiteViewType === WEBSITE_VIEW_TYPES.default && (
            <>
              <u onClick={() => navigate('/')}>{myLiveSessionsStrings?.home || 'Home'}</u>
              <div style={{ marginRight: '10px' }}></div>
            </>
          )}
          <u onClick={goToBookedSessions}>
            {myLiveSessionsStrings?.your_booked_sessions || 'Your Booked Sessions'}
          </u>
        </BackButton>
        <ProfessionalsListPage
          setupData={passedSetupData}
          oneOnOneMentorsData={propOneOnOneMentorsData}
        />
      </SecondarySisterContainer>
    </MySessionsBackground>
  )
}

function EventDetailViewComponent({
  bookedSlotsData,
  chosenEventId: currentEventId,
  currentTime,
  selectedEventData,
  isMobile,
  userProfileData,
  openedAsWebview,
}) {
  const [chosenSlotSavedNotes, setChosenSlotSavedNotes] = useState()
  const [chosenSlotSavedNotesLoading, setChosenSlotSavedNotesLoading] = useState(true)
  const [showPrescription, setShowPrescription] = useState(false)
  const [prescriptionData, setPrescriptionData] = useState(null)
  const [prescriptionLoading, setPrescriptionLoading] = useState(true)
  const [emrSectionsData, setEmrSectionData] = useState(true)

  const { localizedData } = useContext(LocalizationContext)
  const myLiveSessionsStrings = localizedData?.strings?.myLiveSessionsPage

  const chosenEventId = useMemo(() => {
    return currentEventId
  }, [currentEventId])
  const navigate = useNavigate()
  // const sessionDetailsRef = useRef(null)
  const [sessionDetailsRef, shouldScrollTo] = useScrollTo({ offset: 150 })

  const isEventUpcoming = useMemo(() => {
    return (
      checkIfEventUpcoming(selectedEventData, currentTime) ||
      checkIfAllowedToJoinAllDay(selectedEventData)
    )
  }, [selectedEventData, currentTime])

  async function getNotesOfEvent(eventId) {
    setChosenSlotSavedNotesLoading(true)
    const response = await getNotesOfBookedEvent(
      eventId,
      getMyRoleForThisEvent(selectedEventData, userProfileData),
    )
    if (response?.status === 200) {
      setChosenSlotSavedNotes(response?.data)
    }
    setChosenSlotSavedNotesLoading(false)
  }

  const getPrescriptionOfEvent = useCallback(
    async (eventId) => {
      if (selectedEventData?.eventType === 'consultation') {
        setPrescriptionLoading(true)
        const response = await getUserPrescriptionOfEvent(eventId)
        if (response?.status === 200 && isCommonResponseSuccessful(response?.data?.code)) {
          setPrescriptionData(response?.data?.data?.metaPrescription)
          setEmrSectionData(response?.data?.data?.allEmrSections)
        } else {
          setPrescriptionData(null)
        }
        setPrescriptionLoading(false)
      }
    },
    [selectedEventData],
  )

  let meetingPlatformImage = require('src/assets/video_call_icon.png')

  async function Setup() {
    if (!!chosenEventId && selectedEventData?.id === chosenEventId) {
      getNotesOfEvent(chosenEventId)
      getPrescriptionOfEvent(chosenEventId)
      if (sessionDetailsRef?.current) {
        if (isMobile) {
          await sleep(300)
        }
        shouldScrollTo(true)
      }
    }
  }

  useEffect(() => {
    Setup()
  }, [chosenEventId])

  return (
    <DetailsOfSessionContainer ref={sessionDetailsRef}>
      {bookedSlotsData?.length === 0 ? (
        <TextPartDetails>
          <HeadlineOfSession>You do not have any booked events, book one now !</HeadlineOfSession>
        </TextPartDetails>
      ) : (
        bookedSlotsData?.length !== 0 &&
        chosenEventId && (
          <>
            <TextPartDetails>
              {!isMobile && (
                <HeadlineOfSession>
                  {selectedEventData?.eventName}
                  {selectedEventData?.appointmentNumber &&
                    `(${selectedEventData?.appointmentNumber})`}
                </HeadlineOfSession>
              )}
              {isEventUpcoming && (
                <SubheadingOfSession>
                  {myLiveSessionsStrings?.starts || 'Starts at'}{' '}
                  {moment?.utc(selectedEventData?.startTime).local()?.format('MMM DD, YYYY h:mma')}{' '}
                  ({selectedEventData?.eventDuration} mins)
                </SubheadingOfSession>
              )}
              {!isEventUpcoming && (
                <SubheadingOfSession>
                  {myLiveSessionsStrings?.started || 'Started at'}{' '}
                  {moment?.utc(selectedEventData?.startTime).local()?.format('MMM DD, YYYY h:mma')}{' '}
                  ({selectedEventData?.eventDuration} mins)
                </SubheadingOfSession>
              )}
              <DescriptionOfSession>{selectedEventData?.eventDescription}</DescriptionOfSession>

              {moment?.(currentTime)?.isSameOrAfter(
                moment?.utc(selectedEventData?.startTime).local(),
              ) ? (
                <MeetingDetails>
                  {(isEventUpcoming || selectedEventData?.eventType === 'consultation') && (
                    <>
                      <MeetingButtonsContainer>
                        <JoinMeetingButton
                          onClick={() => {
                            if (sessionStorage.getItem('isThisWebviewSession') || openedAsWebview) {
                              passDataToAndroidAndIos(selectedEventData)
                              // navigate(`/webview/l/${selectedEventData?.slug}`)
                            } else {
                              navigate(`/l/${selectedEventData?.slug}`)
                            }
                          }}
                        >
                          Join on
                          <MeetingPlatformImage>
                            <img
                              alt="platformicon"
                              style={{ width: '32px', height: '32px' }}
                              src={meetingPlatformImage}
                            />
                          </MeetingPlatformImage>
                        </JoinMeetingButton>
                      </MeetingButtonsContainer>
                    </>
                  )}
                </MeetingDetails>
              ) : (
                <MeetingDetails>
                  <MeetingButtonsContainer>
                    {canEventBeJoined(selectedEventData, currentTime) ||
                    checkIfAllowedToJoinAllDay(selectedEventData) ? (
                      <JoinMeetingButton
                        active={true}
                        onClick={() => {
                          if (sessionStorage.getItem('isThisWebviewSession') || openedAsWebview) {
                            passDataToAndroidAndIos(selectedEventData)
                            // navigate(`/webview/l/${selectedEventData?.slug}`)
                          } else {
                            navigate(`/l/${selectedEventData?.slug}`)
                          }
                        }}
                      >
                        Join and Wait
                        <MeetingPlatformImage>
                          <img
                            alt="platformicon"
                            style={{ width: '32px', height: '32px' }}
                            src={meetingPlatformImage}
                          />
                        </MeetingPlatformImage>
                      </JoinMeetingButton>
                    ) : (
                      <JoinMeetingButton>
                        {myLiveSessionsStrings?.starts || 'Starts in'}{' '}
                        {formatTime(
                          moment
                            ?.utc(selectedEventData?.startTime)
                            .local()
                            ?.diff(moment?.(currentTime), 'seconds'),
                          true,
                        )}
                        <MeetingPlatformImage>
                          <img
                            alt="platformicon"
                            style={{ width: '32px', height: '32px' }}
                            src={meetingPlatformImage}
                          />
                        </MeetingPlatformImage>
                      </JoinMeetingButton>
                    )}
                  </MeetingButtonsContainer>
                </MeetingDetails>
              )}

              {!Boolean(sessionStorage.getItem('isThisWebviewSession') || openedAsWebview) &&
                moment?.(currentTime)?.isSameOrBefore(
                  moment?.utc(selectedEventData?.endTime).local(),
                ) && (
                  <>
                    <div style={{ marginTop: '10px', display: 'flex' }}>
                      <AddToGoogleCalendarButton
                        name={`${selectedEventData?.eventName} with ${selectedEventData?.mentorName}`}
                        description={`<p>Session Mode : ${
                          platformOptions.find((item) => item?.id === selectedEventData?.meetingSrc)
                            ?.name
                        }</p> <p>${
                          selectedEventData?.meetingSrc === 'aumhum' &&
                          `Joining Link: <a href=${APP_CONSTANTS.LIVE_APP_URL}/l/${selectedEventData?.slug}>${APP_CONSTANTS.LIVE_APP_URL}/l/${selectedEventData?.slug}</a>`
                        }</p> <p>Session Description: ${selectedEventData?.eventDescription}</p> `}
                        utcStartDateTime={selectedEventData?.startTime}
                        utcEndDateTime={selectedEventData?.endTime}
                      />
                    </div>
                  </>
                )}

              {!isEventUpcoming && (
                <>
                  <SubheadingOfSession>
                    {myLiveSessionsStrings?.your_notes || 'Your Notes'}
                  </SubheadingOfSession>
                  <div>
                    {chosenSlotSavedNotesLoading ? (
                      <CircularProgress sx={{ color: '#222222' }} size={35} />
                    ) : chosenSlotSavedNotes?.content ? (
                      chosenSlotSavedNotes?.content
                    ) : (
                      `${myLiveSessionsStrings?.no_notes || 'No Notes'}`
                    )}
                  </div>
                  {selectedEventData?.eventType === 'consultation' &&
                    !prescriptionLoading &&
                    Boolean(prescriptionData) && (
                      <>
                        <SubheadingOfSession
                          style={{ cursor: 'pointer' }}
                          onClick={() => setShowPrescription((prev) => !prev)}
                        >
                          <u>
                            {showPrescription
                              ? 'Your Prescription:'
                              : 'Click here to see prescription'}
                          </u>
                        </SubheadingOfSession>
                        {showPrescription && (
                          <div>
                            {prescriptionLoading ? (
                              <CircularProgress sx={{ color: '#222222' }} size={35} />
                            ) : (
                              <div>
                                <button
                                  onClick={() =>
                                    navigate(`/session/${selectedEventData?.id}/prescription`)
                                  }
                                >
                                  Print
                                </button>
                                <ViewOnlyPrescription
                                  prescriptionData={prescriptionData}
                                  emrSections={emrSectionsData}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                </>
              )}

              <ChatTranscriptViewComponent
                key={selectedEventData?.id}
                selectedEventData={selectedEventData}
                userProfileData={userProfileData}
                thisUserRoleInEvent={getMyRoleForThisEvent(selectedEventData, userProfileData)}
              />
            </TextPartDetails>

            <MentorPartDetails>
              <YourMentorText>{myLiveSessionsStrings?.your_mentor || 'Your Mentor'}</YourMentorText>
              <MentorPictureAndName>
                <PictureAndName>
                  <MentorImage src={getImageLink(selectedEventData?.mentorProfilePic)} />
                  <NameAndSubtext>
                    <MentorName>{selectedEventData?.mentorName}</MentorName>
                  </NameAndSubtext>
                </PictureAndName>

                {/* <DetailsIconBackground>
                                        <img src={require("src/assets/details_icon.png")} />
                                    </DetailsIconBackground>

                                    <DetailsButtonContainer>
                                        <img src={require("src/assets/details_icon.png")} />
                                        About the Mentor
                                    </DetailsButtonContainer> */}
              </MentorPictureAndName>
            </MentorPartDetails>

            {(getMyRoleForThisEvent(selectedEventData, userProfileData) === 'mentor' ||
              getMyRoleForThisEvent(selectedEventData, userProfileData) === 'panelist') && (
              <MentorPartDetails>
                <YourMentorText>Booked By</YourMentorText>
                <MentorName>{selectedEventData?.userName}</MentorName>
              </MentorPartDetails>
            )}
          </>
        )
      )}
    </DetailsOfSessionContainer>
  )
}

// extra functions:

function passDataToAndroidAndIos(bookedEventData) {
  console.log('passing data to Android or IOS')
  try {
    console.log('trying IOS...')
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.iosBridge) {
      window.webkit.messageHandlers.iosBridge.postMessage({
        method: 'setEventDetailData',
        data: bookedEventData,
      })
    }
  } catch (e) {
    console.error('Got error in calling iOS interface method:', e)
  }

  try {
    console.log('trying Android...')
    if (window.Android && typeof window.Android.setEventDetailData === 'function') {
      window.Android.setEventDetailData(JSON.stringify(bookedEventData))
    }
  } catch (error) {
    console.error('Got error in calling Android interface method:', error)
  }
}

// extra components :

export const ViewOnlyPrescription = ({
  prescriptionData,
  printableOnlyWhenPressingOnPrintButton = false,
  printType = PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterData,
  allVitalsOfThisPatient,
  setIsPrintingPastHistoryReports = () => {},
  hideDownloadButton = false,
  emrSections,
  showHeading = false,
  headingText = 'Prescription',
  prescriptionViewSettings,
}) => {
  const [isPrinting, setIsPrinting] = useState(false)

  const [saving, setSaving] = useState(false)

  async function printTherapy() {
    setIsPrinting(true)
    setIsPrintingPastHistoryReports(true)
    await sleep(1000)
    window.print()
    setIsPrintingPastHistoryReports(false)
    setIsPrinting(false)
  }

  const printingClasses = useMemo(() => {
    if (!printableOnlyWhenPressingOnPrintButton) {
      return ''
    }
    if (isPrinting) {
      return ''
    }
    return 'hide-on-print-only'
  }, [isPrinting, printableOnlyWhenPressingOnPrintButton])

  return (
    <>
      {showHeading === true && (
        <div className="d-flex align-items-center gap-2 mb-2 mt-2 hide-on-print-only">
          <u>{headingText}</u>
          {!hideDownloadButton && (
            <Button
              variant="contained"
              startIcon={<Print />}
              sx={{ padding: '5px 10px', minWidth: '0px' }}
              size="small"
              // onMouseEnter={() => {
              //   setHoverButton(true)
              // }}
              // onMouseDown={() => {
              //   setHoverButton(true)
              // }}
              onClick={() => {
                printTherapy()
              }}
            >
              Print {saving && <CircularProgress size="16px" sx={{ ml: 1 }} color="whiteColor" />}
            </Button>
          )}
        </div>
      )}
      <div className={`${printingClasses} w-100`}>
        <ViewOnlyPrescriptionV2
          prescriptionData={prescriptionData}
          useOldUI
          allVitalsOfThisPatient={allVitalsOfThisPatient}
          prescriptionViewSettings={prescriptionViewSettings}
          oldUIDrugSubSections={
            emrSections?.find((item) => item?.id === 'drug_advice')?.subSections
          }
        />
      </div>
    </>
  )
}

{
  /* <PMPParent>
          <PrescriptionHeader
            prescriptionData={prescriptionData}
            viewingType={true}
            printType={printType}
            leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
          />
          <PmpBodyContentContainer>
            <PmpPatientDetails
              prescriptionData={prescriptionData}
              leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
            />
            <PmpContentContainer>
              <div style={{ flex: 1, padding: '10px 20px' }}>
                <PrescriptionBodyFirstSection
                  selectedObservations={getInitialDataForSettingExistingSelectedObservations(
                    prescriptionData?.prescriptionData?.symptomsAndObservations,
                  )}
                  selectedComplaints={getInitialDataForSettingExistingSelectedComplaints(
                    prescriptionData?.prescriptionData?.complaints,
                  )}
                  selectedDiagnosis={getInitialDataForSettingExistingSelectedDiagnosis(
                    prescriptionData?.prescriptionData?.diagnosis,
                  )}
                />
                <PrescriptionDrugAdviceTable
                  prescriptionData={prescriptionData?.prescriptionData}
                  mentorId={prescriptionData?.prescriptionData?.mentorId}
                  drugsAdvice={getInitialDataForSettingExistingDrugAdvice(
                    prescriptionData?.prescriptionData?.drugInfoList,
                  )}
                  viewingType={true}
                  prescriptionViewSettings={prescriptionData?.prescriptionViewSettings}
                  drugAdviceSubSections={
                    emrSections?.find((item) => item?.id === 'drug_advice')?.subSections
                  }
                />
                <PrescriptionBody
                  value=""
                  setValue={() => null}
                  placeholder=""
                  alsoChangePropValue={false}
                  setPropValue={() => null}
                  hidePatientDetails
                  selectedAdvice={getInitialDataForSettingExistingSelectedAdvice(
                    prescriptionData?.prescriptionData?.guidanceAndAdvices,
                  )}
                  selectedTherapies={getInitialDataForSettingExistingSelectedTherapies(
                    prescriptionData?.prescriptionData?.therapiesNeeded,
                  )}
                  selectedAssessments={getInitialDataForSettingExistingHealthEntity(
                    prescriptionData?.prescriptionData?.psychAssessments,
                  )}
                  nextVisitInputType="days"
                  nextVisitInputValue={getInitialDataForSettingDaysOfNextVisit(
                    prescriptionData?.prescriptionData?.nextVisitDate,
                    prescriptionData?.prescriptionData?.createdTime,
                  )}
                  prescriptionData={prescriptionData}
                  viewingType={true}
                  selectedInvestigations={getInitialDataForSettingExistingSelectedInvestigations(
                    prescriptionData?.prescriptionData?.investigations,
                  )}
                  selectedInstructions={getInitialDataForSettingExistingSelectedInstructions(
                    prescriptionData?.prescriptionData?.instructions,
                  )}
                  prescriptionViewSettings={prescriptionData?.prescriptionViewSettings}
                />
              </div>
            </PmpContentContainer>
          </PmpBodyContentContainer>
          <PrescriptionFooter
            prescriptionData={prescriptionData}
            leaveSpace={printType === PRESCRIPTION_PRINT_TYPES.withHeaderAndFooterBlankSpace}
          />
        </PMPParent> */
}

export const ViewOnlyPrescriptionVarTwo = ({ prescriptionData, emrSections }) => {
  const [useJointHomunculusInterface, setUseJointHomunculusInterface] = useState(false)
  const [jointHomunculusData, setJointHomunculusData] = useState(null)

  useEffect(() => {
    if (prescriptionData?.prescriptionData?.jointHomunculusData) {
      setUseJointHomunculusInterface(true)
      setJointHomunculusData(prescriptionData?.prescriptionData?.jointHomunculusData)
    }
  }, [])

  return (
    <PMPParentVarTwo>
      <div style={{ padding: '25px 5px' }}>Date : {getDateOfPrescription(prescriptionData)}</div>
      <div style={{ flex: 1, padding: '5px', borderLeft: '1px solid #000' }}>
        <PrescriptionBodyFirstSection
          selectedObservations={getInitialDataForSettingExistingSelectedObservations(
            prescriptionData?.prescriptionData?.symptomsAndObservations,
          )}
          selectedComplaints={getInitialDataForSettingExistingSelectedComplaints(
            prescriptionData?.prescriptionData?.complaints,
          )}
          selectedDiagnosis={getInitialDataForSettingExistingSelectedDiagnosis(
            prescriptionData?.prescriptionData?.diagnosis,
          )}
        />
        <PrescriptionDrugAdviceTable
          prescriptionData={prescriptionData?.prescriptionData}
          mentorId={prescriptionData?.prescriptionData?.mentorId}
          drugsAdvice={getInitialDataForSettingExistingDrugAdvice(
            prescriptionData?.prescriptionData?.drugInfoList,
          )}
          viewingType={true}
          drugAdviceSubSections={
            emrSections?.find((item) => item?.id === 'drug_advice')?.subSections
          }
        />
        <PrescriptionBody
          value=""
          setValue={() => null}
          placeholder=""
          alsoChangePropValue={false}
          setPropValue={() => null}
          selectedAdvice={getInitialDataForSettingExistingSelectedAdvice(
            prescriptionData?.prescriptionData?.guidanceAndAdvices,
          )}
          selectedTherapies={getInitialDataForSettingExistingSelectedTherapies(
            prescriptionData?.prescriptionData?.therapiesNeeded,
          )}
          nextVisitInputType="days"
          nextVisitInputValue={getInitialDataForSettingDaysOfNextVisit(
            prescriptionData?.prescriptionData?.nextVisitDate,
            prescriptionData?.prescriptionData?.createdTime,
          )}
          prescriptionData={prescriptionData}
          viewingType={true}
          selectedInvestigations={getInitialDataForSettingExistingSelectedInvestigations(
            prescriptionData?.prescriptionData?.investigations,
          )}
          useJointHomunculusInterface={useJointHomunculusInterface}
          jointHomunculusData={jointHomunculusData}
          selectedInstructions={getInitialDataForSettingExistingSelectedInstructions(
            prescriptionData?.prescriptionData?.instructions,
          )}
        />
        {!Boolean(
          prescriptionData?.prescriptionData?.symptomsAndObservations ||
            prescriptionData?.prescriptionData?.guidanceAndAdvices ||
            prescriptionData?.prescriptionData?.complaints ||
            prescriptionData?.prescriptionData?.diagnosis ||
            prescriptionData?.prescriptionData?.drugInfoList ||
            prescriptionData?.prescriptionData?.therapiesNeeded ||
            prescriptionData?.prescriptionData?.nextVisitDate,
        ) && <div style={{ marginTop: '-20px' }}>No data available for the visit</div>}
      </div>
    </PMPParentVarTwo>
  )
}

const ChatTranscriptViewComponent = ({
  selectedEventData,
  thisUserRoleInEvent,
  userProfileData,
}) => {
  const [loadingChats, setLoadingChats] = useState(false)
  const [detailsOfAllPeopleFromAgora, setDetailsOfAllPeopleFromAgora] = useState()
  const [arrayOfAllMessages, setArrayOfAllMessages] = useState([])

  const [otherPersonUserId, setOtherPersonUserId] = useState('')

  const agoraChatServerConnection = useSelector(selectAgoraChatServerConnection)
  const agoraChatId = useSelector(selectAgoraChatId)
  const agoraChatToken = useSelector(selectAgoraChatToken)
  const dispatch = useDispatch()
  const agoraAppKey = process.env.REACT_APP_AGORA_CHAT_APPKEY

  async function getAndSetUserIds() {
    if (thisUserRoleInEvent === 'user') {
      // get mentor's user id ->
      const peopleInformationForUserResponse = await getDataWithParams(
        '/api/secure/live/participants/profile-summary',
        { eventId: selectedEventData?.id },
      )
      if (
        peopleInformationForUserResponse?.status === 200 &&
        isCommonResponseSuccessful(peopleInformationForUserResponse?.data?.code)
      ) {
        setOtherPersonUserId(
          peopleInformationForUserResponse?.data?.data?.mentorList
            ?.find((item) => item?.id === selectedEventData?.mentorId)
            ?.userId?.toLowerCase(),
        )
      }
    } else if (thisUserRoleInEvent === 'mentor') {
      setOtherPersonUserId(selectedEventData?.userId?.toLowerCase())
    }
  }
  useEffect(() => {
    getAndSetUserIds()
  }, [])

  // register this user and get other user data
  async function giveOwnDataGetOthersData() {
    let options = {
      nickname: agoraChatId,
      avatarurl: userProfileData?.profilePicId
        ? getImageLink(userProfileData?.profilePicId)
        : undefined,
    }
    await agoraChatServerConnection.updateUserInfo(options).then((res) => {
      console.log(res)
    })
    const otherUserData = await agoraChatServerConnection.fetchUserInfoById(agoraChatId, [
      'nickname',
      'avatarurl',
    ])
    const detailsOfPeople = [
      {
        id: agoraChatId,
        avatarurl: userProfileData?.profilePicId
          ? getImageLink(userProfileData?.profilePicId)
          : undefined,
        nickname: userProfileData?.name || agoraChatId,
      },
      { id: otherPersonUserId, ...otherUserData?.data?.[otherPersonUserId] },
    ]
    setDetailsOfAllPeopleFromAgora(detailsOfPeople)
  }
  useEffect(() => {
    if (agoraChatServerConnection) {
      giveOwnDataGetOthersData()
    }
  }, [agoraChatServerConnection])

  // get this chat's historical data
  async function getMoreHistoricalData() {
    setLoadingChats(true)
    if (agoraChatServerConnection) {
      agoraChatServerConnection
        .getHistoryMessages({
          targetId: otherPersonUserId, // The user ID of the peer user for one-to-one chat or group ID for group chat.
          chatType: 'singleChat', // The chat type: `singleChat` for one-to-one chat or `groupChat` for group chat.
          pageSize: 50, // The number of messages to retrieve per page. The value range is [1,50] and the default value is 20.
          searchDirection: 'up', // The message search direction: `up` means to retrieve messages in the descending order of the message timestamp and `down` means to retrieve messages in the ascending order of the message timestamp.
          searchOptions: {
            // from: 'message sender userID', // The user ID of the message sender. This parameter is used only for group chat.
            msgTypes: [], // An array of message types like 'txt' for query. If no value is passed in, all types of message will be queried.
            startTime: moment().subtract(7, 'days').startOf('day').toDate().getTime(), // The start timestamp for query. The unit is millisecond.
            endTime: moment().toDate().getTime(), // The end timestamp for query. The unit is millisecond.
          },
        })
        .then((res) => {
          console.log(res)
          if (Array.isArray(res?.messages) && res?.messages?.length > 0) {
            setArrayOfAllMessages((prev) => [...prev, ...res?.messages])
          }
        })
        .catch((error) => console.log(error))
    }
    setLoadingChats(false)
  }
  useEffect(() => {
    getMoreHistoricalData()
  }, [agoraChatServerConnection])

  // auto scroll to bottom on new message addition to array
  useEffect(() => {
    const divToScroll = document.getElementById('chatbox')
    if (divToScroll) {
      divToScroll.scrollBy({
        top: divToScroll.scrollHeight,
        behavior: 'smooth',
      })
    }
  }, [arrayOfAllMessages])

  // ALL IMPORTANT CONNECTION MAKING AND REDUX STUFF
  useEffect(() => {
    // make a connection to agora only when needed (when there are contacts) and store the connection in redux

    // 1. check if redux already data - conn, userid, token
    if (agoraChatServerConnection && agoraChatId && agoraChatToken) {
      console.log('there is data')
    } else {
      // 2. call the function that does this
      makeAndStoreAgoraConnectionInRedux(dispatch, agoraAppKey, userProfileData?.id?.toLowerCase())
    }
  }, [agoraChatServerConnection, agoraChatId, agoraChatToken, userProfileData])

  let heading
  if (thisUserRoleInEvent === 'mentor') {
    heading = 'Your chats with this user'
  } else if (thisUserRoleInEvent === 'user') {
    heading = 'Your chats with this mentor'
  }
  if (thisUserRoleInEvent === 'panelist') {
    return <></>
  }

  // ultimately use twopeoplechatui here. after it starts taking almost all things from outside
  return (
    <div>
      <SubheadingOfSession>{heading}</SubheadingOfSession>
      {agoraChatServerConnection ? (
        <ChatContainer id="chatbox" style={{ maxHeight: '500px', maxWidth: '300px' }}>
          {arrayOfAllMessages?.length > 0 ? (
            prepareMessagesArray(arrayOfAllMessages)?.map((item, index) => {
              if (item?.renderType === 'date') {
                return <ChatBoxDateComponent key={index} time={item?.time} />
              } else if (item?.from === otherPersonUserId && item?.to === agoraChatId) {
                return (
                  <ChatMessageOtherUserWrapper
                    key={index}
                    showTime={true}
                    image={
                      detailsOfAllPeopleFromAgora?.find((item) => item?.id === otherPersonUserId)
                        ?.avatarurl
                    }
                    name={
                      detailsOfAllPeopleFromAgora?.find((item) => item?.id === otherPersonUserId)
                        ?.nickname || 'User'
                    }
                    chatMessageItem={item}
                  />
                )
              } else if (item?.from === agoraChatId) {
                return (
                  <ChatMessageThisUserWrapper
                    key={index}
                    showTime={true}
                    image={
                      detailsOfAllPeopleFromAgora?.find((item) => item?.id === agoraChatId)
                        ?.avatarurl
                    }
                    name={
                      detailsOfAllPeopleFromAgora?.find((item) => item?.id === agoraChatId)
                        ?.nickname || 'User'
                    }
                    chatMessageItem={item}
                  />
                )
              } else {
                console.log('no match')
                return <></>
              }
            })
          ) : (
            <div style={{ margin: 'auto' }}>
              {loadingChats ? (
                <Spinner animation="border" variant="light" size="md" />
              ) : (
                'No messages here'
              )}
            </div>
          )}
        </ChatContainer>
      ) : (
        'Loading...'
      )}
    </div>
  )
}

import { createContext } from 'react'

export const ClinicDataContext = createContext(null)

export function ClinicDataProvider({
  chosenRoleFlow,
  ownerData,
  assistantData,
  refreshOwnerAssociatedClinicsData,
  refreshOwnerAssociatedClinicBrandsData,
  refreshOwnerAssociatedClinicChambersData,
  children,
}) {
  console.log({ ownerData, assistantData })

  return (
    <ClinicDataContext.Provider
      value={{
        chosenRoleFlow,
        ownerData,
        assistantData,
        refreshOwnerAssociatedClinicsData,
        refreshOwnerAssociatedClinicBrandsData,
        refreshOwnerAssociatedClinicChambersData,
      }}
    >
      {children}
    </ClinicDataContext.Provider>
  )
}

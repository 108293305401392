import { DosagePillItem } from '../drugAdvice/components/styledComponents'

export default function PrescriptionPillOptionsInputComponent({
  value,
  options,
  onChange,
  children,
  getOptionValue = (option) => {
    return option?.value
  },
  getOptionLabel = (option) => {
    return option?.label
  },
  pillUnselectedBackground,
}) {
  return (
    <div className="d-flex flex-wrap gap-1 ">
      {options?.map((doseOption, idx) => {
        return (
          <DosagePillItem
            pillUnselectedBackground={pillUnselectedBackground}
            onClick={() => {
              onChange(doseOption)
            }}
            selected={value === getOptionValue(doseOption)}
            key={idx}
          >
            {getOptionLabel(doseOption)}
          </DosagePillItem>
        )
      })}
      {children}
    </div>
  )
}

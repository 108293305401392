import React from 'react'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { PMPBodySectionItems } from './styledComponent'

export default function PrintablePrescriptionAdvice({
  prescriptionAdviceRef,
  debug,
  selectedAdvice,
  debugHighlightStates,
}) {
  return (
    <PMPBodySectionItems
      ref={prescriptionAdviceRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.advice.color}
      debugHighlight={debugHighlightStates?.prescriptionAdvice}
    >
      <strong>Advice</strong>
      <div style={{ whiteSpace: 'pre-wrap' }}>
        {selectedAdvice?.map((item) => item?.label)?.join(', ')}
      </div>
    </PMPBodySectionItems>
  )
}

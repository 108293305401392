import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'
import { PMPBodySectionItems } from './styledComponent'

export default function PrintableAssessments({
  assessmentsRef,
  debug,
  selectedAssessments,
  debugHighlightStates,
  noPadding = false,
}) {
  return (
    <PMPBodySectionItems
      ref={assessmentsRef}
      debug={debug}
      noPadding={noPadding}
      debugColor={PRESCRIPTION_COLORS_MAP.selectedTherapy.color}
      debugHighlight={debugHighlightStates?.assessments}
    >
      <strong>Psych Assessments Needed</strong>
      <div>{selectedAssessments.map((assessmentItem) => assessmentItem.label).join(', ')}</div>
    </PMPBodySectionItems>
  )
}

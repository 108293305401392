import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedTherapist: null,
}

const supportTherapistsSlice = createSlice({
  name: 'supportTherapists',
  initialState: initialState,
  reducers: {
    setSelectedTherapist(state, action) {
      console.log({ action })

      return { ...state, categoriesList: action.payload }
    },
  },
})

export const { setSelectedTherapist } = supportTherapistsSlice.actions
export const supportTherapistsReducer = supportTherapistsSlice.reducer

function getSupportDataStore(state) {
  return state.supportTherapists
}

export const selectSupportTherapist = (state) => {
  return getSupportDataStore(state).selectedTherapist
}

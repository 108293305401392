import { INVESTIGATION_OBJECT } from 'src/clinic/components/InvestigationsForm/InvestigationsForm'
import { PRESCRIPTION_BUILDER_VIEW_TYPES } from 'src/consumer/constants'
import {
  commonStylesPrescriptionSelect,
  loadOptionsInvestigations,
} from '../../PrescriptionMakingPageComponents'
import { InvestigationsCreatableSelect } from '../../PrescriptionMakingPageStyles'
import PrescriptionTableViewInputComponent from '../common/PrescriptionTableViewInputComponent'
import InvestigationDateComponent from './components/InvestigationDateComponent'
import { handleChangeHealthEntityInputType1, handleKeyDownAndSupportEdit } from '../common/helpers'
import { useState } from 'react'

export const InvestigationsSelectMenu = ({
  clinicId,
  clinicBrandId,
  selectedInvestigations,
  finalRequestedTestsBy,
  setFinalRequestedTestsBy,
  setSelectedInvestigations,
  recommendedInvestigationIds,
  onCreateInvestigation,
  prescriptionViewSettings,
}) => {
  console.log({ selectedInvestigations })

  switch (prescriptionViewSettings?.prescriptionBuilderViewType) {
    case PRESCRIPTION_BUILDER_VIEW_TYPES.tabletView:
      return (
        <>
          <PrescriptionTableViewInputComponent
            value={selectedInvestigations}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            getOptionsResponse={loadOptionsInvestigations}
            searchPlaceholder="Investigations"
            title="Investigations"
            onChange={(val) => setSelectedInvestigations(val)}
            onCreate={onCreateInvestigation}
            stickyHeader
            prescriptionViewSettings={prescriptionViewSettings}
            debounceTimeout={200}
            additional={{
              page: 1,
              clinicId: clinicId,
              clinicBrandId: clinicBrandId,
              recommendedInvestigationIds: recommendedInvestigationIds,
              prescriptionViewSettings,
            }}
            useFullPageSearch={true}
          />
        </>
      )
    case PRESCRIPTION_BUILDER_VIEW_TYPES.modern:
      return (
        <>
          <PrescriptionTableViewInputComponent
            value={selectedInvestigations}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            getOptionsResponse={loadOptionsInvestigations}
            searchPlaceholder="Investigations"
            onChange={(val) => setSelectedInvestigations(val)}
            onCreate={onCreateInvestigation}
            prescriptionViewSettings={prescriptionViewSettings}
            debounceTimeout={200}
            additional={{
              page: 1,
              clinicId: clinicId,
              clinicBrandId: clinicBrandId,
              recommendedInvestigationIds: recommendedInvestigationIds,
              prescriptionViewSettings,
            }}
          />
        </>
      )
    default:
      return (
        <div className="w-100">
          <MinimalInvestigationSelect
            clinicBrandId={clinicBrandId}
            clinicId={clinicId}
            selectedInvestigations={selectedInvestigations}
            finalRequestedTestsBy={finalRequestedTestsBy}
            setFinalRequestedTestsBy={setFinalRequestedTestsBy}
            setSelectedInvestigations={setSelectedInvestigations}
            recommendedInvestigationIds={recommendedInvestigationIds}
            prescriptionViewSettings={prescriptionViewSettings}
          />
          {/* <ButtonSCNContainer
            style={{ marginTop: '8px', fontSize: '12px' }}
            onClick={onCreateInvestigation}
          >
            Add New Test
          </ButtonSCNContainer> */}
        </div>
      )
  }
}

export function MinimalInvestigationSelect({
  clinicId,
  clinicBrandId,
  selectedInvestigations,
  setSelectedInvestigations,
  recommendedInvestigationIds = [],
  prescriptionViewSettings,
  finalRequestedTestsBy,
  setFinalRequestedTestsBy,
}) {
  const [inputValue, setInputValue] = useState('')

  return (
    <>
      <div style={{ width: '100%' }} className="d-flex align-items-end gap-2">
        <InvestigationsCreatableSelect
          id="InvestigationsSelection"
          placeholder="Tests"
          isMulti={true}
          closeMenuOnSelect={false}
          createOptionPosition="first"
          debounceTimeout={500}
          loadOptions={loadOptionsInvestigations}
          additional={{
            page: 1,
            clinicId: clinicId,
            clinicBrandId: clinicBrandId,
            recommendedInvestigationIds,
            prescriptionViewSettings,
          }}
          styles={commonStylesPrescriptionSelect}
          isClearable={true}
          isSearchable={true}
          onCreateOption={(inputValue) => {
            const newOption = {
              ...INVESTIGATION_OBJECT,
              value: inputValue,
              label: inputValue,
              __isNew__: true, // Custom flag
            }

            setSelectedInvestigations((prev) => {
              const updatedOptions = [...(prev || []), newOption]
              return updatedOptions
            })
          }}
          defaultOptions={false}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={selectedInvestigations}
          onChange={(value) => {
            setSelectedInvestigations(value)
          }}
          inputValue={inputValue}
          onInputChange={(a, b) => {
            setInputValue((prev) => {
              if (prev === a) {
                return prev
              }
              return a || ''
            })
            handleChangeHealthEntityInputType1(a, b, setSelectedInvestigations)
          }}
          onKeyDown={(e) =>
            handleKeyDownAndSupportEdit(
              e,
              inputValue,
              selectedInvestigations,
              setSelectedInvestigations,
              setInputValue,
            )
          }
        />
        {/* finishRequestedTestsBy */}
        <InvestigationDateComponent
          finalRequestedTestsBy={finalRequestedTestsBy}
          setFinalRequestedTestsBy={setFinalRequestedTestsBy}
        />
      </div>
    </>
  )
}

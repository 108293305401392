let audioQueue = []

export function queueAudioAndSpeech(audioPath, ssmlText, utteranceLang = 'en-IN') {
  if (!audioPath || typeof audioPath !== 'string' || !ssmlText || typeof ssmlText !== 'string') {
    console.error('Invalid audio or text provided.')
    return
  }

  // Add the task to the queue
  audioQueue.push({ audioPath, ssmlText, utteranceLang })

  // If the queue was empty, start processing the queue
  if (audioQueue.length === 1) {
    processQueue()
  }
}

function processQueue() {
  if (audioQueue.length === 0) {
    console.log('All tasks in the queue have been processed.')
    return
  }

  // Get the first task in the queue
  const { audioPath, ssmlText, utteranceLang } = audioQueue[0]

  // Play the audio and speak sequentially
  playAudioAndSpeak(audioPath, ssmlText, utteranceLang)
    .then(() => {
      // Task processed successfully, remove it from the queue
      audioQueue.shift()
      // Process the next task in the queue
      processQueue()
    })
    .catch((error) => {
      // If there is an error, log it but continue processing the queue
      console.error('Error processing the task:', error)
      // Remove the task from the queue even if it failed
      audioQueue.shift()
      // Process the next task in the queue
      processQueue()
    })
}

function playAudioAndSpeak(audioPath, ssmlText, utteranceLang = 'en-IN') {
  return new Promise((resolve, reject) => {
    const audio = new Audio(audioPath)

    audio.play()

    audio.onerror = (event) => {
      speakText(ssmlText, utteranceLang)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject('Speech synthesis failed')
        })
    }

    audio.onended = () => {
      speakText(ssmlText, utteranceLang)
        .then(() => {
          resolve()
        })
        .catch((error) => {
          reject('Speech synthesis failed')
        })
    }
  })
}

export function speakText(ssmlText, utteranceLang = 'en-IN') {
  return new Promise((resolve, reject) => {
    console.log("Starting speakText function...");
    
    // Check if the input text is valid
    if (!ssmlText || typeof ssmlText !== 'string') {
      console.error('Invalid text provided for speech synthesis.');
      reject('Invalid text');
      return;
    }
    console.log("Valid text provided:", ssmlText);

    try {
      // Check if speech synthesis is supported in the browser
      if (!('speechSynthesis' in window)) {
        console.error('Speech synthesis is not supported in this browser.');
        reject('Speech synthesis not supported');
        return;
      }
      console.log("Speech synthesis is supported.");

      // Create a SpeechSynthesisUtterance object
      const utterance = new SpeechSynthesisUtterance(ssmlText);

      // Set properties for speech synthesis
      utterance.lang = utteranceLang; // Language code
      utterance.pitch = 1; // Voice pitch (0 to 2, default is 1)
      utterance.rate = 0.9; // Speaking rate (0.1 to 10, default is 1)
      utterance.volume = 1; // Volume (0 to 1, default is 1)
      console.log("Speech utterance properties set:", {
        lang: utterance.lang,
        pitch: utterance.pitch,
        rate: utterance.rate,
        volume: utterance.volume
      });

      // Handle events
      utterance.onstart = () => {
        console.log("Speech synthesis started.");
      };
      
      utterance.onend = () => {
        console.log("Speech completed.");
        resolve();
      };
      
      utterance.onerror = (event) => {
        console.error("Speech synthesis error occurred:", event);
        reject('Speech synthesis error');
      };

      // Start the speech synthesis
      console.log("Starting speech synthesis...");
      window.speechSynthesis.speak(utterance);
      
    } catch (error) {
      console.error('Error during speech synthesis:', error);
      reject('Error during speech synthesis');
    }
  });
}

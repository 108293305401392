import React, { useMemo } from 'react'
import { PRESCRIPTION_LAYOUTS } from 'src/consumer/constants'
import { ThreeColumnNextVisitRowComponent } from '../../../prescriptionTemplate/body/ThreeColumnFooterBodyComponents'
import { DefaultNextVisitRowComponent } from '../../../prescriptionTemplate/body/DefaultBodyComponents'
import { PMPBodySectionItems } from './styledComponent'
import { PRESCRIPTION_COLORS_MAP } from '../../../inputComponents/common/color-utils'

export default function PrintableNextVisitColumn({
  prescriptionData,
  debug,
  nextVisitInputType,
  nextVisitInputValue,
  nextVisitRef,
  debugHighlightStates,
  styleOverrides
}) {
  const NextVisitColumn = useMemo(() => {
    switch (prescriptionData?.layout) {
      case PRESCRIPTION_LAYOUTS.threeColumnFooter:
      case PRESCRIPTION_LAYOUTS.largerFontLayout:
      case PRESCRIPTION_LAYOUTS.twoColumnFooter:
      case PRESCRIPTION_LAYOUTS.twoColumnWithDisclaimerFooter:
      case PRESCRIPTION_LAYOUTS.twoColumnWithDisclaimerFooterWithDegreesInSeperateLines:
      case PRESCRIPTION_LAYOUTS.listPointersFooter:
        return ThreeColumnNextVisitRowComponent
      default:
        return DefaultNextVisitRowComponent
    }
  }, [prescriptionData?.layout])

  return (
    <PMPBodySectionItems
      ref={nextVisitRef}
      debug={debug}
      debugColor={PRESCRIPTION_COLORS_MAP.nextVisitAndSignature.color}
      debugHighlight={debugHighlightStates?.nextVisit}
    >
      <NextVisitColumn
        nextVisitInputType={nextVisitInputType}
        nextVisitInputValue={nextVisitInputValue}
        prescriptionData={prescriptionData}
        debugHighlightStates={debugHighlightStates}
        styleOverrides={styleOverrides}
      />
    </PMPBodySectionItems>
  )
}

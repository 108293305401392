import { CFormInput, CFormLabel } from '@coreui/react-pro'
import { Delete } from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Switch,
} from '@mui/material'
import { debounce } from 'lodash'
import { useCallback } from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'
import { getCPDataWithParams } from 'src/clinic/services'
import {
  AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS,
  AutomaticListAddingComponent,
  CustomInput,
  CustomSelect,
  CustomTextArea,
  selectTypes,
} from 'src/consumer/components/helperComponents'
import {
  INVESTIGATION_TEST_TYPES_ARRAY,
  INVESTIGATION_TYPES,
  INVESTIGATION_TYPES_ARRAY,
  UNITS_ARRAY,
} from 'src/consumer/constants'
import { giveGenericHandleChangedData } from 'src/consumer/helpers/utilFunctions'
import {
  commonStylesPrescriptionSelect,
  loadOptionsInvestigations,
} from 'src/mentor/components/PrescriptionMakingPage/PrescriptionMakingPageComponents'
// import {
//   ALL_ENTITY_TYPES_FROM_CONSTANTS,
//   INVESTIGATION_TEST_TYPES_ARRAY,
//   INVESTIGATION_TYPES,
//   INVESTIGATION_TYPES_ARRAY,
//   UNITS_ARRAY,
// } from 'src/utilities/constants'
// import {
//   giveGenericHandleChangedData,
//   loadOptionsForAsyncPaginate,
// } from 'src/utilities/generalUtils'
// import { useAppSettingsStore } from 'src/utilities/hooks/useAppSettingsStore'
// import { ActivityItemAsyncSelect } from 'src/views/userFeatures/activityPlan/components/styled-components'

export const RANGE_OBJECT = {
  min: '',
  max: '',
  normalRange: false,
  unit: '',
}

export const INVESTIGATION_OBJECT = {
  apiParam: 'investigation',
  enabled: true,
  deleted: false,
  title: '',
  description: '',
  type: 'pathology',
  investigationType: 'test',
  units: [],
  category: ['biochemistry'],
  // referenceRange: [{ ...RANGE_OBJECT, normalRange: true }],
  referenceRange: [],
}

export default function InvestigationForm({
  initialObject,
  investigation,
  setInvestigation,
  handleChangeObject,
  customOptions,
  clinicId,
  clinicBrandId,
  disabled,
  setTitleValid,
  titleValid,
  setSaving,
}) {
  async function checkTitleValidation(title) {
    const resp = await getCPDataWithParams('/api/secure/doctor/investigation/check-field', {
      field: 'title',
      value: title,
      clinicId: clinicId,
    })
    if (resp.status === 200) {
      setTitleValid(resp.data)
    }
  }
  const debounceCheckTitleValidation = useCallback(
    debounce(async (event, initialValue) => {
      if (initialValue === event.target.value) {
        setTitleValid(true)
        setSaving(false)
        return
      }
      await checkTitleValidation(event.target.value)
      setSaving(false)
    }, 1000),
    [], // Empty dependency array ensures this is only created once
  )

  // const debounceCheckTitleValidation = debounce(async (event) => {
  //   await checkTitleValidation(event.target.value)
  //   setSaving(false)
  // }, 1000)

  function handleChangeReferenceRange(e, idx) {
    setInvestigation((prev) => ({
      ...prev,
      referenceRange: prev?.referenceRange.map((item, index) => {
        return index === idx ? { ...item, ...giveGenericHandleChangedData(e, item) } : item
      }),
    }))
  }

  function handleAddRange() {
    setInvestigation((prev) => ({
      ...prev,
      referenceRange: [
        ...(prev?.referenceRange || []),
        {
          ...RANGE_OBJECT,
          normalRange: (prev?.referenceRange || [])?.length > 0 ? false : true,
          unit: investigation?.units?.[0] || '',
        },
      ],
    }))
  }

  function deleteRange(idx) {
    setInvestigation((prev) => ({
      ...prev,
      referenceRange: prev?.referenceRange.filter((item, index) => index !== idx),
    }))
  }

  return (
    <Grid container spacing={2} sx={{ my: 2 }}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Switch
              size={'small'}
              id="enabled"
              checked={!!investigation?.enabled || false}
              onChange={handleChangeObject}
              disabled={disabled}
            />
          }
          label={'Enabled'}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomInput
          id="title"
          label="Title"
          value={investigation?.title}
          invalid={!titleValid}
          feedbackInvalid="This title is already taken or in invalid format."
          onChange={(e) => {
            setSaving(true)
            debounceCheckTitleValidation(e, initialObject?.title)
            handleChangeObject(e)
          }}
          required
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="altTitles"
          label="Alternative Titles"
          direction={AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.HORIZONTAL}
          maxWidthOfItems="49%"
          value={investigation?.altTitles}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomTextArea
          id="description"
          label="Description"
          value={investigation?.description}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomSelect
          id="investigationType"
          label={'Investigation Type'}
          value={investigation?.investigationType}
          options={INVESTIGATION_TYPES_ARRAY?.sort((a, b) => a?.title.localeCompare(b?.title))}
          onChangeEvent={handleChangeObject}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title}
          disabled={disabled}
          selectType={selectTypes.string}
        />
      </Grid>
      {investigation?.investigationType === INVESTIGATION_TYPES.panel && (
        <Grid item xs={12}>
          <CFormLabel>Tests</CFormLabel>
          <div className="d-flex align-items-center gap-1">
            <div className="w-100">
              <AsyncPaginate
                isMulti
                value={investigation?.testIds}
                placeholder=""
                debounceTimeout={500}
                styles={commonStylesPrescriptionSelect}
                isClearable={true}
                isSearchable={true}
                defaultOptions={false}
                closeMenuOnSelect={false}
                getOptionValue={(option) => option?.value}
                getOptionLabel={(option) => option?.label}
                isDisabled={disabled}
                loadOptions={loadOptionsInvestigations}
                additional={{ page: 1, clinicId: clinicId, clinicBrandId: clinicBrandId }}
                onChange={(selVal) => setInvestigation((prev) => ({ ...prev, testIds: selVal }))}
              />
            </div>
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <CustomSelect
          isMulti
          id="category"
          label="Category"
          value={investigation?.category}
          options={customOptions?.categories
            ?.slice()
            ?.filter((cat) => cat?.categoryType === 'investigation')}
          onChangeEvent={handleChangeObject}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.title || option?.name}
          disabled={disabled}
          selectType={selectTypes.string}
        />
      </Grid>
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="associatedConditions"
          label="Associated Conditions"
          value={investigation?.associatedConditions}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      {investigation?.investigationType !== INVESTIGATION_TYPES.panel && (
        <>
          <Grid item xs={12} md={6}>
            <CustomSelect
              isClearable
              id="type"
              label="Test Type"
              value={investigation?.type}
              options={INVESTIGATION_TEST_TYPES_ARRAY?.sort((a, b) =>
                a?.title.localeCompare(b?.title),
              )}
              onChangeEvent={handleChangeObject}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSelect
              id="sampleType"
              label="Sample Type (Deprecated)"
              value={investigation?.sampleType}
              options={(customOptions?.investigationSample || [])?.sort((a, b) =>
                a?.title.localeCompare(b?.title),
              )}
              onChangeEvent={handleChangeObject}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CustomSelect
              isMulti
              id="sampleTypes"
              label="Sample Types"
              value={investigation?.sampleTypes}
              options={(customOptions?.investigationSample || [])?.sort((a, b) =>
                a?.title.localeCompare(b?.title),
              )}
              onChangeEvent={handleChangeObject}
              getOptionValue={(option) => option?.id}
              getOptionLabel={(option) => option?.title}
              disabled={disabled}
              selectType={selectTypes.string}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <AutomaticListAddingComponent
              id="units"
              label="Units"
              direction={AUTOMATIC_LIST_ADDING_COMPONENT_DIRECTIONS.HORIZONTAL}
              maxWidthOfItems="200px"
              disabled={disabled}
              value={investigation?.units}
              onChange={handleChangeObject}
              InputComponent={UnitInputDropDown}
            />
          </Grid>
          <Grid item xs={12}>
            <CFormLabel>Unit Ranges</CFormLabel>
            {investigation?.referenceRange?.map((rng, idx) => (
              <div
                key={idx}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '10px',
                  gap: '7px',
                  boxShadow: '0 0 5px -1px #000',
                  padding: '15px',
                  borderRadius: '7px',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      size={'small'}
                      id="normalRange"
                      checked={!!rng?.normalRange || false}
                      onChange={(e) => {
                        handleChangeReferenceRange(e, idx, 'referenceRange')
                      }}
                      disabled={disabled}
                    />
                  }
                  label={'Normal Range'}
                  labelPlacement="top"
                />
                <div className="w-100">
                  <CustomInput
                    id="min"
                    label="Min"
                    value={rng?.min}
                    onChange={(e) => handleChangeReferenceRange(e, idx, 'referenceRange')}
                    disabled={disabled}
                  />
                </div>
                <div className="w-100">
                  <CustomInput
                    id="max"
                    label="Max"
                    value={rng?.max}
                    onChange={(e) => handleChangeReferenceRange(e, idx, 'referenceRange')}
                    disabled={disabled}
                  />
                </div>
                <div className="w-100">
                  <CustomSelect
                    id={`unit`}
                    label="Unit"
                    value={rng?.unit}
                    options={
                      investigation?.units?.map((unit) => ({
                        id: unit,
                        title: unit,
                      })) || []
                    }
                    onChangeEvent={(e) => handleChangeReferenceRange(e, idx)}
                    getOptionValue={(option) => option?.id}
                    getOptionLabel={(option) => option?.title}
                    disabled={disabled}
                    selectType={selectTypes.string}
                  />
                </div>
                {!disabled && (
                  <IconButton onClick={() => deleteRange(idx)}>
                    <Delete />
                  </IconButton>
                )}
              </div>
            ))}
          </Grid>
          {!disabled && (
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={handleAddRange}>Add Range</Button>
            </Grid>
          )}
        </>
      )}
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="usage"
          label="Usage"
          value={investigation?.usage}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={12}>
        <AutomaticListAddingComponent
          id="limitations"
          label="Limitations"
          value={investigation?.limitations}
          onChange={handleChangeObject}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  )
}

function UnitInputDropDown({ value, onChange, valueId, disabled }) {
  return (
    <Autocomplete
      inputValue={value}
      disabled={disabled}
      onInputChange={(e, newValue) => onChange({ target: { id: valueId, value: newValue } })}
      freeSolo
      options={UNITS_ARRAY?.map((unit) => unit?.title)}
      renderInput={(params) => (
        <div ref={params.InputProps.ref}>
          <CFormInput disabled={disabled} {...params.inputProps} />
        </div>
      )}
    />
  )
}

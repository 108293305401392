import dayjs from 'dayjs'

export default function ConnectingLines({ date, visitNo, children }) {
  const currentDate = dayjs(date.split(',')[0], 'DD/MM/YYYY')

  // console.log({ date: dayjs(date), propDate: currentDate })

  return (
    <div
      className="d-flex flex-column"
      style={{
        position: 'relative',
        maxWidth: '794px',
        width: '100%',
        margin: '0 auto',
        padding: '8px 0px 24px',
      }}
    >
      <div
      className='hide-on-print-only'
        style={{
          position: 'absolute',
          left: '-100px',
          top: '0',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          zIndex: 0,
        }}
      >
        <div
          className="d-flex flex-column align-items-center justify-content-center"
          style={{
            background: '#c4b9ff',
            width: '75px',
            border: '1px solid #4927ff63',
          }}
        >
          <span
            style={{ width: '100%', textAlign: 'center', background: '#7067bb', color: 'white' }}
          >
            Visit: {visitNo}
          </span>
          <strong>{currentDate.format('D')}</strong> <strong>{currentDate.format('MMM')}</strong>
        </div>
        <div style={{ flexGrow: 1, borderLeft: '2px dotted grey', width: '2px' }}></div>
      </div>
      {/* Visit(
        {visitNo}) */}
      {children}
    </div>
  )
}

import { configureStore, createAction } from '@reduxjs/toolkit'
import { businessLayoutReducer } from './business/reducers/layoutReducer'
import { prescriptionReducer } from './clinic/reducers/prescription/prescriptionReducer'
import { serviceProvidersDataReducer } from './clinic/reducers/serviceProviders/serviceProvidersReducer'
import { genericDataReducer } from './consumer/reducers/genericData/genericDataReducer'
import { personalDataReducer } from './consumer/reducers/personalData/personalDataReducer'
import { bookingRequestReducer } from './mentor/reducers/bookingRequest/bookingRequestReducer'
import { coursesReducer } from './mentor/reducers/courses/coursesReducer'
import { supportTherapistsReducer } from './supportPortal/reducers/therapists/genericDataReducer'
import { setupDataReducer } from './reducers/setupData/setupDataReducer'
import { profileDataReducer } from './reducers/profileData/profileDataReducer'
import { teacherProfileDataReducer } from './reducers/teacherProfileData/teacherProfileDataReducer'

export const resetStores = createAction('resetStores')

export default configureStore({
  reducer: {
    genericDataStore: genericDataReducer,
    personalDataStore: personalDataReducer,
    bookingRequestStore: bookingRequestReducer,
    businessLayoutStore: businessLayoutReducer,
    serviceProvidersDataStore: serviceProvidersDataReducer,
    coursesReducer,
    prescriptionStore: prescriptionReducer,

    supportTherapistsStore: supportTherapistsReducer,

    setupDataStore: setupDataReducer,
    profileDataStore: profileDataReducer,
    teacherProfileDataStore: teacherProfileDataReducer,
  },
  devTools: true,
})

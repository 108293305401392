import { Add } from '@mui/icons-material'
import { Button } from '@mui/material'
import { debounce } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FullPageSearch from 'src/components/FullPageSearch/FullPageSearch'
import PillOptionComponent from 'src/components/FullPageSearch/PillOptionComponent'
import { CustomInput, selectTypes } from 'src/consumer/components/helperComponents'
import { sleep } from 'src/mentor/helpers/utilityFunctions'
import { PMPSectionHeadingTabView } from '../../PrescriptionMakingPageStyles'
import DefaultSelectedPillComponent from './DefaultSelectedPillComponent'
import {
  DoconContextualForm,
  PillOptionsContainer,
  PrescriptionPillViewInputPillContainer,
  TableHeadingContainer
} from './styledComponents'

export default function PrescriptionPillViewInputComponent({
  title,
  value = [],
  getOptionsResponse = (search, loadedOptions, { page, size }) => [],
  debounceTimeout = 200,
  searchPlaceholder,
  onChange,
  customOnRemove,
  customOnAdd,
  onCreate,
  getOptionValue = (optn) => optn?.value,
  getOptionLabel = (optn) => optn?.label,
  valueType = selectTypes.object,
  additional,
  availableOptionsChecker,
  prescriptionViewSettings,
  itemName,
  pillColor,
  highlightedPillColor,
  ContextMenuFormComponent,
  contextMenuAdditionalProps = {},
  contextMenuOpenSide,
  showContextFormOnClickNewCondition = () => true,
  disableContextMenu = false,
  SelectedPillComponent = DefaultSelectedPillComponent,
  useFullPageSearch = false,
}) {
  const searchInputRef = useRef()
  const [searchString, setSearchString] = useState('')
  const [fetchedOptions, setFetchedOptions] = useState([])
  const [pageDetails, setPageDetails] = useState({ page: 1, hasMore: true })
  const availableOptions = useMemo(() => {
    if (!!availableOptionsChecker) {
      return availableOptionsChecker(fetchedOptions, value).slice(0, 20)
    }
    return fetchedOptions
      ?.filter((optn) => !value?.find((val) => getOptionValue(val) === getOptionValue(optn)))
      .slice(0, 20)
  }, [fetchedOptions, value, getOptionValue, availableOptionsChecker])
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
  const [focused, setFocused] = useState(false)

  const [fullPageSearchOpen, setFullPageSearchOpen] = useState(false)

  const selectedItem = value?.[selectedItemIndex]

  function changeHandler(newValue, idx, changedValue) {
    onChange(newValue, idx, changedValue)
    if (idx !== undefined) {
      onChange(newValue, idx, changedValue)
      return
    }
    if (!!value) {
      onChange(newValue, value?.length - 1, changedValue)
      return
    }
    onChange(newValue, 0)
  }

  useEffect(() => {
    const handleFocus = () => {
      if (inputElement) {
        const elementPosition = inputElement.getBoundingClientRect().top + window.scrollY
        window.scrollTo({ top: elementPosition - 70, behavior: 'smooth' })
      }
      setFocused(true)
    }
    const handleBlur = () => {
      setFocused(false)
    }

    const inputElement = searchInputRef.current
    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus)
      inputElement.addEventListener('blur', handleBlur)
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus)
        inputElement.removeEventListener('blur', handleBlur)
      }
    }
  }, [])

  useEffect(() => {
    console.log({ focused })
  }, [focused])

  async function loadOptions(search, addnl) {
    const optionsResponse = await getOptionsResponse(search.trim(), fetchedOptions, addnl)
    setFetchedOptions(optionsResponse.options)
    setPageDetails((prev) => ({
      ...prev,
      hasMore: optionsResponse?.hasMore,
      page: optionsResponse?.additional?.page,
    }))
    return optionsResponse.options
    // setFetchedOptions(optionsResponse.data)
  }

  async function loadFirstIterationOptions() {
    const optionsResponse = await getOptionsResponse(searchString.trim(), fetchedOptions, {
      ...additional,
      page: 1,
      size: 20,
    })
    setFetchedOptions(optionsResponse.options)
    setPageDetails((prev) => ({
      ...prev,
      hasMore: optionsResponse?.hasMore,
      page: 2,
    }))
    return optionsResponse.options
  }

  async function loadMoreOptions(currentValue) {
    if (!pageDetails?.hasMore) {
      return
    }
    if (currentValue?.length / 10 < 1) {
      console.log('currentValue', currentValue)
      return
    }

    const optionsResponse = await getOptionsResponse(searchString.trim(), fetchedOptions, {
      ...additional,
      page: currentValue?.length / 20 + 1,
      size: 10,
    })
    setFetchedOptions((prev) => [...prev, ...optionsResponse.options])
    setPageDetails((prev) => ({
      ...prev,
      hasMore: optionsResponse?.hasMore,
      page: optionsResponse?.additional?.page,
    }))
  }

  function onRemoveOption(optn, idx) {
    if (!!customOnRemove) customOnRemove(optn, idx)

    const newValue = value.filter((val) => getOptionValue(val) !== getOptionValue(optn))
    if (focused) {
      searchInputRef.current.focus({ preventScroll: true })
    }
    setShowContextMenu(false)
    setSelectedItemIndex(-1)
    changeHandler(newValue, idx)
  }

  function onCheckOption(item, focsd) {
    const oldIdx = (value?.length || 1) - 1
    if (!!customOnAdd) customOnAdd(item, oldIdx)

    let newValue = [...value, item]
    let newIdx = ((newValue || [])?.length || 1) - 1

    if (searchString?.length > 0) {
      if (!newValue?.length) {
        loadFirstIterationOptions()
      } else {
        if (value.length % 20 === 0) loadMoreOptions(newValue)
      }
    }
    changeHandler(newValue, value?.length, item)
    if (showContextFormOnClickNewCondition(item)) {
      setShowContextMenu(true)
      setSelectedItemIndex(newIdx)
    }

    if (focsd) {
      searchInputRef.current.focus({ preventScroll: true })
    }
  }

  function editSelectedValue(item, idx) {
    setSelectedItemIndex(idx)
    setShowContextMenu(true)
  }

  function changeSelectedItem(item, idx, editingItem = false) {
    if (!editingItem) {
      setSelectedItemIndex(-1)
      setShowContextMenu(false)
    }
    const updatedValue = value?.map((val, i) => {
      if (i === idx) {
        if (typeof item === 'function') {
          return item(val)
        }
        return item
      }
      return val
    })
    changeHandler(updatedValue, idx, item)
  }

  function onCreateOption(inputValue, e) {
    onCreate(inputValue, e)
  }

  function resetSelectedOptions() {
    onChange([], undefined, null)
  }

  const getOptionsFromSearch = useCallback(
    debounce((searchInput, addnl) => {
      loadOptions(searchInput, addnl)
    }, debounceTimeout),
    [],
  )

  async function closeMenu(delayForResetItem = 10) {
    setShowContextMenu(false)
    await sleep(delayForResetItem)
    setSelectedItemIndex(-1)
  }

  useEffect(() => {
    console.log({ selectedItem, selectedItemIndex, showContextMenu })
  }, [selectedItem, selectedItemIndex, showContextMenu])

  useEffect(() => {
    getOptionsFromSearch(searchString, additional)
  }, [searchString])

  useEffect(() => {
    loadOptions(searchString, additional)
  }, [additional])

  function renderSelectedOptionsList() {
    return (
      <SelectedOptionsList
        values={value}
        searchString={searchString}
        selectedItemIndex={selectedItemIndex}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onRemoveOption={onRemoveOption}
        // focused={focused}
        prescriptionViewSettings={prescriptionViewSettings}
        highlightedPillColor={highlightedPillColor}
        onClickOption={editSelectedValue}
        pillColor={pillColor}
        SelectedPillComponent={SelectedPillComponent}
      />
    )
  }

  function renderOptionsList() {
    return (
      <OptionsList
        options={availableOptions}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        onCheckOption={onCheckOption}
        prescriptionViewSettings={prescriptionViewSettings}
      />
    )
  }

  return (
    <>
      <div className="d-flex flex-column w-100 gap-2">
        <TableHeadingContainer>
          {title && (
            <PMPSectionHeadingTabView>
              {title} {value?.length > 0 ? `(${value?.length})` : ''}
            </PMPSectionHeadingTabView>
          )}
          <SearchRow
            selectedValues={value}
            searchString={searchString}
            setSearchString={setSearchString}
            searchPlaceholder={searchPlaceholder}
            onReset={resetSelectedOptions}
            availableOptions={availableOptions}
            onCreate={onCreateOption}
            searchInputRef={searchInputRef}
            itemName={itemName}
            searchInputClick={useFullPageSearch ? () => setFullPageSearchOpen(true) : undefined}
            disabledSearch={useFullPageSearch}
          />
        </TableHeadingContainer>
        <div className="d-flex flex-column gap-2 w-100">
          {!searchString ? renderSelectedOptionsList() : renderOptionsList()}
          {!!searchString ? renderSelectedOptionsList() : renderOptionsList()}
        </div>
        {!disableContextMenu && (
          <ContextualMenu
            showMenu={showContextMenu}
            selectedItem={selectedItem}
            setSelectedItem={(item, editingItem) =>
              changeSelectedItem(item, selectedItemIndex, editingItem)
            }
            closeMenu={closeMenu}
            InnerComponent={ContextMenuFormComponent}
            innerComponentProps={contextMenuAdditionalProps}
            openSide={contextMenuOpenSide}
          />
        )}
      </div>
      {fullPageSearchOpen && (
        <FullPageSearch
          searchTextPlaceholder={`${searchPlaceholder}`}
          cancelFunction={() => {
            setFullPageSearchOpen(false)
            setSearchString('')
          }}
          searchFunction={async (query) => {
            let searchResultOptions = await loadOptions(query, additional)
            searchResultOptions = (searchResultOptions || [])?.filter((searchedItem) => {
              const searchedItemInAlreadyFetchedItems = (value || [])?.find(
                (item) => getOptionValue(item) === getOptionValue(searchedItem),
              )
              if (searchedItemInAlreadyFetchedItems) {
                return false
              }
              return true
            })
            return searchResultOptions
          }}
          defaultOptionsFunction={loadFirstIterationOptions}
          optionContainerStyles={{
            display: 'flex',
            flexDirection: 'row',
            padding: '16px',
            gap: '16px',
          }}
          OptionComponent={PillOptionComponent}
          optionComponentProps={{
            getOptionValue: (optn) => optn?.id,
            getOptionLabel: (optn) => optn?.label,
            isSelected: () => false,
            pillAccentColor: '#7450AA',
          }}
          optionClickFunction={(data) => {
            onCheckOption(data)
            setSearchString('')
            setFullPageSearchOpen(false)
          }}
          OptionContainerComponent={PillOptionsContainer}
          addNewFunction={(newVal) => {
            setFullPageSearchOpen(false)
            onCreateOption(newVal, null)
          }}
        />
      )}
    </>
  )
}

function SearchRow({
  selectedValues,
  searchString,
  setSearchString,
  onReset,
  searchPlaceholder,
  onCreate,
  availableOptions,
  searchInputRef,
  itemName = 'item',
  searchInputClick,
  disabledSearch,
}) {
  const searchAddButtonRef = useRef()
  const [isTyping, setIsTyping] = useState(false)

  const searchStringIsInOptions = availableOptions?.find(
    (optn) => optn?.label?.toLowerCase() === searchString?.toLowerCase(),
  )

  const showAddBtn = !!searchString && !searchStringIsInOptions && !isTyping

  function handleChangeInputString(e) {
    setSearchString(e.target.value)
    setIsTyping(true)
  }

  useEffect(() => {
    if (isTyping) {
      const timer = setTimeout(() => {
        setIsTyping(false)
      }, 500)

      return () => clearTimeout(timer)
    }
  }, [searchString])

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (
        (e.key === 'Enter' || e.keyCode === 13) &&
        document.activeElement === searchInputRef.current
      ) {
        searchAddButtonRef.current.click()
      }
    }

    var inputElement = searchInputRef?.current
    if (searchInputRef?.current) {
      inputElement.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      inputElement?.removeEventListener('keydown', handleKeyDown)
    }
  }, [searchInputRef])

  return (
    <div
      className="d-flex align-items-center gap-2 w-100"
      onClick={searchInputClick ? searchInputClick : () => {}}
    >
      <CustomInput
        fadedOutPlaceholder
        inputRef={searchInputRef}
        value={searchString}
        placeholder={searchPlaceholder}
        onChange={handleChangeInputString}
        inputAnsMessageContainerStyles={{
          padding: '10px 20px',
          pointerEvents: disabledSearch ? 'none' : '',
        }}
        showClearInputButton
      />
      {showAddBtn && (
        <Button
          ref={searchAddButtonRef}
          variant="outlined"
          endIcon={<Add />}
          onClick={(e) => onCreate(searchString, e)}
          sx={{
            display: !searchString || !!searchStringIsInOptions ? 'none' : 'inline-flex',
            flexShrink: 0,
          }}
        >
          Add
        </Button>
      )}
    </div>
  )
}
// {showAddBtn && (
//   <PrescriptionPillViewInputNewPillContainer
//     ref={searchAddButtonRef}
//     variant="outlined"
//     endIcon={<Add />}
//     onClick={(e) => onCreate(searchString, e)}
//   >
//     Is &quot;{searchString}&quot; a new {itemName}
//   </PrescriptionPillViewInputNewPillContainer>
// )}

function SelectedOptionsList({
  values = [],
  searchString,
  getOptionValue,
  getOptionLabel,
  onRemoveOption,
  prescriptionViewSettings,
  onClickOption,
  selectedItemIndex,
  focused,
  pillColor,
  highlightedPillColor,
  SelectedPillComponent,
}) {
  // TODO: PUT SUPPORT FOR STRING VALUES TOO HERE
  // const [tempFoc, setTempFoc] = useState(false)
  const showComponent = useMemo(() => {
    return !!values && values.length > 0
  }, [values])

  const getLabel = getOptionLabel

  return showComponent ? (
    <PillOptionsContainer>
      {values.map((value, idx) => (
        <SelectedPillComponent
          key={idx}
          value={value}
          idx={idx}
          getOptionValue={getOptionValue}
          getLabel={getLabel}
          onClickOption={onClickOption}
          onRemoveOption={onRemoveOption}
          selectedItemIndex={selectedItemIndex}
          pillColor={pillColor}
          highlightedPillColor={highlightedPillColor}
        />
      ))}
    </PillOptionsContainer>
  ) : (
    <></>
  )
}

function OptionsList({ options = [], getOptionValue, getOptionLabel, onCheckOption, focused }) {
  // TODO: PUT SUPPORT FOR STRING VALUES TOO HERE
  const [tempFoc, setTempFoc] = useState(false)

  const getLabel = useMemo(() => {
    return getOptionLabel
  }, [getOptionLabel])
  return (
    <PillOptionsContainer>
      {options.map((option, idx) => (
        <PrescriptionPillViewInputPillContainer
          key={`${idx}-${getOptionValue(option)}`}
          onMouseDown={() => {
            setTempFoc(focused || false)
          }}
          onClick={() => {
            onCheckOption(option, tempFoc)
          }}
        >
          {getLabel(option)}
        </PrescriptionPillViewInputPillContainer>
      ))}
    </PillOptionsContainer>
  )
}

function ContextualMenu({
  openSide = 'right',
  innerComponentProps = {},
  InnerComponent,
  showMenu,
  selectedItem,
  setSelectedItem,
  closeMenu,
}) {
  const Comp = useMemo(() => InnerComponent || 'div', [InnerComponent])
  const slideTransitionTimeInMs = 500
  function handleCloseMenu() {
    closeMenu(slideTransitionTimeInMs)
  }

  return (
    <DoconContextualForm
      slideTransitionTiming={slideTransitionTimeInMs}
      open={showMenu}
      side={openSide}
    >
      {showMenu ? (
        <Comp
          item={selectedItem}
          setItem={(evePrev) => setSelectedItem(evePrev, true)}
          saveItem={setSelectedItem}
          closeMenu={handleCloseMenu}
          {...innerComponentProps}
        />
      ) : (
        <></>
      )}
    </DoconContextualForm>
  )
}

import { useEffect, useState } from 'react'
import { DEFAULT_PRINT_SETTINGS } from 'src/consumer/constants'
import { isValidNumber } from 'src/consumer/helpers/utilFunctions'
import {
  getInitialDataForSettingDaysOfNextVisit,
  getInitialDataForSettingExistingDrugAdvice,
  getInitialDataForSettingExistingSelectedAdvice,
  getInitialDataForSettingExistingSelectedComplaints,
  getInitialDataForSettingExistingSelectedDiagnosis,
  getInitialDataForSettingExistingSelectedInstructions,
  getInitialDataForSettingExistingSelectedInvestigations,
  getInitialDataForSettingExistingSelectedObservations,
  getInitialDataForSettingExistingSelectedTherapies,
} from '../PrescriptionMakingPageComponents'
import PrescriptionViewComponent from '../printComponents/PrescriptionViewComponent'
import A4PrintSettings from '../printComponents/printSettings/A4PrintSettings'

export default function ViewOnlyPrescriptionV2({
  prescriptionData,
  prescriptionViewSettings,
  doctorEmrSections,
  allVitalsOfThisPatient,
  useOldUI = false,
  forAssistant = false,
  oldUIDrugSubSections,
}) {
  const [printSettings, setPrintSettings] = useState(DEFAULT_PRINT_SETTINGS)

  function setAndStoreRelevantPrintSettingsInLocalStorage(prevOrObj) {
    setPrintSettings((prev) => {
      let finalFormedObject = prev

      // Determine the final formed object
      if (typeof prevOrObj === 'function') {
        finalFormedObject = prevOrObj(prev)
      } else {
        finalFormedObject = prevOrObj
      }

      // Extract relevant fields without destructuring
      const printSettings = {}
      printSettings.topMargin = finalFormedObject?.topMargin ?? 0
      printSettings.bottomMargin = finalFormedObject?.bottomMargin ?? 0
      printSettings.leftMargin = finalFormedObject?.leftMargin ?? 0
      printSettings.rightMargin = finalFormedObject?.rightMargin ?? 0

      // Store in localStorage
      localStorage.setItem('printSettings', JSON.stringify(printSettings))

      // Return the final formed object
      return finalFormedObject
    })
  }

  function setInitialPrintSettings() {
    const printSettingsFromLocalStorage = JSON.parse(localStorage.getItem('printSettings')) || {}

    const fHeight = isValidNumber(prescriptionData?.footer?.customFooterHeight)
      ? prescriptionData?.footer?.customFooterHeight
      : prescriptionViewSettings?.paperPrintSettings?.footerSettings?.footerHeight

    console.log({
      phHeight: prescriptionData?.header,
      pvHeight: prescriptionViewSettings?.paperPrintSettings?.headerSettings?.headerHeight,
    })

    const hHeight = isValidNumber(prescriptionData?.header?.customHeaderHeight)
      ? prescriptionData?.header?.customHeaderHeight
      : prescriptionViewSettings?.paperPrintSettings?.headerSettings?.headerHeight

    const lSbarWidth = isValidNumber(prescriptionData?.leftSidebar?.width)
      ? prescriptionData?.leftSidebar?.width
      : prescriptionViewSettings?.paperPrintSettings?.leftSidebarSettings?.width

    const rSbarWidth = isValidNumber(prescriptionData?.rightSidebar?.width)
      ? prescriptionData?.rightSidebar?.width
      : prescriptionViewSettings?.paperPrintSettings?.rightSidebarSettings?.width

    const topMargin = isValidNumber(prescriptionData?.margins?.marginTop)
      ? prescriptionData?.margins?.marginTop
      : prescriptionViewSettings?.paperPrintSettings?.marginTop
    const bottomMargin = isValidNumber(prescriptionData?.margins?.marginBottom)
      ? prescriptionData?.margins?.marginBottom
      : prescriptionViewSettings?.paperPrintSettings?.marginBottom
    const leftMargin = isValidNumber(prescriptionData?.margins?.marginLeft)
      ? prescriptionData?.margins?.marginLeft
      : prescriptionViewSettings?.paperPrintSettings?.marginLeft
    const rightMargin = isValidNumber(prescriptionData?.margins?.marginRight)
      ? prescriptionData?.margins?.marginRight
      : prescriptionViewSettings?.paperPrintSettings?.marginRight

    const printHeaderType =
      prescriptionData?.header?.customHeaderType ||
      prescriptionViewSettings?.paperPrintSettings?.headerSettings?.headerStyle

    const printFooterType =
      prescriptionData?.footer?.customFooterType ||
      prescriptionViewSettings?.paperPrintSettings?.footerSettings?.showBlankFooter

    setPrintSettings((prev) => ({
      ...prev,

      useOldPrintMethod:
        useOldUI || !prescriptionViewSettings?.paperPrintSettings?.useNewPrintMethod,

      topMargin: printSettingsFromLocalStorage?.topMargin ?? topMargin ?? 0,
      bottomMargin: printSettingsFromLocalStorage?.bottomMargin ?? bottomMargin ?? 0,
      leftMargin: printSettingsFromLocalStorage?.leftMargin ?? leftMargin ?? 0,
      rightMargin: printSettingsFromLocalStorage?.rightMargin ?? rightMargin ?? 0,

      headerHeight: hHeight ?? 0,
      pdfHeaderHeight:
        prescriptionData?.header?.customHeaderHeight ??
        prescriptionViewSettings?.pdfPrintSettings?.headerSettings?.headerHeight,
      pdfHeaderType: prescriptionViewSettings?.pdfPrintSettings?.headerSettings?.headerStyle,
      printHeaderType: printHeaderType,
      blankHeader: false,

      leftSidebarWidth: lSbarWidth ?? 0,
      rightSidebarWidth: rSbarWidth ?? 0,

      footerHeight: fHeight ?? 0,
      printFooterType: printFooterType,
      blankFooter: false,
      // blankFooter: prescriptionViewSettings?.paperPrintSettings?.footerSettings?.showBlankFooter,
    }))
  }

  function setup() {
    setInitialPrintSettings()
  }

  useEffect(() => {
    setup()
  }, [])

  // console.log({
  //   // requestedMntr: prescriptionData?.prescriptionData?.referredToDoctorName,
  //   // testsRequestedBy: prescriptionData?.prescriptionData?.finishRequestedTestsBy,
  //   // allVitalsOfThisPatient,
  //   // prescriptionData,
  //   prescriptionViewSettings,
  // })

  return (
    <div className="d-flex flex-column gap-2">
      {!printSettings?.useOldPrintMethod && (
        <div className="mr-2 hide-on-print-only">
          <div className="mx-3 hide-on-print-only" style={{ flex: 1 }}>
            <A4PrintSettings
              printSettings={printSettings}
              setPrintSettings={setAndStoreRelevantPrintSettingsInLocalStorage}
            />
          </div>
        </div>
      )}
      <PrescriptionViewComponent
        prescriptionTemplateData={prescriptionData}
        showInUI
        selectedObservations={getInitialDataForSettingExistingSelectedObservations(
          prescriptionData?.prescriptionData?.symptomsAndObservations,
        )}
        selectedComplaints={getInitialDataForSettingExistingSelectedComplaints(
          prescriptionData?.prescriptionData?.complaints,
        )}
        selectedDiagnosis={getInitialDataForSettingExistingSelectedDiagnosis(
          prescriptionData?.prescriptionData?.diagnosis,
        )}
        drugsAdvice={getInitialDataForSettingExistingDrugAdvice(
          prescriptionData?.prescriptionData?.drugInfoList,
        )}
        prescriptionData={prescriptionData}
        selectedAdvice={getInitialDataForSettingExistingSelectedAdvice(
          prescriptionData?.prescriptionData?.guidanceAndAdvices,
        )}
        selectedTherapies={getInitialDataForSettingExistingSelectedTherapies(
          prescriptionData?.prescriptionData?.therapiesNeeded,
        )}
        nextVisitInputType="days"
        nextVisitInputValue={getInitialDataForSettingDaysOfNextVisit(
          prescriptionData?.prescriptionData?.nextVisitDate,
          prescriptionData?.prescriptionData?.createdTime,
        )}
        selectedInvestigations={getInitialDataForSettingExistingSelectedInvestigations(
          prescriptionData?.prescriptionData?.investigations,
        )}
        selectedInstructions={getInitialDataForSettingExistingSelectedInstructions(
          prescriptionData?.prescriptionData?.instructions,
        )}
        useOldUI={useOldUI ? useOldUI : printSettings?.useOldPrintMethod}
        finalRequestedTestsBy={prescriptionData?.prescriptionData?.finishRequestedTestsBy}
        referredToDoctorName={prescriptionData?.prescriptionData?.referredToDoctorName}
        vital={allVitalsOfThisPatient?.find(
          (item) => item?.bookedEventId === prescriptionData?.prescriptionData?.bookedEventId,
        )}
        value=""
        emrSections={doctorEmrSections}
        oldUIDrugSubSections={oldUIDrugSubSections}
        setValue={() => null}
        setPropValue={() => null}
        prescriptionViewSettings={prescriptionViewSettings}
        honorPropValue={false}
        printSettings={printSettings}
        refreshDimensionsDependencies={[printSettings, prescriptionData, prescriptionViewSettings]}
      />
    </div>
  )
}

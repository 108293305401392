import { styled as muiStyled } from '@mui/material'

export const A4PageMeasureComponent = muiStyled('div')(
  ({ theme }) => `
    @media print {
        display: none;
        }
`,
)

export const A4PageMeasureComponentWrapperContainer = muiStyled('div')(
  ({ theme, margin = 10, heightOfPage, a4Width = 794 }) => `
    padding: ${margin}px;
    height: ${heightOfPage}px;
    display:flex;
    flex-direction:column;
    align-items:center;
    width: 100%;
    max-width: ${a4Width}px;
    box-shadow: rgb(0 0 0 / 77%) 0px 0px 14px 2px;
    @media print {
    padding: 0px;
    border: none;
    box-shadow: none;
    // height: unset;
    }
`,
)

// @page {
//   size: A4 portrait; /* Or landscape */
//   margin: 1cm;
// }

// margin-top: ${noMargin ? 0 : 30}px;
export const A4PageContainer = muiStyled('div')(
  ({ theme, margin, divWidth }) => `
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media print {
    margin: 0px;
    padding: ${margin}px;
    width: ${divWidth}px;
    align-items: start;  
}
`,
)

export const A4CustomMarginsContainer = muiStyled('div')(
  ({ theme }) => `
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
  width: 400px;
`,
)

export const A4WrapperContainer = muiStyled('div')(
  ({ theme, showInUI }) => `
  position: relative;
  width: 100%;
`,
)

export const InchesPlaceHolderDivStyles = muiStyled('div')(
  ({ theme }) => `
    display: flex;
    align-items:center;
    justify-content:center;
    border-left: 3px solid #4e2ff224;
    height: 100%;
    padding: 0 10px;
    background: #4e2ff22b;
`,
)

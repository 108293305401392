import dayjs from 'dayjs'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { currency_list } from 'src/consumer/constants'
import { getDataWithParams } from 'src/consumer/services/profileService'
import store, { resetStores } from 'src/store'

export const logoutHandlerBusiness = () => {
  let lanCode = sessionStorage.getItem('languageCode')
  sessionStorage.clear()
  localStorage.removeItem('token')
  localStorage.removeItem('authorities')
  localStorage.removeItem('FCMToken')
  localStorage.removeItem('submittedFCMTokenForThisUser')
  sessionStorage.setItem(
    'languageCode',
    lanCode ? lanCode : navigator?.language || navigator?.userLanguage.split('-')[0],
  )
  store.dispatch(resetStores())
}

export const getBodyForSavingCompanyProfileBusiness = (title, seats) => {
  return {
    apiParam: 'company-profile',
    title: title,
    companyName: title,
    desiredSeats: parseInt(seats),
  }
}

export const getPriceStringBusinessPlan = (currency, price) => {
  if (parseFloat(price)) {
    const symbol = currency_list.find((item) => item.code === currency?.toLowerCase())?.symbol
    return `${symbol} ${price} PEPM`
  } else {
    return ''
  }
}

export const prepareOneOnOneOptionsBusiness = (planData) => {
  // ensure that best value always comes
  const options = []
  const liveSessionMin = planData?.liveSessionsPSPMMinimum
  const liveSessionsPSPMMultiple = planData?.liveSessionsPSPMMultiple
  const liveSessionsPSPMMultipleDiscount = planData?.liveSessionsPSPMMultipleDiscount
  const liveSessionsPSPMPrice = planData?.liveSessionsPSPMPrice

  options?.push({
    id: liveSessionMin,
    title: `${liveSessionMin} monthly Live Session${liveSessionMin > 1 ? 's' : ''}`,
    pricePEPM: (
      (liveSessionMin * liveSessionsPSPMPrice) /
      (liveSessionMin === 0 ? 1 : liveSessionMin)
    )?.toFixed(2),
    bestValue: false,
  })

  options?.push({
    id: liveSessionMin + liveSessionsPSPMMultiple,
    title: `${liveSessionMin + liveSessionsPSPMMultiple} monthly Live Session${
      liveSessionMin + liveSessionsPSPMMultiple > 1 ? 's' : ''
    }`,
    pricePEPM: (
      ((liveSessionMin + liveSessionsPSPMMultiple) *
        liveSessionsPSPMPrice *
        ((100 - liveSessionsPSPMMultipleDiscount) / 100)) /
      (liveSessionMin + liveSessionsPSPMMultiple === 0
        ? 1
        : liveSessionMin + liveSessionsPSPMMultiple)
    )?.toFixed(2),
    bestValue: false,
  })

  options?.push({
    id: liveSessionMin + 2 * liveSessionsPSPMMultiple,
    title: `${liveSessionMin + 2 * liveSessionsPSPMMultiple} monthly Live Session${
      liveSessionMin + 2 * liveSessionsPSPMMultiple > 1 ? 's' : ''
    }`,
    pricePEPM: (
      ((liveSessionMin + 2 * liveSessionsPSPMMultiple) *
        liveSessionsPSPMPrice *
        ((100 - 2 * liveSessionsPSPMMultipleDiscount) / 100)) /
      (liveSessionMin + 2 * liveSessionsPSPMMultiple === 0
        ? 1
        : liveSessionMin + 2 * liveSessionsPSPMMultiple)
    )?.toFixed(2),
    bestValue: true,
  })
  return options
}

export const prepareGroupSessionOptionsBusiness = (planData, numberOfEmployees) => {
  // ensure that best value always comes
  const options = []
  const groupSessionMinimum = planData?.groupSessionMinimum
  const groupSessionMultiple = planData?.groupSessionMultiple
  const groupSessionMultipleDiscount = planData?.groupSessionMultipleDiscount
  const groupSessionUnitPrice = planData?.groupSessionUnitPrice

  options?.push({
    id: groupSessionMinimum,
    title: `${groupSessionMinimum} monthly Group Session${groupSessionMinimum > 1 ? 's' : ''}`,
    pricePEPM: (
      (groupSessionMinimum * groupSessionUnitPrice) /
      ((groupSessionMinimum === 0 ? 1 : groupSessionMinimum) * numberOfEmployees)
    )?.toFixed(2),
    bestValue: false,
  })

  options?.push({
    id: groupSessionMinimum + groupSessionMultiple,
    title: `${groupSessionMinimum + groupSessionMultiple} monthly Group Session${
      groupSessionMinimum + groupSessionMultiple > 1 ? 's' : ''
    }`,
    pricePEPM: (
      (groupSessionUnitPrice * ((100 - groupSessionMultipleDiscount) / 100)) /
      numberOfEmployees
    )?.toFixed(2),
    bestValue: false,
  })

  options?.push({
    id: groupSessionMinimum + 2 * groupSessionMultiple,
    title: `${groupSessionMinimum + 2 * groupSessionMultiple} monthly Group Session${
      groupSessionMinimum + 2 * groupSessionMultiple > 1 ? 's' : ''
    }`,
    pricePEPM: (
      (groupSessionUnitPrice * ((100 - 2 * groupSessionMultipleDiscount) / 100)) /
      numberOfEmployees
    )?.toFixed(2),
    bestValue: true,
  })

  return options
}

export const getTotalPricePEPM = (
  numberOfOneOnOneSessions,
  numberOfGroupSessions,
  planData,
  numberOfEmployees,
) => {
  const appAccessPrice = planData?.finalPrice
  const oneOnOneSessionsPrice = prepareOneOnOneOptionsBusiness(planData)?.find(
    (item) => parseInt(item?.id) === parseInt(numberOfOneOnOneSessions),
  )?.pricePEPM
  const groupSessionsPrice = prepareGroupSessionOptionsBusiness(planData, numberOfEmployees)?.find(
    (item) => parseInt(item?.id) === parseInt(numberOfGroupSessions),
  )?.pricePEPM
  return (
    parseFloat(appAccessPrice) +
    parseFloat(oneOnOneSessionsPrice) +
    parseFloat(groupSessionsPrice)
  )?.toFixed(2)
}

export function checkIfMobileIsVerifiedInForm(profileData, phoneValue) {
  const formattedPhone = phoneValue?.startsWith('+') ? phoneValue : `+${phoneValue}`
  const valid = isValidPhoneNumber(formattedPhone || '')
  if (phoneValue !== profileData?.mobile) {
    return !valid
  } else {
    return profileData?.mobileVerified
  }
}

export function showCaptchaUI(verifyClick, foundOtp) {
  if (verifyClick) {
    if (!!foundOtp) {
      return false
    }
    return true
  } else {
    return false
  }
}

export function showOtpUI(foundOtp, otpResponse) {
  if (!!foundOtp) {
    return true
  }
  return false
}

export async function getOtpAfterCaptcha(value, phone) {
  if (!value) {
    // Reset OTP when reCAPTCHA is reset
    return { captcha: !!value, otp: '', response: {} }
  } else {
    // Remove country code and "+" sign from phone number
    const phoneNumberWithoutCountryCode = phone.replace(/^\+/, '').substring(2)
    const otpResponse = await getDataWithParams('/api/generate/otp', {
      phone: phoneNumberWithoutCountryCode,
    })
    console.log(otpResponse)
    return {
      captcha: !!value,
      otp: undefined,
      response: otpResponse,
    }
  }
}

export function getDayJsDateFromMilliseconds(milliseconds) {
  const inSeconds = milliseconds / 1000

  const seconds = Math.floor(inSeconds % 60)
  const minutes = Math.floor((inSeconds / 60) % 60)
  console.log({
    milliseconds,
    dJsDate: dayjs(
      `2022-04-17T15:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,
    ),
  })
  return dayjs(
    `2022-04-17T12:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`,
  )
}

export function capitalizeSentence(sentence) {
  return sentence?.charAt(0).toUpperCase() + sentence?.slice(1)
}

export function getValueFromDotOperatorString(obj, key) {
  return key.split('.').reduce((o, i) => o?.[i], obj)
}

function getUpdatedValueFromListOfKeys(obj, keys, value) {
  if (keys.length === 1) {
    return {
      ...obj,
      [keys[0]]: value,
    }
  }
  const key = keys.shift()
  return {
    ...obj,
    [key]: getUpdatedValueFromListOfKeys(obj[key], keys, value),
  }
}

export function setValueFromDotOperatorString(obj, key, value) {
  const objectKeys = key.split('.')
  return getUpdatedValueFromListOfKeys(obj, objectKeys, value)
}

export function mobileContainsOnlyCountryCode(mobileString) {
  if (!mobileString || typeof mobileString !== 'string') {
    return false // If input is null, undefined, or not a string, return false
  }

  // Regular expression to match a string containing only a '+' followed by digits
  const countryCodeRegex = /^(\+?\d{1,3}|\d{1,4})$/

  return countryCodeRegex.test(mobileString)
}

export function isMobileEmpty(mobileString) {
  return mobileContainsOnlyCountryCode(mobileString) || !mobileString
}

// function getTimezoneFromLatLong(latitude, longitude) {
//   try {
//     const timezone = tzLookup(latitude, longitude);
//     const now = moment().tz(timezone);
//     return now.format('Z'); // Format as "+5:30"
//   } catch (error) {
//     console.error("Error in getting timezone:", error);
//     return null;
//   }
// }

import { useEffect, useState } from 'react'
import { GENDER_LIST_ONBOARDING } from 'src/consumer/constants'
import { getAgeFromDobWithTime } from 'src/consumer/helpers/profilePageHelper'
import { getImageLink } from 'src/mentor/helpers/utilityFunctions'
import { getDateOfPrescription } from '../../../PrescriptionMakingPageComponents'
import { PMPHeaderDetails } from '../../../PrescriptionMakingPageStyles'
import PrescriptionKVItem from '../../common/PrescriptionKVItem'

export default function DefaultPatientDetails({
  prescriptionData,
  prescriptionViewSettings,
  leaveSpace,
  debug,
}) {
  return (
    <>
      <PMPHeaderDetails
        noColor={true}
        debug={debug}
        style={{
          borderTop: '1px solid black',
        }}
        className="d-flex align-items-center justify-content-between"
      >
        <div>
          <PrescriptionKVItem
            itemKey="Patient"
            valueItemProps={{ style: { textTransform: 'capitalize' } }}
            value={
              <>
                {prescriptionData?.patientPrefill?.name}{' '}
                {prescriptionData?.patientPrefill?.patientNumber &&
                  `(${prescriptionData?.patientPrefill?.patientNumber})`}
              </>
            }
          />
          <br />
          <PrescriptionKVItem
            itemKey="Age/Sex"
            value={
              <>
                {getAgeFromDobWithTime(prescriptionData?.patientPrefill?.vital?.dateOfBirth)}/
                {
                  GENDER_LIST_ONBOARDING?.find(
                    (item) => item.value === prescriptionData?.patientPrefill?.vital?.gender,
                  )?.label
                }
              </>
            }
          />
          <br />
          <PrescriptionKVItem
            itemKey="Date"
            value={<>{getDateOfPrescription(prescriptionData, false)}</>}
          />
          {prescriptionViewSettings?.showPatientPhoneOnPrescription &&
            prescriptionData?.patientPrefill?.mobile && (
              <>
                <br />
                <PrescriptionKVItem
                  itemKey="Mobile"
                  value={<>{prescriptionData?.patientPrefill?.mobile}</>}
                />
              </>
            )}
          {prescriptionViewSettings?.showPatientAddressOnPrescription &&
            prescriptionData?.patientPrefill?.location?.city && (
              <>
                <br />
                <PrescriptionKVItem
                  itemKey="City"
                  value={<>{prescriptionData?.patientPrefill?.location?.city}</>}
                />
              </>
            )}
        </div>
        {prescriptionData?.prescriptionData?.qrCodeImageId && (
          <QRCodeImage imageId={prescriptionData.prescriptionData.qrCodeImageId} />
        )}
      </PMPHeaderDetails>
    </>
  )
}

const QRCodeImage = ({ imageId }) => {
  const [imageUrl, setImageUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const maxRetries = 2
  const interval = 1000 // 1 second

  const fetchImageWithPolling = async (id) => {
    const url = getImageLink(id)
    let attempts = 0

    while (attempts < maxRetries) {
      try {
        const response = await fetch(url)

        if (response.ok) {
          const imageBlob = await response.blob()
          const imageObjectURL = URL.createObjectURL(imageBlob)
          setImageUrl(imageObjectURL)
          setLoading(false)
          return
        } else {
          console.log(`Attempt ${attempts + 1}: Image not available (Status: ${response.status})`)
        }
      } catch (error) {
        console.error(`Attempt ${attempts + 1}: Error fetching image - ${error.message}`)
      }

      attempts++
      await new Promise((resolve) => setTimeout(resolve, interval))
    }

    setLoading(false)
    console.error('Image not available after maximum retries')
  }

  useEffect(() => {
    if (imageId) {
      fetchImageWithPolling(imageId)
    }
  }, [imageId])

  return (
    <>
      {loading ? (
        <div>Loading...</div>
      ) : (
        imageUrl && (
          <div className="d-flex flex-column align-items-center">
            <img src={imageUrl} alt="prescription-qr" height="60px" width="60px" />
            <span style={{ textAlign: 'center' }}>View Online</span>
          </div>
        )
      )}
    </>
  )
}

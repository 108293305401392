import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import {
  REDUX_STATUS_FAILED,
  REDUX_STATUS_IDLE,
  REDUX_STATUS_LOADING,
  REDUX_STATUS_SUCCESS,
} from 'src/consumer/constants'
import { logoutHandler } from 'src/consumer/services/loginLogoutHandlers'
import { resetStores } from 'src/store'
import { getSetupData } from './services/setupDataThunk'

const initialState = {
  setupData: {
    data: null,
    status: REDUX_STATUS_IDLE,
    error: null,
  },
}

const setupDataSlice = createSlice({
  name: 'setupDataStore',
  initialState: initialState,

  extraReducers: (builder) => {
    builder
      .addCase(getSetupData.pending, (state) => {
        state.setupData.status = REDUX_STATUS_LOADING
      })
      .addCase(getSetupData.fulfilled, (state, action) => {
        state.setupData.status = REDUX_STATUS_SUCCESS
        state.setupData.data = action.payload
      })
      .addCase(getSetupData.rejected, (state, action) => {
        state.setupData.status = REDUX_STATUS_FAILED
        state.setupData.error = action.payload
        logoutHandler()
        toast.error(action.payload)
      })
      .addCase(resetStores, () => initialState)
  },
})

// export const {} = setupDataSlice.actions
export const setupDataReducer = setupDataSlice.reducer

function selectSetupDataStore(state) {
  return state.setupDataStore
}

export const selectSetupData = (state) => {
  return selectSetupDataStore(state).setupData
}

import { Close, KeyboardReturnOutlined } from '@mui/icons-material'
import {
  PrescriptionPillViewInputPillContainer,
  PrescriptionRemoveIconButton,
} from 'src/mentor/components/PrescriptionMakingPage/inputComponents/common/styledComponents'

export default function PillOptionComponent({
  optionData,
  getOptionValue,
  getOptionLabel,
  isSelected,
  onClickOption,
  pillAccentColor,
  isSingleItemAndSupportsSelectOnEnterKeydown,
}) {
  const selected = isSelected(optionData)
  return (
    <PrescriptionPillViewInputPillContainer
      onClick={() => {
        onClickOption(optionData)
      }}
      style={{
        border: `1px solid ${pillAccentColor}`,
        background: selected ? pillAccentColor : 'rgba(116, 80, 170, 0.1)',
        color: selected ? '#fff' : pillAccentColor,
      }}
      selected={selected}
    >
      <span>{getOptionLabel(optionData)}</span>{' '}
      {selected && (
        <PrescriptionRemoveIconButton size="small">
          <Close sx={{ fontSize: '1.3rem' }} />
        </PrescriptionRemoveIconButton>
      )}
      {isSingleItemAndSupportsSelectOnEnterKeydown && <KeyboardReturnOutlined />}
    </PrescriptionPillViewInputPillContainer>
  )
}

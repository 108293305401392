import { createSlice } from '@reduxjs/toolkit'
import {
  REDUX_STATUS_FAILED,
  REDUX_STATUS_IDLE,
  REDUX_STATUS_LOADING,
  REDUX_STATUS_SUCCESS,
} from 'src/consumer/constants'
import { resetStores } from 'src/store'
import { getProfileData } from './services/profileData'

const initialState = {
  profileData: {
    data: null,
    status: REDUX_STATUS_IDLE,
    error: null,
  },
}

const profileDataSlice = createSlice({
  name: 'profileDataStore',
  initialState,
  reducers: {
    setProfileData(state, action) {
      return {
        ...state,
        profileData: {
          data: action.payload,
          status: REDUX_STATUS_SUCCESS,
          error: null,
        },
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProfileData.pending, (state) => {
        state.profileData.status = REDUX_STATUS_LOADING
      })
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.profileData.status = REDUX_STATUS_SUCCESS
        state.profileData.data = action.payload
      })
      .addCase(getProfileData.rejected, (state, action) => {
        state.profileData.status = REDUX_STATUS_FAILED
        state.profileData.error = action.payload
      })
      .addCase(resetStores, () => initialState)
  },
})

export const profileDataReducer = profileDataSlice.reducer

export const { setProfileData } = profileDataSlice.actions

export const selectProfileData = (state) => state.profileDataStore.profileData

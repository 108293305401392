import dayjs from 'dayjs'
import { TESTS_TYPES_MAP } from '../../../inputComponents/investigation/components/InvestigationDateComponent'

function isValidDiagnosis(diagnosis, isUsedForPrinting = false) {
  const title = diagnosis?.title || diagnosis?.label
  const val = diagnosis?.id || diagnosis?.value

  if (!title || title?.trim() === '') {
    return false
  }
  if (!val || val?.trim() === '') {
    return false
  }
  return true
}

export function getValidDiagnosisArrayForPrescription(diagnosisList) {
  return diagnosisList?.filter((d) => isValidDiagnosis(d, true))
}

export function preparePrescriptionDiagnosisLine(diagnosisItem) {
  let line = diagnosisItem?.label
  if (!!diagnosisItem?.duration) {
    line += ` for ${diagnosisItem?.duration}`
  }
  if (!!diagnosisItem?.date && dayjs(diagnosisItem?.date).isValid()) {
    line += `, Since: ${dayjs(diagnosisItem?.date).format('DD-MMM-YYYY')}`
  }

  return line
}

export function prepareFinalRequestedTestsSentence(finalRequestedTestsBy) {
  if (!finalRequestedTestsBy?.valueType) {
    return null
  }
  let sentence = ''

  if (finalRequestedTestsBy?.valueType === TESTS_TYPES_MAP.calendar.id) {
    sentence = sentence + dayjs(finalRequestedTestsBy?.calendarValue).format('DD-MM-YYYY')
  } else {
    if (
      finalRequestedTestsBy?.valueType === TESTS_TYPES_MAP.days.id ||
      finalRequestedTestsBy?.valueType === TESTS_TYPES_MAP.months.id ||
      finalRequestedTestsBy?.valueType === TESTS_TYPES_MAP.weeks.id
    ) {
      if (!finalRequestedTestsBy?.value) {
        return null
      }
      sentence = sentence + 'after '
    }
    sentence = sentence + finalRequestedTestsBy?.value
    if (sentence !== '') {
      sentence = sentence + ' '
    }
    sentence = sentence + TESTS_TYPES_MAP?.[finalRequestedTestsBy?.valueType]?.title
  }

  return sentence
}

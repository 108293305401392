import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'
import { getCPDataWithParams } from 'src/clinic/services'
import { isCommonResponseSuccessfulV2 } from 'src/mentor/helpers/utilityFunctions'
dayjs.extend(utc)
dayjs.extend(duration)

export async function getPatientVitalForBookedEvent(
  patientId,
  clinicId,
  bookedEventId,
  single = true,
) {
  const response = await getCPDataWithParams(`/api/secure/patient/vitals`, {
    patientId,
    clinicId,
    bookedEventId,
  })
  if (
    response?.status === 200 &&
    isCommonResponseSuccessfulV2(response) &&
    Array.isArray(response?.data?.data?.vital)
  ) {
    if (single) {
      return response?.data?.data?.vital?.[0]
    }
    return response?.data?.data?.vital
  }
  return null
}

export function prepareWaitingTimeStringFromBookedSlot(bookedSlot) {
  if (
    !bookedSlot ||
    !bookedSlot.createdTime ||
    !bookedSlot.sessionStatus ||
    bookedSlot?.markedCompleted
  ) {
    return ''
  }

  let bookedDateTime = dayjs.utc(bookedSlot.createdTime).local()
  let now = dayjs()

  // If slot is created on a previous day, return empty string
  const startOfDayNow = now.startOf('day')
  const endOfDayNow = now.endOf('day')

  // Check if bookedDateTime is NOT between startOfDayNow and endOfDayNow
  if (!bookedDateTime.isBetween(startOfDayNow, endOfDayNow, null, '[]')) {
    return ''
  }

  // Case: Slot is in in-progress
  if (bookedSlot.sessionStatus === 'in-progress') {
    if (bookedDateTime.isBefore(now)) {
      return 'Booking not completed'
    } else {
      return `Booked: ${bookedDateTime.format('h:mm a')}`
    }
  }

  // Case: Slot is in not-started
  if (bookedSlot.sessionStatus === 'not-started') {
    if (bookedDateTime.isAfter(now)) {
      return `Booked: ${bookedDateTime.format('h:mm a')}`
    } else {
      let waitingTime = dayjs.duration(now.diff(bookedDateTime))
      let formattedWaitingTime = `${waitingTime.hours()}h ${waitingTime.minutes()}m`
      return `Waiting For: ${formattedWaitingTime}`
    }
  }

  // If none of the cases match, return an empty string
  return ''
}
